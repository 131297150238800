(function () {
    'use strict';

    angular
        .module('core.agencia-bancaria')
        .service('core.AgenciaBancariaCadModalService', agenciaBancariaCadModalService);

    agenciaBancariaCadModalService.$inject = ['core.AcoesCadastrosModalService'];

    function agenciaBancariaCadModalService(AcoesCadastrosModalService) {

        var modal = {
            templateUrl: 'agencia-bancaria-cad/agencia-bancaria-cad.html',
            controller: 'core.AgenciaBancariaCadController',
            controllerAs: 'vm',
            params: {
                identificador_cadastro: null
            },
            size: 'sm'
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar,
            onAdd: onAdd
        };

        angular.extend(this, service);

        function cadastrar() {
            AcoesCadastrosModalService.cadastrar(modal);
        }

        function editar(data) {
            AcoesCadastrosModalService.editar(modal, data);
        }

        function onAdd(params) {
            return AcoesCadastrosModalService.onAdd(modal, params);
        }
    }
})();
