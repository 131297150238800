(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.unidade-medida.UnidadeMedidaSugestoesSelect
   * @description
   * Serviço do select2 para a unidade de medida
   * @requires bfc.Select2Config
   * @requires UnidadeMedidaService
   **/

  angular
    .module('core.unidade-medida')
    .service('core.UnidadeMedidaSugestoesSelect', UnidadeMedidaSugestoesSelect);

  UnidadeMedidaSugestoesSelect.$inject = [
    'bfc.Select2',
    'core.UnidadeMedidaService',
    'CONFIG_SELECT2'
  ];

  function UnidadeMedidaSugestoesSelect(Select2,
                               UnidadeMedidaService,
                               CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var metodos = {
        formatResult: formatResult,
        formatSelection: formatSelection,
        formatValue: formatValue,
        initSelection: initSelection,
        result: _.debounce(result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('UNIDADE_MEDIDA'),
        dropdownAutoWidth: false,
        onAdd: onAdd
      };

      function formatResult(unidadeMedida) {
        return getNomeExibicao(unidadeMedida);
      }

      function formatSelection(unidadeMedida) {
        //TODO Verificar como ficará representado a unidade de medida na tela
        return getSimbolo(unidadeMedida);
        //return getDescricaoExibicao(unidadeMedida);
      }

      function formatValue(unidadeMedida) {
        return unidadeMedida;
      }

      function initSelection(element, callback) {
        var id = $(element).val();

        if (Number(id)) {
          UnidadeMedidaService.get(id).then(callback);
        }else {
          var unidade = {
            nome: element.val()
          };
          callback(unidade);
        }
      }

      function result(params, callback) {
        var result = UnidadeMedidaService.getSugestoes({filter: params.term});
        result.then(function (unidadeMedida) {
          callback({
            results: unidadeMedida,
            more: unidadeMedida.hasNext
          });
        });
        return result;
      }

      function getNomeExibicao(unidadeMedida) {
        if (unidadeMedida.text) {
          return unidadeMedida.text;
        }
        if (unidadeMedida) {
          return unidadeMedida.simbolo + ' - ' + unidadeMedida.nome;
        }
      }

      function getSimbolo(unidadeMedida) {
        if (unidadeMedida.text) {
          return unidadeMedida.text;
        }
        if (unidadeMedida) {
          return unidadeMedida.simbolo;
        }
      }

      function onAdd(value, target) {
        Select2.set(target, value);
        return false;
      }

      return Select2.create(angular.extend(metodos, options));
    }
  }
})();
