(function () {
    'use strict';

    angular.module('core.integracao')
            .directive('coreContratosContratacaoSelect', contratosContratacaoSelect);

    function contratosContratacaoSelect() {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" minimum-input-length="0" format-result="vm.formatResult(data)" format-selection="vm.formatSelection(data)"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                minimumInputLength: '@',
                criterionSearch: '=?'
            },
            compile: function (element, attributes) {
                element.children('ui-select').attr('minimum-input-length', attributes.minimumInputLength);
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'bfc.$$PermissionsService',
        'core.ContratacaoServiceContratos'
    ];

    function Controller($scope, PermissionsService, service) {
        var vm = this;
        vm.search = search;
        vm.formatResult = formatResult;
        vm.formatSelection = formatSelection;
        vm.params = $scope.criterionSearch;

        function search(params) {
            var paramsDefault = '(numeroTermo like "' + params.term + '")';
            params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
            //params = {};
            return service.getList(params).then(function (data) {
                return data;
            });
        }

        function formatResult(value) {
            return value.numeroTermo + '/' + value.ano;
        }

        function formatResult(value) {
            return value.descricao || value.numeroTermo + '/' + value.ano + ': ' + formatObjetoContratacao(value) + '<br><em>(' + value.tipoObjeto.tipoDescricao + ')</em>';
        }

        function formatSelection(value) {
            return value.numeroTermo + '/' + value.ano + ': ' + formatObjetoContratacao(value);
        }

        function formatObjetoContratacao(value) {
            return value.objetoContratacao.length > 33 ? value.objetoContratacao.slice(0, 30) + '...' : value.objetoContratacao;
        }
    }
})();
