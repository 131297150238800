(function() {
	'use strict';

	/**
	 * @ngdoc service
	 * @name ctr.configuracoes-gerais.service:ConfiguracoesGeraisService
	 *
	 * @description
	 * Controller Configurações Gerais
	 *
	 * @requires ModalCadFactory
	 **/
	angular
			.module('core.configuracoes-gerais')
			.service('core.ConfiguracoesGeraisService', ConfiguracoesGeraisService);

	ConfiguracoesGeraisService.$inject = ['core.ModalCadFactory'];

	function ConfiguracoesGeraisService(ModalCadFactory) {

		var modal = {
			templateUrl: 'configuracoes-gerais.html',
			controller: 'core.ConfiguracoesGeraisController as configGeraisCtrl'
		};

		this.abrir = abrir;

		function abrir() {
			ModalCadFactory.open(modal);
		}
	}
})();
