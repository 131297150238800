(function () {
    'use strict';
    angular
        .module('contratos.filtro-registro-padrao')
        .directive('filtroRegistroPadrao', filtroRegistroPadrao);

    function filtroRegistroPadrao() {
        return {
            restrict: 'E',
            templateUrl: 'filtro-registro-padrao.template.html',
            transclude: true,
            scope: {
                default: '@?',
                exibirAtivoInativo: '=?',
                qtdRegistros: '=?'
            },
            controller: controller,
            controllerAs: 'vm',
        };
    }

    controller.$inject = ['$scope', '$rootScope']

    function controller($scope, $rootScope) {
        var vm = this;
        $scope.filtroSelecionado = $scope.default ? $scope.default : "TODOS";

        $scope.exibirQuantidadeItens = function (filtro) {
            return $scope.filtroSelecionado === filtro
                && !$scope.carregandoRegistros
                && _.isNumber($scope.qtdRegistros);
        }

        vm.onClick = function (filtro) {
            delete $scope.qtdRegistros;

            $scope.filtroSelecionado = filtro;
            var criterio = "";
            switch ($scope.filtroSelecionado) {
                case "TODOS":
                    criterio = '';
                    break;

                case "USUARIO":
                    criterio = 'registroPadrao = false';
                    break;

                case "PADRAO":
                    criterio = 'registroPadrao = true';
                    break;
                case "ATIVO":
                    criterio = 'ativo = true';
                    break
                case 'INATIVO':
                    criterio = 'ativo = false';
                    break
            }

            $rootScope.$broadcast('filtroRegistroPadrao:refresh', {filtro: filtro, criterio: criterio});
        }
    }
})();
