(function () {
    'use strict';

    angular
        .module('core.tipo-ato')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var tipo = {
            route: {
                url: '/tipo-ato',
                data: {
                    id: 'TipoAtoPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'tipo-ato.html',
                        controller: 'core.TipoAtoController',
                        controllerAs: 'tipoAto'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-tipo-ato', tipo.route);
    }
})();