(function() {
    'use strict';
    /**
     * @ngdoc service
     * @name contratos-core.fornecedor.service:FornecedorCadService
     * @description Serviço para abrir o cadastro de fornecedors da frota
     * @requires ModalCadFactory
     **/
    angular
            .module('core.fornecedor')
            .service('core.FornecedorCadService', FornecedorCadService);

    FornecedorCadService.$inject = ['core.ModalCadFactory'];

    function FornecedorCadService(ModalCadFactory) {

        var modal = {
            templateUrl: 'fornecedor-cad/fornecedor-cad.html',
            controller: 'core.FornecedorCadController as fornecedorCad',
            resolve: {
                fornecedorId: null
            }
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar,
            cadastrarFromReceitaFederal: cadastrarFromReceitaFederal,
            editarFromReceitaFederal: editarFromReceitaFederal
        };

        angular.extend(this, service);

        function abrir(modal) {
            ModalCadFactory.open(modal);
        }

        function copia(modal) {
            return angular.copy(modal);
        }

        function cadastrar(fornecedor, optionsCad, selectElem) {
            var m = copia(modal);

            if (fornecedor)
                m.resolve = {
                    fornecedor: fornecedor
                };

            if (optionsCad) {
                m.optionsCad = optionsCad;
            }

            if (selectElem) {
                m.selectElem = selectElem;
            }

            abrir(m);
        }

        function editar(fornecedor) {
            var m = copia(modal);
            m.resolve = {
                fornecedorId: fornecedor.id
            };
            abrir(m);
        }

        function cadastrarFromReceitaFederal() {
            var modalFornecedorFromReceita = {
                templateUrl: 'fornecedor-cad/fornecedor-cad.html',
                controller: 'core.FornecedorCadController as fornecedorCad',
                resolve: {
                    fornecedorId: {
                        id: null,
                        origemAberturaReceitaFederal: true
                    }
                }
            };
            abrir(modalFornecedorFromReceita);
        }

        function editarFromReceitaFederal(fornecedor) {
            var modalFornecedorFromReceita = {
                templateUrl: 'fornecedor-cad/fornecedor-cad.html',
                controller: 'core.FornecedorCadController as fornecedorCad',
                resolve: {
                    fornecedorId: {
                        id: fornecedor.id,
                        origemAberturaReceitaFederal: true
                    }
                }
            };
            abrir(modalFornecedorFromReceita);
        }
    }
})();
