(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.fornecedor.service:UfService
     * @descripition
     * Serviço de UF
     * @requires Restangular
     * @requires $q
     **/
    angular
            .module('core.fornecedor')
            .service('core.UfService', UfService);

    UfService.$inject = ['$q', 'Restangular'];

    function UfService($q, Restangular) {
        var meta;
        var uri = 'fornecedors';

        var service = {
            getDefault: getDefault,
            getTypes: getTypes,
            getUfs: getUfs,
            getUf: getUf
        };

        angular.extend(this, service);

        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.MotoristaService#getMetadata
         * @methodOf contratos-core.fornecedor.service:MotoristaService
         * @description Retorna os metadados trazidos pelo verbo HTTP OPTIONS
         * @returns {promise} promise
         **/
        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri).options().then(function(data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function(data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }
        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.MotoristaService#getDefault
         * @methodOf contratos-core.fornecedor.service:MotoristaService
         * @description Retorna a representação default de um fornecedor
         * @returns {promise} promise
         **/
        function getDefault() {
            return getMetadata().then(function(data) {
                return angular.copy(data.representation.default);
            });
        }
        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.MotoristaService#getTypes
         * @methodOf contratos-core.fornecedor.service:MotoristaService
         * @description Retorna as especificações dos campos de um fornecedor
         * @returns {promise} promise
         **/
        function getTypes() {
            return getMetadata().then(function(data) {
                return angular.copy(data.types);
            });
        }

        function getUf(ufKey) {
            return getValueFromKeyEnum('UF', ufKey);
        }

        function getUfs(filter) {
            return getEnumValues('UF', filter);
        }

        function getEnumValues(enumName, filter) {
            var defer = $q.defer();
            getEnums().then(function (enums) {
                var values = getValuesEnum(enums, enumName);
                if (filter) {
                    values = doFilter(values, filter);
                }
                defer.resolve(values);
            });
            return defer.promise;
        }

        function getEnums() {
            return getMetadata().then(function (data) {
                return angular.copy(data.enums);
            });
        }

        function getValuesEnum(enums, enumName) {
            return enums && enums[enumName] && enums[enumName].values;
        }

        function getValueFromKeyEnum(enumName, key) {
            return getEnums().then(function (enums) {
                var values = getValuesEnum(enums, enumName);
                return _.find(values, {'key': key});
            });
        }

        function doFilter(array, filter) {
            var r;
            if (filter && array) {
                var results = _.filter(array, function (element) {
                    var text = filter.replace(/\%/g, '').toLowerCase();
                    var value = element.value.replace(/\ /g, '').toLowerCase();
                    return value.search(text) !== -1;
                });
                r = results;
            } else {
                r = array;
            }
            return r;
        }
    }
})();
