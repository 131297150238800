(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.organograma.OrganogramaService
     * @description
     * Serviço que se comunica com o back-end de organogramas
     * @requires $q
     * @requires Restangular
     **/
    angular
            .module('core.organograma')
            .factory('core.OrganogramaService', OrganogramaService);

    OrganogramaService.$inject = ['$q', 'Restangular', 'CamposAdicionaisService'];

    function OrganogramaService($q, Restangular, CamposAdicionaisService) {
        var meta;
        var uri = [
            'configuracoesorganogramas',
            'organogramas',
            'niveisorganogramas',
            'parametroestoque',
            'organogramasaldo',
            'buscar',
            'sortbynivel',
            'campos-adicionais',
            'permitidos'
        ];

        var service = {
            get: get,
            getList: getList,
            getListUiListGrid: getListUiListGrid,
            getListNiveis: getListNiveis,
            getDefault: getDefault,
            save: save,
            getTypes: getTypes,
            remove: remove,
            getListSaldoEstoque: getListSaldoEstoque,
            getNiveisBens: getNiveisBens,
            setConfigId: setConfigId,
            getConfigId: getConfigId,
            getListOrderByNivel: getListOrderByNivel,
            getCamposAdicionais: getCamposAdicionais,
            getOrganPorPermissao: getOrganPorPermissao
        };

        return service;

        function get(idConfig, id) {
            return Restangular.one(uri[0], idConfig).one(uri[1], id).get();
        }

        function setConfigId(idConfig) {
            service.configId = idConfig;
        }

        function getConfigId() {
            return service.configId
        }

        function getListUiListGrid(params) {
            params = params || {};
            params.offset = params.offset || 0;
            return Restangular.one(uri[0], service.getConfigId())
                    .one(uri[1])
                    .get(params);
        }

        function getList(params, configuracaoOrganogramaId, ultimoNivel, controleSaldo) {
            params = params || {};
            params.offset = params.offset || 0;
            params.ultimoNivel = ultimoNivel || false;
            params.controleSaldo = controleSaldo || false;
            return Restangular.one(uri[0], configuracaoOrganogramaId)
                    .one(uri[1])
                    .get(params);
        }

        function getListOrderByNivel(params, configuracaoOrganogramaId, ultimoNivel, controleSaldo) {
            params = params || {};
            params.offset = params.offset || 0;
            params.ultimoNivel = ultimoNivel || false;
            params.controleSaldo = controleSaldo || false;
            return Restangular.one(uri[0], configuracaoOrganogramaId)
                    .one(uri[1])
                    .one(uri[6])
                    .get(params);
        }

        function getListSaldoEstoque(params) {
            var custom = {
                filter: params.filter,
                offset: 0,
                limit: 999,
                controleSaldo: params.controleSaldo
            };
            if (params.materialEspecificacao) {
                custom.materialEspecificacao = params.materialEspecificacao;
            }
            return Restangular.one(uri[0], params.configuracaoOrganogramaId)
                    .one(uri[1])
                    .one(uri[3], params.parametroEstoqueId)
                    .customGET(uri[4], custom)
                    .then(function (data) {
                        return data;
                    });
        }

        function getListNiveis(idConfig, filter) {
            var params = {filter: filter};
            return Restangular.one(uri[0], idConfig).one(uri[2]).get(params).then(function (data) {
                return data;
            });
        }

        function getMetadata(idConfig) {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.one(uri[0], idConfig).all(uri[1]).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getDefault(idConfig) {
            return getMetadata(idConfig).then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getTypes(idConfig) {
            return getMetadata(idConfig).then(function (data) {
                return angular.copy(data.types);
            });
        }

        function save(configOrganogramaId, data) {
            var rest = Restangular.one(uri[0], configOrganogramaId).all(uri[1]);
            return !data.id ? rest.post(data) : rest.customPUT(data, data.id);
        }

        function remove(configOrganogramaId, id) {
            return Restangular.one(uri[0], configOrganogramaId).one(uri[1], id).remove();
        }

        function getNiveisBens(data) {
            return Restangular.one(uri[0], data.configuracaoId)
                    .one(uri[1], data.organogramaId)
                    .one(uri[5])
                    .get();
        }

        function getCamposAdicionais(configOrganogramaId, organogramaId) {
            return CamposAdicionaisService.get({
                path: uri[0] + '/' + configOrganogramaId + '/' + uri[1] + '/' + uri[7],
                origemId: organogramaId
            }).then(function(response) {
                return response.data;
            });
        }

        function getOrganPorPermissao(params, configOrganogramaId, idEntidade) {
            params = params || {};
            params.offset = params.offset || 0;
            params.idEntidade = idEntidade || 0;
            return Restangular.one(uri[0], configOrganogramaId)
                    .one(uri[1])
                    .one(uri[8])
                    .get(params);
        }
    }
})();
