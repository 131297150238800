(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.estado
     * @description
     * # contratos-core.estado
     * Módulo de endereços
     **/
    angular
            .module('core.estado', ['core', 'core.commons', 'core.endereco', 'core.pais']);
})();
