(function () {
  'use strict';

  angular
    .module('core.endereco')
    .controller('core.EnderecoGeralController', EnderecoGeralController);

  EnderecoGeralController.$inject = [
    '$rootScope',
    '$state',
    '$timeout',
    'ROTAS',
    'CONFIG_SELECT2'
  ];

  function EnderecoGeralController($rootScope,
                                   $state,
                                   $timeout,
                                   ROTAS,
                                   CONFIG_SELECT2) {

    var viewModel = this;

    viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

    $rootScope.$state = $state;
    $timeout(function () {
      $state.go(ROTAS.LOGRADOURO, null, {redirect: true});
    }, 0);

  }
})();
