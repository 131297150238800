(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.logradouro.controller:LogradouroCadController
     * @description Controller do cadastro de logradouros
     **/
    angular
        .module('core.logradouro')
        .controller('core.LogradouroCadController', LogradouroCadController);

    LogradouroCadController.$inject = [
        '$injector',
        '$q',
        '$rootScope',
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'core.TrackerFactory'
    ];

    function LogradouroCadController($injector, $q, $rootScope, $scope,
                                     $modalInstance, Notification, TrackerFactory) {

        var viewModel = this;
        var LogradouroService = $injector.get('core.LogradouroService');
        var TipoLogradouroService = $injector.get('core.TipoLogradouroService');
        var MunicipioModalService = $injector.get('core.MunicipioModalService');
        var MunicipioService = $injector.get('core.MunicipioService');
        var CONFIG_SELECT2 = $injector.get('CONFIG_SELECT2');
        var Select2Factory = $injector.get('core.Select2Factory');
        var LogradourosSugestoesSelect = $injector.get('core.LogradourosSugestoesSelect');

        viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

        viewModel.argumentos = LogradouroCadController.$inject;
        viewModel.salvar = salvar;
        viewModel.excluir = excluir;
        viewModel.desabilitaSalvar = desabilitaSalvar;
        viewModel.getAcao = getAcao;
        viewModel.temLogradouro = temLogradouro;

        viewModel.buscarMunicipios = buscarMunicipios;
        viewModel.adicionarNovoMunicipio = adicionarNovoMunicipio;
        viewModel.buscarTiposLogradouros = buscarTiposLogradouros;
        viewModel.formataResultadoTipo = formataResultadoTipo;
        viewModel.formataResultadoMunicipio = formataResultadoMunicipio;

        viewModel.adicionando = adicionando;
        viewModel.editando = editando;
        viewModel.buscaLogradouro = buscaLogradouro;
        viewModel.selecionaTemplate = selecionaTemplate;

        inicializaLoadings();
        inicializar();

        function inicializar() {
            inicializarLogradouro();
            initSelect2();
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'iniciaLogradouro',
                'salvar',
                'salvarAdicionar',
                'excluir'
            ]);
        }

        function buscaLogradouro() {
            viewModel.logradouro = _.merge(viewModel.logradouro, viewModel.template);
            viewModel.logradouro.id = null;
        }

        function selecionaTemplate() {
            if (viewModel.template) {
                viewModel.logradouro.nome = viewModel.template.nome;
            }
        }

        function buscarMunicipios(params) {
            params.filter = '(nome like "' + params.term + '")';
            return MunicipioService.getList(params).then(function (data) {
                return data;
            });
        }

        function buscarTiposLogradouros(params) {
            params.filter = '(descricao like "' + params.term + '")';
            return TipoLogradouroService.getList(params).then(function (data) {
                return data;
            });
        }

        function formataResultadoTipo(value) {
            return value.descricao;
        }

        function formataResultadoMunicipio(value) {
            return value.nome;
        }

        function adicionarNovoMunicipio(nomeMunicipio) {
            var param = {
                nome: nomeMunicipio
            };

            return MunicipioModalService.onAdd(param);
        }

        function inicializarLogradouro() {
            var promise;
            if ($modalInstance.params.logradouroId) {
                promise = LogradouroService.get($modalInstance.params.logradouroId);
                promise.then(function (logradouro) {
                    viewModel.logradouro = logradouro;
                });
            } else {
                promise = LogradouroService.getDefault();
                promise.then(function (logradouroDefault) {
                    viewModel.logradouro = logradouroDefault;
                    viewModel.logradouro.definidaUsuario = true;

                    if ($modalInstance.params.params) {
                        viewModel.desabilitaCampo = !!$modalInstance.params.params.params;
                        viewModel.logradouro.nome = $modalInstance.params.params.nome;
                        if($modalInstance.params.params.params) {
                            viewModel.logradouro.municipio = $modalInstance.params.params.params.municipio;
                            viewModel.logradouro.cep = $modalInstance.params.params.params.cep;
                        }
                    }
                });
            }
            viewModel.loadings.iniciaLogradouro.addPromise(promise);
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([{
                element: 'nome',
                select2: LogradourosSugestoesSelect
            }]);
        }

        function excluir() {
            $rootScope.$broadcast('logradouro:actionRemove', {id: viewModel.logradouro.id});
            fechar();
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;
            tracker.addPromise(LogradouroService.salvar(viewModel.logradouro).then(function (logradouro) {
                if (continuar) {
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                        viewModel.form.$setUntouched();
                    }
                    $modalInstance.params.logradouroId = undefined;
                    inicializar();
                } else {
                    fechar(logradouro);
                }
                showNotificationSave();
            }));
        }

        function showNotificationSave() {
            if (temLogradouro()) {
                Notification.publish('Logradouro alterado com sucesso', 'success');
            } else {
                Notification.publish('Logradouro adicionado com sucesso', 'success');
            }
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
        }

        function fechar(logradouro) {
            $modalInstance.close(logradouro);
        }

        function getAcao() {
            return $modalInstance.params.logradouroId ? 'Editando' : 'Adicionando';
        }

        function temLogradouro() {
            return $modalInstance.params.logradouroId || (viewModel.logradouro && viewModel.logradouro.id);
        }

        function adicionando() {
            return !editando();
        }

        function editando() {
            return Boolean(viewModel.logradouro && !viewModel.logradouro.id) ?
                ($modalInstance.params.params || $modalInstance.params.logradouroId) : viewModel.logradouro && viewModel.logradouro.id;
        }
    }
})();
