(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos.filtro-registro-padrao
     * @description
     * # contratos.filtro-registro-padrao
     * Módulo de registros padrões
     */
    angular
        .module('contratos.filtro-registro-padrao', ['core', 'core.commons']);
})();