(function () {
  'use strict';

  angular
    .module('core.integracao')
    .service('core.ProcessoAdministrativoServiceCompras', ProcessoAdministrativoServiceCompras);

  ProcessoAdministrativoServiceCompras.$inject = ['core.ComprasResources', '$q'];

  function ProcessoAdministrativoServiceCompras(ComprasResources, $q) {

    var uri = ['processosadministrativo', 'contratacao', 'atasregistropreco', 'todosProcessosPorEntidadeParticipante'];

    var meta;

    this.getList = getList;
    this.get = get;
    this.getProcessos = getProcessos;
    this.getProcessosPorEntidadeParticipante = getProcessosPorEntidadeParticipante;
    this.getProcesso = getProcesso;
    this.setProcessoAdministrativo = setProcessoAdministrativo;
    this.getProcessoAdministrativo = getProcessoAdministrativo;
    this.getRegistroPreco = getRegistroPreco;

    function setProcessoAdministrativo(data){
      this.processo = data;
    }

    function getProcessoAdministrativo(){
      return this.processo;
    }

    function getList(params) {
      params = params || {};
      params.offset = params.offset || 0;
      return ComprasResources.one(uri[0])
        .get(params);
    }

    function get(id) {
      return ComprasResources.one(uri[0], id).get();
    }

    function getProcessos(params) {
      params = params || {};
      params.offset = params.offset || 0;
      return ComprasResources.one(uri[0]).one(uri[1])
        .get(params);
    }

    function getProcessosPorEntidadeParticipante(params) {
      params = params || {};
      params.offset = params.offset || 0;
      return ComprasResources.one(uri[0]).one(uri[3])
          .get(params);
    }

    function getRegistroPreco(params) {
      params = params || {};
      params.offset = params.offset || 0;
      return ComprasResources.one(uri[0], this.processo.id)
        .one(uri[2])
        .get(params);
    }

    function getMetadata() {
      var deferred = $q.defer();
      if (!meta) {
        ComprasResources.all(uri[0])
          .options()
          .then(function (data) {
            meta = data;
            deferred.resolve(meta);
          }, function (data) {
            deferred.reject(data);
          });
      } else {
        deferred.resolve(meta);
      }
      return deferred.promise;
    }

    function getProcesso(params) {
      var urlProcesso = params.ano + '/' + params.numeroProcesso,
        urlFinal = params.vencedores ? urlProcesso + '/' + params.vencedores : urlProcesso;
      return ComprasResources
        .one(uri[0], params.idEntidade)
        .one(urlFinal)
        .get();
    }
  }
})();
