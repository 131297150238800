(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name estoque.encerramentos.controller:EncerramentosCadController
     *
     * @description
     * Controller cadastro de encerramentos do estoque
     *
     * @requires $scope
     **/
    angular
        .module('core.fornecedor')
        .controller('CoreContaBancariaCadController', contaBancariaCadController);

    contaBancariaCadController.$inject = [
        '$rootScope',
        '$scope',
        '$modalInstance',
        '$injector'
    ];

    function contaBancariaCadController($rootScope,
                                        $scope,
                                        $modalInstance,
                                        $injector) {
        var viewModel = this;

        var TrackerFactory = $injector.get('core.TrackerFactory'),
            CONFIG_SELECT2 = $injector.get('CONFIG_SELECT2'),
            FornecedorService = $injector.get('core.FornecedorService'),
            Notification = $injector.get('bfc.Notification');

        viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

        inicializaLoadings();
        inicializar();

        function inicializar() {
            inicializaMetodos();
            inicializaPropriedades();
            inicializaCadastro();
            inicializaEnums();
        }

        function inicializaEnums() {
            viewModel.tipoConta = FornecedorService.getEnumsTipoConta();
            viewModel.situacao = FornecedorService.getEnumsStatusConta();
        }

        function inicializaPropriedades() {
            viewModel.argumentos = contaBancariaCadController.$inject;
            viewModel.cadastro = {};
            viewModel.cadastro.situacao = 'ABERTA';
            viewModel.idCadastro = ($modalInstance.params.params && $modalInstance.params.params.index);
        }

        function inicializaMetodos() {
            viewModel.situacaoCadastro = situacaoCadastro;
            viewModel.temItem = temItem;
            viewModel.desabilitaSalvar = desabilitaSalvar;
            viewModel.adicionarContaBancaria = adicionarContaBancaria;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'salvar',
                'salvarAdicionar',
                'inicializaCadastro',
                'cadastro'
            ]);
        }

        function inicializaCadastro() {
            if ($modalInstance.params.params) {
                viewModel.cadastro = $modalInstance.params.params.cadastro;
            }
        }

        function situacaoCadastro() {
            return temItem() ? 'Editando' : 'Adicionando';
        }

        function temItem() {
            return Boolean($modalInstance.params.params);
        }

        function cancelar(item) {
            $modalInstance.close(item);
        }

        function verificaForm() {
            return (viewModel.form.$dirty && viewModel.form.$invalid);
        }

        function desabilitaSalvar() {
            var primeiraCondicao = viewModel.form.$pristine && !temItem();
            return verificaForm() || primeiraCondicao;
        }

        function adicionarContaBancaria(index) {
            $rootScope.$broadcast('adicionaContaBancaria', {cadastro: viewModel.cadastro, index: index});
            cancelar();
        }

        $scope.$watch(
            'vm.cadastro.banco',
            function (newValue, oldValue) {
                if (newValue && newValue !== oldValue) {
                    viewModel.paramsAgencia = _.template('((nome like "<%= termo %>" or numeroAgencia like "<%= termo %>") and idBanco in (' + newValue.id + '))');
                    $rootScope.$broadcast('agenciaBancariaSelect:refresh');
                }
                if (oldValue !== newValue) {
                    viewModel.cadastro.agenciaBancaria = null;
                }
                if (newValue == oldValue) {
                    viewModel.paramsAgencia = _.template('((nome like "<%= termo %>" or numeroAgencia like "<%= termo %>") and idBanco in (' + newValue.id + '))');
                    $rootScope.$broadcast('agenciaBancariaSelect:refresh');
                }
            }
        );

        $scope.$watch(
            'vm.cadastro.situacao',
            function (newValue, oldValue) {
                /*if (newValue && newValue !== oldValue) {
                    viewModel.paramsAgencia = _.template('(nome like "<%= termo %>" and idBanco in (' + newValue.id + '))');
                    $rootScope.$broadcast('agenciaBancariaSelect:refresh');
                }*/
                if (oldValue !== newValue) {
                    viewModel.cadastro.dataFechamento = null;
                }
            }
        );
    }
})();