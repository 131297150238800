(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.distrito
     * @description
     * # contratos-core.distrito
     * Módulo de endereços
     **/
    angular
            .module('core.distrito', ['core', 'core.commons','core.endereco', 'core.municipio']);
})();
