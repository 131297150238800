(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.fonte-divulgacao.controller:FonteDivulgacaoCadController
   *
   * @description
   * Controller responsável pelo cadastro de fontes de divulgação
   *
   * @requires $rootScope
   * @requires $q
   * @requires promiseTracker
   * @requires $modalInstance
   * @requires $modalTracker
   * @requires bfc.FocusCtrl
   **/
  angular
    .module('core.fonte-divulgacao')
    .controller('core.FonteDivulgacaoCadController', FonteDivulgacaoCadController);

  FonteDivulgacaoCadController.$inject = [
    '$rootScope',
    '$q',
    '$modalInstance',
    '$modalTracker',
    'core.TrackerFactory',
    'bfc.FocusCtrl',
    'bfc.Notification',
    'core.FonteDivulgacaoService',
    'CamposAdicionaisService',
    'CPA_CONSTANTS',
    'core.Select2Factory',
    'core.FonteDivulgacoesSugestoesSelect'
  ];

  function FonteDivulgacaoCadController($rootScope,
                                        $q,
                                        $modalInstance,
                                        $modalTracker,
                                        TrackerFactory,
                                        Focus,
                                        Notification,
                                        FonteDivulgacaoService,
                                        CamposAdicionaisService,
                                        CPA_CONSTANTS,
                                        Select2Factory,
                                        FonteDivulgacoesSugestoesSelect) {

    var viewModel = this;

    viewModel.cancelar = cancelar;
    viewModel.estaAdicionando = estaAdicionando;
    viewModel.salvar = salvar;
    viewModel.existeFonteDivulgacao = existeFonteDivulgacao;
    viewModel.excluir = excluir;
    viewModel.formataResultado = formataResultado;
    viewModel.desabilitaSalvar = desabilitaSalvar;
    viewModel.buscarFonteDivulgacao = buscarFonteDivulgacao;
    viewModel.editando = editando;
    viewModel.adicionando = adicionando;

    inicializaLoadings();
    inicializar();

    function inicializar() {
      viewModel.fonteDivulgacao = {};
      initEnums();
      inicializaFonteDivulgacao();
      inicializaCamposAdicionais();
      initSelect2();
      viewModel.visivel = 1;
    }

    function buscarFonteDivulgacao() {
      viewModel.fonteDivulgacao = viewModel.template;
      viewModel.fonteDivulgacao.id = null;
    }

    function initSelect2() {
      viewModel.select2 = Select2Factory.init([{
        element: 'nome',
        select2: FonteDivulgacoesSugestoesSelect
      }]);
    }

    function initEnums() {
      viewModel.meioComunicacao = FonteDivulgacaoService.getEnumMeioComunicacao();
    }

    function inicializaFonteDivulgacao() {
      var promise;
      if ($modalInstance.params.identificador_cadastro) {
        promise = FonteDivulgacaoService.get($modalInstance.params.identificador_cadastro);
        promise.then(function (novaFonteDivulgacao) {
          viewModel.fonteDivulgacao = novaFonteDivulgacao;
        });
      } else {
        promise = FonteDivulgacaoService.getDefault();
        promise.then(function (novaFonteDivulgacao) {
          viewModel.FonteDivulgacao = novaFonteDivulgacao;
          if ($modalInstance.params.params) {
            viewModel.fonteDivulgacao.nome = $modalInstance.params.params.nome;
          }
        });
      }
      viewModel.loadings.fonteDivulgacao.addPromise(promise);
    }

    function inicializaCamposAdicionais() {
      var promise = CamposAdicionaisService.get(CPA_CONSTANTS.Cadastros.FONTE_DIVULGACAO, $modalInstance.params.identificador_cadastro);
      promise.then(function (response) {
        viewModel.campoAdicional = response.data;
      });
      return promise;
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init([
        'salvar',
        'salvarAdicionar',
        'excluir',
        'fonteDivulgacao',
        'camposAdicionais'
      ]);
    }

    function cancelar(fonteDivulgacao) {
      $modalInstance.close(fonteDivulgacao);
    }

    function estaAdicionando() {
      return temFonteDivulgacao() ? 'Editando' : 'Adicionando';
    }

    function desabilitaSalvar() {
      return (viewModel.form.$dirty && viewModel.form.$invalid)
        || (viewModel.form.$pristine && !temFonteDivulgacao())
        || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
    }

    function temFonteDivulgacao() {
      return (viewModel.fonteDivulgacao.id === undefined) ? $modalInstance.params.identificador_cadastro : viewModel.fonteDivulgacao.id;
    }

    function salvar(continuar) {
      var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;

      if (viewModel.campoAdicional)
        viewModel.fonteDivulgacao.campoAdicional = viewModel.campoAdicional;

      tracker.addPromise(
        FonteDivulgacaoService.save(viewModel.fonteDivulgacao).then(function (fonteDivulgacao) {
          if (continuar) {
            if (viewModel.form) {
              viewModel.form.$setPristine();
              viewModel.form.$setUntouched();
            }
            $modalInstance.params.identificador_cadastro = null;
            $modalInstance.params.params = undefined;
            inicializar();
            inicializaCamposAdicionais();
            viewModel.visivel = 1;
            Focus.set('fonteDivCadDescricao');
          } else {
            cancelar(fonteDivulgacao);
          }
          var mensagem = $modalInstance.params.identificador_cadastro ? 'alterada' : 'salva';
          Notification.publish('Fonte de divulgação ' + mensagem + ' com sucesso', 'success');
        })
      );
    }

    function excluir() {
      $rootScope.$broadcast('fonteDivulgacao:actionRemove', {id: viewModel.fonteDivulgacao.id});
      cancelar();
    }

    function existeFonteDivulgacao() {
      return Boolean(temFonteDivulgacao());
    }

    function formataResultado(value) {
      return value.nome;
    }

    function adicionando() {
      return !editando();
    }

    function editando() {
      return (viewModel.fonteDivulgacao && viewModel.fonteDivulgacao.id === undefined) ? $modalInstance.params.fonteDivulgacaoId : viewModel.fonteDivulgacao && viewModel.fonteDivulgacao.id;
    }
  }
})();
