(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ctr.prazo-entrega.ExercicioSelect
   * @descripition
   * Serviço do select2 para tipo de entrega/execução do prazo de entrega
   * @requires $q
   * @requires bfc.Select2Config
   * @requires ExercicioService
   * @requires UtilsService
   **/
  angular
    .module('core.exercicio')
    .service('core.ExercicioSelect', ExercicioSelect);

  ExercicioSelect.$inject = [
    '$q',
    'bfc.Select2',
    'core.ExercicioService',
    'core.UtilsService',
    'CONFIG_SELECT2'
  ];

  function ExercicioSelect($q,
                           Select2,
                           ExercicioService,
                           UtilsService,
                           CONFIG_SELECT2) {

    var select2 = this;

    this.select2Config = select2Config;

    function select2Config(options) {
      var methods = {
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('EXERCICIO'),
        dropdownAutoWidth: false
      };

      function formatResult(exercicio) {
        return exercicio.exercicio;
      }

      function formatSelection(exercicio) {
        return exercicio.exercicio;
      }

      function formatValue(exercicio) {
        return exercicio;
      }

      function initSelection(element, callback) {
        var id = element.val();

        if (_.isNumber(id)) {
          ExercicioService.getExercicio(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter = params && params.term;

        var promise = ExercicioService.getExercicios();
        promise.then(function (exercicios) {
          select2.exercicios = exercicios;
          callback({
            results: UtilsService.doFilterByProperty({
              array: select2.exercicios,
              filter: filter,
              property: 'exercicio'
            }),
            more: exercicios.hasNext
          });
        });
        return promise;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
