(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.fornecedor.service:FornecedorService
     * @descripition
     * Serviço de fornecedor
     * @requires Restangular
     * @requires $q
     **/
    angular
        .module('core.fornecedor')
        .service('core.FornecedorService', FornecedorService);

    FornecedorService.$inject = [
        '$q',
        'Restangular',
        'ui.components.EventEmitter',
        'bfc.Notification',
        'CamposAdicionaisService'
    ];

    function FornecedorService($q,
                               Restangular,
                               EventEmitter,
                               Notification,
                               CamposAdicionaisService) {
        var meta;
        var URI = {
            fornecedores: 'fornecedores',
            fornecedoresLista: 'fornecedores/lista',
            fornecedoresPessoas: 'fornecedores/pessoas',
            fornecedoresConsulta: 'fornecedores/consulta',
            fornecedoresJuridicos: 'fornecedores/juridicos',
            socios: 'fornecedores/socios/pessoas',
            pessoasCompleto: 'fornecedores/pessoas/completo',
            municipios: 'municipios',
            logradouros: 'logradouros',
            tiposlogradouros: 'tiposlogradouros',
            bairros: 'bairros',
            naturezasjuridicas: 'fornecedores/naturezasjuridicas',
            camposAdicionais: 'fornecedores/campos-adicionais',
            logradourosPost: 'fornecedores/logradouros',
            cnaes: 'cnaes'
        };

        var service = new EventEmitter();

        _.extend(service, FornecedorService);

        service.consulta = consulta;
        service.get = get;
        service.getList = getList;
        service.getListFornecedores = getListFornecedores;
        service.getFornecedoresJuridicosList = getFornecedoresJuridicosList;
        service.getPessoa = getPessoa;
        service.getPessoasList = getPessoasList;
        service.getDefault = getDefault;
        service.getTypes = getTypes;
        service.save = save;
        service.remove = remove;
        service.emitDirty = emitDirty;
        service.mensagemExclusao = mensagemExclusao;
        service.getSocio = getSocio;
        service.getEnumPorteEmpresa = getEnumPorteEmpresa;
        service.getEnumOrgaoRegistro = getEnumOrgaoRegistro;
        service.getEnumsTipoConta = getEnumsTipoConta;
        service.getEnumsStatusConta = getEnumsStatusConta;
        service.setConsultaReceita = setConsultaReceita;
        service.getConsultaReceita = getConsultaReceita;
        service.getPessoaCompleto = getPessoaCompleto;
        service.getMunicipioFornecedor = getMunicipioFornecedor;
        service.getLogradouroFornecedor = getLogradouroFornecedor;
        service.getBairroFornecedor = getBairroFornecedor;
        service.getNaturezaJuridicaFornecedor = getNaturezaJuridicaFornecedor;
        service.getEnumTipoParticipacao = getEnumTipoParticipacao;
        service.getCamposAdicionais = getCamposAdicionais;
        service.getCnaesFornecedor = getCnaesFornecedor;

        return service;

        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.FornecedorService#get
         * @methodOf contratos-core.fornecedor.service:FornecedorService
         * @description Retorna o fornecedor especificado pelo id.
         * @param {Number} id identificação do fornecedor
         * @returns {promise} promise
         **/
        function get(id) {
            return Restangular.one(URI.fornecedores, id).customGET(null, null, {
                'Accept': 'application/json, application/vnd.betha.lookup.extendido+json'
            });
        }


        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.FornecedorService#getList
         * @methodOf contratos-core.fornecedor.service:FornecedorService
         * @description Listagem de fornecedors
         * @param {string} filter Filtro
         * @returns {promise} promise
         **/
        function getList(filter) {
            return Restangular.all(URI.fornecedores).getList({
                filter: filter
            }).then(function (todos) {
                _(todos).forEach(function (value, key) {
                    todos[key] = value.plain();
                });
                return todos;
            });
        }

        function getListFornecedores(params) {
            params = params || {};

            params.offset = params.offset || 0;

            return Restangular.one(URI.fornecedoresLista)
                .get(params)
                .then(function (data) {
                    if (params.offset > 0 && data.total <= params.offset) {
                        params.offset = 0;
                        data = getListFornecedores(params);
                    }
                    return data;
                });
        }

        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.FornecedorService#getList
         * @methodOf contratos-core.fornecedor.service:FornecedorService
         * @description Listagem de fornecedores, com pessoas do tipo juridica
         * @param {string} filter Filtro
         * @returns {promise} promise
         **/
        function getFornecedoresJuridicosList(filter) {
            return Restangular.all(URI.fornecedoresJuridicos).getList({
                filter: filter
            }).then(function (todos) {
                _(todos).forEach(function (value, key) {
                    todos[key] = value.plain();
                });
                return todos;
            });
        }

        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.FornecedorService#get
         * @methodOf contratos-core.fornecedor.service:FornecedorService
         * @description Retorna a pessoa especificada pelo id.
         * @param {Number} id identificação da pessoa
         * @returns {promise} promise
         **/
        function getPessoa(id) {
            return Restangular.one(URI.fornecedoresPessoas, id).customGET(null, null, {
                'Accept': 'application/json, application/vnd.betha.lookup.extendido+json'
            });
        }

        function getPessoaCompleto(id) {
            return Restangular.one(URI.pessoasCompleto, id).customGET(null, null, {
                'Accept': 'application/json, application/vnd.betha.lookup.extendido+json'
            });
        }

        function getSocio(filter, idPessoa) {
            var params = {filter: filter};
            if (idPessoa) {
                params.idPessoa = idPessoa;
            }
            return Restangular.all(URI.socios)
                .getList(params)
                .then(function (todos) {
                    _(todos).forEach(function (value, key) {
                        todos[key] = value.plain();
                    });
                    return todos;
                });
        }

        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.FornecedorService#getList
         * @methodOf contratos-core.fornecedor.service:FornecedorService
         * @description Listagem de pessoas
         * @param {string} filter Filtro
         * @returns {promise} promise
         **/
        function getPessoasList(filter) {
            return Restangular.all(URI.fornecedoresPessoas).getList({
                filter: filter
            }).then(function (todos) {
                _(todos).forEach(function (value, key) {
                    todos[key] = value.plain();
                });
                return todos;
            });
        }

        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.FornecedorService#getMetadata
         * @methodOf contratos-core.fornecedor.service:FornecedorService
         * @description Retorna os metadados trazidos pelo verbo HTTP OPTIONS
         * @returns {promise} promise
         **/
        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(URI.fornecedores).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.FornecedorService#getDefault
         * @methodOf contratos-core.fornecedor.service:FornecedorService
         * @description Retorna a representação default de um fornecedor
         * @returns {promise} promise
         **/
        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.FornecedorService#getTypes
         * @methodOf contratos-core.fornecedor.service:FornecedorService
         * @description Retorna as especificações dos campos de um fornecedor
         * @returns {promise} promise
         **/
        function getTypes() {
            return getMetadata().then(function (data) {
                return angular.copy(data.types);
            });
        }

        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.FornecedorService#save
         * @methodOf contratos-core.fornecedor.service:FornecedorService
         * @description Salva o fornecedor
         * @param {ModelO} fornecedor Objeto fornecedor para salvar
         * @returns {promise} promise
         **/
        function save(fornecedor) {
            var rest = Restangular.all(URI.fornecedores);
            return !fornecedor.id ?
                rest.post(fornecedor).then(emitDirty) :
                rest.customPUT(fornecedor, fornecedor.id).then(emitDirty);
        }

        /**
         * @ngdoc method
         * @name contratos-core.fornecedor.FornecedorService#remove
         * @methodOf contratos-core.fornecedor.service:FornecedorService
         * @description Remove o fornecedor
         * @returns {promise} promise
         **/
        function remove(id) {
            return Restangular.one(URI.fornecedores, id).remove().then(emitDirty);
        }

        function consulta(texto) {
            return Restangular.all(URI.fornecedoresConsulta).getList({
                consulta: texto
            }).then(function (fornecedores) {
                _(fornecedores).forEach(function (value, key) {
                    fornecedores[key] = value.plain();
                });
                return fornecedores;
            });
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }

        function mensagemExclusao() {
            Notification.publish('Fornecedor excluído com sucesso', 'success');
        }

        function getEnumPorteEmpresa() {
            return getMetadata().then(function (data) {
                var novo = data.enums.PorteEmpresaEnum;
                var porteEmpresa = {};
                angular.forEach(novo.values, function (valor, index) {
                    porteEmpresa[valor.id] = valor;
                });
                porteEmpresa.values = [
                    porteEmpresa.MICROEMPRESA,
                    porteEmpresa.EMPRESA_PEQUENO_PORTE,
                    porteEmpresa.EMPRESA_MEDIO_PORTE,
                    porteEmpresa.EMPRESA_GRANDE_PORTE,
                    porteEmpresa.MICROEMPREENDEDOR_INDIVIDUAL
                ];
                return porteEmpresa;
            });
        }

        function getEnumOrgaoRegistro() {
            return getMetadata().then(function (data) {
                var novo = data.enums.OrgaoRegistroEmpresaEnum;
                var orgaoRegistro = {};
                angular.forEach(novo.values, function (valor, index) {
                    orgaoRegistro[valor.id] = valor;
                });
                orgaoRegistro.values = [
                    orgaoRegistro.JUNTA_COMERCIAL,
                    orgaoRegistro.CARTORIO,
                    orgaoRegistro.OAB,
                    orgaoRegistro.PORTAL_EMPREENDEDOR_MEI
                ];
                return orgaoRegistro;
            });
        }

        function getEnumsTipoConta() {
            return getMetadata().then(function () {
                //var novo = data.enums.OrgaoRegistroEmpresaEnum;
                var novo = {
                    CORRENTE: {
                        'id': 'CORRENTE',
                        'key': 'CORRENTE',
                        'value': 'CORRENTE',
                        'description': 'Corrente'
                    },
                    POUPANCA: {
                        'id': 'POUPANCA',
                        'key': 'POUPANCA',
                        'value': 'POUPANCA',
                        'description': 'Poupança'
                    },
                    SALARIO: {
                        'id': 'SALARIO',
                        'key': 'SALARIO',
                        'value': 'SALARIO',
                        'description': 'Salário'
                    }
                };
                novo.values = [
                    novo.CORRENTE,
                    novo.POUPANCA,
                    novo.SALARIO
                ];
                var lista = {};
                angular.forEach(novo.values, function (valor) {
                    lista[valor.id] = valor;
                });
                lista.values = [
                    lista.CORRENTE,
                    lista.POUPANCA,
                    lista.SALARIO
                ];
                return lista;
            });
        }

        function getEnumsStatusConta() {
            return getMetadata().then(function () {
                //var novo = data.enums.OrgaoRegistroEmpresaEnum;
                var novo = {
                    ABERTA: {
                        'id': 'ABERTA',
                        'key': 'ABERTA',
                        'value': 'ABERTA',
                        'description': 'Aberta'
                    },
                    ENCERRADA: {
                        'id': 'ENCERRADA',
                        'key': 'ENCERRADA',
                        'value': 'ENCERRADA',
                        'description': 'Encerrada'
                    }
                };
                novo.values = [
                    novo.ABERTA,
                    novo.ENCERRADA
                ];
                var lista = {};
                angular.forEach(novo.values, function (valor) {
                    lista[valor.id] = valor;
                });
                lista.values = [
                    lista.ABERTA,
                    lista.ENCERRADA
                ];
                return lista;
            });
        }

        function setConsultaReceita(data) {
            service.dadosReceita = data;
        }

        function getConsultaReceita() {
            return service.dadosReceita;
        }

        function getMunicipioFornecedor(codigoMunicipioReceitaFederal) {
            var municipioCodRF = "" + codigoMunicipioReceitaFederal;
            if(municipioCodRF.length == 3) {
                municipioCodRF = "0" + municipioCodRF;
            }

            return Restangular.one(URI.municipios).get({
                filter: 'codigoSiafiNumerico = 98' + municipioCodRF,
                limit: 1
            }).then(function (municipio) {
                return municipio.content[0];
            });
        }

        function getBairroFornecedor(nomeBairro, municipio) {
            return Restangular.one(URI.bairros).get({
                filter: "nome like '%" + nomeBairro + "%' and idMunicipio = "+ municipio.id +"",
                limit: 1
            }).then(function (response) {
                var existeBairroCadastrado = response.content.length > 0;
                if (existeBairroCadastrado) {
                    return response.content[0];
                } else {
                    var novoBairro = {
                        definidaUsuario: true,
                        municipio: municipio,
                        nome: nomeBairro
                    }
                    return Restangular.all(URI.bairros).post(novoBairro)
                    .then(function (response) {
                        return response;
                    });
                }
            });
        }

        function getLogradouroFornecedor(logradouro, tipoLogradouro, cep, municipio) {
            return Restangular.one(URI.logradouros).get({
                filter: "nome like '%" + logradouro + "%' and idMunicipio = "+ municipio.id +"",
                limit: 1
            }).then(function (response) {
                var existeLogradouroCadastrado = response.content.length > 0;
                if (existeLogradouroCadastrado) {
                    return response.content[0];
                } else {
                    return Restangular.one(URI.tiposlogradouros).get({
                        filter: "descricao like '%" + tipoLogradouro + "%'",
                        limit: 1
                    }).then(function (data) {
                        var existeTipoLogradouroCadastrado = data.content.length > 0;
                        if (existeTipoLogradouroCadastrado) {
                            var criarNovoLogradouro = {
                                cep: cep,
                                definidaUsuario: true,
                                municipio: municipio,
                                nome: logradouro,
                                tipoLogradouro: data.content[0]
                            };
                            return Restangular.all(URI.logradourosPost).post(criarNovoLogradouro)
                            .then(function (response) {
                                return response;
                            });
                        } else {
                            return Restangular.one(URI.tiposlogradouros).get({
                                filter: "descricao='Rua'",
                                limit: 1
                            }).then(function (tipoLogradouroEncontrado) {
                                var criarNovoLogradouro = {
                                    cep: cep,
                                    definidaUsuario: true,
                                    municipio: municipio,
                                    nome: logradouro,
                                    tipoLogradouro: tipoLogradouroEncontrado.content[0]
                                };
                                return Restangular.all(URI.logradourosPost).post(criarNovoLogradouro)
                                .then(function (novoLogradouro) {
                                    return novoLogradouro;
                                });
                            });
                        }
                    });
                }
            });
        }

        function getNaturezaJuridicaFornecedor(codigoNaturezaJuridicaReceita) {
            return Restangular.one(URI.naturezasjuridicas).get({
                filter: "numero=" + codigoNaturezaJuridicaReceita,
                limit: 1
            }).then(function (response) {
                return response.content.length > 0 ? response.content[0] : null;
            });
        }

        function getEnumTipoParticipacao() {
            return getMetadata().then(function () {
                var novo = {
                    REPRESENTANTE_LEGAL: {
                        'id': 'REPRESENTANTE_LEGAL',
                        'key': 'REPRESENTANTE_LEGAL',
                        'value': 'REPRESENTANTE_LEGAL',
                        'description': 'Representante legal'
                    },
                    DEMAIS_MEMBROS_QUADRO_SOCIETARIO: {
                        'id': 'DEMAIS_MEMBROS_QUADRO_SOCIETARIO',
                        'key': 'DEMAIS_MEMBROS_QUADRO_SOCIETARIO',
                        'value': 'DEMAIS_MEMBROS_QUADRO_SOCIETARIO',
                        'description': 'Demais membros do quadro societário'
                    }
                };
                novo.values = [
                    novo.REPRESENTANTE_LEGAL,
                    novo.DEMAIS_MEMBROS_QUADRO_SOCIETARIO
                ];
                var lista = {};
                angular.forEach(novo.values, function (valor) {
                    lista[valor.id] = valor;
                });
                lista.values = [
                    lista.REPRESENTANTE_LEGAL,
                    lista.DEMAIS_MEMBROS_QUADRO_SOCIETARIO
                ];
                return lista;
            });
        }

        function getCamposAdicionais(fornecedorId) {
            return CamposAdicionaisService.get({
                path: URI.camposAdicionais,
                origemId: fornecedorId
            }).then(function(response) {
                return response.data;
            });
        }

        function getCnaesFornecedor(fornecedorId) {
            return Restangular.one(URI.fornecedores, fornecedorId).all(URI.cnaes).get('').then(function (cnaes) {
                return cnaes;
            });
        }
    }
})();
