(function () {
  'use strict';

  angular.module('core.ato')
    .directive('coreAtoTipoatoSelect', AtoTipoAtoSelectDirective);

  AtoTipoAtoSelectDirective.$inject = ['CONFIG_SELECT2'];

  function AtoTipoAtoSelectDirective(CONFIG_SELECT2) {
    return {
      restrict: 'E',
      template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" data-ng-disabled="ngDisabled" format-result="vm.formatResult(data)"></ui-select>',
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=?',
        hiddenAddValue: '=?',
        criterionSearch: '=?',
        ngDisabled: '=?',
        identifierType: '='
      },
      compile: function (element, attributes) {
        var addValue = attributes.hiddenAddValue === 'true';
        if (!addValue) {
          element.children('ui-select').attr('add-value', 'vm.onAdd');
        }
        element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
        element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
        element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('ATO_TIPO_ATO'));
      },
      controller: Controller,
      controllerAs: 'vm'
    };
  }

  Controller.$inject = [
    '$scope',
    'bfc.$$PermissionsService',
    'core.AtoService',
    'core.AtoModalService'
  ];

  function Controller($scope, PermissionsService, service, modal) {
    var vm = this;
    vm.search = search;
    vm.onAdd = !PermissionsService.isRevokedOperation('AtoPageMapping', 'criar') && onAdd;
    vm.params = $scope.criterionSearch;
    vm.formatResult = formatResult;

    function search(params) {
      var paramsDefault = '(numeroAto like "' + params.term + '" and tipoAtoId="' + $scope.identifierType.id + '")';
      params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
//            return service.getList(params).then(function (data) {
      return service.getListTemplate(params).then(function (data) {
        return data;
      });
    }

    function onAdd(descricao) {
      var param = {
        numeroAto: descricao,
        tipoAto: $scope.identifierType
      };
      return modal.onAdd(param);
    }

    function formatResult(value) {
      return value.numeroAto;
    }

  }
})();
