(function() {
    'use strict';

   /**
   * @ngdoc directive
   * @name contratos-core.organograma.directive:ctrOrganogramaList
   * @restrict EA
   * @description
   * Diretiva para listar os organogramas de uma configuração
   **/
    angular
            .module('core.organograma')
            .directive('coreOrganogramaList', coreOrganogramaList);

    function coreOrganogramaList() {
        return{
            restrict: 'EA',
            templateUrl: 'organograma-list.template.html',
            scope: true
        };
    }
})();
