(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.unidade-medida.GrandezaSelect
   * @descripition
   * Serviço do select2 para as Grandezas da unidade de medida
   * @requires bfc.Select2Config
   * @requires UnidadeMedidaService
   **/
  angular
    .module('core.unidade-medida')
    .service('core.GrandezaSelect', GrandezaSelect);

  GrandezaSelect.$inject = [
    'bfc.Select2',
    'core.UnidadeMedidaService',
    'CONFIG_SELECT2'
  ];

  function GrandezaSelect(Select2,
                          UnidadeMedidaService,
                          CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var methods = {
        id: id,
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT(),
        dropdownAutoWidth: false
      };

      function id(grandeza) {
        return grandeza.description ? grandeza.description : grandeza.key;
      }

      function formatResult(grandeza) {
        return grandeza.description;
      }

      function formatSelection(grandeza) {
        return grandeza && grandeza.description;
      }

      function formatValue(grandeza) {
        return grandeza.key;
      }

      function initSelection(element, callback) {
        var id = element.val();

        if (id) {
          UnidadeMedidaService.getGrandeza(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter = params && params.term;

        var result = UnidadeMedidaService.getGrandezas(filter);
        result.then(function (grandeza) {
          callback({
            results: grandeza,
            more: grandeza.hasNext
          });
        });
        return result;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
