(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.fonte-divulgacao.controller:FonteDivulgacaoController
   *
   * @description
   * Controller fontes de divulgacao
   **/
  angular
    .module('core.fonte-divulgacao')
    .controller('core.FonteDivulgacaoController', FonteDivulgacaoController);

  FonteDivulgacaoController.$inject = [
    '$rootScope',
    '$scope',
    'core.TrackerFactory',
    'core.FonteDivulgacaoService',
    'core.FonteDivulgacaoModalService',
    'bfc.Notification'
  ];

  function FonteDivulgacaoController($rootScope,
                                     $scope,
                                     TrackerFactory,
                                     FonteDivulgacaoService,
                                     FonteDivulgacaoModalService,
                                     Notification) {

    var viewModel = this;

    viewModel.fonteDivulgacaoService = FonteDivulgacaoService;

    viewModel.adicionar = adicionar;
    viewModel.editar = editar;
    viewModel.remover = remover;

    inicializar();

    // Funções inicializadoras
    function inicializar() {
      viewModel.loadings = {};
      inicializaLoadings();
      initEnums();
      escutaEventos();
    }

    function escutaEventos() {
      $scope.$on('fonteDivulgacao:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

    function initEnums() {
      viewModel.meioComunicacao = FonteDivulgacaoService.getEnumMeioComunicacao();
    }

    function inicializaLoadings() {
      viewModel.inicializaLoadings = TrackerFactory.init(['fonteDivulgacaoList']);
    }

    function adicionar() {
      FonteDivulgacaoModalService.cadastrar();
    }

    function editar(fonteDivulgacao) {
      FonteDivulgacaoModalService.editar(fonteDivulgacao);
    }

    function remover(fonteDivulgacao) {
      var promise = FonteDivulgacaoService.remove(fonteDivulgacao.id);
      return promise.then(function () {
        Notification.publish('Fonte de divulgação excluída com sucesso', 'success');
      }, function error() {
        return promise;
      });
    }
  }
})();
