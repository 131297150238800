(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.configuracao-organograma.ConfiguracaoOrganogramaService
   * @description
   * Serviço de comunicação com o serviço Rest das configurações de organograma
   * @requires Restangular
   * @requires $q
   * @requires UtilsService
   **/
  angular
    .module('core.configuracao-organograma')
    .factory('core.ConfiguracaoOrganogramaService', ConfiguracaoOrganogramaService);

  ConfiguracaoOrganogramaService.$inject = ['$q',
    'Restangular',
    'core.UtilsService',
    'ui.components.EventEmitter'
  ];

  function ConfiguracaoOrganogramaService($q,
                                          Restangular,
                                          UtilsService,
                                          EventEmitter) {

    var meta;

    var URI = {
      CONFIG_ORGAN : 'configuracoesorganogramas',
      PAGE: 'page'
    }

    var service = new EventEmitter();

    _.extend(service, ConfiguracaoOrganogramaService);

    service.duplicate = duplicate;
    service.get = get;
    service.getDefault = getDefault;
    service.getEnums = getEnums;
    service.getList = getList;
    service.getSeparador = getSeparador;
    service.getSeparadores = getSeparadores;
    service.getTypes = getTypes;
    service.remove = remove;
    service.save = save;
    service.getListPage = getListPage;

    return service;

    function duplicate(id) {
      return Restangular.all(URI.CONFIG_ORGAN).customPOST(undefined, id + '/duplicar');
    }

    // Funções criadas devido a problemas no select2
    // toda a representação null será tratada como 'nulo'
    function converteSeparadorNullEmNuloNiveis(niveis) {
      _.forEach(niveis, function (nivel) {
        nivel = converteNivelSeparadorNullEmNulo(nivel);
      });
      return niveis;
    }

    function converteNivelSeparadorNullEmNulo(nivel) {
      nivel.separador = _.isNull(nivel.separador) ? 'nulo' : nivel.separador;
      return nivel;
    }

    function converteNivelSeparadorNuloEmNull(nivel) {
      nivel.separador = nivel.separador === 'nulo' ? null : nivel.separador;
      return nivel;
    }

    function get(id) {
      return Restangular.one(URI.CONFIG_ORGAN, id).get().then(function (configuracao) {
        // Troca o 'null' devido a problemas no select2 para 'nulo'
        configuracao.niveis = converteSeparadorNullEmNuloNiveis(configuracao.niveis);
        return configuracao;
      });
    }

    function getDefault() {
      return getMetadata().then(function (configuracaoDefault) {
        var configuracao = configuracaoDefault.representation.default;
        // Troca o 'null' devido a problemas no select2 para 'nulo'
        configuracao.niveis = converteSeparadorNullEmNuloNiveis(configuracao.niveis);
        return _.cloneDeep(configuracao);
      });
    }


    function getList() {
      return Restangular.all(URI.CONFIG_ORGAN).getList().then(function (configuracoes) {
        _.forEach(configuracoes, function (configuracao) {
          // Troca o 'null' devido a problemas no select2 para 'nulo'
          configuracao.niveis = converteSeparadorNullEmNuloNiveis(configuracao.niveis);
        });
        return configuracoes;
      });
    }

    function getMetadata() {
      var deferred = $q.defer();
      if (!meta) {
        Restangular.all(URI.CONFIG_ORGAN).options().then(function (data) {
          meta = data;
          deferred.resolve(meta);
        }, function (data) {
          deferred.reject(data);
        });
      } else {
        deferred.resolve(meta);
      }
      return deferred.promise;
    }

    function getSeparador(value) {
      return getEnums().then(function (enums) {
        return UtilsService.getFromValue(enums.NivelOrganogramaSeparadorEnum.values, value);
      });
    }

    function getSeparadores(filter) {
      return getEnums().then(function (enums) {
        return UtilsService.getFilteredValues(enums.NivelOrganogramaSeparadorEnum.values, filter);
      });
    }

    function getEnums() {
      return getMetadata().then(function (data) {
        _.forEach(data.enums.NivelOrganogramaSeparadorEnum.values, function (value) {
          // Troca o 'null' devido a problemas no select2 para 'nulo'
          if (_.isUndefined(value.value)) {
            value.value = 'nulo';
          }
          ;
        });
        return _.cloneDeep(data.enums);
      });
    }

    function getTypes() {
      return getMetadata().then(function (data) {
        return angular.copy(data.types);
      });
    }

    function remove(id) {
      return Restangular.one(URI.CONFIG_ORGAN, id).remove().then(emitDirty);
    }

    function save(data) {
      var rest = Restangular.all(URI.CONFIG_ORGAN);
      var configuracao;

      // Troca o 'nulo' para null devido a problemas no select2
      // antes de enviar para o back-end
      configuracao = _.cloneDeep(data);

      _.forEach(configuracao.niveis, function (nivel) {
        nivel = converteNivelSeparadorNuloEmNull(nivel);
      });
      return !configuracao.id ?
        rest.post(configuracao).then(emitDirty) :
        rest.customPUT(configuracao, configuracao.id).then(emitDirty);
    }

    function emitDirty(data) {
      service.emit('dirty');
      return data;
    }

    function getListPage(params) {
      params = params || {};
      params.offset = params.offset || 0;
      return Restangular.one(URI.CONFIG_ORGAN)
                        .one(URI.PAGE)
                        .get(params);
    }

  }
})();
