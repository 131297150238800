(function () {
    'use strict';

    angular.module('core.integracao')
            .directive('coreContratosAditivosSelect', contratosAditivosSelect);

    function contratosAditivosSelect() {
        return {
            restrict: 'E',
            template: '<ui-select filtro-Contrato="filtroContrato" ng-model="ngModel" ng-required="ngRequired" search="vm.search" minimum-input-length="0" format-result="vm.formatResult(data)"></ui-select>',
            scope: {
                filtroContrato: '@',
                ngModel: '=ngModel',
                ngRequired: '=?',
                minimumInputLength: '@',
                criterionSearch: '=?'
            },
            compile: function (element, attributes) {
                element.children('ui-select').attr('minimum-input-length', attributes.minimumInputLength);
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'bfc.$$PermissionsService',
        'core.ContratacaoServiceContratos'
    ];

    function Controller($scope, PermissionsService, service) {
        var vm = this;
        vm.search = search;
        vm.formatResult = formatResult;
        vm.params = $scope.criterionSearch;

        function search(params) {
            var paramsDefault = '(sequencial like "' + params.term + '")';
            params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
            //params = {};
            return service.getListAditivos($scope.filtroContrato, params).then(function (data) {
                return data;
            });
        }

        function formatResult(value) {
            return value.numeroAno;
        }
    }
})();
