(function () {
    'use strict';

    angular
        .module('core.loteamento')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var loteamento = {
            route: {
                url: '/loteamento',
                data: {
                    id: 'LoteamentoPageMapping'
                },
                views: {
                    '@main.endereco': {
                        templateUrl: 'loteamento.html',
                        controller: 'core.LoteamentoController',
                        controllerAs: 'loteamento'
                    }
                }
            }
        };

        stateProvider
            .from('core-endereco')
            .create('core-loteamento', loteamento.route);
    }
})();