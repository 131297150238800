(function() {
	'use strict';
	/**
	 * @ngdoc service
	 * @name contratos-core.utils.ctr-datetime.service
	 * @description
	 * Serviço que retorna datas formatadas para que trabalhe corretamente no front-end de acordo com a máscara necessário sendo que
	 será utilizada para mostrar no front-end e para enviar do front-end para o back-end.
	 **/
	angular
	  .module('core.commons')
	  .service('core.DateTimeService', DateTimeService);

	DateTimeService.$inject = ['$filter'];

	function DateTimeService($filter) {
		var service = {
			convertDateTimeToFrontSecond: convertDateTimeToFrontSecond,
			convertDateTimeToFrontMinute: convertDateTimeToFrontMinute,
			convertDateTimeToBackSecond: convertDateTimeToBackSecond,
			getToday: getToday,
			getNow: getNow,
			getJustNow: getJustNow
		};
		angular.extend(this, service);

		function convertDateTimeToFrontSecond(data) {
			if (!_.isUndefined(data)) {
				if (moment(data).isValid()) {
					return moment(data).format('YYYY-MM-DD HH:mm:ss');
				}
			}
		}

		function convertDateTimeToFrontMinute(data) {
			if (!_.isUndefined(data)) {
				if (moment(data).isValid()) {
					return moment(data).format('YYYY-MM-DD HH:mm');
				}
			}
		}

		function convertDateTimeToBackSecond(data) {
			if (!_.isUndefined(data)) {
				if(moment(data).isValid()) {
					//return moment(data).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
					return moment(data).format("YYYY-MM-DDTHH:mm:ss.SSS")
				}
			}
		 }

		 function getToday() {
			return $filter('date')(new Date(), 'yyyy-MM-dd');
		 }

		 function getNow() {
			//return $filter('date')(new Date().toLocaleString('pt-BR'), 'yyyy-MM-dd hh:mm');
			//return $filter('date')(getDateBrasilianFormatAM(), 'yyyy-MM-dd hh:mm');
			return moment(getDateBrasilianFormatAM()).format("YYYY-MM-DD HH:mm");
		 }

		 function getJustNow() {
			//return $filter('date')(getDateBrasilianFormatAM(), 'yyyy-MM-dd hh:mm:ss');
			return moment(getDateBrasilianFormatAM()).format("YYYY-MM-DD HH:mm:ss");
		 }

		 function getDateBrasilianFormatAM() {
			var options = { hour12: false };
			var dataBrasil = new Date().toLocaleString('pt-BR', options);
			var posicaoEspacoBranco  = parseInt(dataBrasil.indexOf(' '));
			var posicaoPrimeiroDoisPontos = parseInt(dataBrasil.indexOf(':'));
			var posicaoSegundoDoisPontos = parseInt(dataBrasil.indexOf(':', posicaoPrimeiroDoisPontos + 1));
			var posicaoEspacoBrancoFinal = parseInt(dataBrasil.indexOf(' ', posicaoSegundoDoisPontos));

			var ano  =dataBrasil.substr(6,4).trim();
			var mes = dataBrasil.substr(3,2).trim();
			var dia = dataBrasil.substr(0,2).trim();
			var hora = dataBrasil.substr(posicaoEspacoBranco, posicaoPrimeiroDoisPontos - posicaoEspacoBranco).trim();
			var minuto = dataBrasil.substr(posicaoPrimeiroDoisPontos + 1, posicaoSegundoDoisPontos - posicaoPrimeiroDoisPontos -1).trim();
			var segundo = dataBrasil.substr(posicaoSegundoDoisPontos + 1, 2).trim();
			if (mes.length === 1) mes = '0' + mes;
			if (dia.length === 1) dia = '0' + dia;
			if (hora.length === 1) hora = '0' + hora;
			if (minuto.length === 1) minuto = '0' + minuto;
			if (segundo.length === 1) segundo = '0' + segundo;

			return ano + '-' + mes + '-' + dia + ' ' + hora + ':' + minuto + ':' + segundo;
		 }


	}
})();
