(function () {
    'use strict';

    angular
        .module('core.condominio')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var condominio = {
            route: {
                url: '/condominio',
                data: {
                    id: 'CondominioPageMapping'
                },
                views: {
                    '@main.endereco': {
                        templateUrl: 'condominio.html',
                        controller: 'core.CondominioController',
                        controllerAs: 'condominio'
                    }
                }
            }
        };

        stateProvider
            .from('core-endereco')
            .create('core-condominio', condominio.route);
    }
})();