(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.loteamento
     * @description
     * # contratos-core.loteamento
     * Módulo de endereços
     **/
    angular
            .module('core.loteamento', ['core', 'core.commons', 'core.endereco', 'core.bairro', 'core.distrito', 'core.municipio']);
})();
