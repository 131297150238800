(function() {
	'use strict';

	angular
	  .module('core.integracao')
	  .factory('core.PlanejamentoResources', PlanejamentoResources);

	PlanejamentoResources.$inject = ['Restangular', 'URL_BASE_API_PLANEJAMENTO'];

	function PlanejamentoResources(Restangular, URL_BASE_API_PLANEJAMENTO) {
        return Restangular.withConfig(function(RestangularConfigurer) {
        	RestangularConfigurer.setBaseUrl(URL_BASE_API_PLANEJAMENTO);
        	RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
        });
	}
})();
