(function() {
    'use strict';

    angular.module('ui-components')
        .provider('uiNumberFormatConfig', NumberFormatConfigProvider);

    NumberFormatConfigProvider.$inject = [];

    function NumberFormatConfigProvider() {
        this.precision = '12,2';
        this.allowNegative = false;

        this.$get = function() {
            return {
                precision: this.precision,
                allowNegative: this.allowNegative
            };
        };

        this.setPrecision = function(precision) {
            this.precision = precision;
        };

        this.setAllowNegative = function(allowNegative) {
            this.allowNegative = allowNegative;
        };
    }
})();