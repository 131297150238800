(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.veiculos-publicacao.service:VeiculosPublicacaoSelect
   *
   * @description
   * Serviço do select2 para o veiculos de publicacao
   *
   * @requires bfc.Select2
   * @requires VeiculosPublicacaoService
   **/
  angular
    .module('core.veiculos-publicacao')
    .service('core.VeiculosPublicacaoSelect', VeiculosPublicacaoSelect);

  VeiculosPublicacaoSelect.$inject = [
    'bfc.Select2',
    'core.VeiculosPublicacaoService',
    'CONFIG_SELECT2'
  ];

  function VeiculosPublicacaoSelect(Select2,
                                    VeiculosPublicacaoService,
                                    CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var methods = {
        onAdd: onAdd,
        id: options.id ? options.id : id,
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('VEICULO_PUBLICACAO'),
        dropdownAutoWidth: false
      };

      function formatResult(veiculoPublicacao) {
        return veiculoPublicacao.text ? veiculoPublicacao.text : veiculoPublicacao.fornecedor.pessoa.nome;
      }

      function formatSelection(veiculoPublicacao) {
        return veiculoPublicacao.fornecedor && veiculoPublicacao.fornecedor.pessoa.nome;
      }

      function formatValue(veiculoPublicacao) {
        return veiculoPublicacao['plain'] ? veiculoPublicacao.plain() : veiculoPublicacao;
      }

      function initSelection(element, callback) {
        var id = $(element).val();
        if (id) {
          VeiculosPublicacaoService.get(id).then(callback);
        }
      }

      function id(veiculoPublicacao) {
        if (veiculoPublicacao) {
          return veiculoPublicacao.id;
        }
      }

      function result(params, callback) {
        var filter = '(nomefornecedor like "' + encodeURI(params.term) + '"';
        filter = filter + " and ativo = " + true + ")";
        var promise = VeiculosPublicacaoService.getList({
          offset: 0,
          limit: 999,
          filter: filter
        });

        promise.then(function (veiculosPublicacao) {
          veiculosPublicacao = veiculosPublicacao.plain();
          callback({
            results: veiculosPublicacao.content,
            more: veiculosPublicacao.hasNext
          });
        });
        return promise;
      }

      function onAdd(value, target) {
        Select2.set(target, value);
        return false;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
