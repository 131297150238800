(function () {
  'use strict';
  angular
    .module('core.configuracao-casa-decimal')
    .service('core.ConfiguracaoCasaDecimalService', configuracaoCasaDecimalService);

  configuracaoCasaDecimalService.$inject = [
    '$q',
    'Restangular',
    'ui.components.EventEmitter'
  ];

  function configuracaoCasaDecimalService($q,
                                     Restangular,
                                     EventEmitter) {
    var meta,
      uri = [
        'configuracaocasasdecimais',
        'configinicialcasasdecimais'
      ],
      service = new EventEmitter();

    _.extend(service, configuracaoCasaDecimalService);

    service.get = get;
    service.getConfigInicial = getConfigInicial;
    service.getDefault = getDefault;
    service.getList = getList;
    service.remove = remove;
    service.save = save;
    service.emitDirty = emitDirty;

    return service;

    function get(id) {
      return Restangular.one(uri[0], id).get();
    }

    function getConfigInicial(id) {
      return Restangular.one(uri[1], id).get();
    }

    function getDefault() {
      return getMetadata().then(function (data) {
        return angular.copy(data.representation.default);
      });
    }

    function getList(params) {
      params = params || {};
      params.offset = params.offset || 0;
      return Restangular.one(uri[0])
        .get(params);
    }

    function getMetadata() {
      var deferred = $q.defer();
      if (!meta) {
        Restangular.all(uri[0]).options().then(function (data) {
          meta = data;
          deferred.resolve(meta);
        }, function (data) {
          deferred.reject(data);
        });
      } else {
        deferred.resolve(meta);
      }
      return deferred.promise;
    }

    function remove(id) {
      return Restangular.one(uri[0], id)
        .remove()
        .then(emitDirty);
    }

    function save(data) {
      var rest = Restangular.all(uri[0]);
      return rest.post(data).then(emitDirty) 
    }

    function emitDirty(data) {
      service.emit('dirty');
      return data;
    }
  }
})();
