(function () {
    'use strict';
    angular
        .module('core.commons')
        .factory('core.RegrasGeraisService', regrasGeraisService);

    regrasGeraisService.$inject = [];

    function regrasGeraisService() {
        var service = {
            get: get
        };

        return service;

        function get(data) {
            data = !data ? {} : data;
            return {
                precoMedio: data.precoMedio || 8,
                quantidade: data.quantidade || 3,
                valorUnitario: data.valorUnitario || 4,
                percentualDesconto: data.percentualDesconto || 4,
                valorTotal: {
                    precisao: data.valorTotalPrecisao || 2,
                    tipo: data.valorTotalTipo || BigNumber.ROUND_HALF_EVEN
                }
            };
        }
    }
})();