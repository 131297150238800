(function() {
	'use strict';
	/**
	 * @ngdoc service
	 * @name contratos-core.municipio.service:MunicipioModalService
	 * @description Serviço para abrir o cadastro de municípios
	 * @requires ModalCadFactory
	 **/
	angular
			.module('core.municipio')
			.service('core.MunicipioModalService', MunicipioModalService);

	MunicipioModalService.$inject = ['ui.components.Modal'];

	function MunicipioModalService(uiModal) {

		var modal = {
			templateUrl: 'municipio-cad/municipio-cad.html',
			controller: 'core.MunicipioCadController',
			controllerAs: 'municipioCad',
			params: {
				municipioId: null
			}
		};

		var service = {
			cadastrar: cadastrar,
			editar: editar,
			onAdd: onAdd
		};

		angular.extend(this, service);

		function abrir(modal) {
			 return uiModal.open(modal).result;
		}

		function copia(modal) {
			return angular.copy(modal);
		}

		function cadastrar() {
			var m = copia(modal);
			m.resolve = {
				municipioId: null
			};
			abrir(m);
		}

		function editar(municipio) {
			var m = copia(modal);
			m.params = {
				municipioId: municipio.id
			};
			abrir(m);
		}

	    function onAdd(params){
            var m = copia(modal);
            m.params = {
              municipioId: null,
                params: params
            };
            return uiModal.open(m).result;
        }
	}
})();
