(function() {
	'use strict';

	angular
	  .module('core.commons')
	  .filter('regraGeralFilter', regraGeralFilter);

	regraGeralFilter.$inject = [
		'$filter'
	];

	function regraGeralFilter($filter) {
		return function(data, rules, isFraction) {
			if(isFraction || isFraction === undefined) {
				var convertNumber = Number(data);
				var result = new BigNumber(convertNumber).toFixed(rules.precisao, rules.tipoVisualizacao);
				return $filter('number')(result, rules.precisao);
			}else{
				var _result = data instanceof BigNumber ? data.toFixed() : data;
				return $filter('number')(_result);
			}
		};
	}
})();
