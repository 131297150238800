(function() {
    'use strict';

    /**
     * @ngdoc service 
     * @name contratos.pessoa.service:PessoaService
     * @descripition 
     * Serviço de pessoa
     * @requires Restangular 
     * @requires $q 
     **/
    angular
            .module('core.pessoa')
            .service('core.PessoaService', PessoaService);

    PessoaService.$inject = ['$q', 'Restangular'];

    function PessoaService($q, Restangular) {
        var meta;
        var URI = {
            pessoas: 'pessoas'
        };

        var service = {
            consulta: consulta,
            get: get,
            getList: getList,
            getDefault: getDefault,
            getTypes: getTypes,
            save: save,
            remove: remove
        };

        angular.extend(this, service);

        /**
         * @ngdoc method
         * @name contratos.pessoa.PessoaService#get
         * @methodOf contratos.pessoa.service:PessoaService
         * @description Retorna a pessoa especificado pelo id.
         * @param {Number} id identificação do fornecedor
         * @returns {promise} promise 
         **/
        function get(id) {
            return Restangular.one(URI.pessoas, id).get();
        }

        /**
         * @ngdoc method
         * @name contratos.pessoa.PessoaService#getList
         * @methodOf contratos.pessoa.service:PessoaService
         * @description Listagem de pessoas
         * @param {string} filter Filtro
         * @returns {promise} promise
         **/
        function getList(filter) {
            return Restangular.all(URI.pessoas).getList({
                filter: filter
            }).then(function(todos) {
                _(todos).forEach(function(value, key) {
                    todos[key] = value.plain();
                });
                return todos;
            });
        }
        

        /**
         * @ngdoc method
         * @name contratos.pessoa.PessoaService#getMetadata
         * @methodOf contratos.pessoa.service:PessoaService
         * @description Retorna os metadados trazidos pelo verbo HTTP OPTIONS
         * @returns {promise} promise
         **/
        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(URI.pessoas).options().then(function(data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function(data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        /**
         * @ngdoc method
         * @name contratos.pessoa.PessoaService#getDefault
         * @methodOf contratos.pessoa.service:PessoaService
         * @description Retorna a representação default de um pessoas     
         * @returns {promise} promise
         **/
        function getDefault() {
            return getMetadata().then(function(data) {
                return angular.copy(data.representation.default);
            });
        }

        /**
         * @ngdoc method
         * @name contratos.pessoa.PessoaService#getTypes
         * @methodOf contratos.pessoa.service:PessoaService
         * @description Retorna as especificações dos campos de um pessoas     
         * @returns {promise} promise
         **/
        function getTypes() {
            return getMetadata().then(function(data) {
                return angular.copy(data.types);
            });
        }

        /**
         * @ngdoc method
         * @name contratos.pessoa.PessoaService#save
         * @methodOf contratos.pessoa.service:PessoaService
         * @description Salva o fornecedor 
         * @param {ModelO} fornecedor Objeto fornecedor para salvar
         * @returns {promise} promise
         **/
        function save(pessoa) {
            var rest = Restangular.all(URI.pessoas);
            return !pessoa.id ?
                    rest.post(pessoa) :
                    rest.customPUT(pessoa, pessoa.id);
        }

        /**
         * @ngdoc method
         * @name contratos.pessoa.PessoaService#remove
         * @methodOf contratos.pessoa.service:PessoaService
         * @description Remove o fornecedor     
         * @returns {promise} promise
         **/
        function remove(id) {
            return Restangular.one(URI.pessoas, id).remove();
        }

        function consulta(texto) {
            return Restangular.all(URI.pessoas).getList({
                consulta: texto
            }).then(function(pessoas) {
                _(pessoas).forEach(function(value, key) {
                    pessoas[key] = value.plain();
                });
                return pessoas;
            });
        }
    }
})();