(function() {
    'use strict';
    /**
     * @ngdoc service
     * @name contratos-core.utils.MasksService
     * @description
     * Serviço que retorna os valores passados já mascarados
     **/

    angular
        .module('core.commons')
        .service('core.MasksService', MasksService);

    MasksService.$inject = ['$filter'];

    function MasksService($filter) {
        var uri = [];

        var service = {
            getCpf: getCpf,
            getTelefoneFormatado: getTelefoneFormatado,
            getMascaraDocumento: getMascaraDocumento
        };

        angular.extend(this, service);

        function getCpf() {
            return '000.000.000-00';
        }

        function getTelefoneFormatado() {
            return '(00) 00000-0000';
        }

        function getMascaraDocumento(documento) {
            //console.log(documento);
            if (documento && documento.length <= 11) {
                return '000.000.000-000';
            }
            return '00.000.000/0000-00';
        }
    }
})();
