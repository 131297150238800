(function() {
    'use strict';

    angular
            .module('core.exercicio')
            .controller('core.ExercicioCadController', ExercicioCadController);

    ExercicioCadController.$inject = [
        '$rootScope',
        '$q',
        '$scope',
        '$modalTracker',
        '$modalInstance',
        'bfc.FocusCtrl',
        'bfc.Notification',
        'core.TrackerFactory',
        'core.ConfiguracaoOrganogramaService',
        'core.ConfiguracaoOrganogramaCadService',
        'core.ExercicioService',
        'ano',
        'ContratosConfirmService'
    ];

    function ExercicioCadController(
            $rootScope,
            $q,
            $scope,
            $modalTracker,
            $modalInstance,
            Focus,
            Notification,
            TrackerFactory,
            ConfiguracaoOrganogramaService,
            ConfiguracaoOrganogramaCadService,
            ExercicioService,
            ano,
            ContratosConfirmService
            ) {

        var exercicioCadController = this;

        //Usado para teste unitário
        exercicioCadController.argumentos = ExercicioCadController.$inject;

        // Propriedades
        exercicioCadController.exercicio = {};
        exercicioCadController.configuracoesOrganograma = [];
        exercicioCadController.loadings = {};
        exercicioCadController.ultimoExercicio = {};

        // Métodos
        exercicioCadController.adicionar = adicionar;
        exercicioCadController.adicionando = adicionando;
        exercicioCadController.buscaExercicio = buscaExercicio;
        exercicioCadController.cancelar = cancelar;
        exercicioCadController.configuracaoOrganogramaSelecionada = configuracaoOrganogramaSelecionada;
        exercicioCadController.desabilitaSalvamento = desabilitaSalvamento;
        exercicioCadController.editando = editando;
        exercicioCadController.eProximoExercicio = eProximoExercicio;
        exercicioCadController.foraDeUso = foraDeUso;
        exercicioCadController.fechar = fechar;
        exercicioCadController.emUso = emUso;
        exercicioCadController.editar = editar;
        exercicioCadController.excluir = excluir;
        exercicioCadController.naoExisteUltimoExercicio = naoExisteUltimoExercicio;
        exercicioCadController.salvar = salvar;
        exercicioCadController.salvarAdicionar = salvarAdicionar;
        exercicioCadController.usar = usar;

      inicializarLoadings();
        inicializar();

        function inicializar() {
            inicializarEscutaEventos();
            inicializarPromises();
        }

        function inicializarPromises() {
            var promise = $q.all([
                inicializarExercicio(),
                getTypes(),
                getUltimoExercicio(),
                getConfiguracoesOrganograma()
            ]);
            $modalTracker.addPromise(promise);
            return promise;
        }

        function inicializarExercicio() {
            var promise = ano ? getExercicio(ano) : getProximoExercicio();
            promise.then(function(e) {
                exercicioCadController.exercicio = _.cloneDeep(e);
            });
            return promise;
        }

        function getConfiguracoesOrganograma() {
            var promise = ConfiguracaoOrganogramaService.getList();
            promise.then(function(configuracoesOrganograma) {
                exercicioCadController.configuracoesOrganograma = configuracoesOrganograma;
            });
            return promise;
        }

        function getExercicio() {
            return ExercicioService.getExercicio(ano);
        }

        function getExercicioDefault() {
            return ExercicioService.getDefault();
        }

        function getProximoExercicio() {
            var promise = $q.defer();

            getExercicioDefault().then(function(exercicioDefault) {
                exercicioCadController.exercicio = exercicioDefault;
                ExercicioService.getProximoExercicio().then(function(exercicio) {
                    exercicioCadController.exercicio.exercicio = exercicio;
                    promise.resolve(exercicioCadController.exercicio);
                    exercicioCadController.form.$setDirty();
                });
            });
            return promise.promise;
        }

        function getTypes() {
            var promise = ExercicioService.getTypes();
            promise.then(function(types) {
                exercicioCadController.types = types;
            });
            return promise;
        }

        function getUltimoExercicio() {
            var promise = ExercicioService.getUltimoExercicio();
            promise.then(function(ultimoExercicio) {
                exercicioCadController.ultimoExercicio = _.cloneDeep(ultimoExercicio);
            });
            return promise;
        }


        function inicializarLoadings() {
            exercicioCadController.loadings = TrackerFactory.init([
                'busca',
                'configuracoesOrganograma',
                'excluir',
                'salvar',
                'salvarAdicionar'
            ]);
        }

        function inicializarEscutaEventos() {
            $scope.$on('refreshConfiguracaoOrganograma', getConfiguracoesOrganograma);
        }

        function adicionando() {
            return !editando();
        }

        function adicionar() {
            ConfiguracaoOrganogramaCadService.nova();
        }

        function adicionarNovo() {
            inicializarPromises().then(function() {
                exercicioCadController.form.$setPristine();
                Focus.set('lbExercicioCadControllerExercicio');
            });
        }

        function buscaExercicio() {
            var anoExercicio = _.cloneDeep(exercicioCadController.exercicio.exercicio);
            if (exercicioValido('mostrar')) {
                var promise = ExercicioService.getExercicio(anoExercicio);

                promise.then(function(exercicio) {
                    if (_.isEmpty(exercicio)) {
                        getExercicioDefault().then(function(exercicioDefault) {
                            exercicioCadController.exercicio = exercicioDefault;
                            exercicioCadController.exercicio.exercicio = anoExercicio;
                        });
                    } else {
                        exercicioCadController.exercicio = exercicio;
                        exercicioCadController.form.$setPristine();
                    }
                });
                exercicioCadController.loadings.busca.addPromise(promise);
            }
        }

        function exercicioValido(msg) {
            var e = exercicioCadController.exercicio && exercicioCadController.exercicio.exercicio;
            var valido = true;

            if (e === undefined) {
                valido = false;
            } else if (e.toString().length !== 4) {
                valido = false;
            } else if (e > (exercicioCadController.ultimoExercicio && exercicioCadController.ultimoExercicio.exercicio + 1)) {
                //Inserido validação para não corrigir erro SAR 238584
                if (msg) {
                    Notification.publish('O ano do novo exercício não pode ser maior que o ano de ' + (exercicioCadController.ultimoExercicio.exercicio + 1) + '.', 'error');
                }
                valido = false;
            }

            return valido;
        }

        function eProximoExercicio() {
            return exercicioCadController.exercicio &&
                    exercicioCadController.exercicio.exercicio &&
                    exercicioCadController.ultimoExercicio &&
                    exercicioCadController.ultimoExercicio.exercicio &&
                    exercicioCadController.exercicio.exercicio === exercicioCadController.ultimoExercicio.exercicio + 1;
        }

        function cancelar() {
            fechar();
        }

        function configuracaoOrganogramaSelecionada() {
            var retorno = exercicioCadController.exercicio &&
                    exercicioCadController.exercicio.configuracaoOrganograma &&
                    exercicioCadController.exercicio.configuracaoOrganograma !== null &&
                    exercicioCadController.exercicio.configuracaoOrganograma !== undefined;
            return retorno;
        }


        function desabilitaSalvamento() {
            return (exercicioCadController.form.$dirty && exercicioCadController.form.$invalid) ||
                    exercicioCadController.form.$pristine ||
                    !configuracaoOrganogramaSelecionada() ||
                    !exercicioValido();
        }

        function editando() {
            return exercicioCadController.exercicio && exercicioCadController.exercicio.id;
        }


        function excluir() {
            var promise = ExercicioService.remover(exercicioCadController.exercicio);
            promise.then(function() {
                Notification.publish('Exercício excluído com sucesso', 'success');
                fechar();
            });
            exercicioCadController.loadings.excluir.addPromise(promise);

        }

        function foraDeUso(configuracaoOrganograma) {
            return !emUso(configuracaoOrganograma);
        }

        function emUso(configuracaoOrganograma) {
            return exercicioCadController.exercicio &&
                    exercicioCadController.exercicio.configuracaoOrganograma &&
                    configuracaoOrganograma &&
                    configuracaoOrganograma.id &&
                    exercicioCadController.exercicio.configuracaoOrganograma.id === configuracaoOrganograma.id;
        }

        function editar(configuracaoOrganograma) {
            ConfiguracaoOrganogramaCadService.editar(configuracaoOrganograma);
        }

        function fechar() {
            $modalInstance.close();
        }

        function naoExisteUltimoExercicio() {
            var u = exercicioCadController.ultimoExercicio;
            var resultado = ((u === null) || (u === undefined) || _.isEmpty(u));
            return resultado;
        }

        function salvaExercicio() {
            var exercicio = exercicioCadController.exercicio;
            getExercicio(exercicio.exercicio).then(function(exercicioOriginal) {

                var permiteAtualizarConfiguracao = angular.isDefined(exercicioOriginal) ? (exercicioOriginal.configuracaoOrganograma.id === exercicio.configuracaoOrganograma.id) : true;
                var promisse = ExercicioService.getExercicioAnterior(exercicio).then(function(exercicioAnterior) {
                   
                    if (angular.isDefined(exercicioAnterior) && (!permiteAtualizarConfiguracao)){
                        return exibirConfirmar(exercicioAnterior, exercicio);
                    }
                    if(angular.isUndefined(exercicio.id) && (!permiteAtualizarConfiguracao)){
                        return exibirConfirmar(exercicioAnterior, exercicio);                            
                    }                
                    return continuarSalvarExercicio()            
                });
                exercicioCadController.loadings.salvar.addPromise(promisse);
            });                       
        }
        
        function continuarSalvarExercicio() {
            return ExercicioService
                    .salvar(exercicioCadController.exercicio)
                    .then(function(exercicio) {
                        exercicioCadController.exercicio = exercicio;
                        Notification.publish('Exercício alterado com sucesso', 'success');
                        fechar();
                    });
        }

        function salvar() {
            salvaExercicio();
        }

        function salvarAdicionar() {
            exercicioCadController.loadings.salvarAdicionar.addPromise(salvaExercicio().then(adicionarNovo));
        }

        function usar(configuracaoOrganograma) {
            exercicioCadController.exercicio.configuracaoOrganograma = _.cloneDeep(configuracaoOrganograma);
            Focus.set('btnExercicioCadControllerSalvar');
            exercicioCadController.form.$setDirty();
        }

        function exibirConfirmar(exercicioAnterior, exercicio) {
            if(angular.isDefined(exercicioAnterior) && (exercicioAnterior.configuracaoOrganograma.id != exercicio.configuracaoOrganograma.id)){                
                ContratosConfirmService.confirm({
                    title: 'Confirmar alteração de organograma',
                    body:   getTemplate(exercicioAnterior, exercicio)
                }, function sim() {	                                       
                    return continuarSalvarExercicio();
                }, function nao() { return; });
            }else{                
                return continuarSalvarExercicio();
            }
        }

        function getTemplate(exercicioAnterior, exercicio) {
            var exercicioAnteriorNumero = angular.isDefined(exercicioAnterior) ? exercicioAnterior.exercicio : exercicio.exercicio -1;
            return 'Para o exercício de <strong>'+(exercicio.exercicio)+'</strong>, foi selecionada uma <i>Configuração de Organograma</i> diferente do ano anterior <strong>'+(exercicioAnteriorNumero)+'</strong>.'
                    +'<p></p>'
                    +'Recomenda-se alterar a configuração de um ano para o outro apenas se a <strong>Estrutura Organizacional da sua Entidade</strong> realmente foi modificada.' 
                    +'<p></p>'
                    +'Em caso de dúvidas, consulte o seu canal de suporte. Você deseja continuar?';
        }


    }
})();
