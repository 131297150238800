(function () {
    'use strict';
    angular
        .module('core.agencia-bancaria')
        .controller('core.AgenciaBancariaController', agenciaBancariaController);

    agenciaBancariaController.$inject = [
        '$scope',
        '$injector'
    ];

    function agenciaBancariaController($scope,
                                       $injector) {

        var viewModel = this;

        var TrackerFactory = $injector.get('core.TrackerFactory'),
            Notification = $injector.get('bfc.Notification'),
            AgenciaBancariaService = $injector.get('core.AgenciaBancariaService'),
            AgenciaBancariaCadModalService = $injector.get('core.AgenciaBancariaCadModalService');

        inicializa();

        function inicializa() {
            inicializaMetodos();
            inicializaPropriedadesPadroes();
            escutaEventos();
            inicializaLoadings();
        }

        function inicializaPropriedadesPadroes() {
            viewModel.service = AgenciaBancariaService;
            viewModel.argumentos = agenciaBancariaController.$inject;
            viewModel.columns = [
                {
                    id: 'nome',
                    label: 'Grupo',
                    model: 'nome',
                    filterable: true
                },
                {
                    id: 'responsavel',
                    label: 'Responsável',
                    model: 'responsavel',
                    filterable: true
                },
                {
                    id: 'cpfcnpj',
                    label: 'Cpf',
                    model: 'cpfcnpj',
                    filterable: true
                },
                {
                    id: 'funcao',
                    label: 'Função',
                    model: 'funcao',
                    filterable: true
                },
                {
                    id: 'complemento',
                    label: 'Complemento',
                    model: 'complemento',
                    filterable: true
                }
            ];
        }

        function inicializaMetodos() {
            viewModel.cadastrar = cadastrar;
            viewModel.editar = editar;
            viewModel.excluir = excluir;
        }

        function escutaEventos() {
            $scope.$on('agenciaBancaria:actionRemove', function (eventos, params) {
                angular.element('#btnAgenciaBancariaExcluir-' + params.id).triggerHandler('click');
            });
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init(['listagem']);
        }

        function cadastrar() {
            AgenciaBancariaCadModalService.cadastrar();
        }

        function editar(item) {
            AgenciaBancariaCadModalService.editar(item);
        }

        function excluir(item) {
            var promise = AgenciaBancariaService.remove(item.id);
            return promise.then(function () {
                Notification.publish('Agência bancária excluída com sucesso', 'success');
            }, function () {
                return promise;
            });
        }
    }
})();