(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.configuracao-organograma.controller:ConfiguracaoOrganogramaCadController
   * @description
   * Controller do cadastro de Configuração de Organograma do módulo contratos-core.configuracao-organograma
   * @requires $rootScope
   * @requires $q
   * @requires $modalInstance
   * @requires promiseTracker
   * @requires bfc.FocusCtrl
   * @requires Select2Factory
   * @requires ConfiguracaoOrganogramaService
   * @requires SeparadorSelect
   * @param {Number} id Id da Configuração de Organograma a ser editada, se ```undefined``` considera-se que é uma nova configuração.
   */
  angular
    .module('core.configuracao-organograma')
    .controller('core.ConfiguracaoOrganogramaCadController', ConfiguracaoOrganogramaCadController);

  ConfiguracaoOrganogramaCadController.$inject = [
    '$rootScope',
    '$q',
    '$modalInstance',
    '$modalTracker',
    'promiseTracker',
    'bfc.FocusCtrl',
    'core.Select2Factory',
    'core.ConfiguracaoOrganogramaService',
    'core.SeparadorSelect',
    'id',
    'core.TrackerFactory',
    'bfc.Notification'
  ];

  function ConfiguracaoOrganogramaCadController($rootScope,
                                                $q,
                                                $modalInstance,
                                                $modalTracker,
                                                promiseTracker,
                                                Focus,
                                                Select2Factory,
                                                ConfiguracaoOrganogramaService,
                                                SeparadorSelect,
                                                id,
                                                TrackerFactory,
                                                Notification) {

    var viewModel = this;

    viewModel.argumentos = ConfiguracaoOrganogramaCadController.$inject;
    viewModel.addNivel = addNivel;
    viewModel.cancel = cancel;
    viewModel.downDigito = downDigito;
    viewModel.duplicate = duplicate;
    viewModel.existeConfiguracao = existeConfiguracao;
    viewModel.isAdd = isAdd;
    viewModel.isLastNivel = isLastNivel;
    viewModel.isNivel = isNivel;
    viewModel.isNivelMinimo = isNivelMinimo;
    viewModel.isPrimeiroNivel = isPrimeiroNivel;
    viewModel.isRegistered = isRegistered;
    viewModel.isUltimoNivel = isUltimoNivel;
    viewModel.remove = remove;
    viewModel.removeNivel = removeNivel;
    viewModel.save = save;
    viewModel.upDigito = upDigito;
    viewModel.permiteAdicionarNiveis = permiteAdicionarNiveis;

    initTrackers();
    init();

    function actionDuplicate(configuracaoOrganograma) {
      refresh(configuracaoOrganograma, 'duplicate');
    }

    function actionRemove(configuracaoOrganograma) {
      refresh(configuracaoOrganograma, 'remove');
    }

    function actionSave(configuracaoOrganograma, continuar) {
      refresh(configuracaoOrganograma, 'save', continuar);
    }

    function addNivel() {
//			if (!permiteAdicionarNiveis()) {
//				return;
//			}

      var length = viewModel.configuracaoOrganograma.niveis.length;
      var nivelAnterior = viewModel.configuracaoOrganograma.niveis[length - 1];

      var novo = {
        nivel: length + 1,
        separador: nivelAnterior.separador,
        digitos: nivelAnterior.digitos || 1
      };

      viewModel.configuracaoOrganograma.niveis.push(novo);
      Focus.set('lbConfiguracaoOrganogramaCadControllerIivel-' + (Number(novo.nivel) - 1));
    }

    function cancel() {
      close();
    }

    function close() {
      $modalInstance.close();
    }

    function downDigito(nivel) {
      if (isNivelMinimo(nivel)) {
        return false;
      }
      nivel.digitos = nivel.digitos > 1 ? Number(nivel.digitos) - 1 : nivel.digitos;
      viewModel.form.$setDirty();
    }

    function duplicate() {
      var promise = ConfiguracaoOrganogramaService.duplicate(viewModel.configuracaoOrganograma.id);
      promise.then(function (configuracaoOrganograma) {
        actionDuplicate(configuracaoOrganograma);
      });
      viewModel.tracker.duplicate.addPromise(promise);
    }

    function existeConfiguracao() {
      return Boolean(hasConfiguracaoOrganograma());
    }

    function hasConfiguracaoOrganograma() {
      return (viewModel.configuracaoOrganograma && viewModel.configuracaoOrganograma.id) ? id : viewModel.configuracaoOrganograma && viewModel.configuracaoOrganograma.id;
    }

    function init() {
      initSelect2();
      initProperties();
    }

    function initConfiguracaoOrganograma() {
      return id ?
        ConfiguracaoOrganogramaService.get(id).then(function (data) {
          viewModel.configuracaoOrganograma = data;
        }) :
        ConfiguracaoOrganogramaService.getDefault().then(function (data) {
          viewModel.configuracaoOrganograma = data;
        });
    }

    function initProperties() {
      $modalTracker.addPromise(
        $q.all([
          ConfiguracaoOrganogramaService.getTypes().then(function (data) {
            viewModel.configOrganogramaProperties = data.ConfiguracaoOrganogramaBO;
            viewModel.nivelOrganogramaProperties = data.NivelOrganogramaBO;
          }),
          initConfiguracaoOrganograma()
        ])
      );
    }

    function initSelect2() {
      var select2 = [{
        element: 'separadores',
        select2: SeparadorSelect
      }];

      viewModel.select2 = Select2Factory.init(select2);
    }

    function initTrackers() {
      viewModel.tracker = TrackerFactory.init(['save', 'remove', 'duplicate', 'saveAdd']);
    }

    function isAdd() {
      return hasConfiguracaoOrganograma() ? 'Editando' : 'Adicionando';
    }

    function isLastNivel(nivel) {
      return nivel.nivel === viewModel.configuracaoOrganograma.niveis.length && !isNivelMinimo(nivel);
    }

    function isNivel(nivel, numero) {
      return nivel.nivel === numero;
    }

    function isNivelMinimo(nivel) {
      return nivel.nivel <= 2;
    }

    function isPrimeiroNivel(nivel) {
      return viewModel.isNivel(nivel, 1);
    }

    function isRegistered() {
      return angular.isDefined(id);
    }

    function isUltimoNivel(configuracaoOrganograma) {
      if (!configuracaoOrganograma) {
        return;
      }
      return configuracaoOrganograma.niveis.length === 13;
    }

    function permiteAdicionarNiveis() {
      var totalDigitos = 0;
      var digitos = 0;
      var totalNiveis = 0;
      angular.forEach(viewModel.configuracaoOrganograma.niveis, function (nivel) {
        digitos = Number(nivel.digitos);
        totalDigitos = totalDigitos + digitos;
        totalNiveis++;
      });
      if (totalNiveis >= 9) {
        alert('A configuração do organograma não pode ter mais de 9 níveis.');
        return false;
      }
      if (totalDigitos > 16) {
        alert('A soma dos dígitos de todos os níveis não pode ultrapassar 16.');
        return false;
      }
      return true;
    }

    function refresh(configuracaoOrganograma, operation, continuar) {
      $rootScope.$broadcast('refreshConfiguracaoOrganograma', {
        configuracaoOrganograma: configuracaoOrganograma,
        operation: operation
      });
      if (!continuar) {
        close();
      }
    }

    function remove() {
      $rootScope.$broadcast('configuracaoOrganograma:actionRemove', {id: viewModel.configuracaoOrganograma.id});
      close();
    }

    function removeNivel(index) {
      viewModel.configuracaoOrganograma.niveis.splice(index, 1);

      var i = 0;
      angular.forEach(viewModel.configuracaoOrganograma.niveis, function (nivel) {
        i++;
        nivel.nivel = i;
      });
      viewModel.form.$setDirty();
      Focus.on('nivel');
    }

    function save(continuar) {
      var tracker = continuar ? viewModel.tracker.saveAdd : viewModel.tracker.save;
      tracker.addPromise(
        ConfiguracaoOrganogramaService.save(viewModel.configuracaoOrganograma).then(function (configuracaoOrganograma) {
          actionSave(configuracaoOrganograma, continuar);
          if (continuar) {
            if (viewModel.form) {
              viewModel.form.$setPristine();
              viewModel.form.$setUntouched();
            }
            id = undefined;
            init();
            Focus.set('lbConfiguracaoOrganogramaCadControllerDescricaoDaConfiguracao');
          } else {
            cancel();
          }
          var mensagem = id ? 'alterado' : 'adicionado';
          Notification.publish('Configuração de organograma ' + mensagem + ' com sucesso', 'success');
        })
      );
    }

    function upDigito(nivel) {
      if (isNivelMinimo(nivel)) {
        return false;
      }
      nivel.digitos = nivel.digitos < 10 ? Number(nivel.digitos) + 1 : nivel.digitos;
      viewModel.form.$setDirty();
    }
  }
})();
