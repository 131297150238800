(function() {

	'use strict';

	angular.module('core.commons')
		.directive('coreMaskNumberInteger', [function() {
			return {
				restrict: 'A',
				require: 'ngModel',
				link: function(scope, elem, attrs, ctrl) {
					if (!ctrl) {
						return;
					}

					ctrl.$formatters.push(function(modelValue) {
						if (modelValue !== 0 && !modelValue) {
							return;
						}
						return modelValue.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, '.');
					});

					ctrl.$parsers.push(function(viewValue) {
						return parseFloat(viewValue.replace(new RegExp(',', 'g'), ''));
					});

					elem.bind('focus', function() {
						if (ctrl.$modelValue !== 0 && !ctrl.$modelValue) {
							return;
						}
						elem.val(ctrl.$modelValue);
					});

					elem.bind('blur', function() {
						if (ctrl.$modelValue !== 0 && !ctrl.$modelValue) {
							return;
						}
						elem.val(ctrl.$modelValue.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, '.'));
					});

					elem.on('keyup', function(e) {
						var LETTERS_PATTERN = /[^\x2B-\x2E0-9]/g;
						var value = e.target.value;

						if (LETTERS_PATTERN.test(value)) {
							setViewValue(e.target.value.replace(LETTERS_PATTERN, ''));
						}
					});

					function setViewValue(value) {
						ctrl.$setViewValue(value);
						ctrl.$render();
					}
				}
			};
		}]);
}());
