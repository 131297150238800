(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name core.organograma.OrganogramaEntidadesSelect
     *
     * @description
     * Serviço do select2 para entidades
     *
     * @requires bfc.Select2
     * @requires EntidadesService
     **/
    angular
        .module('core.organograma')
        .service('EntidadesSelect', entidadesSelect);

    entidadesSelect.$inject = [
        'bfc.Select2',
        'EntidadesService',
        'CONFIG_SELECT2'
    ];

    function entidadesSelect(Select2,
                             EntidadesService,
                             CONFIG_SELECT2) {

        this.select2Config = select2Config;

        function select2Config(options) {
            var metodos = {
                formatResult: formatResult,
                formatSelection: formatSelection,
                formatValue: formatValue,
                initSelection: initSelection,
                result: _.debounce(result, CONFIG_SELECT2.DELAY),
                minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
                maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('ENTIDADES'),
                dropdownAutoWidth: false
            };

            function formatResult(entidadeGestora) {
                return getNomeExibicao(entidadeGestora);
            }

            function formatSelection(entidadeGestora) {
                return getNomeExibicao(entidadeGestora);
            }

            function formatValue(entidadeGestora) {
                return entidadeGestora.id;
            }

            function getNomeExibicao(entidadeGestora) {
                //if (entidadeGestora.text) {
                  //  return entidadeGestora.text;
                //}
                if (entidadeGestora) {
                    return entidadeGestora.nome;
                }
            }

            function initSelection(element, callback) {
                var id = $(element).val();
                if (id) {
                    EntidadesService.getEntidadeGestora(id).then(callback);
                }
            }

            function result(params, callback) {
                var filter;
                if (params.term) {
                    var id = Number(params.term.replace(/[^\w]/g, ''));
                    filter = '(nome like "' + params.term + '")';
                    if (angular.isNumber(id) && !isNaN(id) && id.length < 13) {
                        filter += ' or (id = ' + id + ')';
                    }
                }

                var resultado = EntidadesService.getList(filter);
                resultado.then(function (entidadeGestora) {
                    callback({
                        results: entidadeGestora.content,
                        more: entidadeGestora.hasNext
                    });
                });
                return resultado;
            }

            return Select2.create(angular.extend(metodos, options));
        }
    }
})();     