(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratacao.organograma.service:OrganogramaCadModalService
   * @description Serviço para abrir o cadastro de locais de entrega
   **/
  angular
    .module('core.organograma')
    .service('core.OrganogramaCadModalService', OrganogramaCadModalService);

  OrganogramaCadModalService.$inject = ['core.AcoesCadastrosModalService'];

  function OrganogramaCadModalService(AcoesCadastrosModalService) {

    var modal = {
      templateUrl: 'organograma-cad/organograma-cad.html',
      controller: 'core.OrganogramaCadController',
      controllerAs: 'vm',
      params: {
        identificador_cadastro: null,
        params: {}
      },
      size: 'lg'
    };

    var service = {
      cadastrar: cadastrar,
      editar: editar,
      onAdd: onAdd
    };

    angular.extend(this, service);

    function abrir(modal) {
      return AcoesCadastrosModalService.abrir(modal);
    }

    function cadastrar(configuracaoOrganogramaId) {
      if (configuracaoOrganogramaId) {
        modal.params.params.configuracaoOrganogramaId = configuracaoOrganogramaId;
      }

      AcoesCadastrosModalService.cadastrar(modal);
    }

    function editar(organograma) {
      AcoesCadastrosModalService.editar(modal, organograma);
    }

    function onAdd(params) {
      return AcoesCadastrosModalService.onAdd(modal, params);
    }
  }
})();
