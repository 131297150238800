(function() {
    'use strict';
    /**
     * @ngdoc service
     * @name contratos-core.veiculos-publicacao.service:VeiculosPublicacaoModalService
     * @description Serviço para abrir o cadastro de Veículos de publicação
     * @requires ModalCadFactory
     **/
    angular
        .module('core.veiculos-publicacao')
        .service('core.VeiculosPublicacaoModalService', VeiculosPublicacaoModalService);

    VeiculosPublicacaoModalService.$inject = ['core.ModalCadFactory'];

    function VeiculosPublicacaoModalService(ModalCadFactory) {

        var modal = {
            templateUrl: 'veiculos-publicacao-cad/veiculos-publicacao-cad.html',
            controller: 'core.VeiculosPublicacaoCadController as veiculosPublicacaoCad',
            resolve: {
                VeiculosPublicacaoId: null,
                readOnly: false
            }
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar
        };

        angular.extend(this, service);

        function abrir(modal) {
            ModalCadFactory.open(modal);
        }

        function copia(modal) {
            return angular.copy(modal);
        }

        function cadastrar() {
            var m = copia(modal);
            abrir(m);
        }

        function editar(veiculoPublicacao, isReadOnly) {
            var m = copia(modal);
            m.resolve = {
                VeiculosPublicacaoId: veiculoPublicacao.id,
                readOnly: isReadOnly
            };
            abrir(m);
        }
    }
})();
