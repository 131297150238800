(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.veiculos-publicacao.VeiculosPublicacaoService
     * @description
     * Serviço que se comunica com o back-end de veiculos publicacao
     * @requires $q
     * @requires Restangular
     **/
    angular
            .module('core.veiculos-publicacao')
            .factory('core.VeiculosPublicacaoService', VeiculosPublicacaoService);

    VeiculosPublicacaoService.$inject = [
        '$q',
        'Restangular',
        '$filter',
        'ui.components.EventEmitter'
    ];

    function VeiculosPublicacaoService($q,
            Restangular,
            $filter,
            EventEmitter) {

        var meta;
        var uri = ['veiculospublicacao'];

        var orderBy = $filter('orderBy');

        var service = new EventEmitter();

        _.extend(service, VeiculosPublicacaoService);

        service.get = get;
        service.getList = getList;
        service.getDefault = getDefault;
        service.getEnums = getEnums;
        service.getTypes = getTypes;
        service.get = get;
        service.save = save;
        service.remove = remove;
        service.emitDirty = emitDirty;

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getList(params) {
            params = params || {};

            params.offset = params.offset || 0;
            return Restangular.one(uri[0]).get({
                offset: params.offset,
                limit: params.limit,
                filter: params.filter,
                sort: params.sort
            }).then(function (data) {
                if (params.offset > 0 && data.total <= params.offset) {
                    params.offset = 0;
                    data = getList(params);
                }
                return data;
            });
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri[0]).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getEnums() {
            return getMetadata().then(function (data) {
                return angular.copy(data.enums);
            });
        }

        function getTypes() {
            return getMetadata().then(function (data) {
                return angular.copy(data.types);
            });
        }

        function save(data) {
            var rest = Restangular.all(uri[0]);
            return !data.id ?
                    rest.post(data).then(emitDirty) :
                    rest.customPUT(data, data.id).then(emitDirty);
        }

        //TODO alterar metodo para exclusão diretamente do banco
        function remove(item) {
            return Restangular.one(uri[0], item.id).remove().then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }
    }
})();
