(function() {
    'use strict';

    /**
     * @ngdoc controller
     * @name contratos.tipo-instrumento.controller:TipoInstrumentoCadController
     *
     * @description
     * Controller responsável pelo cadastro de tipos de instrumento de contratação
     *
     * @requires $rootScope
     * @requires $q
     * @requires promiseTracker
     * @requires $modalInstance
     * @requires $modalTracker
     * @requires bfc.FocusCtrl
     **/
    angular
        .module('core.tipo-ato')
        .controller('core.TipoAtoCadController', TipoAtoCadController);

    TipoAtoCadController.$inject = [
        '$injector',
        '$rootScope',
        '$q',
        '$modalInstance',
        '$modalTracker',
        'core.TrackerFactory',
        'bfc.FocusCtrl',
        'bfc.Notification',
        'core.TipoAtoService',
        'CamposAdicionaisService'
    ];

    function TipoAtoCadController(
        $injector,
        $rootScope,
        $q,
        $modalInstance,
        $modalTracker,
        TrackerFactory,
        Focus,
        Notification,
        TipoAtoService,
        CamposAdicionaisService) {
        var viewModel = this;

        var CPA_CONSTANTS = $injector.get('CPA_CONSTANTS');
        var Select2Factory = $injector.get('core.Select2Factory');
        var TipoAtoSugestoesSelect = $injector.get('core.TipoAtoSugestoesSelect');

        viewModel.cancelar = cancelar;
        viewModel.estaAdicionando = estaAdicionando;
        viewModel.salvar = salvar;
        viewModel.existeTipoAto = existeTipoAto;
        viewModel.excluir = excluir;
        viewModel.formataResultado = formataResultado;
        viewModel.desabilitaSalvar = desabilitaSalvar;

        viewModel.adicionando = adicionando;
        viewModel.editando = editando;
        viewModel.buscaTipoAto = buscaTipoAto;

        inicializaLoadings();
        inicializar();

        function inicializar() {
            viewModel.tipoAto = {};
            initEnums();
            inicializaTipoAto();
            inicializaCamposAdicionais();
            initSelect2();
          viewModel.visivel = 1;
        }

        function initEnums() {
            viewModel.classificacao = TipoAtoService.getEnumClassificacaoAto();
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([{
                element: 'descricao',
                select2: TipoAtoSugestoesSelect
            }]);
        }

        function inicializaTipoAto() {
            var promise;
            if ($modalInstance.params.identificador_cadastro) {
                promise = TipoAtoService.get($modalInstance.params.identificador_cadastro);
                promise.then(function(novaTipoAto) {
                    viewModel.tipoAto = novaTipoAto.plain();
                });
            } else {
                promise = TipoAtoService.getDefault();
                promise.then(function(novaTipoAto) {
                    viewModel.tipoAto = novaTipoAto;
                    if ($modalInstance.params.params) {
                        viewModel.tipoAto.descricao = $modalInstance.params.params.descricao;
                    }
                });
            }
            viewModel.loadings.tipoAto.addPromise(promise);
        }

        function inicializaCamposAdicionais() {
            var promise = TipoAtoService.getCamposAdicionais($modalInstance.params.identificador_cadastro)
                .then(function(data) {
                    viewModel.campoAdicional = data;
                });

            return promise;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'salvar',
                'salvarAdicionar',
                'excluir',
                'tipoAto',
                'camposAdicionais'
            ]);
        }

        function buscaTipoAto() {
            viewModel.tipoAto = viewModel.template;
        }

        function cancelar(tipoAto) {
            $modalInstance.close(tipoAto);
        }

        function estaAdicionando() {
            return temTipoAto() ? 'Editando' : 'Adicionando';
        }

        function temTipoAto() {
            return (viewModel.tipoAto.id === undefined) ? $modalInstance.params.identificador_cadastro : viewModel.tipoAto.id;
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || (viewModel.form.$pristine && !temTipoAto()) || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;

            if (viewModel.campoAdicional) { viewModel.tipoAto.campoAdicional = viewModel.campoAdicional; }

            tracker.addPromise(
                TipoAtoService.save(viewModel.tipoAto).then(function(tipoAto) {
                    if (continuar) {
                        if (viewModel.form) {
                            viewModel.form.$setPristine();
                            viewModel.form.$setUntouched();
                        }
                        $modalInstance.params.identificador_cadastro = null;
                        inicializaTipoAto();
                        inicializaCamposAdicionais();
                        viewModel.visivel = 1;
                        Focus.set('tipoAtoCadDescricao');
                    } else {
                        cancelar(tipoAto);
                    }
                    var mensagem = $modalInstance.params.identificador_cadastro ? 'alterado' : 'adicionado';
                    Notification.publish('Tipo de ato ' + mensagem + ' com sucesso', 'success');
                })
            );
        }

        function excluir() {
            $rootScope.$broadcast('tipoAto:actionRemove', { id: viewModel.tipoAto.id });
            cancelar();
        }

        function existeTipoAto() {
            return Boolean(temTipoAto());
        }

        function formataResultado(value) {
            return value.descricao;
        }

        function adicionando() {
            return !editando();
        }

        function editando() {
            return (viewModel.tipoAto && viewModel.tipoAto.id === undefined) ? $modalInstance.params.identificador_cadastro : viewModel.tipoAto && viewModel.tipoAto.id;
        }
    }
})();
