(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos.tipo-ato.controller:TipoAtoController
   *
   * @description
   * Controller tipos de ato
   **/
  angular
    .module('core.tipo-ato')
    .controller('core.TipoAtoController', TipoAtoController);

  TipoAtoController.$inject = [
    '$rootScope',
    '$scope',
    'core.TrackerFactory',
    'core.TipoAtoService',
    'core.TipoAtoModalService',
    'bfc.Notification'
  ];

  function TipoAtoController($rootScope,
                             $scope,
                             TrackerFactory,
                             TipoAtoService,
                             TipoAtoModalService,
                             Notification) {

    var viewModel = this;

    viewModel.tipoAtoService = TipoAtoService;

    viewModel.adicionar = adicionar;
    viewModel.editar = editar;
    viewModel.remover = remover;

    inicializar();

    // Funções inicializadoras
    function inicializar() {
      viewModel.loadings = {};
      inicializaLoadings();
      initEnums();
      escutaEventos();
    }

    function escutaEventos() {
      $scope.$on('tipoAto:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

    function initEnums() {
      viewModel.classificacao = TipoAtoService.getEnumClassificacaoAto();
    }

    function inicializaLoadings() {
      viewModel.inicializaLoadings = TrackerFactory.init(['tipoAtoList']);
    }

    function adicionar() {
      TipoAtoModalService.cadastrar();
    }

    function editar(tipoAto) {
      TipoAtoModalService.editar(tipoAto);
    }

    function remover(tipoAto) {
      var promise = TipoAtoService.remove(tipoAto.id);
      return promise.then(function () {
        Notification.publish('Tipo de ato excluído com sucesso', 'success');
      }, function error() {
        return promise;
      });
    }
  }
})();
