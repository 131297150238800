(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.unidade-medida:UnidadeMedidaController
   * @description Controller principal da listagem de unidade de medidas
   * @requires $scope
   * @requires bfc.dialog.Dialog
   **/

  angular
    .module('core.unidade-medida')
    .controller('core.UnidadeMedidaController', UnidadeMedidaController);

  UnidadeMedidaController.$inject = [
    '$rootScope',
    '$q',
    '$scope',
    'core.TrackerFactory',
    'core.UnidadeMedidaService',
    'core.UnidadeMedidaModalService',
    'ContratosConfirmService',
    'bfc.Notification'
  ];

  function UnidadeMedidaController($rootScope,
                                   $q,
                                   $scope,
                                   TrackerFactory,
                                   UnidadeMedidaService,
                                   UnidadeMedidaModalService,
                                   ContratosConfirmService,
                                   Notification) {

    var viewModel = this;
    viewModel.service = UnidadeMedidaService;

    viewModel.search = search;
    viewModel.cadastrar = cadastrar;
    viewModel.editar = editar;
    viewModel.excluir = excluir;
    viewModel.isFracionado = isFracionado;

    viewModel.showDescricao = showDescricao;
    viewModel.showAbreviatura = showAbreviatura;
    viewModel.showDescricaoPlural = showDescricaoPlural;
    viewModel.showGrandeza = showGrandeza;
    viewModel.showFracionaria = showFracionaria;
    viewModel.showSituacao = showSituacao;
    viewModel.selectedFilter = 'PADRAO';
    viewModel.isFilterInativo = isFilterInativo;
    viewModel.isFilterAtivo = isFilterAtivo;
    viewModel.ativarDesativarRegistros = ativarDesativarRegistros;
    viewModel.permiteDesabilitarPesquisaAvancadaDescricao = permiteDesabilitarPesquisaAvancadaDescricao;
    viewModel.permiteDesabilitarPesquisaAvancadaAbreviatura = permiteDesabilitarPesquisaAvancadaAbreviatura;
    viewModel.permiteDesabilitarPesquisaAvancadaGrandeza = permiteDesabilitarPesquisaAvancadaGrandeza;
    viewModel.permiteDesabilitarPesquisaAvancadaFracionaria = permiteDesabilitarPesquisaAvancadaFracionaria;
    viewModel.permiteDesabilitarPesquisaSituacao = permiteDesabilitarPesquisaSituacao;
    viewModel.page = {};

    inicializa();

    function inicializa() {
      inicializaPropriedades();
      inicializaLoadings();
      escutaEventos();
    }

    function escutaEventos() {
      $scope.$on('unidadeMedida:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

    function isFilterAtivo(){
      return viewModel.selectedFilter === 'ATIVO';
    }

    function isFilterInativo(){
      return viewModel.selectedFilter === 'INATIVO';
    }


    function inicializaPropriedades() {
      viewModel.showCheckBox = false;
      viewModel.unidadeMedida = [];
      viewModel.list = {};
      viewModel.filter = {};
      viewModel.columns = [
        {
          id: 'nome',
          model: 'nome',
          label: 'Descrição',
          filterable: true,
          alwaysVisible: false
        },
        {
          id: 'nomePlural',
          model: 'nomePlural',
          label: 'Descrição no plural',
          filterable: true,
          alwaysVisible: false
        },
        {
          id: 'simbolo',
          model: 'simbolo',
          label: 'Abreviatura',
          filterable: true,
          alwaysVisible: false
        }
      ];

      viewModel.listCustomBuilder = {
        onBeforeBuild: function () {
          return '';
        },
        grandeza: function (filters) {
          if (filters.grandeza && filters.grandeza.length > 0) {
            return getCustomFilter(filters);
          }
        },
        fracionaria: function (filters) {
          filters.fracionaria = filters.fracionaria && filters.fracionaria.length ? filters.fracionaria : undefined;
          if (filters.fracionaria) {
            var busca = filters.fracionaria.length > 1 ? 'fracionaria is ' + filters.fracionaria[0] + ' or fracionaria is ' + filters.fracionaria[1] : 'fracionaria is ' + filters.fracionaria;
            return busca;
          }
        }
      };

      $scope.$on('filtroRegistroPadrao:refresh', function (eventos, params) {
        viewModel.listCustomBuilder.onBeforeBuild = function () {
          viewModel.selectedFilter = params.filtro
          return params.criterio;
        };
        var showCheckBox_ = _.includes(['ATIVO', 'INATIVO'], params.filtro);

        if (showCheckBox_ !== viewModel.showCheckBox) {
          viewModel.showCheckBox = showCheckBox_;
          $rootScope.$broadcast('unidadeMedida:relink-ui-list-grid');
        } else {
          viewModel.listControls.search();
        }
      });

      viewModel.listControls = {};

      viewModel.colDescricaoIsVisible = true;
      viewModel.colAbreviaturaIsVisible = true;
      viewModel.colDescricaoPluralIsVisible = true;
      viewModel.colGrandezaIsVisible = true;
      viewModel.colFracionariaIsVisible = true;
      viewModel.colSituacaoIsVisible = true;
    }

    $scope.$watch(
      'unidadeMedida.colDescricaoIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('nome', newValue);
          viewModel.colDescricaoIsVisible = newValue;
        }

      }
    );

    $scope.$watch('unidadeMedida.page', function (newValue, oldValue) {
      if(newValue.total == undefined || newValue.total == oldValue.total) {
        return;
      }

      var total = newValue.total
      $rootScope.$broadcast('filtroRegistroPadrao:AtualizarTotal', total)
    })

    $scope.$watch(
      'unidadeMedida.colAbreviaturaIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('simbolo', newValue);
          viewModel.colAbreviaturaIsVisible = newValue;
        }
      }
    );
    $scope.$watch(
      'unidadeMedida.colDescricaoPluralIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('nomePlural', newValue);
          viewModel.colDescricaoPluralIsVisible = newValue;
        }
      }
    );
    $scope.$watch(
      'unidadeMedida.colGrandezaIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('grandeza', newValue);
          viewModel.colGrandezaIsVisible = newValue;
        }
      }
    );
    $scope.$watch(
      'unidadeMedida.colFracionariaIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('fracionaria', newValue);
          viewModel.colFracionariaIsVisible = newValue;
        }
      }
    );

    $scope.$watch(
      'unidadeMedida.colSituacaoIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('ativo', newValue);
          viewModel.colSituacaoIsVisible = newValue;
        }
      }
    );
      

    function showDescricao() {
      return viewModel.colDescricaoIsVisible;
    }

    function showAbreviatura() {
      return viewModel.colAbreviaturaIsVisible;
    }

    function showDescricaoPlural() {
      return viewModel.colDescricaoPluralIsVisible;
    }

    function showGrandeza() {
      return viewModel.colGrandezaIsVisible;
    }

    function showFracionaria() {
      return viewModel.colFracionariaIsVisible;
    }

    function showSituacao(){
      return viewModel.colSituacaoIsVisible;
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init(['unidadeMedidas', 'getList', 'exclusao']);
    }

    function search() {
      viewModel.listControls.search();
    }

    function cadastrar() {
      UnidadeMedidaModalService.cadastrar();
    }

    function editar(item) {
      UnidadeMedidaModalService.editar(item);
    }

    function excluir(item) {
      var promise = UnidadeMedidaService.remove(item.id);
      return promise.then(function () {
        Notification.publish('Unidade de medida excluída com sucesso', 'success');
      }, function error() {
        return promise;
      });
    }

    $scope.setLineHeight = function (h) {
      $scope.lineHeight = h;
    };

    function formatBoolean(dataFormat) {
      return dataFormat ? 'Sim' : 'Não';
    }

    function isFracionado(dataFormat) {
      return formatBoolean(dataFormat);
    }

    function getCustomFilter(filters) {
      var filtro;
      for (var idx = 0; idx < filters.grandeza.length; idx++) {
        if (idx === 0) {
          filtro = "grandeza in ('" + filters.grandeza[0] + "'";
        } else {
          filtro = filtro + " ,'" + filters.grandeza[idx] + "'";
        }
      }
      filtro = filtro + ")";
      return filtro;
    }


    viewModel.modalConfirmarAtivarDesativarRegistros = function() {
      ContratosConfirmService.confirm({
        title: 'Inativar Unidades de medida',
        body: 'Deseja inativar '+ viewModel.itensSelecionados.length +' unidades de medida?',
        buttonYes: 'Sim',
        buttonNo: 'Não'
      }, function sim() {
        ativarDesativarRegistros(false);
      }, function nao() {
      });
    }

    function ativarDesativarRegistros(status) {
      const ids = viewModel.itensSelecionados.map(function(s) {
          return s.id;
      });
      const promise = UnidadeMedidaService.ativarDesativarEmLote(ids, status)
      promise.then(function() {
        const acao = status ? 'ativados' : 'inativados';
        Notification.publish('Unidades de medida ' + acao + ' com sucesso', 'success');
        search();
      });
    }

    function permiteDesabilitarPesquisaAvancadaAbreviatura(){
      return !viewModel.colDescricaoIsVisible
       && !viewModel.colDescricaoPluralIsVisible
        && !viewModel.colGrandezaIsVisible
         && !viewModel.colFracionariaIsVisible;
    }
    
    function permiteDesabilitarPesquisaAvancadaDescricao(){
      return !viewModel.colAbreviaturaIsVisible
       && !viewModel.colDescricaoPluralIsVisible
        && !viewModel.colGrandezaIsVisible
         && !viewModel.colFracionariaIsVisible;
    }

    function permiteDesabilitarPesquisaAvancadaGrandeza(){
      return !viewModel.colAbreviaturaIsVisible
       && viewModel.colDescricaoIsVisible
        && !viewModel.colDescricaoPluralIsVisible
         && !viewModel.colFracionariaIsVisible;
    }

    function permiteDesabilitarPesquisaAvancadaFracionaria(){
      return !viewModel.colAbreviaturaIsVisible
       && !viewModel.colDescricaoIsVisible
        && !viewModel.colDescricaoPluralIsVisible
         && !viewModel.colGrandezaIsVisible;
    }

   function permiteDesabilitarPesquisaSituacao(){
      return !viewModel.colSituacaoIsVisible
   }

    var fracionaria = {
      true: {
        "id": "true",
        "key": "true",
        "value": "true",
        "description": "Sim"
      },
      false: {
        "id": "false",
        "key": "false",
        "value": "false",
        "description": "Não"
      }
    };
    fracionaria.values = [
      fracionaria.true,
      fracionaria.false
    ];
    viewModel.fracionariaOptions = $q.when(fracionaria);

    var GrandezaEnum = {
      ACAO: {"id": "ACAO", "key": "ACAO", "value": "ACAO", "description": "Ação"},
      ACELERACAO: {"id": "ACELERACAO", "key": "ACELERACAO", "value": "ACELERACAO", "description": "Aceleração"},
      ACELERACAO_ANGULAR: {
        "id": "ACELERACAO_ANGULAR",
        "key": "ACELERACAO_ANGULAR",
        "value": "ACELERACAO_ANGULAR",
        "description": "Aceleração angular"
      },
      ANGULO_PLANO: {
        "id": "ANGULO_PLANO",
        "key": "ANGULO_PLANO",
        "value": "ANGULO_PLANO",
        "description": "Ângulo plano"
      },
      ANGULO_SOLIDO: {
        "id": "ANGULO_SOLIDO",
        "key": "ANGULO_SOLIDO",
        "value": "ANGULO_SOLIDO",
        "description": "Ângulo sólido"
      },
      AREA: {"id": "AREA", "key": "AREA", "value": "AREA", "description": "Área"},
      ATIVIDADE_CATALITICA: {
        "id": "ATIVIDADE_CATALITICA",
        "key": "ATIVIDADE_CATALITICA",
        "value": "ATIVIDADE_CATALITICA",
        "description": "Atividade catalítica"
      },
      ATIVIDADE_RADIONUCLIDEO: {
        "id": "ATIVIDADE_RADIONUCLIDEO",
        "key": "ATIVIDADE_RADIONUCLIDEO",
        "value": "ATIVIDADE_RADIONUCLIDEO",
        "description": "Atividade de um radionuclídeo"
      },
      CALOR: {"id": "CALOR", "key": "CALOR", "value": "CALOR", "description": "Calor"},
      CAMPO_ELETRICO: {
        "id": "CAMPO_ELETRICO",
        "key": "CAMPO_ELETRICO",
        "value": "CAMPO_ELETRICO",
        "description": "Campo elétrico"
      },
      CAMPO_MAGNETICO: {
        "id": "CAMPO_MAGNETICO",
        "key": "CAMPO_MAGNETICO",
        "value": "CAMPO_MAGNETICO",
        "description": "Campo magnético"
      },
      CAPACIDADE: {"id": "CAPACIDADE", "key": "CAPACIDADE", "value": "CAPACIDADE", "description": "Capacidade"},
      CAPACIDADE_TERMICA: {
        "id": "CAPACIDADE_TERMICA",
        "key": "CAPACIDADE_TERMICA",
        "value": "CAPACIDADE_TERMICA",
        "description": "Capacidade térmica"
      },
      CAPACIDADE_TERMICA_ESPECIFICA: {
        "id": "CAPACIDADE_TERMICA_ESPECIFICA",
        "key": "CAPACIDADE_TERMICA_ESPECIFICA",
        "value": "CAPACIDADE_TERMICA_ESPECIFICA",
        "description": "Capacidade térmica específica"
      },
      CAPACIDADE_TERMICA_MOLAR: {
        "id": "CAPACIDADE_TERMICA_MOLAR",
        "key": "CAPACIDADE_TERMICA_MOLAR",
        "value": "CAPACIDADE_TERMICA_MOLAR",
        "description": "Capacidade térmica molar"
      },
      CAPACITANCIA: {
        "id": "CAPACITANCIA",
        "key": "CAPACITANCIA",
        "value": "CAPACITANCIA",
        "description": "Capacitância"
      },
      CARGA: {"id": "CARGA", "key": "CARGA", "value": "CARGA", "description": "Carga"},
      CARGA_ELETRICA: {
        "id": "CARGA_ELETRICA",
        "key": "CARGA_ELETRICA",
        "value": "CARGA_ELETRICA",
        "description": "Carga elétrica"
      },
      COMPRIMENTO: {"id": "COMPRIMENTO", "key": "COMPRIMENTO", "value": "COMPRIMENTO", "description": "Comprimento"},
      CONCENTRACAO_ATIVIDADE_CATALITICA: {
        "id": "CONCENTRACAO_ATIVIDADE_CATALITICA",
        "key": "CONCENTRACAO_ATIVIDADE_CATALITICA",
        "value": "CONCENTRACAO_ATIVIDADE_CATALITICA",
        "description": "Concentração de atividade catalítica"
      },
      CONCENTRACAO_MASSICA: {
        "id": "CONCENTRACAO_MASSICA",
        "key": "CONCENTRACAO_MASSICA",
        "value": "CONCENTRACAO_MASSICA",
        "description": "concentração mássica"
      },
      CONCENTRACAO_QUANTIDADE_SUBSTANCIA: {
        "id": "CONCENTRACAO_QUANTIDADE_SUBSTANCIA",
        "key": "CONCENTRACAO_QUANTIDADE_SUBSTANCIA",
        "value": "CONCENTRACAO_QUANTIDADE_SUBSTANCIA",
        "description": "Concentração de quantidade de substância"
      },
      CONDUTANCIA_ELETRICA: {
        "id": "CONDUTANCIA_ELETRICA",
        "key": "CONDUTANCIA_ELETRICA",
        "value": "CONDUTANCIA_ELETRICA",
        "description": "Condutância elétrica"
      },
      CONDUTIVIDADE_ELETRICA: {
        "id": "CONDUTIVIDADE_ELETRICA",
        "key": "CONDUTIVIDADE_ELETRICA",
        "value": "CONDUTIVIDADE_ELETRICA",
        "description": "Condutividade elétrica"
      },
      CONDUTIVIDADE_ELETROLITICA: {
        "id": "CONDUTIVIDADE_ELETROLITICA",
        "key": "CONDUTIVIDADE_ELETROLITICA",
        "value": "CONDUTIVIDADE_ELETROLITICA",
        "description": "Condutividade eletrolítica"
      },
      CONDUTIVIDADE_MOLAR: {
        "id": "CONDUTIVIDADE_MOLAR",
        "key": "CONDUTIVIDADE_MOLAR",
        "value": "CONDUTIVIDADE_MOLAR",
        "description": "Condutividade molar"
      },
      CORRENTE_ELETRICA: {
        "id": "CORRENTE_ELETRICA",
        "key": "CORRENTE_ELETRICA",
        "value": "CORRENTE_ELETRICA",
        "description": "Corrente elétrica"
      },
      DENSIDADE: {"id": "DENSIDADE", "key": "DENSIDADE", "value": "DENSIDADE", "description": "Densidade"},
      DENSIDADE_CARGA_ELETRICA: {
        "id": "DENSIDADE_CARGA_ELETRICA",
        "key": "DENSIDADE_CARGA_ELETRICA",
        "value": "DENSIDADE_CARGA_ELETRICA",
        "description": "Densidade de carga elétrica"
      },
      DENSIDADE_CARGA_SUPERFICIAL: {
        "id": "DENSIDADE_CARGA_SUPERFICIAL",
        "key": "DENSIDADE_CARGA_SUPERFICIAL",
        "value": "DENSIDADE_CARGA_SUPERFICIAL",
        "description": "Densidade de carga superficial"
      },
      DENSIDADE_CORRENTE_ELETRICA: {
        "id": "DENSIDADE_CORRENTE_ELETRICA",
        "key": "DENSIDADE_CORRENTE_ELETRICA",
        "value": "DENSIDADE_CORRENTE_ELETRICA",
        "description": "Densidade de corrente elétrica"
      },
      DENSIDADE_FLUXO_TERMICO: {
        "id": "DENSIDADE_FLUXO_TERMICO",
        "key": "DENSIDADE_FLUXO_TERMICO",
        "value": "DENSIDADE_FLUXO_TERMICO",
        "description": "Densidade de fluxo térmico"
      },
      DENSIDADE_LINEAR: {
        "id": "DENSIDADE_LINEAR",
        "key": "DENSIDADE_LINEAR",
        "value": "DENSIDADE_LINEAR",
        "description": "Densidade linear"
      },
      DENSIDADE_RELATIVA: {
        "id": "DENSIDADE_RELATIVA",
        "key": "DENSIDADE_RELATIVA",
        "value": "DENSIDADE_RELATIVA",
        "description": "Densidade relativa"
      },
      DENSIDADE_SUPERFICIAL: {
        "id": "DENSIDADE_SUPERFICIAL",
        "key": "DENSIDADE_SUPERFICIAL",
        "value": "DENSIDADE_SUPERFICIAL",
        "description": "Densidade superficial"
      },
      DIFERENCA_POTENCIAL_ELETRICO: {
        "id": "DIFERENCA_POTENCIAL_ELETRICO",
        "key": "DIFERENCA_POTENCIAL_ELETRICO",
        "value": "DIFERENCA_POTENCIAL_ELETRICO",
        "description": "Diferença de potencial elétrico"
      },
      DISTANCIA: {"id": "DISTANCIA", "key": "DISTANCIA", "value": "DISTANCIA", "description": "Distância"},
      DOSE_ABSORVIDA: {
        "id": "DOSE_ABSORVIDA",
        "key": "DOSE_ABSORVIDA",
        "value": "DOSE_ABSORVIDA",
        "description": "Dose absorvida"
      },
      EFICACIA_LUMINOSA_ESPECTRAL: {
        "id": "EFICACIA_LUMINOSA_ESPECTRAL",
        "key": "EFICACIA_LUMINOSA_ESPECTRAL",
        "value": "EFICACIA_LUMINOSA_ESPECTRAL",
        "description": "Eficácia luminosa espectral"
      },
      EMISSIVIDADE: {
        "id": "EMISSIVIDADE",
        "key": "EMISSIVIDADE",
        "value": "EMISSIVIDADE",
        "description": "Emissividade"
      },
      ENERGIA: {"id": "ENERGIA", "key": "ENERGIA", "value": "ENERGIA", "description": "Energia"},
      ENERGIA_INTERNA_MOLAR: {
        "id": "ENERGIA_INTERNA_MOLAR",
        "key": "ENERGIA_INTERNA_MOLAR",
        "value": "ENERGIA_INTERNA_MOLAR",
        "description": "Energia interna molar"
      },
      ENTROPIA_MOLAR: {
        "id": "ENTROPIA_MOLAR",
        "key": "ENTROPIA_MOLAR",
        "value": "ENTROPIA_MOLAR",
        "description": "Entropia molar"
      },
      EQUIVALENTE_DOSE: {
        "id": "EQUIVALENTE_DOSE",
        "key": "EQUIVALENTE_DOSE",
        "value": "EQUIVALENTE_DOSE",
        "description": "Equivalente de dose"
      },
      EXCITANCIA_LUMINOSA: {
        "id": "EXCITANCIA_LUMINOSA",
        "key": "EXCITANCIA_LUMINOSA",
        "value": "EXCITANCIA_LUMINOSA",
        "description": "Excitância luminosa"
      },
      EXCITANCIA_RADIANTE: {
        "id": "EXCITANCIA_RADIANTE",
        "key": "EXCITANCIA_RADIANTE",
        "value": "EXCITANCIA_RADIANTE",
        "description": "Excitância radiante"
      },
      EXPOSICAO: {"id": "EXPOSICAO", "key": "EXPOSICAO", "value": "EXPOSICAO", "description": "Exposição"},
      FLUXO_LUMINOSO: {
        "id": "FLUXO_LUMINOSO",
        "key": "FLUXO_LUMINOSO",
        "value": "FLUXO_LUMINOSO",
        "description": "Fluxo luminoso"
      },
      FLUXO_MAGNETICO: {
        "id": "FLUXO_MAGNETICO",
        "key": "FLUXO_MAGNETICO",
        "value": "FLUXO_MAGNETICO",
        "description": "Fluxo magnético"
      },
      FLUXO_RADIANTE: {
        "id": "FLUXO_RADIANTE",
        "key": "FLUXO_RADIANTE",
        "value": "FLUXO_RADIANTE",
        "description": "Fluxo radiante"
      },
      FLUXO_TERMICO: {
        "id": "FLUXO_TERMICO",
        "key": "FLUXO_TERMICO",
        "value": "FLUXO_TERMICO",
        "description": "Fluxo térmico"
      },
      FORCA: {"id": "FORCA", "key": "FORCA", "value": "FORCA", "description": "Força"},
      FORCA_ELETROMOTRIZ: {
        "id": "FORCA_ELETROMOTRIZ",
        "key": "FORCA_ELETROMOTRIZ",
        "value": "FORCA_ELETROMOTRIZ",
        "description": "Força eletromotriz"
      },
      FREQUENCIA: {"id": "FREQUENCIA", "key": "FREQUENCIA", "value": "FREQUENCIA", "description": "Frequência"},
      GRADIENTE_TEMPERATURA: {
        "id": "GRADIENTE_TEMPERATURA",
        "key": "GRADIENTE_TEMPERATURA",
        "value": "GRADIENTE_TEMPERATURA",
        "description": "Gradiente de temperatura"
      },
      GRANDEZAS_RAZAO_LOGARITMICAS: {
        "id": "GRANDEZAS_RAZAO_LOGARITMICAS",
        "key": "GRANDEZAS_RAZAO_LOGARITMICAS",
        "value": "GRANDEZAS_RAZAO_LOGARITMICAS",
        "description": "Grandezas de razão logarítmicas"
      },
      ILUMINANCIA: {"id": "ILUMINANCIA", "key": "ILUMINANCIA", "value": "ILUMINANCIA", "description": "Iluminância"},
      IMPULSO: {"id": "IMPULSO", "key": "IMPULSO", "value": "IMPULSO", "description": "Impulso"},
      IMPULSO_ANGULAR: {
        "id": "IMPULSO_ANGULAR",
        "key": "IMPULSO_ANGULAR",
        "value": "IMPULSO_ANGULAR",
        "description": "impulso angular"
      },
      INDICE_REFRACAO: {
        "id": "INDICE_REFRACAO",
        "key": "INDICE_REFRACAO",
        "value": "INDICE_REFRACAO",
        "description": "Indice de refração"
      },
      INDUCAO_ELETRICA: {
        "id": "INDUCAO_ELETRICA",
        "key": "INDUCAO_ELETRICA",
        "value": "INDUCAO_ELETRICA",
        "description": "Indução elétrica"
      },
      INDUCAO_MAGNETICA: {
        "id": "INDUCAO_MAGNETICA",
        "key": "INDUCAO_MAGNETICA",
        "value": "INDUCAO_MAGNETICA",
        "description": "Indução magnética"
      },
      INDUTANCIA: {"id": "INDUTANCIA", "key": "INDUTANCIA", "value": "INDUTANCIA", "description": "Indutância"},
      INTENSIDADE_LUMINOSA: {
        "id": "INTENSIDADE_LUMINOSA",
        "key": "INTENSIDADE_LUMINOSA",
        "value": "INTENSIDADE_LUMINOSA",
        "description": "Intensidade luminosa"
      },
      INTENSIDADE_RADIANTE: {
        "id": "INTENSIDADE_RADIANTE",
        "key": "INTENSIDADE_RADIANTE",
        "value": "INTENSIDADE_RADIANTE",
        "description": "Intensidade radiante"
      },
      IRRADIANCIA: {"id": "IRRADIANCIA", "key": "IRRADIANCIA", "value": "IRRADIANCIA", "description": "Irradiância"},
      LUMINANCIA: {"id": "LUMINANCIA", "key": "LUMINANCIA", "value": "LUMINANCIA", "description": "Luminância"},
      MASSA: {"id": "MASSA", "key": "MASSA", "value": "MASSA", "description": "Massa"},
      MASSA_MOLAR: {"id": "MASSA_MOLAR", "key": "MASSA_MOLAR", "value": "MASSA_MOLAR", "description": "Massa molar"},
      MOMENTO_ANGULAR: {
        "id": "MOMENTO_ANGULAR",
        "key": "MOMENTO_ANGULAR",
        "value": "MOMENTO_ANGULAR",
        "description": "Momento angular"
      },
      MOMENTO_DIPOLO_ELETRICO: {
        "id": "MOMENTO_DIPOLO_ELETRICO",
        "key": "MOMENTO_DIPOLO_ELETRICO",
        "value": "MOMENTO_DIPOLO_ELETRICO",
        "description": "Momento de dipolo elétrico"
      },
      MOMENTO_FORCA_TORQUE: {
        "id": "MOMENTO_FORCA_TORQUE",
        "key": "MOMENTO_FORCA_TORQUE",
        "value": "MOMENTO_FORCA_TORQUE",
        "description": "Momento de uma força/Torque"
      },
      MOMENTO_INERCIA: {
        "id": "MOMENTO_INERCIA",
        "key": "MOMENTO_INERCIA",
        "value": "MOMENTO_INERCIA",
        "description": "Momento inércia"
      },
      NUMERO_ONDA: {"id": "NUMERO_ONDA", "key": "NUMERO_ONDA", "value": "NUMERO_ONDA", "description": "Número de onda"},
      PERMEABILIDADE: {
        "id": "PERMEABILIDADE",
        "key": "PERMEABILIDADE",
        "value": "PERMEABILIDADE",
        "description": "Permiabilidade"
      },
      PERMISSIVIDADE: {
        "id": "PERMISSIVIDADE",
        "key": "PERMISSIVIDADE",
        "value": "PERMISSIVIDADE",
        "description": "Permissividade"
      },
      POTENCIA: {"id": "POTENCIA", "key": "POTENCIA", "value": "POTENCIA", "description": "Potência"},
      POTENCIA_APARENTE: {
        "id": "POTENCIA_APARENTE",
        "key": "POTENCIA_APARENTE",
        "value": "POTENCIA_APARENTE",
        "description": "Potência aparente"
      },
      POTENCIA_REATIVA: {
        "id": "POTENCIA_REATIVA",
        "key": "POTENCIA_REATIVA",
        "value": "POTENCIA_REATIVA",
        "description": "Potência reativa"
      },
      PRESSAO: {"id": "PRESSAO", "key": "PRESSAO", "value": "PRESSAO", "description": "Pressão"},
      QUANTIDADE_MOVIMENTO: {
        "id": "QUANTIDADE_MOVIMENTO",
        "key": "QUANTIDADE_MOVIMENTO",
        "value": "QUANTIDADE_MOVIMENTO",
        "description": "Quantidade de movimento"
      },
      QUANTIDADE_SUBSTANCIA: {
        "id": "QUANTIDADE_SUBSTANCIA",
        "key": "QUANTIDADE_SUBSTANCIA",
        "value": "QUANTIDADE_SUBSTANCIA",
        "description": "Quantidade de subtância"
      },
      RADIANCIA: {"id": "RADIANCIA", "key": "RADIANCIA", "value": "RADIANCIA", "description": "Radiância"},
      RELUTANCIA: {"id": "RELUTANCIA", "key": "RELUTANCIA", "value": "RELUTANCIA", "description": "Relutância"},
      RESISTENCIA_ELETRICA: {
        "id": "RESISTENCIA_ELETRICA",
        "key": "RESISTENCIA_ELETRICA",
        "value": "RESISTENCIA_ELETRICA",
        "description": "Resistência elétrica"
      },
      RESISTIVIDADE: {
        "id": "RESISTIVIDADE",
        "key": "RESISTIVIDADE",
        "value": "RESISTIVIDADE",
        "description": "Resistividade"
      },
      TAXA_DOSE_ABSORVIDA: {
        "id": "TAXA_DOSE_ABSORVIDA",
        "key": "TAXA_DOSE_ABSORVIDA",
        "value": "TAXA_DOSE_ABSORVIDA",
        "description": "Taxa de dose absorvida"
      },
      TEMPERATURA_CELSIUS: {
        "id": "TEMPERATURA_CELSIUS",
        "key": "TEMPERATURA_CELSIUS",
        "value": "TEMPERATURA_CELSIUS",
        "description": "Temperatura Celsius"
      },
      TEMPERATURA_TERMODINAMICA: {
        "id": "TEMPERATURA_TERMODINAMICA",
        "key": "TEMPERATURA_TERMODINAMICA",
        "value": "TEMPERATURA_TERMODINAMICA",
        "description": "Temperatura termodinâmica Kelvin"
      },
      TEMPO: {"id": "TEMPO", "key": "TEMPO", "value": "TEMPO", "description": "Tempo"},
      TENSAO: {"id": "TENSAO", "key": "TENSAO", "value": "TENSAO", "description": "Tensão"},
      TENSAO_ELETRICA: {
        "id": "TENSAO_ELETRICA",
        "key": "TENSAO_ELETRICA",
        "value": "TENSAO_ELETRICA",
        "description": "tensão elétrica"
      },
      TRABALHO: {"id": "TRABALHO", "key": "TRABALHO", "value": "TRABALHO", "description": "Trabalho"},
      VAZAO_MASSICA: {
        "id": "VAZAO_MASSICA",
        "key": "VAZAO_MASSICA",
        "value": "VAZAO_MASSICA",
        "description": "Vazão mássica"
      },
      VAZAO_VOLUMETRICA: {
        "id": "VAZAO_VOLUMETRICA",
        "key": "VAZAO_VOLUMETRICA",
        "value": "VAZAO_VOLUMETRICA",
        "description": "Vazão volumétrica"
      },
      VELOCIDADE: {"id": "VELOCIDADE", "key": "VELOCIDADE", "value": "VELOCIDADE", "description": "Velocidade"},
      VELOCIDADE_ANGULAR: {
        "id": "VELOCIDADE_ANGULAR",
        "key": "VELOCIDADE_ANGULAR",
        "value": "VELOCIDADE_ANGULAR",
        "description": "Velocidade angular"
      },
      VISCOSIDADE_CINEMATICA: {
        "id": "VISCOSIDADE_CINEMATICA",
        "key": "VISCOSIDADE_CINEMATICA",
        "value": "VISCOSIDADE_CINEMATICA",
        "description": "Viscosidade cinemática"
      },
      VISCOSIDADE_DINAMICA: {
        "id": "VISCOSIDADE_DINAMICA",
        "key": "VISCOSIDADE_DINAMICA",
        "value": "VISCOSIDADE_DINAMICA",
        "description": "Viscosidade dinâmica"
      },
      VOLUME: {"id": "VOLUME", "key": "VOLUME", "value": "VOLUME", "description": "Volume"},
      VOLUME_ESPECIFICO: {
        "id": "VOLUME_ESPECIFICO",
        "key": "VOLUME_ESPECIFICO",
        "value": "VOLUME_ESPECIFICO",
        "description": "Volume específico"
      },
      VOLUME_MOLAR: {
        "id": "VOLUME_MOLAR",
        "key": "VOLUME_MOLAR",
        "value": "VOLUME_MOLAR",
        "description": "Volume molar"
      }
    };
    GrandezaEnum.values = [
      GrandezaEnum.ACAO,
      GrandezaEnum.ACELERACAO,
      GrandezaEnum.ACELERACAO_ANGULAR,
      GrandezaEnum.ANGULO_PLANO,
      GrandezaEnum.ANGULO_SOLIDO,
      GrandezaEnum.AREA,
      GrandezaEnum.ATIVIDADE_CATALITICA,
      GrandezaEnum.ATIVIDADE_RADIONUCLIDEO,
      GrandezaEnum.CALOR,
      GrandezaEnum.CAMPO_ELETRICO,
      GrandezaEnum.CAMPO_MAGNETICO,
      GrandezaEnum.CAPACIDADE,
      GrandezaEnum.CAPACIDADE_TERMICA,
      GrandezaEnum.CAPACIDADE_TERMICA_ESPECIFICA,
      GrandezaEnum.CAPACIDADE_TERMICA_MOLAR,
      GrandezaEnum.CAPACITANCIA,
      GrandezaEnum.CARGA,
      GrandezaEnum.CARGA_ELETRICA,
      GrandezaEnum.COMPRIMENTO,
      GrandezaEnum.CONCENTRACAO_ATIVIDADE_CATALITICA,
      GrandezaEnum.CONCENTRACAO_MASSICA,
      GrandezaEnum.CONCENTRACAO_QUANTIDADE_SUBSTANCIA,
      GrandezaEnum.CONDUTANCIA_ELETRICA,
      GrandezaEnum.CONDUTIVIDADE_ELETRICA,
      GrandezaEnum.CONDUTIVIDADE_ELETROLITICA,
      GrandezaEnum.CONDUTIVIDADE_MOLAR,
      GrandezaEnum.CORRENTE_ELETRICA,
      GrandezaEnum.DENSIDADE,
      GrandezaEnum.DENSIDADE_CARGA_ELETRICA,
      GrandezaEnum.DENSIDADE_CARGA_SUPERFICIAL,
      GrandezaEnum.DENSIDADE_CORRENTE_ELETRICA,
      GrandezaEnum.DENSIDADE_FLUXO_TERMICO,
      GrandezaEnum.DENSIDADE_LINEAR,
      GrandezaEnum.DENSIDADE_RELATIVA,
      GrandezaEnum.DENSIDADE_SUPERFICIAL,
      GrandezaEnum.DIFERENCA_POTENCIAL_ELETRICO,
      GrandezaEnum.DISTANCIA,
      GrandezaEnum.DOSE_ABSORVIDA,
      GrandezaEnum.EFICACIA_LUMINOSA_ESPECTRAL,
      GrandezaEnum.EMISSIVIDADE,
      GrandezaEnum.ENERGIA,
      GrandezaEnum.ENERGIA_INTERNA_MOLAR,
      GrandezaEnum.ENTROPIA_MOLAR,
      GrandezaEnum.EQUIVALENTE_DOSE,
      GrandezaEnum.EXCITANCIA_LUMINOSA,
      GrandezaEnum.EXCITANCIA_RADIANTE,
      GrandezaEnum.EXPOSICAO,
      GrandezaEnum.FLUXO_LUMINOSO,
      GrandezaEnum.FLUXO_MAGNETICO,
      GrandezaEnum.FLUXO_RADIANTE,
      GrandezaEnum.FLUXO_TERMICO,
      GrandezaEnum.FORCA,
      GrandezaEnum.FORCA_ELETROMOTRIZ,
      GrandezaEnum.FREQUENCIA,
      GrandezaEnum.GRADIENTE_TEMPERATURA,
      GrandezaEnum.GRANDEZAS_RAZAO_LOGARITMICAS,
      GrandezaEnum.ILUMINANCIA,
      GrandezaEnum.IMPULSO,
      GrandezaEnum.IMPULSO_ANGULAR,
      GrandezaEnum.INDICE_REFRACAO,
      GrandezaEnum.INDUCAO_ELETRICA,
      GrandezaEnum.INDUCAO_MAGNETICA,
      GrandezaEnum.INDUTANCIA,
      GrandezaEnum.INTENSIDADE_LUMINOSA,
      GrandezaEnum.INTENSIDADE_RADIANTE,
      GrandezaEnum.IRRADIANCIA,
      GrandezaEnum.LUMINANCIA,
      GrandezaEnum.MASSA,
      GrandezaEnum.MASSA_MOLAR,
      GrandezaEnum.MOMENTO_ANGULAR,
      GrandezaEnum.MOMENTO_DIPOLO_ELETRICO,
      GrandezaEnum.MOMENTO_FORCA_TORQUE,
      GrandezaEnum.MOMENTO_INERCIA,
      GrandezaEnum.NUMERO_ONDA,
      GrandezaEnum.PERMEABILIDADE,
      GrandezaEnum.PERMISSIVIDADE,
      GrandezaEnum.POTENCIA,
      GrandezaEnum.POTENCIA_APARENTE,
      GrandezaEnum.POTENCIA_REATIVA,
      GrandezaEnum.PRESSAO,
      GrandezaEnum.QUANTIDADE_MOVIMENTO,
      GrandezaEnum.QUANTIDADE_SUBSTANCIA,
      GrandezaEnum.RADIANCIA,
      GrandezaEnum.RELUTANCIA,
      GrandezaEnum.RESISTENCIA_ELETRICA,
      GrandezaEnum.RESISTIVIDADE,
      GrandezaEnum.TAXA_DOSE_ABSORVIDA,
      GrandezaEnum.TEMPERATURA_CELSIUS,
      GrandezaEnum.TEMPERATURA_TERMODINAMICA,
      GrandezaEnum.TEMPO,
      GrandezaEnum.TENSAO,
      GrandezaEnum.TENSAO_ELETRICA,
      GrandezaEnum.TRABALHO,
      GrandezaEnum.VAZAO_MASSICA,
      GrandezaEnum.VAZAO_VOLUMETRICA,
      GrandezaEnum.VELOCIDADE,
      GrandezaEnum.VELOCIDADE_ANGULAR,
      GrandezaEnum.VISCOSIDADE_CINEMATICA,
      GrandezaEnum.VISCOSIDADE_DINAMICA,
      GrandezaEnum.VOLUME,
      GrandezaEnum.VOLUME_ESPECIFICO,
      GrandezaEnum.VOLUME_MOLAR
    ];
    viewModel.grandezaEnumOptions = $q.when(GrandezaEnum);
  }
})();

