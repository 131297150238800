(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.veiculos-publicacao.controller:VeiculosPublicacaoController
   * @description Controller da página principal de Veículos de publicação
   * @requires bfc.dialog.Dialog
   * @requires TrackerFactory
   **/
  angular
    .module('core.veiculos-publicacao')
    .controller('core.VeiculosPublicacaoController', VeiculosPublicacaoController);

  VeiculosPublicacaoController.$inject = [
    '$rootScope',
    '$scope',
    '$q',
    'core.TrackerFactory',
    'core.VeiculosPublicacaoService',
    'core.VeiculosPublicacaoModalService',
    'bfc.Notification'
  ];

  function VeiculosPublicacaoController($rootScope,
                                        $scope,
                                        $q,
                                        TrackerFactory,
                                        VeiculosPublicacaoService,
                                        VeiculosPublicacaoModalService,
                                        Notification) {

    var viewModel = this;
    //Usado para teste Unitário
    viewModel.argumentos = VeiculosPublicacaoController.$inject;

    viewModel.veiculosPublicacaoService = VeiculosPublicacaoService;

    viewModel.adicionar = adicionar;
    viewModel.editar = editar;
    viewModel.remover = remover;
    viewModel.removerTodosSelecionados = removerTodosSelecionados;

    var situacao = {
      true: {
        "id": "true",
        "key": "true",
        "value": "true",
        "description": "Ativo"
      },
      false: {
        "id": "false",
        "key": "false",
        "value": "false",
        "description": "Inativo"
      }
    };
    situacao.values = [
      situacao.true,
      situacao.false
    ];
    viewModel.situacaoVeiculo = $q.when(situacao);

    var tipoVeiculo = {
      EMPRESA_CONTRATADA: {
        "id": "EMPRESA_CONTRATADA",
        "key": "EMPRESA_CONTRATADA",
        "value": "EMPRESA_CONTRATADA",
        "description": "Empresa contratada (Órgão oficial)"
      },
      ORGAO_OFICIAL_PROPRIO: {
        "id": "ORGAO_OFICIAL_PROPRIO",
        "key": "ORGAO_OFICIAL_PROPRIO",
        "value": "ORGAO_OFICIAL_PROPRIO",
        "description": "Órgão oficial próprio"
      },
      ORGAO_OFICIAL_ELETRONICO: {
        "id": "ORGAO_OFICIAL_ELETRONICO",
        "key": "ORGAO_OFICIAL_ELETRONICO",
        "value": "ORGAO_OFICIAL_ELETRONICO",
        "description": "Órgão oficial eletrônico"
      },
      NAO_OFICIAL: {
        "id": "NAO_OFICIAL",
        "key": "NAO_OFICIAL",
        "value": "NAO_OFICIAL",
        "description": "Não oficial"
      }
    };
    tipoVeiculo.values = [
      tipoVeiculo.EMPRESA_CONTRATADA,
      tipoVeiculo.ORGAO_OFICIAL_PROPRIO,
      tipoVeiculo.ORGAO_OFICIAL_ELETRONICO,
      tipoVeiculo.NAO_OFICIAL
    ];
    viewModel.tipoVeiculo = $q.when(tipoVeiculo);

    inicializa();

    function inicializa() {
      inicializaPropriedades();
      inicializaLoadings();
      escutaEventos();
    }

    function escutaEventos() {
      $scope.$on('veiculoPublicacao:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

    function inicializaPropriedades() {
      viewModel.veiculosPublicacao = [];
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init(['veiculosPublicacao']);
    }

    function adicionar() {
      VeiculosPublicacaoModalService.cadastrar();
    }

    function editar(item) {
      VeiculosPublicacaoModalService.editar(item);
    }

    function remover(item) {
      var promise = VeiculosPublicacaoService.remove(item);
      return promise.then(function () {
        Notification.publish('Veículo de publicação excluído com sucesso', 'success');
      }, function error() {
        return promise;
      });
    }

    function removerTodosSelecionados() {
      _.forEach(viewModel.selecionarTodos, function (veiculoPublicacao) {
        remover(veiculoPublicacao);
      });
      VeiculosPublicacaoService.emitDirty();
    }

    viewModel.expressionCustomBuilders = {
      ativo: function (filters) {
        //Inserido para corrigir erro quando não há nenhum valor setado para o campo
        filters.ativo = filters.ativo && filters.ativo.length ? filters.ativo : undefined;
        if (filters.ativo) {
          var busca = filters.ativo.length > 1 ? 'ativo is ' + filters.ativo[0] + ' or ativo is ' + filters.ativo[1] : 'ativo is ' + filters.ativo;
          return busca;
        }
      },
      dataInicial: function (filters) {
        if (filters.dataInicial) {
          return 'datainicio >= ' + filters.dataInicial;
        }
      },
      dataFinal: function (filters) {
        if (filters.dataFinal) {
          return 'datainicio <= ' + filters.dataFinal;
        }
      },
      tipo: function (filters) {
        if (filters.tipo && filters.tipo.length > 0) {
          var filtro;
          for (var idx = 0; idx < filters.tipo.length; idx++) {
            if (idx === 0) {
              filtro = "tipo in ('" + filters.tipo[0] + "'";
            } else {
              filtro = filtro + " ,'" + filters.tipo[idx] + "'";
            }
          }
          filtro = filtro + ")";
          return filtro;
        }

      }
    };
  }
})();
