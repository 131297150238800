(function () {
  'use strict';

  angular
    .module('core.exercicio')
    .service('core.ExercicioCadService', ExercicioCadService);

  ExercicioCadService.$inject = ['core.ModalCadFactory'];

  function ExercicioCadService(ModalCadFactory) {

    var modal = {
      templateUrl: 'exercicio-cad/exercicio-cad.html',
      controller: 'core.ExercicioCadController as exercicioCadController',
      resolve: {
        ano: null
      }
    };

    this.cadastrar = cadastrar;
    this.editar = editar;

    function cadastrar() {
      ModalCadFactory.open(modal);
    }

    function editar(exercicio) {
      var m = _.cloneDeep(modal);
      m.resolve = {
        ano: exercicio.exercicio
      };
      ModalCadFactory.open(m);
    }

  }
})();
