(function() {

    'use strict';

    angular
            .module('core.configuracao-organograma')
            .filter('coreConfiguracaoOrganogramaSeparadorFilter', ConfiguracaoOrganogramaSeparadorFilter);

    ConfiguracaoOrganogramaSeparadorFilter.$inject = [];

    function ConfiguracaoOrganogramaSeparadorFilter() {
        return function(separador) {
            return separador !== 'nulo' ? separador : '';
        };
    }
})();
