(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.municipio.MunicipiosSugestoesSelect
   * @description
   * Serviço do select2 para o template de municipio
   * @requires bfc.Select2Config
   * @requires MunicipioService
   **/

  angular
    .module('core.municipio')
    .service('core.MunicipiosSugestoesSelect', MunicipiosSugestoesSelect);

  MunicipiosSugestoesSelect.$inject = [
    '$filter',
    'bfc.Select2',
    'core.MunicipioService',
    'CONFIG_SELECT2'
  ];

  function MunicipiosSugestoesSelect($filter,
    Select2,
    MunicipioService,
    CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var metodos = {
        formatResult: formatResult,
        formatSelection: formatSelection,
        formatValue: formatValue,
        initSelection: initSelection,
        result: _.debounce(result, CONFIG_SELECT2.DELAY),
        minimumInputLength: CONFIG_SELECT2.MINIMUMINPUT,
        dropdownAutoWidth: false,
        onAdd: onAdd
      };

      function formatResult(data) {
        return getDescricaoExibicao(data);
      }

      function formatSelection(data) {
        return getDescricaoExibicao(data);
      }

      function formatValue(data) {
        return data;
      }

      function initSelection(element, callback) {
        var id = $(element).val();

        if (Number(id)) {
          MunicipioService.get(id).then(callback);
        } else {
          var municipio = {
            nome: element.val()
          };
          callback(municipio);
        }
      }

      function result(params, callback) {
        var result = MunicipioService.getSugestoes({ filter: params.term });
        result.then(function(data) {
          callback({
            results: data,
            more: data.hasNext
          });
        });
        return result;
      }

      function getDescricaoExibicao(data) {
        var descricao = '';

        if (data.text) {
          return data.text;
        }

        if (data) {
          descricao += data.nome;

          if (data.cep) {
            descricao += ' - ' + $filter('bfMaskCep')(data.cep);
          }
        }

        return descricao;
      }

      function onAdd(value, target) {
        Select2.set(target, value);
        return false;
      }

      return Select2.create(angular.extend(metodos, options));
    }
  }
})();
