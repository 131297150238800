(function() {
    'use strict';

    /**
     * @ngdoc directive
     * @name contratos-core.configuracao-organograma.directive:ctrConfiguracaoOrganogramaList
     * @restrict 'E'
     * @description
     * Diretiva com as listagem das configurações de organograma
     **/
    angular
            .module('core.configuracao-organograma')
            .directive('coreConfiguracaoOrganogramaList', ctrConfiguracaoOrganogramaList);

    function ctrConfiguracaoOrganogramaList() {
        return {
            restrict: 'E',
            replace: true,
            controller: 'core.ConfiguracaoOrganogramaController',
            controllerAs: 'configuracaoOrganograma',
            templateUrl: 'configuracao-organograma.template.html'
        };
    }
})();
