(function () {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.ato
     * @description
     * #contratos-core.ato
     * Módulo de atos
     **/
    angular
            .module('core.ato', ['core', 'core.commons', 'suite.cadastro-unico']);
})();
