(function() {
	'use strict';
	/**
	 * @ngdoc service 
	 * @name contratos-core.condominio.service:CondominioModalService
	 * @description Serviço para abrir o cadastro de condominios
	 * @requires ModalCadFactory
	 **/
	angular
			.module('core.condominio')
			.service('core.CondominioModalService', CondominioModalService);

	CondominioModalService.$inject = ['ui.components.Modal'];

	function CondominioModalService(uiModal) {

		var modal = {
			templateUrl: 'condominio-cad/condominio-cad.html',
			controller: 'core.CondominioCadController',
			controllerAs: 'condominioCad',
			params: {
				condominioId: null
			},
			size:'sm'
		};

		var service = {
			cadastrar: cadastrar,
			editar: editar,
			onAdd: onAdd
		};

		angular.extend(this, service);

		function abrir(modal) {
			 return uiModal.open(modal).result;
		}

		function copia(modal) {
			return angular.copy(modal);
		}

		function cadastrar() {
			var m = copia(modal);
			m.resolve = {
				condominioId: null
			};
			abrir(m);
		}

		function editar(condominio) {
			var m = copia(modal);
			m.params = {
				condominioId: condominio.id
			};
			abrir(m);
		}

	    function onAdd(params){
            var m = copia(modal);
            m.params = {
                condominioId: null,
                params: params
            };
            return uiModal.open(m).result;
        }
	}
})();