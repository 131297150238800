(function() {
	'use strict';
/**
	* @ngdoc service
	* @name contratos-core.utils.UtilsService
	* @description Serviços com funções utilitárias para enums
**/

	angular
	  .module('core.commons')
	  .service('core.UtilsService', UtilsService);

    UtilsService.$inject = ['$q'];

	function UtilsService($q) {

        this.doPromise = doPromise;
        this.doFilterByProperty = doFilterByProperty;
        this.getFilteredValues = getFilteredValues;
        this.getValueFromKey = getValueFromKey;
        this.getFromValue = getFromValue;
        this.Math = Math;

        function doFilterByProperty(params) {
            var r;
            if (params.filter &&
                params.property &&
                params.array) {

                var results = _.filter(params.array, function(element) {
                    // Retira o percentual do texto, em consequência a Regex retira também o espaço
                    var text = params.filter.replace(/\%/g, '').toLowerCase();

                    // Como a regex anterior retira o espaço, então para encontrar o valor retiro o espaço da descrição
                    var description = element[params.property] && element[params.property].toString().replace(/\ /g, '').toLowerCase();
                    // Procuro o texto na descrição, se diferente de menos 1 é porquê o valor bate com o texto pesquisado

                    if(_.isEmpty(description)) {
                        return false;
                    } else {
                        return description.search(text) !== -1;
                    }
                });
                r = results;
            } else {
                r = params.array;
            }
            return r;
        }

        function doPromise(fn) {
            return $q.when(fn);
        }


		 // Funções utilitárias para tratar das enums
        function doFilter(array, filter) {
            var r;
            // Filtro no front-end
            if (filter && array) {
                var results = _.filter(array, function(element) {
                    // Retira o percentual do texto, em consequência a Regex retira também o espaço
                    var text = filter.replace(/\%/g, '').toLowerCase();
                    // Como a regex anterior retira o espaço, então para encontrar o valor retiro o espaço da descrição

                    var description = element.description && element.description.replace(/\ /g, '').toLowerCase();

                    if(_.isNull(description) || _.isUndefined(description)) {
                        return false;
                    }
                    // Procuro o texto na descrição, se diferente de menos 1 é porquê o valor bate com o texto pesquisado
                    return description.search(text) !== -1;
                });
                r = results;
            } else {
                r = array;
            }
            return r;
        }

        function getFilteredValues(values, filter) {
            if (filter) {
                values = doFilter(values, filter);
            }
            return $q.when(values);
        }

        function getValueFromKey(values, key) {
            return $q.when(_.find(values, {'key': key}));
        }

        function getFromValue(values, value) {
            return $q.when(_.find(values, {'value': value}));
        }


        /*Funçoes para lidar com números de ponto flutuante*/
        /* http://jsfiddle.net/Dm6F5/4/ */
        var _cf = (function() {
            function _shift(x) {
                var parts = x.toString().split('.');
                return (parts.length < 2) ? 1 : Math.pow(10, parts[1].length);
            }
            return function() {
                /*console.log(arguments);*/
                return Array.prototype.reduce.call(arguments, function (prev, next) { return prev === undefined || next === undefined ? undefined : Math.max(prev, _shift (next)); }, -Infinity);
            };
        })();

        /*Função para adicionar números decimais.*/
        Math.a = function () {
            var f = _cf.apply(null, arguments); if(f === undefined) return undefined;
            function cb(x, y, i, o) {
                return x + ~~(f*y); //f * y; //
            }
            return Array.prototype.reduce.call(arguments, cb, 0) / f;
        };

        //Funçao para subtração de números de ponto flutuante.
        Math.s = function (l,r) { var f = _cf(l,r); return (~~(l * f) - ~~(r * f)) / f; };

        /*Função para multiplicar. Resolve o problema da multiplicação de floats no javascript (ex.: 3 * 0.3 = 0.88888...9) */
        Math.m = function () {
            var f = _cf.apply(null, arguments);
            function cb(x, y, i, o) {
                return ~~(x*f) * ~~(y*f) / (f * f);
            }
            return Array.prototype.reduce.call(arguments, cb, 1);
        };

        //Funçao para divisão de números de ponto flutuante.
        Math.d = function (l,r) {
            var f = _cf(l,r);
            return ~~(l * f) / ~~(r * f);
        };
	}
})();
