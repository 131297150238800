(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.parametros.ParametrosService
   * @description
   * Serviço que se comunica com o Back-end para trazer paramêtros da aplicação
   * por enquanto contendo somente a configuração de organograma
   * @requires $q
   * @requires Restangular
   **/
  angular
    .module('core.parametros')
    .service('core.ParametrosService', ParametrosService);

  ParametrosService.$inject = [
    '$q',
    'Restangular',
    'bfcApplicationContext',
    'core.ExercicioService',
    'core.ConfiguracaoOrganogramaService',
    'core.ServiceFactory'
  ];

  function ParametrosService($q,
                             Restangular,
                             bfcApplicationContext,
                             ExercicioService,
                             ConfiguracaoOrganogramaService,
                             ServiceFactory) {

    var service = ServiceFactory.create('parametrosexercicio');
    service.getConfiguracaoOrganograma = getConfiguracaoOrganograma;
    service.getConfiguracaoOrganogramaPorAno = getConfiguracaoOrganogramaPorAno;
    angular.extend(this, service);

    function getConfiguracaoOrganograma() {
      var promise = $q.defer();
      var anoExercicio = bfcApplicationContext.get('exercicio');

      ExercicioService.getExercicio(anoExercicio).then(function (exercicio) {
        var id = exercicio && exercicio.configuracaoOrganograma && exercicio.configuracaoOrganograma.id;
        ConfiguracaoOrganogramaService.get(id).then(function (configuracaoOrganograma) {
          promise.resolve(configuracaoOrganograma);
        });
      });
      return promise.promise;
    }

    function getConfiguracaoOrganogramaPorAno(ano) {
      var promise = $q.defer();
      var anoExercicio = ano ? ano : bfcApplicationContext.get('exercicio');

      ExercicioService.getExercicio(anoExercicio).then(function (exercicio) {
        var id = exercicio && exercicio.configuracaoOrganograma && exercicio.configuracaoOrganograma.id;
        ConfiguracaoOrganogramaService.get(id).then(function (configuracaoOrganograma) {
          promise.resolve(configuracaoOrganograma);
        });
      });
      return promise.promise;
    }
  }
})();
