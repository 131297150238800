(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos.fornecedor.FornecedorSelect
   * @description
   * Serviço para usar o select2 nos fornecedores
   * @requires bfc.Select2Config
   * @requires FornecedorService
   **/

  angular
    .module('core.pessoa')
    .service('core.PessoaSelect', PessoaSelect);

  PessoaSelect.$inject = [
    'bfc.Select2',
    'core.PessoaService',
    '$filter',
    'CONFIG_SELECT2'
  ];

  function PessoaSelect(Select2,
                        PessoaService,
                        $filter,
                        CONFIG_SELECT2) {

    angular.extend(this, {
      select2Config: select2Config
    });

    function select2Config(options) {
      var methods = {
        onAdd: onAdd,
        id: options.id ? options.id : id,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('PESSOA'),
        dropdownAutoWidth: false
      };

      function formatSelection(pessoa) {
        if (pessoa && pessoa.nome) {
          return pessoa.nome;
        }
        return pessoa.nome;
      }

      function formatValue(pessoa) {
        if (pessoa.nome) {
          pessoa = {
            nome: pessoa.nome,
            cpfcnpj: pessoa.cpfcnpj,
            id: pessoa.id,
          };
          //return fornecedor.nome;
        }

        //return fornecedor.nome ? fornecedor.nome : fornecedor.pessoa;
        return pessoa;
      }

      function id(pessoa) {
        return pessoa.nome ? pessoa.nome : pessoa.id;
      }

      function initSelection(element, callback) {
        var id = element.val();

        if (!isNaN(id)) {
          PessoaService.getPessoa(id).then(callback);
        } else {
          var pessoa = {
            nome: element.val()
          };
          callback(pessoa);
        }
      }


      function result(params, callback) {
        var filter = '(nome like "' + encodeURI(params.term) + '" or cpfcnpj like "' + encodeURI(params.term) + '")',
          result = PessoaService.getList(filter);

        result.then(function (pessoas) {
          callback({
            results: pessoas,
            more: pessoas.hasNext
          });
        });
        return result;
      }

      function onAdd(value, target) {
        Select2.set(target, value);
        return false;
      }

      var opt = Select2.create(angular.extend(methods, options));

      opt.formatResult = function (pessoa) {
        if (pessoa.text) {
          return pessoa.text;
        }

        if (pessoa.cpfCnpj) {
          var prefixo = pessoa.cpfCnpj.length === 14 ? 'CNPJ: ' : 'CPF: ';
          return pessoa.nome + '<br>' + prefixo + $filter('bfMaskCpfCnpj')(pessoa.cpfCnpj);
        }
      }
      return opt;
    }
  }
})();
