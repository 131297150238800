(function () {
    'use strict';
    
    angular
        .module('core.fornecedor')
        .service('core.ConsultaReceitaFederalModalService', consultaReceitaFederalModalService);

    consultaReceitaFederalModalService.$inject = ['core.AcoesCadastrosModalService'];

    function consultaReceitaFederalModalService(AcoesCadastrosModalService) {

        var modal = {
            templateUrl: 'consulta-receita-federal/consulta-receita-federal.html',
            controller: 'core.ConsultaReceitaFederalController',
            controllerAs: 'vm',
            params: {
                identificador_cadastro: null
            },
            size: 'xl'
        };

        var service = {
            cadastrar: cadastrar
        };

        angular.extend(this, service);

        function cadastrar(data, tipoAbertura) {
            var params = {};
            params.consultaCnpj = data;
            params.tipoAberturaListagem = tipoAbertura;
            return AcoesCadastrosModalService.onAdd(modal, params);
        }
    }
})();