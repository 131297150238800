(function () {
  'use strict';
  /**
   * @ngdoc controller
   * @name contratos-core.Ato.controller:AtoController
   * @dcontratacaocription Controller da página principal de tipos de aditivos
   **/

  angular
    .module('core.ato')
    .controller('core.AtoController', AtoController);
  AtoController.$inject = [
    '$rootScope',
    '$q',
    '$scope',
    'core.TrackerFactory',
    'core.AtoService',
    'core.AtoModalService',
    'bfc.Notification'
  ];

  function AtoController($rootScope,
                         $q,
                         $scope,
                         TrackerFactory,
                         AtoService,
                         AtoModalService,
                         Notification) {

    var viewModel = this;
    viewModel.service = AtoService;

    //Usado para tcontratacaote Unitário
    viewModel.argumentos = AtoController.$inject;

    viewModel.cadastrar = cadastrar;
    viewModel.editar = editar;
    viewModel.excluir = excluir;

    inicializa();

    function inicializa() {
      inicializaPropriedades();
      inicializaLoadings();
      escutaEventos();
    }

    function escutaEventos() {
      $scope.$on('ato:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

    function inicializaPropriedades() {
      viewModel.ato = {};
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init(['list', 'excluir']);
    }

    function cadastrar() {
      AtoModalService.cadastrar();
    }

    function editar(item) {
      AtoModalService.editar(item);
    }

    function excluir(item) {
      var promise = AtoService.excluir(item.id);
      return promise.then(function () {
        Notification.publish('Ato excluído com sucesso', 'success');
      }, function error() {
        return promise;
      });
    }
  }
})();
