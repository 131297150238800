(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.condominio.controller:CondominioCadController
     * @description Controller do cadastro de condomínios
     **/
    angular
        .module('core.condominio')
        .controller('core.CondominioCadController', CondominioCadController);

    CondominioCadController.$inject = [
        '$injector',
        '$q',
        '$rootScope',
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'core.TrackerFactory',
        'core.CondominioService',
        'core.BairroService'
    ];

    function CondominioCadController($injector,
                                     $q,
                                     $rootScope,
                                     $scope,
                                     $modalInstance,
                                     Notification,
                                     TrackerFactory,
                                     CondominioService,
                                     BairroService) {

        var viewModel = this;
        var carregandoDados = false;

        var MunicipioService = $injector.get('core.MunicipioService');
        var MunicipioModalService = $injector.get('core.MunicipioModalService');
        var CONFIG_SELECT2 = $injector.get('CONFIG_SELECT2');
        var Select2Factory = $injector.get('core.Select2Factory');
        var CondominiosSugestoesSelect = $injector.get('core.CondominiosSugestoesSelect');
        var BairroModalService = $injector.get('core.BairroModalService');
        var LogradouroService = $injector.get('core.LogradouroService');
        var LogradouroModalService = $injector.get('core.LogradouroModalService');

        viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

        viewModel.argumentos = CondominioCadController.$inject;
        viewModel.salvar = salvar;
        viewModel.excluir = excluir;
        viewModel.desabilitaSalvar = desabilitaSalvar;
        viewModel.getAcao = getAcao;
        viewModel.temCondominio = temCondominio;

        viewModel.buscaCondominio = buscaCondominio;
        viewModel.buscarBairros = buscarBairros;
        viewModel.adicionarNovoBairro = adicionarNovoBairro;
        viewModel.buscarLogradouros = buscarLogradouros;
        viewModel.adicionarNovoLogradouro = adicionarNovoLogradouro;
        viewModel.buscarMunicipios = buscarMunicipios;
        viewModel.adicionarNovoMunicipio = adicionarNovoMunicipio;
        viewModel.formataResultado = formataResultado;
        viewModel.changeLogradouro = changeLogradouro;
        viewModel.changeBairro = changeBairro;

        viewModel.adicionando = adicionando;
        viewModel.editando = editando;
        viewModel.selecionaTemplate = selecionaTemplate;

        inicializaLoadings();
        inicializar();

        function inicializar() {
            if ($modalInstance.params.condominioId) {
                carregandoDados = true;
            }

            inicializarCondominio();
            inicializaEnums();
            initSelect2();
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([{
                element: 'nome',
                select2: CondominiosSugestoesSelect
            }]);
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'iniciaCondominio',
                'salvar',
                'salvarAdicionar',
                'excluir'
            ]);
        }

        function buscaCondominio() {
            viewModel.condominio = _.merge(viewModel.condominio, viewModel.template);
        }

        function selecionaTemplate() {
            if (viewModel.template) {
                viewModel.condominio.nome = viewModel.template.nome;
            }
        }

        function buscarBairros(params) {
            params.filter = '(nome like "' + params.term + '")';
            if (viewModel.condominio.municipio) {
                params.filter = params.filter + ' and idMunicipio = ' + viewModel.condominio.municipio.id;
            }
            return BairroService.getList(params).then(function (data) {
                return data;
            });
        }

        function adicionarNovoBairro(nomeBairro) {
            var param = {
                nome: nomeBairro
            };
            return BairroModalService.onAdd(param);
        }

        function buscarLogradouros(params) {
            params.filter = '(nome like "' + params.term + '")';
            if (viewModel.condominio.municipio) {
                params.filter = params.filter + ' and idMunicipio = ' + viewModel.condominio.municipio.id;
            }

            return LogradouroService.getList(params).then(function (data) {
                return data;
            });
        }

        function adicionarNovoLogradouro(nomeLogradouro) {
            var param = {
                nome: nomeLogradouro
            };
            return LogradouroModalService.onAdd(param);
        }

        function buscarMunicipios(params) {
            params.filter = '(nome like "' + params.term + '")';
            return MunicipioService.getList(params).then(function (data) {
                return data;
            });
        }

        function adicionarNovoMunicipio(nomeMunicipio) {
            var param = {
                nome: nomeMunicipio
            };
            return MunicipioModalService.onAdd(param);
        }

        function formataResultado(value) {
            return value.nome;
        }

        function inicializarCondominio() {
            var promise;
            if ($modalInstance.params.condominioId) {
                promise = CondominioService.get($modalInstance.params.condominioId);
                promise.then(function (condominio) {
                    viewModel.condominio = condominio;
                });
            } else {
                promise = CondominioService.getDefault();
                promise.then(function (condominioDefault) {
                    viewModel.condominio = condominioDefault;

                    if ($modalInstance.params.params) {
                        viewModel.desabilitaCampo = !!$modalInstance.params.params.params;
                        viewModel.condominio.nome = $modalInstance.params.params.nome;
                        if ($modalInstance.params.params.params) {
                            viewModel.condominio.municipio = $modalInstance.params.params.params.municipio;
                            viewModel.condominio.cep = $modalInstance.params.params.params.cep;
                            viewModel.condominio.logradouro = $modalInstance.params.params.params.logradouro;
                            viewModel.condominio.numero = $modalInstance.params.params.params.nro;
                            viewModel.condominio.bairro = $modalInstance.params.params.params.bairro;
                        }
                    }
                });
            }
            viewModel.loadings.iniciaCondominio.addPromise(promise);

        }

        function excluir() {
            $rootScope.$broadcast('condominio:actionRemove', {id: viewModel.condominio.id});
            fechar();
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;
            tracker.addPromise(CondominioService.salvar(viewModel.condominio).then(function (condominio) {
                if (continuar) {
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                        viewModel.form.$setUntouched();
                    }
                    $modalInstance.params.condominioId = undefined;
                    inicializar();
                } else {
                    fechar(condominio);
                }
                showNotificationSave();
            }));
        }

        function showNotificationSave() {
            if (temCondominio()) {
                Notification.publish('Condomínio alterado com sucesso', 'success');
            } else {
                Notification.publish('Condomínio adicionado com sucesso', 'success');
            }
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
        }

        function fechar(condominio) {
            $modalInstance.close(condominio);
        }

        function getAcao() {
            return $modalInstance.params.condominioId ? 'Editando' : 'Adicionando';
        }

        function temCondominio() {
            return $modalInstance.params.condominioId || (viewModel.condominio && viewModel.condominio.id);
        }

        function inicializaEnums() {
            viewModel.tipoCondominio = CondominioService.getEnumTipoCondominio();
        }

        $scope.$watch(
            'condominioCad.condominio.municipio',
            function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (!carregandoDados && !viewModel.desabilitaCampo) {
                        viewModel.condominio.logradouro = null;
                        viewModel.condominio.bairro = null;
                    }
                    carregandoDados = false;
                }
            }
        );

        function changeLogradouro() {
            if ($modalInstance.params.params) return;
            if (!viewModel.condominio.municipio && viewModel.condominio.logradouro) {
                carregandoDados = true;
                viewModel.condominio.municipio = viewModel.condominio.logradouro.municipio;
            }
        }

        function changeBairro() {
            if ($modalInstance.params.params) return;
            if (!viewModel.condominio.municipio && viewModel.condominio.bairro) {
                carregandoDados = true;
                viewModel.condominio.municipio = viewModel.condominio.bairro.municipio;
            }
        }

        function adicionando() {
            return !editando();
        }

        function editando() {
            return Boolean(viewModel.condominio && !viewModel.condominio.id) ? ($modalInstance.params.params || $modalInstance.params.condominioId) : viewModel.condominio && viewModel.condominio.id;
        }
    }
})();
