(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.pais
     * @description
     * # contratos-core.pais
     * Módulo de países
     **/
    angular
            .module('core.tipo-logradouro', ['core', 'core.commons']);
})();
