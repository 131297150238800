(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.parametros
     * @description
     * # contratos-core.parametros
     * Módulo com os paramêtros gerais do sistema
     **/

    angular
            .module('core.parametros', ['core', 'core.commons', 'core.exercicio', 'core.configuracao-organograma']);
})();
