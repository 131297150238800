(function () {
    'use strict';

    angular.module('core.ato')
            .directive('coreAtoSelect', AtoSelectDirective);

    AtoSelectDirective.$inject = ['CONFIG_SELECT2'];

    function AtoSelectDirective(CONFIG_SELECT2) {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" bf-required="bfRequired" search="vm.search" data-ng-disabled="ngDisabled" format-result="vm.formatResult(data)"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                bfRequired: '=?',
                hiddenAddValue: '=?',
                criterionSearch: '=?',
                paramsModal: '=?',
                ngDisabled: '=?'
            },
            compile: function (element, attributes) {
                var addValue = attributes.hiddenAddValue === 'true';
                if (!addValue) {
                    element.children('ui-select').attr('add-value', 'vm.onAdd');
                }
                element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
                element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
                element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('ATO'));
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'bfc.$$PermissionsService',
        'core.AtoService',
        'core.AtoModalService'
    ];

    function Controller($scope, PermissionsService, service, modal) {
        var vm = this;
        vm.search = search;
        vm.onAdd = !PermissionsService.isRevokedOperation('AtoPageMapping', 'criar') && onAdd;
        vm.params = $scope.criterionSearch;
        vm.formatResult = formatResult;

        function search(params) {
            //var paramsDefault = '(numeroAto elike "' + params.term + '" or ementa elike "' + params.term +'")';
            var paramsDefault = '(numeroAto elike "' + params.term + '")';
            params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
            return service.getList(params).then(function (data) {
                return data;
            });
        }

        function onAdd(descricao) {
            var param = {
                numeroAto: descricao,
                paramsModal: $scope.paramsModal
            };
            return modal.onAdd(param);
        }

        function formatResult(value) {
            return value.descricao || value.tipoAto.classificacaoDesc + ' - ' + value.numeroAto + '/' + value.dataCriacao.slice(0, 4);
        }
    }
})();
