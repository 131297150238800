(function () {
    'use strict';

    angular
        .module('core.fornecedor')
        .factory('core.ConsultaReceitaFederalResources', ConsultaReceitaFederalResources);

    ConsultaReceitaFederalResources.$inject = ['Restangular', 'URL_API_CONSULTA_RECEITA'];

    function ConsultaReceitaFederalResources(Restangular, URL_API_CONSULTA_RECEITA) {
        return Restangular.withConfig(function (RestangularConfigurer) {
            RestangularConfigurer.setBaseUrl(URL_API_CONSULTA_RECEITA);
            RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
        });
    }
})();
