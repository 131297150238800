(function() {
	'use strict';
	/**
	 * @ngdoc service 
	 * @name contratos-core.unidade-medida.service:UnidadeMedidaModalService
	 * @description Serviço para abrir o cadastro de unidades de medidas
	 * @requires ModalCadFactory
	 **/
	angular
			.module('core.unidade-medida')
			.service('core.UnidadeMedidaModalService', UnidadeMedidaModalService);

	UnidadeMedidaModalService.$inject = ['ui.components.Modal'];

	function UnidadeMedidaModalService(uiModal) {

		var modal = {
			templateUrl: 'unidade-medida-cad/unidade-medida-cad.html',
			controller: 'core.UnidadeMedidaCadController',
			controllerAs: 'unidadeMedidaCad',
			params: {
				UnidadeMedidaId: null
			}
		};

		var service = {
			cadastrar: cadastrar,
			editar: editar,
			onAdd: onAdd
		};

		angular.extend(this, service);

		function abrir(modal) {
			 return uiModal.open(modal).result;
		}

		function copia(modal) {
			return angular.copy(modal);
		}

        function cadastrar() {
            var m = copia(modal);
            abrir(m);
        }

		function editar(unidadeMedida) {
			var m = copia(modal);
			m.params = {
				UnidadeMedidaId: unidadeMedida.id
			};
			abrir(m);
		}

		function onAdd(params){
            var m = copia(modal);
            m.params = {
                UnidadeMedidaId: null,
                params: params
            };
            return uiModal.open(m).result;
        }
	}
})();