(function() {
  'use strict';

  angular
    .module('core.integracao')
    .factory('core.ComprasResources', ComprasResources);

  ComprasResources.$inject = ['Restangular', 'URL_API'];

  function ComprasResources(Restangular, URL_API) {
    return Restangular.withConfig(function(RestangularConfigurer) {
      var novaUrl = URL_API.split('/')[3] === 'compras' ? URL_API : URL_API + 'compras/';
      RestangularConfigurer.setBaseUrl(novaUrl);
      RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
    });
  }
})();
