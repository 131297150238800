(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.ato.AtoService
     * @dcontratacaocription
     * Serviço que se comunica com o back-end de atos
     * @requircontratacao $q
     * @requircontratacao Restangular
     **/

    angular
            .module('core.ato')
            .service('core.AtoService', AtoService);

    AtoService.$inject = [
        '$q',
        'Restangular',
        'ui.components.EventEmitter',
        'CamposAdicionaisService'
    ];

    function AtoService(
            $q,
            Restangular,
            EventEmitter,
            CamposAdicionaisService) {

        var meta;
        var uri = ['atos', 'campos-adicionais'];

        var service = new EventEmitter();

        _.extend(service, AtoService);

        service.get = get;
        service.getList = getList;
        service.getListTemplate = getListTemplate;
        service.getDefault = getDefault;
        service.getTypes = getTypes;
        service.salvar = salvar;
        service.excluir = excluir;
        service.getSugestoes = getSugestoes;
        service.getCamposAdicionais = getCamposAdicionais;

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getList(params) {
            params = params || {};

            params.offset = params.offset || 0;

            return Restangular.one(uri[0]).get({
                offset: params.offset,
                limit: params.limit,
                filter: params.filter,
                sort: params.sort
            }).then(function (data) {
                if (params.offset > 0 && data.total <= params.offset) {
                    params.offset = 0;
                    data = getList(params);
                }

                return data;
            });
        }

        function getSugestoes(params) {
            params = params || {};

            return Restangular.one(uri[0]+'/sugestoes').get({
                filter: params.filter
            });
        }

        function getListTemplate(params) {
            params = params || {};

            return Restangular.one(uri[0]).one('template').get({
                filter: params.filter,
                sort: params.sort,
                termo: params.term
            }).then(function (content) {
                var data = {
                    content: content
                };
                return data;
            });
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri[0]).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getTypes() {
            return getMetadata().then(function (data) {
                return angular.copy(data.types);
            });
        }

        function salvar(ato, anexos) {
            var rest = Restangular.all(uri[0]);
            var formData = new FormData();
            formData.append('ato', angular.toJson(Restangular.stripRestangular(ato)));
            if (anexos) {
                angular.forEach(anexos, function (file) {
                    if(file.file){
                        formData.append('files', file.file, file.unique);
                    }
                });
            }

            if (ato.id) {
                return rest.withHttpConfig({transformRequest: angular.identity})
                    .customPUT(formData, ato.id, undefined, {'Content-Type': undefined})
                    .then(emitDirty);
                // return rest.customPUT(ato, ato.id).then(emitDirty);
            } else {
                return rest.withHttpConfig({transformRequest: angular.identity})
                    .customPOST(formData, '', undefined, {'Content-Type': undefined})
                    .then(emitDirty);
            }
        }

        function excluir(id) {
            return Restangular.one(uri[0], id).remove().then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }

        function getCamposAdicionais(atoId) {
            return CamposAdicionaisService.get({
                path: uri[0] + '/' + uri[1],
                origemId: atoId
            }).then(function(response) {
                return response.data;
            });
        }
    }
})();