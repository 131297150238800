(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.configuracao-organograma.controller:ConfiguracaoOrganogramaController
   * @description
   * Controller principal do cadastro de configuração de organograma
   * @requires $rootScope
   * @requires $scope
   * @requires promiseTracker
   * @requires bfc.dialog.Dialog
   * @requires contratos-core.configuracao-organograma.ConfiguracaoOrganogramaService
   * @requires ctr.ParametrosService
   */
  angular
    .module('core.configuracao-organograma')
    .controller('core.ConfiguracaoOrganogramaController', ConfiguracaoOrganogramaController);

  ConfiguracaoOrganogramaController.$inject = [
    '$rootScope',
    '$scope',
    'promiseTracker',
    'core.ModalCadFactory',
    'core.ParametrosService',
    'core.ConfiguracaoOrganogramaService',
    'bfc.Notification'
  ];

  function ConfiguracaoOrganogramaController($rootScope,
                                             $scope,
                                             promiseTracker,
                                             ModalCadFactory,
                                             ParametrosService,
                                             ConfiguracaoOrganogramaService,
                                             Notification) {

    var viewModel = this;

    viewModel.argumentos = ConfiguracaoOrganogramaController.$inject;

    viewModel.add = add;
    viewModel.change = change;
    viewModel.duplicate = duplicate;
    viewModel.edit = edit;
    viewModel.formatNivel = formatNivel;
    viewModel.hasError = hasError;
    viewModel.hasList = hasList;
    viewModel.isEmptyList = isEmptyList;
    viewModel.isInUse = isInUse;
    viewModel.remove = remove;
    viewModel.ordenaNiveis = ordenaNiveis;

    init();

    function actionChange(configuracaoOrganograma) {
      viewModel.configuracaoOrganogramaSelected = configuracaoOrganograma;
      $rootScope.$broadcast('refreshConfiguracaoOrganograma', {
        configuracaoOrganogramaSelected: configuracaoOrganograma,
        operation: 'change'
      });
    }

    function actionDuplicate(configuracaoOrganograma) {
      viewModel.configuracaoOrganogramaList.push(configuracaoOrganograma);
      change(configuracaoOrganograma);
      edit(configuracaoOrganograma);
    }

    function actionList(list) {
      viewModel.configuracaoOrganogramaList = list;
    }

    function actionRemove(configuracaoOrganograma) {
      if (configuracaoOrganograma.id) {
        initConfiguracaoOrganogramaSelected();
        viewModel.configuracaoOrganogramaList = _.reject(viewModel.configuracaoOrganogramaList, {
          'id': configuracaoOrganograma.id
        });
      }
    }

    function actionSave(configuracaoOrganograma) {
      var configuracaoOrganogramaExist = _.find(viewModel.configuracaoOrganogramaList, {
        'id': configuracaoOrganograma.id
      });

      if (configuracaoOrganogramaExist === undefined) {
        viewModel.configuracaoOrganogramaList.push(configuracaoOrganograma);
      } else {
        angular.copy(configuracaoOrganograma, configuracaoOrganogramaExist);
      }
      change(configuracaoOrganograma);
    }

    function add() {
      var modal = angular.copy(viewModel.modal);
      ModalCadFactory.open(modal);
    }

    function change(configuracaoOrganograma) {
      actionChange(configuracaoOrganograma);
    }

    function duplicate(configuracaoOrganograma) {
      var promise = ConfiguracaoOrganogramaService.duplicate(configuracaoOrganograma.id);
      promise.then(function (data) {
        actionDuplicate(data);
      });
      viewModel.tracker.list.addPromise(promise);
    }

    function edit(configuracaoOrganograma) {
      var modal = angular.copy(viewModel.modal);
      modal.resolve = {
        id: angular.copy(configuracaoOrganograma.id)
      };
      ModalCadFactory.open(modal);
    }

    function formatNivel(nivel) {
      return nivel.separador === 'nulo' ?
      nivel.separador + repeatStrings('0', nivel.digitos) :
        repeatStrings('0', nivel.digitos);
    }

    function hasError(configuracaoOrganograma) {
      return Boolean(configuracaoOrganograma.avisos);
    }

    function hasList() {
      return viewModel.configuracaoOrganogramaList && viewModel.configuracaoOrganogramaList.length > 0;
    }

    function init() {
      viewModel.configuracaoOrganogramaSelected = {};
      viewModel.tracker = {list: null};
      viewModel.configuracaoOrganogramaList = [];
      viewModel.modal = {};
      initTrackers();
      initConfiguracaoOrganogramaSelected();
      initConfiguracaoOrganogramaList();
      initModal();
      initListeningEvents();
    }

    function initConfiguracaoOrganogramaList() {
      list();
    }

    function initConfiguracaoOrganogramaSelected() {
      viewModel.configParametroId = undefined;

      var promise = ParametrosService.getConfiguracaoOrganograma();
      promise.then(function (configuracaoOrganograma) {
        viewModel.configParametroId = configuracaoOrganograma && configuracaoOrganograma.id;
        actionChange(configuracaoOrganograma);
      });
      viewModel.tracker.list.addPromise(promise);
    }

    function initListeningEvents() {
      $scope.$on('app-context:exercicio:new', function (event, args) {
        initConfiguracaoOrganogramaSelected();
      });

      $scope.$on('refreshConfiguracaoOrganograma', function (event, args) {
        if (!args.configuracaoOrganograma || !args.operation) {
          return;
        }
        switch (args.operation) {
          case 'save':
            actionSave(args.configuracaoOrganograma);
            break;
          case 'duplicate':
            actionDuplicate(args.configuracaoOrganograma);
            break;
          case 'remove':
            actionRemove(args.configuracaoOrganograma);
            break;
        }
      });

      $scope.$on('configuracaoOrganograma:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
        //TODO remover quando tiver paginacao
        angular.element('#hrefConfiguracaoOrganogramaControllerExcluir-' + params.id).triggerHandler('click');
      });
    }

    function initModal() {
      viewModel.modal = {
        templateUrl: 'configuracao-organograma-cad/configuracao-organograma-cad.html',
        controller: 'core.ConfiguracaoOrganogramaCadController as cfgOrgCad',
        resolve: {
          id: null
        }
      };
    }

    function initTrackers() {
      viewModel.tracker.list = promiseTracker();
    }

    function isEmptyList() {
      return viewModel.configuracaoOrganogramaList && viewModel.configuracaoOrganogramaList.length === 0;
    }

    function isInUse(configuracaoOrganograma) {
      return configuracaoOrganograma.id === viewModel.configParametroId;
    }

    function list() {
      var promise = ConfiguracaoOrganogramaService.getList();
      promise.then(function (data) {
        actionList(data);
      });
      viewModel.tracker.list.addPromise(promise);
    }

    function remove(configuracaoOrganograma) {
      var configuracaoOrganogramaRemove = configuracaoOrganograma;
      var promise;
      promise = ConfiguracaoOrganogramaService.remove(configuracaoOrganograma.id);
      return promise.then(function () {
        actionRemove(configuracaoOrganogramaRemove);
        Notification.publish('Configuração de organograma excluído com sucesso', 'success');
      }, function error() {
        return promise;
      });
    }

    function repeatStrings(pattern, count) {
      if (count < 1)
        return '';
      var result = '';
      while (count > 0) {
        if (count & 1)
          result += pattern;
        count >>= 1, pattern += pattern;
      }
      return result;
    }

    function ordenaNiveis(niveis) {
      return niveis.sort(function(a, b) {
            return a.nivel < b.nivel ? -1 : a.nivel > b.nivel ? 1 : 0;
        });
    }
  }
})();
