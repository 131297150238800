(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.logradouro
     * @description
     * # contratos-core.logradouro
     * Módulo de endereços
     **/
    angular
            .module('core.logradouro', ['core', 'core.commons', 'core.endereco', 'core.tipo-logradouro', 'core.municipio']);
})();
