(function () {
    'use strict';

    angular
        .module('core.estado')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var estado = {
            route: {
                url: '/estado',
                data: {
                    id: 'EstadoPageMapping'
                },
                views: {
                    '@main.endereco': {
                        templateUrl: 'estado.html',
                        controller: 'core.EstadoController',
                        controllerAs: 'estado'
                    }
                }
            }
        };

        stateProvider
            .from('core-endereco')
            .create('core-estado', estado.route);
    }
})();