(function () {
  'use strict';

  angular
    .module('core.relatorios')
    .service('core.RelatoriosNotificacoesService', relatoriosNotificacoesService);

  relatoriosNotificacoesService.$inject = ['bfc.Notification'];

  function relatoriosNotificacoesService(Notification) {

    return {
      notificarSucesso : notificarSucesso,
      notificarErro : notificarErro
    }

    function notificarSucesso() {
      Notification.publish('Gerando relatório... Aguarde o processamento!', 'info');
    }

    function notificarErro() {
      Notification.publish('Erro ao enviar relatório para execução', 'error');
    }
  }

})();
