(function () {
    'use strict';

    angular
        .module('core.endereco')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var endereco = {
            route: {
                url: '/endereco',
                views: {
                    '@main': {
                        templateUrl: 'endereco-geral.html',
                        controller: 'core.EnderecoGeralController',
                        controllerAs: 'enderecoGeralController'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-endereco', endereco.route);
    }
})();