(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.municipio
     * @description
     * # contratos-core.municipio
     * Módulo de endereços
     **/
    angular
            .module('core.municipio', ['core', 'core.commons','core.endereco', 'core.estado']);
})();
