(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.configuracao-organograma
     * @description
     * # contratos-core.configuracao-organograma
     * Módulo para gerenciar as configurações de organograma do sistema
     **/
    angular
            .module('core.configuracao-organograma', ['core', 'core.commons', 'core.parametros','core.exercicio']);
})();
