(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.ato.controller:AtoCadController
     * @description Controller do cadastro de atos
     **/
    angular
            .module('core.ato')
            .controller('core.AtoCadController', AtoCadController);

    AtoCadController.$inject = [
        '$rootScope',
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'bfc.FocusCtrl',
        'core.TrackerFactory',
        'core.AtoService',
        'CamposAdicionaisService',
        'CPA_CONSTANTS',
        'core.Select2Factory',
        'core.AtosSugestoesSelect'
    ];

    function AtoCadController($rootScope,
            $scope,
            $modalInstance,
            Notification,
            Focus,
            TrackerFactory,
            AtoService,
            CamposAdicionaisService,
            CPA_CONSTANTS,
            Select2Factory,
            AtosSugestoesSelect) {

        var viewModel = this;
        viewModel.desabilitaTipo = false;

        var metodos = {
            salvar: salvar,
            excluir: excluir,
            desabilitaSalvar: desabilitaSalvar,
            getAcao: getAcao,
            estaAdicionando: estaAdicionando,
            temAto: temAto,
            hasDataPublicacao: hasDataPublicacao,
            buscarAtos: buscarAtos,
            editando: editando,
            adicionando: adicionando
        };

        var propriedades = {
            argumentos: AtoCadController.$inject
        };

        _.extend(this, metodos);
        _.extend(this, propriedades);

        inicializaLoadings();
        inicializar();

        function inicializar() {
            viewModel.arquivos = [];
            inicializaCamposAdicionais();
            inicializarAto();
            initSelect2();
            viewModel.visivel = 1;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'iniciaAto',
                'salvar',
                'salvarAdicionar',
                'excluir',
                'camposAdicionais'
            ]);
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([{
                    element: 'numeroAto',
                    select2: AtosSugestoesSelect
                }]);
        }

        function buscarAtos() {
            viewModel.ato.numeroAto = viewModel.template.numeroAto;
            viewModel.ato.id = null;
        }

        function inicializarAto() {
            var promise;
            if ($modalInstance.params.identificador_cadastro) {
                promise = AtoService.get($modalInstance.params.identificador_cadastro);
                promise.then(function (ato) {
                    viewModel.ato = ato;
                    viewModel.arquivos = ato.arquivos;
                });
            } else {
                promise = AtoService.getDefault();
                promise.then(function (atoDefault) {
                    viewModel.ato = atoDefault;
                    viewModel.arquivos = atoDefault.arquivos;
                    if ($modalInstance.params && $modalInstance.params.params) {
                        viewModel.ato.numeroAto = $modalInstance.params.params.numeroAto;
                        viewModel.ato.tipoAto = $modalInstance.params.params.paramsModal.tipoAto;
                        if ($modalInstance.params.params.tipoAto) {
                            viewModel.ato.tipoAto = $modalInstance.params.params.tipoAto;
                            viewModel.desabilitaTipo = true;
                        }
                    }
                });
            }
            viewModel.loadings.iniciaAto.addPromise(promise);
        }

        function inicializaCamposAdicionais() {
            var promise = AtoService.getCamposAdicionais($modalInstance.params.identificador_cadastro)
                .then(function(data) {
                    viewModel.campoAdicional = data;
                });

            viewModel.loadings.camposAdicionais.addPromise(promise);

            return promise;
        }

        function excluir() {
            $rootScope.$broadcast('ato:actionRemove', {id: viewModel.ato.id});
            fechar();
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;

            if (viewModel.campoAdicional)
                viewModel.ato.campoAdicional = viewModel.campoAdicional;

            tracker.addPromise(AtoService.salvar(viewModel.ato, viewModel.arquivos).then(function (ato) {
                if (continuar) {
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                        viewModel.form.$setUntouched();
                    }
                    if ($modalInstance.params.params) {
                        $modalInstance.params.params.numeroAto = null;
                    }
                    $modalInstance.params.identificador_cadastro = undefined;
                    Focus.on('numeroAto');
                    inicializar();
                    inicializaCamposAdicionais();
                    viewModel.visivel = 1;
                } else {
                    viewModel.arquivos = [];
                    fechar(ato);
                }
                showNotificationSave();
            }));
        }

        function showNotificationSave() {
            var palavra;
            if (temAto()) {
                palavra = "alterado";
            } else {
                palavra = "adicionado";
            }
            Notification.publish('Ato ' + palavra + ' com sucesso', 'success');
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid)
                    || (viewModel.form.$pristine && !temAto())
                    || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
        }

        function fechar(ato) {
            $modalInstance.close(ato);
        }

        function getAcao() {
            return $modalInstance.params.identificador_cadastro ? "Editando" : "Adicionando";
        }

        function temAto() {
            return $modalInstance.params.identificador_cadastro || (viewModel.ato && viewModel.ato.id);
        }

        function estaAdicionando() {
            return temAto() ? 'Editando' : 'Adicionando';
        }

        function hasDataPublicacao() {
            return Boolean(viewModel.ato && viewModel.ato.dataPublicacao);
        }

        function adicionando() {
            return !editando();
        }

        function editando() {
            return (viewModel.ato && viewModel.ato.id === undefined) ? $modalInstance.params.atoId : viewModel.ato && viewModel.ato.id;
        }
    }
})();
