(function () {
    'use strict';

    angular.module('core.agencia-bancaria')
        .directive('coreAgenciaBancariaSelect', agenciaBancariaSelect);

    agenciaBancariaSelect.$inject = ['CONFIG_SELECT2'];

    function agenciaBancariaSelect(CONFIG_SELECT2) {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" bf-required="bfRequired" ng-disabled="ngDisabled" search="vm.search" format-result="vm.formataResultado(data)" title="{{vm.titulo}}"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                bfRequired: '=?',
                ngDisabled: '=?',
                hiddenAddValue: '=?',
                criterionSearch: '=?',
                multiple: '=?',
                identifierType: '=?'
            },
            compile: function (element, attributes) {
                var addValue = attributes.hiddenAddValue === 'true';
                if (!addValue) {
                    element.children('ui-select')
                        .attr('add-value', 'vm.onAdd');
                }
                if (attributes.multiple === 'true') {
                    element.children('ui-select').attr('multiple', 'true');
                }
                element.children('ui-select')
                    .attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
                element.children('ui-select')
                    .attr('debounce-delay', CONFIG_SELECT2.DELAY);
                element.children('ui-select')
                    .attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('AGENCIA_BANCARIA'));
            },
            controller: controller,
            controllerAs: 'vm'
        };
    }

    controller.$inject = [
        '$scope',
        'bfc.$$PermissionsService',
        'core.AgenciaBancariaService',
        'core.AgenciaBancariaCadModalService'
    ];

    function controller($scope, PermissionsService, service, modal) {
        var vm = this;
        vm.search = search;
        vm.onAdd = !PermissionsService.isRevokedOperation('AgenciaBancariaPageMapping', 'criar') && onAdd;
        vm.params = $scope.criterionSearch;
        vm.formataResultado = formataResultado;

        function search(params) {
            var paramsDefault = '(nome like "' + params.term + '" or numeroAgencia like "' + params.term + '")';
            params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
            return service.getList(params).then(function (data) {
                return data;
            });
        }

        function formataResultado(value) {
            if (value.added) return "";

            vm.titulo = value.nome + " (" + value.numeroAgencia + ")";
            return value.nome + " (" + value.numeroAgencia + ")";
        }

        function onAdd(descricao) {
            var param = {
                nome: descricao,
                banco: $scope.identifierType
            };
            return modal.onAdd(param);
        }
    }
})();
