(function() {
    'use strict';

    angular
        .module('core.commons')
        .filter('coreDateTimeFilter', dateTimeFilter);

    dateTimeFilter.$inject = ['$filter'];

    function dateTimeFilter($filter) {
        return function(data) {
            if (data && !_.isNull(data)) {
                var d = moment(data).format("DD/MM/YYYY HH:mm");
                return d;
            }
        }
    }
})();
