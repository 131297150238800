(function () {
    'use strict';

    angular
        .module('core.organograma')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var organograma = {
            route: {
                url: '/organograma',
                data: {
                    id: 'OrganogramaPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'organograma.html',
                        controller: 'core.OrganogramaController',
                        controllerAs: 'vm'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-organograma', organograma.route);
    }
})();