(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name contratos-core.organograma.controller:OrganogramaCadController
     * @description
     * Cadastro de organograma
     * @requires $rootScope
     * @requires $q
     * @requires $modalInstance
     * @requires $modalTracker
     * @requires promiseTracker
     * @requires OrganogramaService
     * @requires ctr.ConfiguracaoOrganogramaService
     */
    angular
        .module('core.organograma')
        .controller('core.OrganogramaCadController', OrganogramaCadController);

    OrganogramaCadController.$inject = [
        '$modalInstance',
        '$q',
        '$rootScope',
        '$injector',
        'UserAccessContext',
        '$modalTracker'
    ];

    function OrganogramaCadController($modalInstance,
                                      $q,
                                      $rootScope,
                                      $injector,
                                      UserAccessContext,
                                      $modalTracker) {
        var viewModel = this;

        var TrackerFactory = $injector.get('core.TrackerFactory'),
            OrganogramaService = $injector.get('core.OrganogramaService'),
            Select2Factory = $injector.get('core.Select2Factory'),
            EntidadesSelect = $injector.get('EntidadesSelect'),
            EntidadesService = $injector.get('EntidadesService'),
            ConfiguracaoOrganogramaService = $injector.get('core.ConfiguracaoOrganogramaService'),
            CamposAdicionaisService = $injector.get('CamposAdicionaisService'),
            CPA_CONSTANTS = $injector.get('CPA_CONSTANTS'),
            Notification = $injector.get('bfc.Notification')

        inicializaLoadings();
        inicializa();

        function inicializa() {
            inicializaMetodos();
            inicializaPropriedades();
            inicializaPromises();
            inicializaCamposAdicionais();
            initSelect2();
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'salvar',
                'salvarAdicionar',
                'inicializaCadastro',
                'cadastro',
                'excluir',
                'camposAdicionais'
            ]);
        }

        function inicializaPropriedades() {
            viewModel.argumentos = OrganogramaCadController.$inject;
            viewModel.configuracaoOrganograma = {};
            viewModel.mask = {
                mask: ''
            };
            viewModel.cadastro = {};
            viewModel.select2 = {};
            viewModel.types = {};
            viewModel.visivel = 1;
        }

        function inicializaMetodos() {
            viewModel.getMask = getMask;
            viewModel.moreThanOneItem = moreThanOneItem;
            viewModel.salvar = salvar;
            viewModel.situacaoCadastro = situacaoCadastro;
            viewModel.existeOrganograma = existeOrganograma;
            viewModel.desabilitaSalvar = desabilitaSalvar;
            viewModel.excluir = excluir;
            viewModel.getSistemaPatrimonio = getSistemaPatrimonio;
        }

        function inicializaPromises() {
            var promise = $q.all([
                inicializaCadastro()
            ]);
            viewModel.loadings.inicializaCadastro.addPromise(promise);
            $modalTracker.addPromise(promise);
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([
                {
                    element: 'entidadeGestora',
                    select2: EntidadesSelect,
                    options: {
                        maximumInputLength: 60
                    }
                }
            ]);
        }

        function inicializaTypes(configuracaoOrganogramaId) {
            var promise = OrganogramaService.getTypes(configuracaoOrganogramaId);
            promise.then(function (types) {
                viewModel.types = types.OrganogramaBO;
            });
            return promise;
        }

        function inicializaCadastro() {
            var promise;
            if ($modalInstance.params.identificador_cadastro) {
                ConfiguracaoOrganogramaService.get(OrganogramaService.getConfigId())
                    .then(function (configuracaoOrganograma) {
                        viewModel.configuracaoOrganograma = configuracaoOrganograma;
                        viewModel.mask.mask = getMask(configuracaoOrganograma);
                        promise = OrganogramaService.get('0', $modalInstance.params.identificador_cadastro);
                        promise.then(function (organograma) {
                            inicializaTypes(organograma.configuracao.id);
                            viewModel.cadastro = organograma.plain();
                        });
                    });
            } else {
                if ($modalInstance.params.params && $modalInstance.params.params.configuracaoOrganogramaId) {
                    inicializaTypes($modalInstance.params.params.configuracaoOrganogramaId);
                    promise = ConfiguracaoOrganogramaService.get($modalInstance.params.params.configuracaoOrganogramaId);
                    promise.then(function (configuracaoOrganograma) {
                        viewModel.configuracaoOrganograma = configuracaoOrganograma;
                        viewModel.mask.mask = getMask(configuracaoOrganograma);
                        if ($modalInstance.params.params && $modalInstance.params.params.descricao) {
                            viewModel.cadastro.descricao = $modalInstance.params.params.descricao;
                        }
                    });
                }
            }
            return promise;
        }

        function inicializaCamposAdicionais() {
            if (!CPA_CONSTANTS.Cadastros.ORGANOGRAMA) {
                viewModel.campoAdicional = undefined;
            } else {
                var promise = OrganogramaService.getCamposAdicionais(OrganogramaService.getConfigId(), $modalInstance.params.identificador_cadastro)
                    .then(function (data) {
                        viewModel.campoAdicional = data;
                    });

                viewModel.loadings.camposAdicionais.addPromise(promise);

                return promise;
            }
        }

        function getMask(configuracaoOrganograma) {
            var niveis = configuracaoOrganograma.niveis;

            if (angular.isUndefined(niveis)) {
                return null;
            }

            function repeatStrings(pattern, count) {
                if (count < 1)
                    return '';
                var result = '';
                while (count > 0) {
                    if (count & 1)
                        result += pattern;
                    count >>= 1, pattern += pattern;
                }
                return result;
            }

            var mask = '';
            angular.forEach(niveis, function (value, index) {
                mask += value.separador !== 'nulo' && angular.isDefined(value.separador) ?
                    value.separador + repeatStrings('0', value.digitos) :
                    repeatStrings('0', value.digitos);
            });
            return mask;
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid)
                || (viewModel.form.$pristine && !existeOrganograma())
                || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
        }

        function situacaoCadastro() {
            return existeOrganograma() ? 'Editando' : 'Adicionando';
        }

        function existeOrganograma() {
            return Boolean($modalInstance.params.identificador_cadastro);
        }

        function moreThanOneItem() {
            return viewModel.items && viewModel.items.length > 1;
        }

        function getSistemaPatrimonio() {
            return UserAccessContext.getUserAccess().system == 32 ? true : false;
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;

            if (viewModel.campoAdicional) {
                viewModel.cadastro.campoAdicional = viewModel.campoAdicional;
            }

            tracker.addPromise(
                OrganogramaService.save(viewModel.configuracaoOrganograma.id, viewModel.cadastro)
                    .then(function (data) {
                        $rootScope.$broadcast('refreshOrganograma');
                        if (continuar) {
                            if (viewModel.form) {
                                viewModel.form.$setPristine();
                                viewModel.form.$setUntouched();
                            }
                            $modalInstance.params.identificador_cadastro = null;
                            inicializaPropriedades();
                            inicializaCadastro();
                            inicializaCamposAdicionais();
                        } else {
                            cancelar(data);
                        }
                        Notification.publish('Organograma salvo com sucesso', 'success');
                    })
            );
        }

        function cancelar(item) {
            $modalInstance.close(item);
        }

        function excluir() {
            $rootScope.$broadcast('organograma:actionRemove', {id: viewModel.cadastro.id});
            cancelar();
        }
    }
})();
