(function () {
    'use strict';

    angular
        .module('core.natureza-texto-juridico-ato')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var natureza = {
            route: {
                url: '/natureza-texto-juridico-ato',
                data: {
                    id: 'NaturezaTextoJuridicoAtoPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'natureza-texto-juridico-ato.html',
                        controller: 'core.NaturezaTextoJuridicoAtoController',
                        controllerAs: 'natTextoJuridico'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-natureza-texto-juridico-ato', natureza.route);
    }
})();