(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.condominio
     * @description
     * # contratos-core.condominio
     * Módulo de endereços
     **/
    angular
            .module('core.condominio', ['core', 'core.commons', 'core.endereco', 'core.municipio', 'core.bairro', 'core.logradouro']);
})();
