(function () {
	'use strict';

	/**
	 * @ngdoc controller
	 * @name contratos-core.condominio:CondominioController
	 * @description Controller principal da listagem de condomínios
	 * @requires $scope
	 * @requires bfc.dialog.Dialog
	 **/

	angular
			.module('core.condominio')
			.controller('core.CondominioController', CondominioController);

	CondominioController.$inject = [
		'$rootScope',
		'$q',
		'$scope',
		'core.TrackerFactory',
		'core.CondominioService',
		'core.CondominioModalService'
	];

	function CondominioController(
			$rootScope,
			$q,
			$scope,
			TrackerFactory,
			CondominioService,
			CondominioModalService
      ) {

		var viewModel = this;
		viewModel.service = CondominioService;

		viewModel.cadastrar = cadastrar;
		viewModel.editar = editar;
		viewModel.excluir = excluir;

		inicializa();

		function inicializa() {
			inicializaPropriedades();
			inicializaLoadings();
      escutaEventos();
		}

    function escutaEventos() {
      $scope.$on('condominio:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

		function inicializaPropriedades() {
			viewModel.condominio = [];
			viewModel.list = {};
			viewModel.filter = {};
		}

		function inicializaLoadings() {
			viewModel.loadings = TrackerFactory.init(['condominios', 'getList', 'exclusao']);
		}

		function cadastrar() {
			CondominioModalService.cadastrar();
		}

		function editar(item) {
			CondominioModalService.editar(item);
		}

		function excluir(item) {
			var promise = CondominioService.excluir(item.id);
			return promise.then(function () {
				CondominioService.mensagemExclusao();
			},function error(){
				return promise;
			});
		}

		$scope.setLineHeight = function (h) {
			$scope.lineHeight = h;
		};
	}
})();
