(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name estoque.encerramentos.service:EncerramentosModalService
     *
     * @description
     * Serviço para abrir o cadastro de encerramentos
     *
     * @requires AcoesCadastrosModalService
     **/
    angular
        .module('core.fornecedor')
        .service('core.ContaBancariaModalService', contaBancariaModalService);

    contaBancariaModalService.$inject = ['core.AcoesCadastrosModalService'];

    function contaBancariaModalService(AcoesCadastrosModalService) {

        var modal = {
            templateUrl: 'fornecedor-cad/conta-bancaria-cad.html',
            controller: 'CoreContaBancariaCadController',
            controllerAs: 'vm',
            params: {
                identificador_cadastro: null
            }
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar
        };

        angular.extend(this, service);

        function cadastrar() {
            AcoesCadastrosModalService.cadastrar(modal);
        }

        function editar(item, index) {
            var novoItem = {
                params: {
                    cadastro: angular.copy(item),
                    index: index
                }
            };
            AcoesCadastrosModalService.editar(modal, novoItem);
        }
    }
})();
