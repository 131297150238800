(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.configuracao-entidade.ConfiguracaoEntidadeService
     * @description
     * Serviço que se comunica com o back-end de Entidades
     * @requires $q
     * @requires Restangular
     **/

    angular
            .module('core.configuracao-entidade')
            .service('core.ConfiguracaoEntidadeService', ConfiguracaoEntidadeService);

    ConfiguracaoEntidadeService.$inject = [
        '$q',
        'Restangular',
        '$filter',
        'ui.components.EventEmitter'
    ];

    function ConfiguracaoEntidadeService(
            $q,
            Restangular,
            $filter,
            EventEmitter) {

        var meta;
        var uri = ['entidades', 'naturezas-juridicas'];

        var orderBy = $filter('orderBy');

        var service = new EventEmitter();

        _.extend(service, ConfiguracaoEntidadeService);

        service.get = get;
        service.getBrasao = getBrasao;
        service.getBrasaoAtual = getBrasaoAtual;
        service.getList = getList;
        service.getDefault = getDefault;
        service.getTypes = getTypes;
        service.salvar = salvar;
        service.salvarBrasao = salvarBrasao;
        service.excluir = excluir;
        service.getListNaturezaJuridica = getListNaturezaJuridica;
        //service.getClassificacaoAditivo = getClassificacaoAditivo;

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getBrasao(id) {
            return Restangular.one(uri[0] + "/" + id + "/brasao").get();
        }

        function getBrasaoAtual() {
            return Restangular.one(uri[0] + "/atual/brasao").get();
        }

        function getList(params) {
            params = params || {};

            params.offset = params.offset || 0;

            return Restangular.one(uri[0]).get({
                offset: params.offset,
                limit: params.limit,
                filter: params.filter,
                sort: params.sort
            }).then(function (data) {
                if (params.offset > 0 && data.total <= params.offset) {
                    params.offset = 0;
                    data = getList(params);
                }
                return data;
            });
        }

        function getListNaturezaJuridica(params) {
            params = params || {};

            params.offset = params.offset || 0;

            return Restangular.one(uri[1]).get({
                offset: params.offset,
                limit: params.limit,
                filter: params.filter,
                sort: params.sort
            }).then(function (data) {
                if (params.offset > 0 && data.total <= params.offset) {
                    params.offset = 0;
                    data = getList(params);
                }
                return data;
            });
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri[0]).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getTypes() {
            return getMetadata().then(function (data) {
                return angular.copy(data.types);
            });
        }

        function salvar(dataObj) {
            var rest = Restangular.all(uri[0]);
            if (dataObj.id) {
                return rest.customPUT(dataObj, dataObj.id).then(emitDirty);
            } else {
                return rest.post(dataObj).then(emitDirty);
            }
        }

        function salvarBrasao(id, brasao) {
            return Restangular.all(uri[0] + "/" + id + "/brasao").post(brasao).then(emitDirty);
        }

        function excluir(id) {
            return Restangular.one(uri[0], id).remove().then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }
    }
})();