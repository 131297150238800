(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name contratos-core.unidade-medida.controller:UnidadeMedidaCadController
     * @description
     * UnidadeMedidaCadController de contratos-core.unidade-medida
     * @requires $rootScope
     * @requires $q
     * @requires promiseTracker
     * @requires $modalInstance
     * @requires $modalTracker
     * @requires UnidadeMedidaService
     * @requires unidadeMedidaId
     * @param {number} unidadeMedidaId identificação da unidade de medida
     * @param {object} optionsCad paramêtros de modal
     * @param {object} fnCad função para retornos de dados da modal
     */

    angular
            .module('core.unidade-medida')
            .controller('core.UnidadeMedidaCadController', UnidadeMedidaCadController);

    UnidadeMedidaCadController.$inject = [
        '$rootScope',
        '$q',
        '$timeout',
        'promiseTracker',
        '$modalInstance',
        '$modalTracker',
        'core.TrackerFactory',
        'core.Select2Factory',
        'core.UnidadeMedidaService',
        'bfc.FocusCtrl',
        'core.GrandezaSelect',
        'bfc.Notification',
        'CamposAdicionaisService',
        'CPA_CONSTANTS',
        'core.UnidadeMedidaSugestoesSelect'
    ];

    function UnidadeMedidaCadController(
            $rootScope,
            $q,
            $timeout,
            promiseTracker,
            $modalInstance,
            $modalTracker,
            TrackerFactory,
            Select2Factory,
            UnidadeMedidaService,
            Focus,
            GrandezaSelect,
            Notification,
            CamposAdicionaisService,
            CPA_CONSTANTS,
            UnidadeMedidaSugestoesSelect) {
        var viewModel = this;

        var unidadeDefault = {};

        viewModel.argumentos = UnidadeMedidaCadController.$inject;
        viewModel.save = save;
        viewModel.cancel = cancel;
        viewModel.isAdd = isAdd;
        viewModel.emUso = emUso;
        viewModel.remove = remove;
        viewModel.existeUnidadeMedida = existeUnidadeMedida;
        viewModel.alteradoCampos = alteradoCampos;
        viewModel.desabilitaSalvar = desabilitaSalvar;
        viewModel.buscaUnidadeMedida = buscaUnidadeMedida;
        viewModel.selecionaTemplate = selecionaTemplate;
        viewModel.adicionando = adicionando;
        viewModel.editando = editando;
        viewModel.onAlterarStatus = onAlterarStatus;

        inicializaLoadings();
        init();

        function init() {
            viewModel.unidadeMedida = {};
            initSelect2();
            initPromises();
            inicializaCamposAdicionais();
            viewModel.visivel = 1;
        }

        function selecionaTemplate() {
          if (viewModel.descricaoTemplate) {            
              viewModel.unidadeMedida.nome = viewModel.descricaoTemplate.nome;
          }
        }

        function buscaUnidadeMedida() {
            viewModel.unidadeMedida = viewModel.descricaoTemplate;                  
        }

        function initPromises() {
            var promise = $q.all([initUnidadeMedida(), initProperties()]);
            $modalTracker.addPromise(promise);
        }

        function initUnidadeMedida() {
            var promise;
            if ($modalInstance.params.UnidadeMedidaId) {
                promise = UnidadeMedidaService.get($modalInstance.params.UnidadeMedidaId);
                promise.then(function (unidadeMedida) {
                    viewModel.unidadeMedida = unidadeMedida;
                    viewModel.representation = angular.copy(unidadeMedida);
                });

            } else {
                promise = UnidadeMedidaService.getDefault();
                promise.then(function (unidadeMedidaDefault) {
                    viewModel.unidadeMedida = unidadeMedidaDefault;
                    viewModel.unidadeMedida.definidaUsuario = true;

                    if ($modalInstance.params.params) {
                        viewModel.unidadeMedida.nome = $modalInstance.params.params.nome;
                    }
                });
            }
            viewModel.loadings.iniciaUnidadeMedida.addPromise(promise);
            return promise;
        }

        function inicializaCamposAdicionais() {
            var idCadastro = viewModel.unidadeMedida.id || $modalInstance.params.UnidadeMedidaId;
            var promise = UnidadeMedidaService.getCamposAdicionais(idCadastro)
                .then(function(data) {
                    viewModel.campoAdicional = data;
                });

            return promise;
        }

        function initProperties() {
            var promise = UnidadeMedidaService.getTypes();
            promise.then(function (data) {
                viewModel.unidadeMedidaProperties = data.UnidadeMedidaBO;
            });
            return promise;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'iniciaUnidadeMedida',
                'salvar',
                'salvarAdicionar',
                'excluir',
                'camposAdicionais'
            ]);
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([
                {
                    element: 'grandeza',
                    select2: GrandezaSelect
                },
                {
                    element: 'nome',
                    select2: UnidadeMedidaSugestoesSelect
                }
            ]);
        }

        function alteradoCampos() {
            return emUso() ? viewModel.unidadeMedida.nome.$dirty || viewModel.unidadeMedida.nomePlural.$dirty : false;
        }

        function sendUnidadeMedidaList(unidadeMedida) {
            $rootScope.$broadcast('unidadeMedidaCad:list', unidadeMedida);
        }

        function remove() {
            $rootScope.$broadcast('unidadeMedida:actionRemove', {id: viewModel.unidadeMedida.id});
            cancel();
        }

        function save(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;

            if (viewModel.campoAdicional)
                viewModel.unidadeMedida.campoAdicional = viewModel.campoAdicional; 

            tracker.addPromise(UnidadeMedidaService.save(viewModel.unidadeMedida).then(function (unidadeMedida) {
                sendUnidadeMedidaList(unidadeMedida);
                if (continuar) {
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                        viewModel.form.$setUntouched();
                    }
                    init();
                    Focus.set('lbUnidadeMedidaCadControllerNome');
                } else {
                    cancel(unidadeMedida);
                }
                showNotificationSave();
            }));
        }

        function showNotificationSave() {
            if ($modalInstance.params.UnidadeMedidaId) {
                Notification.publish('Unidade de medida alterada com sucesso', 'success');
            } else {
                Notification.publish('Unidade de medida adicionada com sucesso', 'success');
            }
        }

        function verificaForm() {
            return (viewModel.form.$dirty && viewModel.form.$invalid);
        }

        function desabilitaSalvar() {
            var primeiraCondicao = viewModel.form.$pristine && !existeUnidadeMedida();
            var segundaCondicao = !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
            return verificaForm() || primeiraCondicao || segundaCondicao;
        }


        function cancel(unidadeMedida) {
            $modalInstance.close(unidadeMedida);
        }

        function hasUnidadeMedida() {
            return (viewModel.unidadeMedida.id === undefined) ? $modalInstance.params.UnidadeMedidaId : viewModel.unidadeMedida.id;
        }

        function existeUnidadeMedida() {
            return Boolean(hasUnidadeMedida());
        }

        function isAdd() {
            return hasUnidadeMedida() ? 'Editando' : 'Adicionando';
        }

        function emUso() {
            return viewModel.unidadeMedida.emUso;
        }

        function adicionando() {
            return !editando();
        }

        function editando() {
          return (viewModel.unidadeMedida.id === undefined) ? $modalInstance.params.UnidadeMedidaId : viewModel.unidadeMedida.id;
          //return fornecedorId || (viewModel.fornecedor && viewModel.fornecedor.id);
        }

        function onAlterarStatus() {
            viewModel.statusAlterado = !viewModel.statusAlterado;
          }
    }
})();
