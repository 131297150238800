(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.bairro.controller:BairroCadController
     * @description Controller do cadastro de bairros
     **/
    angular
        .module('core.bairro')
        .controller('core.BairroCadController', BairroCadController);

    BairroCadController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'core.TrackerFactory',
        'core.BairroService',
        'core.MunicipioService',
        'core.MunicipioModalService',
        'CONFIG_SELECT2',
        'core.Select2Factory',
        'core.BairrosSugestoesSelect'
    ];

    function BairroCadController($q,
                                 $rootScope,
                                 $scope,
                                 $modalInstance,
                                 Notification,
                                 TrackerFactory,
                                 BairroService,
                                 MunicipioService,
                                 MunicipioModalService,
                                 CONFIG_SELECT2,
                                 Select2Factory,
                                 BairrosSugestoesSelect) {

        var viewModel = this;

        viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

        viewModel.argumentos = BairroCadController.$inject;
        viewModel.salvar = salvar;
        viewModel.excluir = excluir;
        viewModel.desabilitaSalvar = desabilitaSalvar;
        viewModel.getAcao = getAcao;
        viewModel.temBairro = temBairro;

        viewModel.buscarMunicipios = buscarMunicipios;
        viewModel.buscarBairros = buscarBairros;
        viewModel.formataResultado = formataResultado;
        viewModel.adicionarNovoMunicipio = adicionarNovoMunicipio;
        viewModel.editando = editando;
        viewModel.adicionando = adicionando;

        inicializaLoadings();
        inicializar();

        function inicializar() {
            inicializarBairro();
            initSelect2();
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'iniciaBairro',
                'salvar',
                'salvarAdicionar',
                'excluir'
            ]);
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([{
                element: 'nome',
                select2: BairrosSugestoesSelect
            }]);
        }

        function buscarBairros() {
            viewModel.bairro = _.merge(viewModel.bairro, viewModel.template);
            viewModel.bairro.id = null;
        }

        function buscarMunicipios(params) {
            params.filter = '(nome like "' + params.term + '")';
            return MunicipioService.getList(params).then(function (data) {
                return data;
            });
        }

        function formataResultado(value) {
            return value.nome;
        }

        function adicionarNovoMunicipio(nomeMunicipio) {
            var param = {
                nome: nomeMunicipio
            }
            return MunicipioModalService.onAdd(param);
        }

        function inicializarBairro() {
            var promise;
            if ($modalInstance.params.bairroId) {
                promise = BairroService.get($modalInstance.params.bairroId);
                promise.then(function (bairro) {
                    viewModel.bairro = bairro;
                });
            } else {
                promise = BairroService.getDefault();
                promise.then(function (bairroDefault) {
                    viewModel.bairro = bairroDefault;
                    viewModel.bairro.definidaUsuario = true;

                    if ($modalInstance.params.params) {
                        viewModel.desabilitaCampo = !!$modalInstance.params.params.params;
                        viewModel.form.$setDirty();
                        viewModel.bairro.nome = $modalInstance.params.params.nome;
                        if ($modalInstance.params.params.params) {
                            viewModel.bairro.municipio = $modalInstance.params.params.params.municipio;
                        }
                    }
                });
            }
            viewModel.loadings.iniciaBairro.addPromise(promise);

        }

        function excluir() {
            $rootScope.$broadcast('bairro:actionRemove', {id: viewModel.bairro.id});
            fechar();
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;
            tracker.addPromise(BairroService.salvar(viewModel.bairro).then(function (bairro) {
                if (continuar) {
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                        viewModel.form.$setUntouched();
                    }
                    $modalInstance.params.bairroId = undefined;
                    inicializar();
                } else {
                    fechar(bairro);
                }
                showNotificationSave();
            }));
        }

        function showNotificationSave() {
            if (temBairro()) {
                Notification.publish('Bairro alterado com sucesso', 'success');
            } else {
                Notification.publish('Bairro adicionado com sucesso', 'success');
            }
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
        }

        function fechar(bairro) {
            $modalInstance.close(bairro);
        }

        function getAcao() {
            return $modalInstance.params.bairroId ? "Editando" : "Adicionando";
        }

        function temBairro() {
            return $modalInstance.params.bairroId || (viewModel.bairro && viewModel.bairro.id);
        }

        function adicionando() {
            return !editando();
        }

        function editando() {
            return Boolean(viewModel.bairro && !viewModel.bairro.id) ? ($modalInstance.params.params || $modalInstance.params.bairroId) : viewModel.bairro && viewModel.bairro.id;
        }
    }
})();
