(function () {
  'use strict';

  angular
    .module('core.relatorios')
    .service('core.RelatoriosService', relatoriosService);

  relatoriosService.$inject = [
    'core.RelatoriosResources',
    '$filter',
    'ui.components.EventEmitter',
    'core.EmitirRelatorioModalService',
    '$q',
    'core.RelatoriosNotificacoesService'
  ];

  function relatoriosService(relatoriosResources,
                             $filter,
                             EventEmitter,
                             EmitirRelatorioModalService,
                             $q,
                             RelatoriosNotificacoesService) {

    var API_RELATORIOS = relatoriosResources.all('relatorios');

    const PROPRIEDADES_EMISSAO_RELATORIO = {
      opcoesExecucao: {
        enviarEmailAoFinalizar: false,
        emailsParaNotificar: [],
        visibilidadeExecucaoPublica: true,
        formatoExportacao: 'PDF',
        assinarAoFinalizar: false,
        usuariosParaAssinatura: [],
        naturezaQualificadora: null,
        armazenarDocumento: false,
        abrirAoFinalizar: false
      },
      parametros: {},
    }

    var service = new EventEmitter();

    _.extend(service, relatoriosResources);

    service.get = get;
    service.getList = getList;
    service.executarRapidoPorNatureza = executarRapidoPorNatureza;
    service.executarRapido = executarRapido;
    service.abrirEmissaoPorNatureza = abrirEmissaoPorNatureza;

    return service;

    function get(id) {
      return API_RELATORIOS.get(id);
    }

    function getList(params) {
      params = params || {};
      params.offset = params.offset || 0;
      return API_RELATORIOS.customGET(null, params);
    }

    function executarRapidoPorNatureza(natureza, parametrosRelatorio, opcoesExecucao, isOcultarNotificacoes) {
      const deferred = $q.defer();

      var post = API_RELATORIOS
        .one('naturezas', natureza)
        .all('executar')
        .post(_montarPropriedades(opcoesExecucao, parametrosRelatorio));

      post.then(function (result) {

        if (!isOcultarNotificacoes) {
          RelatoriosNotificacoesService.notificarSucesso();
        }

        deferred.resolve(result);
      }, function (reason) {
        if (!isOcultarNotificacoes) {
          RelatoriosNotificacoesService.notificarErro();
        }

        deferred.reject(reason);
      });

      return deferred.promise;
    }

    function executarRapido(idRelatorio, parametrosRelatorio, opcoesExecucao, isOcultarNotificacoes) {
      const deferred = $q.defer();

      var post = API_RELATORIOS
        .one(idRelatorio)
        .all('executar')
        .post(_montarPropriedades(opcoesExecucao, parametrosRelatorio));

      post.then(function (result) {

        if (!isOcultarNotificacoes) {
          RelatoriosNotificacoesService.notificarSucesso();
        }
        deferred.resolve(result);
      }, function (reason) {

        if (!isOcultarNotificacoes) {
          RelatoriosNotificacoesService.notificarErro();
        }
        deferred.reject(reason);
      })

      return deferred.promise;
    }

    function abrirEmissaoPorNatureza(natureza, parametros, opcoesExecucao, isOcultarNotificacoes) {
      return EmitirRelatorioModalService.abrir(natureza, parametros, opcoesExecucao, isOcultarNotificacoes)
    }

    function _montarPropriedades(opcoesExecucao, parametros) {
      var _opcoesExecucao = _.mapValues(PROPRIEDADES_EMISSAO_RELATORIO.opcoesExecucao, function (value, key) {
        return _.get(opcoesExecucao, key, value);
      });
      return _.assign({}, {opcoesExecucao: _opcoesExecucao, parametros: parametros});
    }
  }
})();
