(function () {
  'use strict';

  angular.module('core.integracao')
    .directive('coreExecutaScriptIntegracaoSelect', integracaoSelectDirective);

  integracaoSelectDirective.$inject = ['CONFIG_SELECT2'];

  function integracaoSelectDirective(CONFIG_SELECT2) {
    return {
      restrict: 'E',
      template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" format-result="vm.formatResult(data)" ng-change="ngChange()"></ui-select>',
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=?',
        criterionSearch: '=?',
        ngChange: '&?'
      },
      compile: function (element, attributes) {
        element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
        element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
        element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT());
      },
      controller: Controller,
      controllerAs: 'vm'
    };
  }

  Controller.$inject = [
    '$scope',
    'core.ScriptIntegracaoService'
  ];

  function Controller($scope, service) {
    var vm = this;
    vm.search = search;
    vm.formatResult = formatResult;
    vm.params = $scope.criterionSearch;

    function search(params) {
      var paramsDefault = '(titulo like "' + params.term + '")';
      params.term = params.term.replaceAll('%25', '%');
      if (params.originalTerm) {
        params.term = '%' + params.originalTerm.replaceAll(' ', '%') + '%';
      }
      params.filter = $scope.criterionSearch ? vm.params({ termo: params.term }) : paramsDefault;
      return service.getList(params).then(function (data) {
        return data;
      });
    }

    function formatResult(value) {
      return value.titulo;
    }
  }
})();
