(function () {
    'use strict';
    angular
        .module('core.agencia-bancaria')
        .service('core.AgenciaBancariaService', agenciaBancariaService);

    agenciaBancariaService.$inject = [
        '$q',
        'Restangular',
        'ui.components.EventEmitter',
        'CamposAdicionaisService'
    ];

    function agenciaBancariaService(
        $q,
        Restangular,
        EventEmitter,
        CamposAdicionaisService) {

        var meta,
            uri = [
                'agenciabancaria', 'campos-adicionais'
            ],
            service = new EventEmitter();

        _.extend(service, agenciaBancariaService);

        service.get = get;
        service.getDefault = getDefault;
        service.getList = getList;
        service.remove = remove;
        service.save = save;
        service.emitDirty = emitDirty;
        service.getCamposAdicionais = getCamposAdicionais;

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getList(params) {
            params = params || {};
            params.offset = params.offset || 0;
            return Restangular.one(uri[0])
                .get(params);
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri[0]).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function remove(id) {
            return Restangular.one(uri[0], id)
                .remove()
                .then(emitDirty);
        }

        function save(data) {
            var rest = Restangular.all(uri[0]);
            return !data.id ?
                rest.post(data).then(emitDirty) :
                rest.customPUT(data, data.id).then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }

        function getCamposAdicionais(agenciaId) {
            return CamposAdicionaisService.get({
                path: uri[0] + '/' + uri[1],
                origemId: agenciaId
            }).then(function (response) {
                return response.data;
            });
        }
    }
})();