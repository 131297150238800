(function () {
  'use strict';

  angular
    .module('core.integracao')
    .service('core.ScriptIntegracaoService', ScriptIntegracaoService);

  ScriptIntegracaoService.$inject = [
    'core.ScriptIntegracaoResources',
    '$filter',
    'ui.components.EventEmitter'
  ];

  function ScriptIntegracaoService(ScriptIntegracaoResources,
                                   $filter,
                                   EventEmitter) {

    const API_SCRIPTS = ScriptIntegracaoResources.all('scripts');

    var service = new EventEmitter();

    _.extend(service, ScriptIntegracaoService);

    service.get = get;
    service.getList = getList;

    return service;

    function get(idPublicoScript) {
      return API_SCRIPTS.get(idPublicoScript);
    }

    function getList(params) {
      params = params || {};
      params.offset = params.offset || 0;

      return API_SCRIPTS.customGET(null, params);
    }
  }
})();
