(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.fonte-divulgacao.service:FonteDivulgacaoModalService
     *
     * @description
     * Serviço para abrir o cadastro de fontes de divulgacao
     *
     * @requires ModalCadFactory
     **/
    angular
            .module('core.fonte-divulgacao')
            .service('core.FonteDivulgacaoModalService', FonteDivulgacaoModalService);

    FonteDivulgacaoModalService.$inject = ['core.AcoesCadastrosModalService'];

    function FonteDivulgacaoModalService(AcoesCadastrosModalService) {

        var modal = {
            templateUrl: 'fonte-divulgacao-cad/fonte-divulgacao-cad.html',
            controller: 'core.FonteDivulgacaoCadController',
            controllerAs: 'fonteDivCad',
            params: {
                identificador_cadastro: null
            },
            size: 'lg'
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar,
            onAdd: onAdd
        };

        angular.extend(this, service);

        function abrir(modal) {
            return AcoesCadastrosModalService.abrir(modal);
        }

        function cadastrar() {
            AcoesCadastrosModalService.cadastrar(modal);
        }

        function editar(fonteDivulgacao) {
            AcoesCadastrosModalService.editar(modal, fonteDivulgacao);
        }

        function onAdd(params) {
            return AcoesCadastrosModalService.onAdd(modal, params);
        }
    }
})();
