(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.organograma.OrganogramaService
     * @description
     * Serviço que se comunica com o back-end de organogramas
     * @requires $q
     * @requires Restangular
     **/
    angular
            .module('core.unidade-medida')
            .factory('core.UnidadeMedidaService', UnidadeMedidaService);

    UnidadeMedidaService.$inject = [
        '$q',
        'Restangular',
        'core.UtilsService',
        'ui.components.EventEmitter',
        'CamposAdicionaisService'
    ];

    function UnidadeMedidaService(
            $q,
            Restangular,
            UtilsService,
            EventEmitter,
            CamposAdicionaisService
            ) {
        var meta;
        var uri = ['unidadesmedida', 'campos-adicionais', 'alteracao-em-lote'];

        var service = new EventEmitter();
        _.extend(service, UnidadeMedidaService);

        service.get = get;
        service.getList = getList;
        service.getDefault = getDefault;
        service.save = save;
        service.getTypes = getTypes;
        service.remove = remove;
        service.getEnums = getEnums;
        service.getGrandeza = getGrandeza;
        service.getGrandezas = getGrandezas;
        service.getListSelect2 = getListSelect2;
        service.getSugestoes = getSugestoes;
        service.getCamposAdicionais = getCamposAdicionais;
        service.ativarDesativarEmLote = ativarDesativarEmLote;


        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getList(params) {
            params = params || {};
            params.offset = params.offset || 0;
            return Restangular.one(uri[0])
                .get(params)
                .then(function (data) {
                    return data;
            });
        }

        function getSugestoes(params) {
            params = params || {};
            params.offset = params.offset || 0;
            
            return Restangular.one(uri[0] + '/sugestoes')
                .get(params)
                .then(function (data) {
                    return data;
            });
        }

        //TODO remover quando for utilizado o ui-select
        function getListSelect2(params) {
            params = params || {};
            params.offset = params.offset || 0;
            return Restangular.one(uri[0])
                .get(params)
                .then(function (data) {
                    return data.content;
            });
        }

        function getEnums() {
            return getMetadata().then(function(data) {
                return angular.copy(data.enums);
            });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri[0]).options().then(function(data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function(data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getGrandeza(key) {
            return getEnums().then(function(enums) {
                return UtilsService.getValueFromKey(enums.GrandezaEnum.values, key);
            });
        }

        function getGrandezas(filter) {
            return getEnums().then(function(enums) {
                return UtilsService.getFilteredValues(enums.GrandezaEnum.values, filter);
            });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri[0]).options().then(function(data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function(data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getTypes() {
            return getMetadata().then(function(data) {
                return angular.copy(data.types);
            });
        }

        function save(data) {
            var rest = Restangular.all(uri[0]);
            return !data.id ?
                    rest.post(data).then(emitDirty) :
                    rest.customPUT(data, data.id).then(emitDirty);
        }

        function remove(id) {
            return Restangular.one(uri[0], id).remove().then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }

        function getCamposAdicionais(unidadeMedidaId) {
            return CamposAdicionaisService.get({
                path: uri[0] + '/' + uri[1],
                origemId: unidadeMedidaId
            }).then(function(response) {
                return response.data;
            });
        }

        function ativarDesativarEmLote(idsUnidadeMedida, status) {
            return Restangular
                .one(uri[0])
                .all(uri[2])
                .customPUT({
                    acao: status ? 'ATIVAR' : 'INATIVAR',
                    ids: idsUnidadeMedida
                });
        }

    }
})();
