(function() {
    'use strict';

    angular
            .module('core.exercicio')
            .service('core.ExercicioService', ExercicioService);

    ExercicioService.$inject = [
        '$q',
        '$rootScope',
        'bfcApplicationContext',
        'bfcState',
        'Restangular'
    ];

    function ExercicioService(
            $q,
            $rootScope,
            bfcApplicationContext,
            bfcState,
            Restangular
            ) {


        var metadados;
        var exercicios = [];

        this.getDefault = getDefault;
        this.getExercicio = getExercicio;
        this.getExercicioContexto = getExercicioContexto;
        this.getExercicioEmUso = getExercicioEmUso;
        this.getExercicios = getExercicios;
        this.getProximoExercicio = getProximoExercicio;
        this.getUltimoExercicio = getUltimoExercicio;
        this.getExercicioAnterior = getExercicioAnterior;
        this.getTypes = getTypes;
        this.remover = remover;
        this.salvar = salvar;
        this.validar = validar;

        Restangular.extendModel('parametrosexercicio', extendModel);
        Restangular.extendModel('parametrosexercicio/ultimo', extendModel);

        function extendModel(model) {
            model.getAno = function() {
                return model.exercicio;
            };
            model.getId = function() {
                return model.id;
            };
            return model;
        }

        function getDefault() {
            return getMetadata().then(function(data) {
                return angular.copy(data.representation.default);
            });
        }

        function getExercicio(ano) {
            var filtro = ano ? 'exercicio = ' + ano : '';
            var promise = $q.defer();
            Restangular.all('parametrosexercicio').getList({
                filter: filtro
            }).then(function(exercicio) {
                promise.resolve(exercicio[0]);
            });
            return promise.promise;
        }

        function getExercicioContexto() {
            return bfcApplicationContext.get('exercicio');
        }

        function getExercicioEmUso() {
            return getExercicio(bfcApplicationContext.get('exercicio'));
        }

        function getExercicios() {
            var promise = Restangular.all('parametrosexercicio').getList();
            promise.then(function(response) {
                exercicios = response;
            });
            return promise;
        }

        function getUltimoExercicio() {
            return Restangular.one('parametrosexercicio/ultimo').get();
        }

        function getProximoExercicio() {
            return Restangular.one('parametrosexercicio/novo').get();
        }

        function getExercicioAnterior(exercicio) {
            return Restangular.one('parametrosexercicio/anterior',exercicio.exercicio).get();
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!metadados) {
                Restangular.all('parametrosexercicio').options().then(function(data) {
                    metadados = data;
                    deferred.resolve(metadados);
                }, function(data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(metadados);
            }
            return deferred.promise;
        }

        function getTypes() {
            return getMetadata().then(function(data) {
                return angular.copy(data.types);
            });
        }

        function salvar(exercicio) {
            var rest = Restangular.all('parametrosexercicio');

            var fn = exercicio.configuracaoOrganograma &&
                    exercicio.configuracaoOrganograma['plain'];

            if (angular.isDefined(fn) && angular.isFunction(fn) && fn !== null) {
                exercicio.configuracaoOrganograma = fn();
            }

            function post() {
                return rest.post(exercicio).then(function(exercicio) {
                    $rootScope.$broadcast('exercicio:novo', _.cloneDeep(exercicio));
                });
            }

            function put() {
                return rest.customPUT(exercicio, exercicio.id).then(function(exercicio) {
                    $rootScope.$broadcast('exercicio:atualizado', _.cloneDeep(exercicio));
                });
            }

            return exercicio.id ? put() : post();
        }

        function remover(exercicio) {
            return Restangular.one('parametrosexercicio', exercicio.id).remove().then(function() {
                if (bfcApplicationContext.get('exercicio') === exercicio.exercicio) {
                    bfcState.go('exercicios');
                }

                $rootScope.$broadcast('exercicio:remover', _.cloneDeep(exercicio));
            });
        }

        function validar(ano) {
            var defer = $q.defer();
            var fromCache = _.find(exercicios, {exercicio: parseInt(ano, 10)});

            $q.when(fromCache || getExercicio(ano)).then(function(response) {
                if (_.isEmpty(response)) {
                    defer.reject();
                } else {
                    var exercicio = _.isArray(response) ? response[0] : response;
                    defer.resolve(exercicio); //TODO: Realmente é um parametro para um exercicio? Talvez outra api para exericio?
                }
            });
            return defer.promise;
        }

    }
})();
