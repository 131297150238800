(function () {
  'use strict';
  angular
    .module('core.relatorios')
    .controller('core.EmitirRelatorioModalController', emitirRelatorioModalController);

  emitirRelatorioModalController.$inject = [
    '$rootScope',
    '$scope',
    '$modalInstance',
    '$modalTracker',
    '$injector',
    '$q'
  ];

  function emitirRelatorioModalController($rootScope,
                                          $scope,
                                          $modalInstance,
                                          $modalTracker,
                                          $injector,
                                          $q) {
    var viewModel = this;

    var TrackerFactory = $injector.get('core.TrackerFactory'),
      ConfiguracaoRelatorioProvider = $injector.get('ConfiguracaoRelatorioProvider'),
      RelatorioService = $injector.get('core.RelatoriosService'),
      Notification = $injector.get('bfc.Notification');

    const paramsModal = $modalInstance.params;
    inicializaLoadings();
    inicializar();


    function inicializar() {
      inicializaMetodos();
      inicializaPropriedades();
      inicializaPromises();
    }

    function inicializaPromises() {
      var promise = $q.all([inicializaCadastro()]);
      viewModel.loadings.inicializaCadastro.addPromise(promise);
      $modalTracker.addPromise(promise);
    }


    function inicializaPropriedades() {
      viewModel.argumentos = emitirRelatorioModalController.$inject;
      viewModel.configRelatorio = {};
      viewModel.relatorio = {};
    }

    function inicializaMetodos() {
      viewModel.emitir = emitir;
      viewModel.desabilitaEmitir = desabilitaEmitir;
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init([
        'emitir',
        'inicializaCadastro'
      ]);
    }

    function inicializaCadastro() {
      var deferred = $q.defer();

      var relatoriosProvider = ConfiguracaoRelatorioProvider.get();
      viewModel.configRelatorio = _.find(relatoriosProvider.content, {'naturezaRelatorio': paramsModal.natureza});

      if (viewModel.configRelatorio) {
        deferred.resolve();
      } else {
        cancelar();
        deferred.reject();
        Notification.publish('Não foi localizada a configuração para relatório com a natureza \"' + paramsModal.natureza + '\"', 'error')
      }
      return deferred.promise;
    }

    function emitir() {
      var tracker = viewModel.loadings.emitir;
      var idRelatorio = viewModel.relatorio.id;
      tracker.addPromise(
        RelatorioService.executarRapido(idRelatorio, paramsModal.parametros, paramsModal.opcoesExecucao, paramsModal.isOcultarNotificacoes)
          .then(function (data) {
            $rootScope.$broadcast(paramsModal.natureza, {data: data});
            cancelar();
          })
      );
    }

    function cancelar(item) {
      $modalInstance.close(item);
    }

    function desabilitaEmitir() {
      return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
    }
  }
})();
