(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.loteamento:LoteamentoController
   * @description Controller principal da listagem de loteamentos
   * @requires $scope
   * @requires bfc.dialog.Dialog
   **/

  angular
    .module('core.loteamento')
    .controller('core.LoteamentoController', LoteamentoController);

  LoteamentoController.$inject = [
    '$rootScope',
    '$q',
    '$scope',
    'core.TrackerFactory',
    'core.LoteamentoService',
    'core.LoteamentoModalService'
  ];

  function LoteamentoController($rootScope,
                                $q,
                                $scope,
                                TrackerFactory,
                                LoteamentoService,
                                LoteamentoModalService
                                ) {

    var viewModel = this;
    viewModel.service = LoteamentoService;

    viewModel.cadastrar = cadastrar;
    viewModel.editar = editar;
    viewModel.excluir = excluir;

    inicializa();

    function inicializa() {
      inicializaPropriedades();
      inicializaLoadings();
      escutaEventos();
    }

    function escutaEventos() {
      $scope.$on('loteamento:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

    function inicializaPropriedades() {
      viewModel.loteamento = [];
      viewModel.list = {};
      viewModel.filter = {};
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init(['loteamentos', 'getList', 'exclusao']);
    }

    function cadastrar() {
      LoteamentoModalService.cadastrar();
    }

    function editar(item) {
      LoteamentoModalService.editar(item);
    }

    function excluir(item) {
      var promise = LoteamentoService.excluir(item.id);
      return promise.then(function () {
        LoteamentoService.mensagemExclusao();
      }, function error() {
        return promise;
      });
    }

    $scope.setLineHeight = function (h) {
      $scope.lineHeight = h;
    };
  }
})();
