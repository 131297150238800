(function () {
  'use strict';

  angular
    .module('core.relatorios')
    .factory('core.RelatoriosResources', relatoriosResources);

  relatoriosResources.$inject = ['Restangular', 'URL_API'];

  function relatoriosResources(Restangular, URL_API) {
    return Restangular.withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setBaseUrl(URL_API);
      RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
    });
  }
})();
