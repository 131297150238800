(function () {
	'use strict';

	/**
	 * @ngdoc controller
	 * @name contratos-core.municipio:MunicipioController
	 * @description Controller principal da listagem de municipios
	 * @requires $scope
	 * @requires bfc.dialog.Dialog
	 **/

	angular
			.module('core.municipio')
			.controller('core.MunicipioController', MunicipioController);

	MunicipioController.$inject = [
		'$rootScope',
		'$q',
		'$scope',
		'core.TrackerFactory',
		'core.MunicipioService',
		'core.MunicipioModalService'
	];

	function MunicipioController(
			$rootScope,
			$q,
			$scope,
			TrackerFactory,
			MunicipioService,
			MunicipioModalService
      ) {

		var viewModel = this;
		viewModel.service = MunicipioService;

		viewModel.search = search;
		viewModel.cadastrar = cadastrar;
		viewModel.editar = editar;
		viewModel.excluir = excluir;

		viewModel.showDescricao = showDescricao;
		viewModel.showEstado = showEstado;
		viewModel.showSiglaEstado = showSiglaEstado;
		viewModel.showCodigoIbge = showCodigoIbge;
		viewModel.showCodigoSiafi = showCodigoSiafi;


		inicializa();

		function inicializa() {
			inicializaPropriedades();
			inicializaLoadings();
      		escutaEventos();
		}

    function escutaEventos() {
      $scope.$on('municipio:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

		function inicializaPropriedades() {
			viewModel.municipio = [];
			viewModel.list = {};
			viewModel.filter = {};
			viewModel.columns = [
				{
					id: 'nome',
					model: 'nome',
					label: 'Descrição',
					filterable: true,
					alwaysVisible: false
				},
				{
					id: 'estado',
					model: 'estado',
					label: 'Estado',
					filterable: true,
					alwaysVisible: false
				},
				{
					id: 'unidadeFederativa',
					model: 'unidadeFederativa',
					label: 'Sigla',
					filterable: true,
					alwaysVisible: false
				},
				{
					id: 'codigoIBGE',
					model: 'codigoIBGE',
					label: 'Código do IBGE',
					filterable: true,
					alwaysVisible: false
				},
				{
					id: 'codigoSIAFI',
					model: 'codigoSIAFI',
					label: 'Código no SIAFI',
					filterable: true,
					alwaysVisible: false
				}
			];

			viewModel.listCustomBuilder = {
				onBeforeBuild: function () {
					return '';
				}
			};

			$scope.$on('filtroRegistroPadrao:refresh', function (eventos, params) {
				viewModel.listCustomBuilder.onBeforeBuild = function(){
					return params.criterio;
				};
			
				viewModel.listControls.search();
			});

			viewModel.listControls = {};

			viewModel.colDescricaoIsVisible = true;
			viewModel.colEstadoIsVisible = true;
			viewModel.colSiglaEstadoIsVisible = true;
			viewModel.colCodigoIbgeIsVisible = true;
			viewModel.colCodigoSiafiIsVisible = true;

		}

		$scope.$watch(
				'municipio.colDescricaoIsVisible',
				function changeValueDesc(newValue, oldValue) {
					if (viewModel.listControls.setColumnVisible !== undefined) {
						viewModel.listControls.setColumnVisible('nome', newValue);
						viewModel.colDescricaoIsVisible = newValue;
					}

				}
		);

		$scope.$watch(
				'municipio.colEstadoIsVisible',
				function changeValueDesc(newValue, oldValue) {
					if (viewModel.listControls.setColumnVisible !== undefined) {
						viewModel.listControls.setColumnVisible('estado', newValue);
						viewModel.colEstadoIsVisible = newValue;
					}
				}
		);

		$scope.$watch(
				'municipio.colSiglaEstadoIsVisible',
				function changeValueDesc(newValue, oldValue) {
					if (viewModel.listControls.setColumnVisible !== undefined) {
						viewModel.listControls.setColumnVisible('unidadeFederativa', newValue);
						viewModel.colSiglaEstadoIsVisible = newValue;
					}

				}
		);

		$scope.$watch(
				'municipio.colCodigoIbgeIsVisible',
				function changeValueDesc(newValue, oldValue) {
					if (viewModel.listControls.setColumnVisible !== undefined) {
						viewModel.listControls.setColumnVisible('codigoIbge', newValue);
						viewModel.colCodigoIbgeIsVisible = newValue;
					}
				}
		);

		$scope.$watch(
				'municipio.colCodigoSiafiIsVisible',
				function changeValueDesc(newValue, oldValue) {
					if (viewModel.listControls.setColumnVisible !== undefined) {
						viewModel.listControls.setColumnVisible('codigoSiafi', newValue);
						viewModel.colCodigoSiafiIsVisible = newValue;
					}
				}
		);

		function showDescricao() {
			return viewModel.colDescricaoIsVisible;
		}
		function showEstado() {
			return viewModel.colEstadoIsVisible;
		}
		function showSiglaEstado() {
			return viewModel.colSiglaEstadoIsVisible;
		}
		function showCodigoIbge() {
			return viewModel.colCodigoIbgeIsVisible;
		}
		function showCodigoSiafi() {
			return viewModel.colCodigoSiafiIsVisible;
		}

		function inicializaLoadings() {
			viewModel.loadings = TrackerFactory.init(['municipios', 'getList', 'exclusao']);
		}

		function search() {
			viewModel.listControls.search();
		}

		function cadastrar() {
			MunicipioModalService.cadastrar();
		}

		function editar(item) {
			MunicipioModalService.editar(item);
		}

		function excluir(item) {
		   var promise = MunicipioService.excluir(item.id);
		   return promise.then(function () {
         MunicipioService.mensagemExclusao();
		   },function error(){
			  return promise;
		   });
		}

		$scope.setLineHeight = function (h) {
			$scope.lineHeight = h;
		};
	}
})();
