(function() {
	'use strict';
	/**
	 * @ngdoc overview
	 * @name contratacao.fonte-ato
	 * @description
	 * #contratacao.tipo-ato
	 * Módulo de tipos de ato
	 **/
	angular
	  .module('core.tipo-ato', ['core', 'core.commons']);
})();
