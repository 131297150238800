(function () {
    'use strict';

    angular
        .module('core')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider',
        'URL_API'
    ];

    function configuration(
        stateProvider,
        URL_API
    ) {

        var contratosCore = {
            route: {
                abstract: true,
                url: '/core'
            }
        };

        var newState = (URL_API.split('/')[3] === 'estoque') ? 'contextoAlmoxarifado' : (URL_API.split('/')[3] === 'monitor-dfe') ? 'entidades' : 'exercicios';

        stateProvider
            .from(newState)
            .create('core', contratosCore.route);
    }
})();