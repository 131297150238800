(function () {
    'use strict';
    angular
        .module('core.fornecedor')
        .controller('core.FornecedorController', FornecedorController);

    FornecedorController.$inject = [
        '$rootScope',
        '$scope',
        '$q',
        'core.TrackerFactory',
        'core.FornecedorService',
        'core.FornecedorCadService',
        'core.ConsultaReceitaFederalModalService'
    ];

    function FornecedorController($rootScope,
                                  $scope,
                                  $q,
                                  TrackerFactory,
                                  FornecedorService,
                                  FornecedorCadService,
                                  ConsultaReceitaFederalModalService) {

        var viewModel = this;
        viewModel.service = FornecedorService;

        inicializa();

        function inicializa() {
            inicializaPropriedades();
            inicializaMetodos();
            inicializaLoadings();
            escutaEventos();
        }

        function escutaEventos() {
            $scope.$on('fornecedor:actionRemove', function (eventos, params) {
                angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
            });
        }

        function inicializaPropriedades() {
            viewModel.loadings = {};
            viewModel.argumentos = FornecedorController.$inject;
            viewModel.pesquisa = {texto: ''};
            viewModel.fornecedor = [];
            viewModel.searchFilter = {};
            viewModel.filter = {};
            viewModel.listPage = {};
            viewModel.itemsPerPage = 20;
            viewModel.currentPage = 1;
        }

        function inicializaMetodos() {
            viewModel.cadastrar = cadastrar;
            viewModel.editar = editar;
            viewModel.emUso = emUso;
            viewModel.excluir = excluir;
            viewModel.getTipoFornecedor = getTipoFornecedor;
            viewModel.status = status;
            viewModel.consultaReceitaFederal = consultaReceitaFederal;
            viewModel.consultaReceitaFederalFromGrid = consultaReceitaFederalFromGrid;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init(['listagem']);
            viewModel.loadings.fornecedors = [];
        }

        function cadastrar() {
            FornecedorService.setConsultaReceita('');
            FornecedorCadService.cadastrar();
        }

        function editar(fornecedor) {
            FornecedorCadService.editar(fornecedor);
        }

        function emUso(uso) {
            return uso;
        }

        function excluir(fornecedor) {
            var promise = FornecedorService.remove(fornecedor.id);
            return promise.then(function () {
                FornecedorService.mensagemExclusao();
            }, function error() {
                return promise;
            });
        }

        function status(item) {
            switch (item.ativo) {
                case true:
                    return {style: 'label label-success', text: 'Ativo'};
                case false:
                    return {style: 'label label-default', text: 'Inativo'};
            }
        }

        function getTipoFornecedor(item) {
            return item.pessoa.tipoPessoa === "FISICA" ? "Pessoa física" : "Pessoa jurídica";
        }

        viewModel.expressionCustomBuilders = {
            ativo: function (filters) {
                if (filters.ativo !== null && filters.ativo !== undefined) {
                    var busca;
                    if (filters.ativo) {
                        busca = "situacao = 'A'";
                    } else {
                        busca = "situacao = 'I'";
                    }
                    return busca;
                }
            },
            dataInicial: function (filters) {
                if (filters.dataInicial) {
                    return 'dtInclusao >= ' + filters.dataInicial;
                }
            },
            dataFinal: function (filters) {
                if (filters.dataFinal) {
                    return 'dtInclusao <= ' + filters.dataFinal;
                }
            },
            tipo: function (filters) {
                if (filters.tipo !== null && filters.tipo !== undefined) {
                    var busca;
                    if (filters.tipo === "FISICA") {
                        busca = "tipo = '" + tipoEnum.FISICA.value + "'";
                    } else {
                        busca = "tipo = '" + tipoEnum.JURIDICA.value + "'";
                    }
                    return busca;
                }

            },
            cnaes: function (filters) {
                if(filters.cnaes.length === 1) {
                    return 'cnae = "' + filters.cnaes[0].id+'"';
                } else if(filters.cnaes.length > 1) {
                    var filtro = [];
                    _.forEach(filters.cnaes, function (data) {
                        filtro.push('"'+data.id+'"');
                    });
                    return 'cnae in ('+filtro.join(',')+')';
                } else {
                    return '';
                }
            }
        };

        var tipoEnum = {
            FISICA: {
                "id": "FISICA",
                "key": "FISICA",
                "value": "F",
                "description": "Física"
            },
            JURIDICA: {
                "id": "JURIDICA",
                "key": "JURIDICA",
                "value": "J",
                "description": "Jurídica"
            }
        };
        tipoEnum.values = [
            tipoEnum.FISICA,
            tipoEnum.JURIDICA
        ];
        viewModel.tipoEnum = $q.when(tipoEnum);

        function consultaReceitaFederal() {
            ConsultaReceitaFederalModalService.cadastrar(viewModel.buscaCnpj, true);
        }

        function consultaReceitaFederalFromGrid(cnpj) {
            ConsultaReceitaFederalModalService.cadastrar(cnpj, true);
        }
    }
})();
