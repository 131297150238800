(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name ctr.configuracoes-gerais.controller:ConfiguracoesGeraisController
   *
   * @description
   * Controller Configurações Gerais
   *
   * @requires $rootScope
   * @requires $modalInstance
   * @requires bfcApplicationContext
   * @requires TrackerFactory
   * @requires Select2Factory
   * @requires ExercicioService
   * @requires ExercicioCadService
   * @requires ExercicioSelect
   * @requires ParametrosService
   **/
  angular
    .module('core.configuracoes-gerais')
    .controller('core.ConfiguracoesGeraisController', ConfiguracoesGeraisController);

  ConfiguracoesGeraisController.$inject = [
    '$rootScope',
    '$modalInstance',
    'bfcApplicationContext',
    'core.TrackerFactory',
    'core.Select2Factory',
    'core.ExercicioService',
    'core.ExercicioCadService',
    'core.ExercicioSelect',
    'core.ParametrosService',
    'bfc.Notification'
  ];

  function ConfiguracoesGeraisController($rootScope,
                                         $modalInstance,
                                         bfcApplicationContext,
                                         TrackerFactory,
                                         Select2Factory,
                                         ExercicioService,
                                         ExercicioCadService,
                                         ExercicioSelect,
                                         ParametrosService,
                                         Notification) {

    var configGeraisCtrl = this;
    configGeraisCtrl.argumentos = ConfiguracoesGeraisController.$inject;

    // Propridades
    configGeraisCtrl.exercicioEmUso = null;
    configGeraisCtrl.exercicioSelecionado = null;
    configGeraisCtrl.exercicios = [];
    configGeraisCtrl.loadings = {};
    configGeraisCtrl.opcoesNumeracaoLicitacoes = [];
    configGeraisCtrl.parametroExercicioCompra = null;
    configGeraisCtrl.select2 = [];
    configGeraisCtrl.ultimoExercicio = null;

    // Métodos
    configGeraisCtrl.carregarParametroExercicioCompra = carregarParametroExercicioCompra;
    configGeraisCtrl.desabilitaMudancaConfiguracao = desabilitaMudancaConfiguracao;
    configGeraisCtrl.excluirExercicio = excluirExercicio;
    configGeraisCtrl.igualExercicioEmUso = igualExercicioEmUso;
    configGeraisCtrl.igualUltimoExercicio = igualUltimoExercicio;
    configGeraisCtrl.mudarConfiguracao = mudarConfiguracao;
    configGeraisCtrl.novoExercicio = novoExercicio;
    configGeraisCtrl.salvar = salvar;

    inicializar();

    // Funções inicializadoras
    function inicializar() {
      inicializarLoadings();
      inicializarSelect2();
      inicializarPromises();
      inicializarEventos();
    }

    function inicializarEventos() {
      $rootScope.$on('exercicio:novo', inicializar);
      $rootScope.$on('exercicio:remover', inicializar);
      $rootScope.$on('exercicio:atualizado', function (event, exercicio) {
        setExercicioSelecionado(exercicio);
      });
    }

    function inicializarLoadings() {
      configGeraisCtrl.loadings = TrackerFactory.init(['principal', 'salvar']);
    }

    function inicializarPromises() {
      var promises = [
        carregarUltimoExercicio(),
        carregarExercicios(),
        carregarExercicioEmUso()
      ];
      configGeraisCtrl.loadings.principal.addPromises(promises);
    }

    function inicializarSelect2() {
      var select2 = Select2Factory.init([{
        element: 'exercicios',
        select2: ExercicioSelect
      }]);
      configGeraisCtrl.select2 = select2;
    }

    // Getters e Setters
    function getExercicioSelecionado() {
      return configGeraisCtrl.exercicioSelecionado;
    }

    function getExercicioEmUso() {
      return configGeraisCtrl.exercicioEmUso;
    }

    function getUltimoExercicio() {
      return configGeraisCtrl.ultimoExercicio;
    }

    function getParametroExercicio() {
      //configGeraisCtrl.parametroExercicioCompra.parametroExercicio = getExercicioSelecionado().plain();
      //return configGeraisCtrl.parametroExercicioCompra;
      return getExercicioSelecionado().plain();
    }

    function setOpcoesNumeracaoLicitacoes(o) {
      configGeraisCtrl.opcoesNumeracaoLicitacoes = o;
    }

    function setUltimoExercicio(u) {
      return configGeraisCtrl.ultimoExercicio = u;
    }

    function setExercicios(e) {
      configGeraisCtrl.exercicios = e;
    }

    function setExercicioEmUso(e) {
      configGeraisCtrl.exercicioEmUso = e;
    }

    function setExercicioSelecionado(e) {
      configGeraisCtrl.exercicioSelecionado = e;
      var promise = carregarParametroExercicioCompra();
      if (promise)
        configGeraisCtrl.loadings.principal.addPromise(promise);
    }

    function setParametroExercicioCompra(p) {
      if (p === undefined) {
        configGeraisCtrl.loadings.principal.addPromise(carregarParametroExercicioCompraDefault());
      } else {
        configGeraisCtrl.parametroExercicioCompra = p;
      }
    }


    function carregarUltimoExercicio() {
      return ExercicioService.getUltimoExercicio().then(setUltimoExercicio);
    }

    function carregarExercicios() {
      return ExercicioService.getExercicios().then(setExercicios);
    }

    function carregarExercicioEmUso() {
      return ExercicioService.getExercicioEmUso().then(function (e) {
        setExercicioEmUso(e);
        setExercicioSelecionado(e);
      });
    }

    function carregarParametroExercicioCompraDefault() {
      return ParametrosService.getDefault().then(setParametroExercicioCompra);
    }

    function carregarParametroExercicioCompra() {
      var e = getExercicioSelecionado();
      if (_.isUndefined(e) || _.isEmpty(e))
        return;
      return ParametrosService.getConfiguracaoOrganogramaPorAno(e.exercicio).then(setParametroExercicioCompra);
    }

    // Demais métodos
    function desabilitaMudancaConfiguracao() {
      return !igualUltimoExercicio();
    }

    function excluirExercicio() {
      var promise = ExercicioService.remover(getExercicioSelecionado());
      promise.then(function () {
        //if (ExercicioService.getExercicioContexto() === getExercicioSelecionado().exercicio) {
          fechar();
          Notification.publish('Exercício excluído com sucesso', 'success');
        //}
        //setExercicioSelecionado(null);
      }, function error() {
        return promise;
      });
      configGeraisCtrl.loadings.principal.addPromise(promise);
    }

    function fechar() {
      $modalInstance.close();
    }

    function igualExercicioEmUso() {
      return getExercicioSelecionado() &&
        getExercicioSelecionado().exercicio &&
        getExercicioEmUso() &&
        getExercicioEmUso().exercicio &&
        getExercicioSelecionado().exercicio === getExercicioEmUso().exercicio;
    }

    function igualUltimoExercicio() {
      return getExercicioSelecionado() &&
        getExercicioSelecionado().exercicio &&
        getUltimoExercicio() &&
        getUltimoExercicio().exercicio &&
        getExercicioSelecionado().exercicio === getUltimoExercicio().exercicio;
    }

    function mudarConfiguracao() {
      ExercicioCadService.editar(getExercicioSelecionado());
    }

    function novoExercicio() {
      ExercicioCadService.cadastrar();
    }

    function salvar() {
      var promise = ParametrosService.save(getParametroExercicio());
      promise.then(function(){
                Notification.publish('Exercício alterado com sucesso', 'success');
                fechar();
            });
      configGeraisCtrl.loadings.salvar.addPromise(promise);
    }

  }
})();
