(function () {
	'use strict';
	/**
	 * @ngdoc overview
	 * @name contratos-core.tipo-logradouro
	 * @description
	 * # contratos-core.tipo-logradouro
	 * Módulo de Tipos de logradouros
	 */
	angular
			.module('core.tipo-logradouro')
			.factory('core.TipoLogradouroService', TipoLogradouroService);

	TipoLogradouroService.$inject = [
		'$q',
		'Restangular',
		'$filter',
		'ui.components.EventEmitter'
	];

	function TipoLogradouroService(
			$q,
			Restangular,
			$filter,
			EventEmitter) {

		var meta;
		var uri = ['tiposlogradouros'];

		var orderBy = $filter('orderBy');

		var service = new EventEmitter();

		_.extend(service, TipoLogradouroService);

		service.get = get;
		service.getList = getList;
		service.getDefault = getDefault;
		service.getTypes = getTypes;

		return service;

		function get(id) {
			return Restangular.one(uri[0], id).get();
		}

		function getList(params) {
			params = params || {};

			params.offset = params.offset || 0;

			return Restangular.one(uri[0]).get({
				offset: params.offset,
				limit: params.limit,
				filter: params.filter,
				sort: params.sort
			}).then(function (data) {
				if (params.offset > 0 && data.total <= params.offset) {
					params.offset = 0;
					data = getList(params);
				}
				return data;
			});
		}

		function getDefault() {
			return getMetadata().then(function (data) {
				return angular.copy(data.representation.default);
			});
		}

		function getMetadata() {
			var deferred = $q.defer();
			if (!meta) {
				Restangular.all(uri[0]).options().then(function (data) {
					meta = data;
					deferred.resolve(meta);
				}, function (data) {
					deferred.reject(data);
				});
			} else {
				deferred.resolve(meta);
			}
			return deferred.promise;
		}

		function getTypes() {
			return getMetadata().then(function (data) {
				return angular.copy(data.types);
			});
		}
	}
})();