(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos.natureza-texto-juridico-ato.controller:NaturezaTextoJuridicoAtoController
   *
   * @description
   * Controller natrurezas de texto jurídico dos atos
   **/
  angular
    .module('core.natureza-texto-juridico-ato')
    .controller('core.NaturezaTextoJuridicoAtoController', NaturezaTextoJuridicoAtoController);

  NaturezaTextoJuridicoAtoController.$inject = [
    '$rootScope',
    '$scope',
    'core.TrackerFactory',
    'core.NaturezaTextoJuridicoAtoService',
    'core.NaturezaTextoJuridicoAtoModalService',
    'bfc.Notification'
  ];

  function NaturezaTextoJuridicoAtoController($rootScope,
                                              $scope,
                                              TrackerFactory,
                                              NaturezaTextoJuridicoAtoService,
                                              NaturezaTextoJuridicoAtoModalService,
                                              Notification) {

    var viewModel = this;

    viewModel.natTextoJuridicoAtoService = NaturezaTextoJuridicoAtoService;

    viewModel.adicionar = adicionar;
    viewModel.editar = editar;
    viewModel.remover = remover;

    inicializar();

    // Funções inicializadoras
    function inicializar() {
      viewModel.loadings = {};
      inicializaLoadings();
      escutaEventos();
    }

    function escutaEventos() {
      $scope.$on('naturezaTexto:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

    function inicializaLoadings() {
      viewModel.inicializaLoadings = TrackerFactory.init(['natTextoJuridicoAtoList']);
    }

    function adicionar() {
      NaturezaTextoJuridicoAtoModalService.cadastrar();
    }

    function editar(natTextoJuridicoAto) {
      NaturezaTextoJuridicoAtoModalService.editar(natTextoJuridicoAto);
    }

    function remover(natTextoJuridicoAto) {
      var promise = NaturezaTextoJuridicoAtoService.remove(natTextoJuridicoAto.id);
      return promise.then(function () {
        Notification.publish('Natureza de texto jurídico excluída com sucesso', 'success');
      }, function error() {
        return promise;
      });
    }
  }
})();
