(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.fornecedor.FornecedorConvidadoSelect
   * @description
   * Serviço para usar o select2 nos fornecedores
   * @requires bfc.Select2Config
   * @requires FornecedorService
   **/

  angular
    .module('core.fornecedor')
    .service('core.FornecedorConvidadoSelect', FornecedorConvidadoSelect);

  FornecedorConvidadoSelect.$inject = [
    '$filter',
    'bfc.Select2',
    'core.FornecedorService',
    'CONFIG_SELECT2'
  ];

  function FornecedorConvidadoSelect($filter,
                                     Select2,
                                     FornecedorService,
                                     CONFIG_SELECT2) {

    angular.extend(this, {
      select2Config: select2Config
    });

    function select2Config(options) {
      var methods = {
        onAdd: options.onAdd ? options.onAdd : onAdd,
        id: options.id ? options.id : id,
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('FORNECEDOR'),
        dropdownAutoWidth: false
      };

      function formatResult(fornecedor) {
        return fornecedor.text ? fornecedor.text : fornecedor.pessoa.nome + " - " + $filter('bfMaskCpfCnpj')(fornecedor.pessoa.cpfCnpj);
      }

      function formatSelection(fornecedor) {
        return fornecedor.text ? fornecedor.text : fornecedor.pessoa.nome + " - " + $filter('bfMaskCpfCnpj')(fornecedor.pessoa.cpfCnpj);
      }

      function formatValue(fornecedor) {
        return fornecedor['plain'] ? fornecedor.plain() : fornecedor;
      }

      function id(fornecedor) {
        return (fornecedor.pessoa && fornecedor.pessoa.nome) ? fornecedor.pessoa.nome : fornecedor.id;
      }

      function initSelection(element, callback) {
        var id = $(element).val();

        if (id) {
          FornecedorService.get(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter = params && params.term && params.term.replace(/['"]+/g, ''),
          result = FornecedorService.consulta(filter);

        result.then(function (fornecedores) {
          callback({
            results: fornecedores,
            more: fornecedores.hasNext
          });
        });
        return result;
      }

      function onAdd(value, target) {
        Select2.set(target, value);
        return false;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
