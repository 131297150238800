(function () {
    'use strict';

    angular
        .module('core.fornecedor')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var fornecedor = {
            route: {
                url: '/fornecedor',
                data: {
                    id: 'FornecedorPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'fornecedor.html',
                        controller: 'core.FornecedorController',
                        controllerAs: 'fornecedor'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-fornecedor', fornecedor.route);
    }
})();