(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratacao.tipo-garantia.service:TipoGarantiaModalService
     *
     * @description
     * Serviço para abrir o cadastro de tipos de garantia do contrato
     *
     * @requires ModalCadFactory
     **/
    angular
            .module('core.tipo-ato')
            .service('core.TipoAtoModalService', TipoAtoModalService);

    TipoAtoModalService.$inject = ['core.AcoesCadastrosModalService'];

    function TipoAtoModalService(AcoesCadastrosModalService) {

        var modal = {
            templateUrl: 'tipo-ato-cad/tipo-ato-cad.html',
            controller: 'core.TipoAtoCadController',
            controllerAs: 'tipoAtoCad',
            params: {
                identificador_cadastro: null
            },
            size: 'lg'
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar,
            onAdd: onAdd
        };
        
        angular.extend(this, service);
        
        function abrir(modal) {
            return AcoesCadastrosModalService.abrir(modal);
        }

        function cadastrar() {
            AcoesCadastrosModalService.cadastrar(modal);
        }

        function editar(tipoAto) {
            AcoesCadastrosModalService.editar(modal, tipoAto);
        }

        function onAdd(params) {
            return AcoesCadastrosModalService.onAdd(modal, params);
        }
    }
})();