(function () {
    'use strict';
    /**
     * @ngdoc service 
     * @name contratos-core.ato.service:AtoModalService
     * @dcontratacaocription Serviço para abrir o cadastro de atos
     * @requircontratacao ModalCadFactory
     **/
    angular
            .module('core.ato')
            .service('core.AtoModalService', AtoModalService);

    AtoModalService.$inject = ['ui.components.Modal', 'core.AcoesCadastrosModalService'];

    function AtoModalService(uiModal, AcoesCadastrosModalService) {

        var modal = {
            templateUrl: 'ato-cad/ato-cad.html',
            controller: 'core.AtoCadController',
            controllerAs: 'AtosCadCtrl',
            params: {
                identificador_cadastro: null
            },
            size: 'lg'
        };

        var service = {
            cadastrar: cadastrar,
            editar: editar,
            onAdd: onAdd
        };

        angular.extend(this, service);

        function abrir(modal) {
            return AcoesCadastrosModalService.abrir(modal);
        }

        function cadastrar() {
            AcoesCadastrosModalService.cadastrar(modal);
        }

        function editar(ato) {
            AcoesCadastrosModalService.editar(modal, ato);
        }

        function onAdd(params) {
            return AcoesCadastrosModalService.onAdd(modal, params);
        }

    }
})();