(function () {
    'use strict';

    angular.module('core.estado')
        .directive('coreEstadoSelect', estadoSelectDirective);

    estadoSelectDirective.$inject = ['CONFIG_SELECT2'];

    function estadoSelectDirective(CONFIG_SELECT2) {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" data-ng-disabled="ngDisabled" format-result="vm.formataResultado(data)"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                ngRequired: '=?',
                hiddenAddValue: '=?',
                criterionSearch: '=?',
                ngDisabled: '=?'
            },
            compile: function (element, attributes) {
                var addValue = attributes.hiddenAddValue === 'true';
                if (!addValue) {
                    element.children('ui-select').attr('add-value', 'vm.onAdd');
                }
                element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
                element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
                element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('ESTADO'));
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'bfc.$$PermissionsService',
        'core.EstadoService',
        'core.EstadoModalService'
    ];

    function Controller($scope, PermissionsService, service, modal) {
        var vm = this;
        vm.search = search;
        vm.formataResultado = formataResultado;
        vm.onAdd = !PermissionsService.isRevokedOperation('EstadoPageMapping', 'criar') && onAdd;
        vm.params = $scope.criterionSearch;

        function search(params) {
            var paramsDefault = '(nome like "' + params.term + '")';
            params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
            return service.getList(params).then(function (data) {
                return data;
            });
        }

        function onAdd(nome) {
            var param = {
                nome: nome
            };
            return modal.onAdd(param);
        }

        function formataResultado(value) {
            if (value) {
                return value.nome;
            }
        }
    }
})();
