(function () {
  'use strict';
  /**
   * @ngdoc controller
   * @name contratos-core.organograma.controller:OrganogramaController
   * @description
   * # OrganogramaController
   * Controller de organograma
   * @requires $scope
   * @requires $injector
   */
  angular
    .module('core.organograma')
    .controller('core.OrganogramaController', OrganogramaController);

  OrganogramaController.$inject = [
    '$scope',
    '$injector'
  ];

  function OrganogramaController($scope,
                                 $injector) {

    var viewModel = this;

    var TrackerFactory = $injector.get('core.TrackerFactory'),
      Notification = $injector.get('bfc.Notification'),
      OrganogramaService = $injector.get('core.OrganogramaService'),
      OrganogramaCadModalService = $injector.get('core.OrganogramaCadModalService'),
      promiseTracker = $injector.get('promiseTracker');

    inicializaLoadings();
    inicializa();

    function inicializa() {
      inicializaMetodos();
      inicializaPropriedadesPadroes();
      inicializaEscutaEventos();
    }

    function inicializaMetodos() {
      viewModel.cadastrar = cadastrar;
      viewModel.editar = editar;
      viewModel.excluir = excluir;
      viewModel.inicializaListagem = inicializaListagem;
      viewModel.format = format;
    }

    function inicializaPropriedadesPadroes() {
      viewModel.service = OrganogramaService;
      viewModel.argumentos = OrganogramaController.$inject;
      $scope.stopPropagation = false;
      viewModel.configuracaoOrganogramaSelected = {};
      viewModel.searchFilter = {};

      viewModel.columns = [
        {
          id: 'descricao',
          label: 'Descrição',
          model: 'descricao',
          filterable: true
        },
        {
          id: 'codigo',
          label: 'Código',
          model: 'numero',
          filterable: true
        }
      ];
    }

    function inicializaListagem() {
      viewModel.listaBusca.search();
    }

    function inicializaEscutaEventos() {
      $scope.$on('refreshConfiguracaoOrganograma', function (event, args) {
        actionChange(args.configuracaoOrganogramaSelected);
      });

      $scope.$on('refreshOrganograma', inicializaListagem);
      $scope.$on('organograma:actionRemove', function (eventos, params) {
        angular.element('#btnOrganogramaExcluir-' + params.id).triggerHandler('click');
      });
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init(['editSave', 'list', 'remove']);
    }

    function format(organograma) {
      var index = organograma.nivel;
      var nivel = viewModel.configuracaoOrganogramaSelected.niveis[index - 1];

      if (angular.isDefined(nivel)) {
        organograma.leftNumber = String(organograma.numeroFormatadoReduzido).substr(0, organograma.numeroFormatadoReduzido.length - nivel.digitos);
        organograma.rightNumber = String(organograma.numeroFormatadoReduzido).substr(organograma.numeroFormatadoReduzido.length - nivel.digitos);
      } else {
        organograma.leftNumber = organograma.numero;
      }
      return organograma;
    }

    function actionChange(configuracaoOrganogramaSelected) {
      if (configuracaoOrganogramaSelected) {
        viewModel.configuracaoOrganogramaSelected = configuracaoOrganogramaSelected;
        viewModel.service.setConfigId(configuracaoOrganogramaSelected.id);
        viewModel.listaBusca.search();
      }
    }

    function cadastrar() {
      OrganogramaCadModalService.cadastrar(viewModel.configuracaoOrganogramaSelected.id);
    }

    function editar(organograma) {
      OrganogramaService.setConfigId(organograma.configuracao.id);
      OrganogramaCadModalService.editar(organograma);
    }

    function excluir(organograma) {
      if (organograma.id) {
        var promise = OrganogramaService.remove(organograma.configuracao.id, organograma.id);
        return promise.then(function () {
          $scope.$broadcast('refreshOrganograma');
          Notification.publish('Organograma excluído com sucesso', 'success');
        }, function error() {
          return promise;
        });
      }
    }
  }
})();
