(function () {
    'use strict';

    angular
            .module('core.integracao')
            .service('core.ContratacaoServiceContratos', ContratacaoServiceContratos);

    ContratacaoServiceContratos.$inject = ['core.ContratosResources', '$q'];

    function ContratacaoServiceContratos(ContratosResources, $q) {

        var uri = ['contratacoes', 'aditivos'];

        var meta;

        this.save = save;
        this.get = get;
        this.getList = getList;
        this.getListAditivos = getListAditivos;

        function save(contratacao) {
            var rest = ContratosResources.all(uri[0]);
            return rest.post(contratacao);
        }

        function get(id) {
            return ContratosResources.one(uri[0], id).get();
        }

        function getList(params) {
            params = params || {};
            params.offset = params.offset || 0;
            return ContratosResources.one(uri[0])
                    .get(params);
        }

        function getListAditivos(codContrato, params) {
            params = params || {};
            params.offset = params.offset || 0;
            return ContratosResources.one(uri[0], codContrato).one(uri[1]).get(params);
        }

    }
})();
