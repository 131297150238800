(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name compras.processo-administrativo.service:GerarContratosCadService
     *
     * @description
     * Serviço que se comunica com o back-end de Gerar processso a partir da solicitacao
     *
     * @requires $q
     * @requires Restangular
     **/
    angular
        .module('core.fornecedor')
        .factory('core.ConsultaReceitaFederalService', consultaReceitaFederalService);

    consultaReceitaFederalService.$inject = [
        '$q',
        'core.ConsultaReceitaFederalResources',
        'ui.components.EventEmitter'
    ];

    function consultaReceitaFederalService($q,
                                           ConsultaReceitaFederalResources,
                                           EventEmitter) {
        var uri = {
            CONSULTA_CNPJ: 'opendata/empresas/receita-federal/v1/cnpj/'
        };

        var service = new EventEmitter();

        _.extend(service, consultaReceitaFederalService);

        service.getDadosReceita = getDadosReceita;

        var naturezaJuridica = {
            '1015': {codigo: '101-5', descricao: 'Órgão Público do Poder Executivo Federal'},
            '1023': {codigo: '102-3', descricao: 'Órgão Público do Poder Executivo Estadual ou do Distrito Federal'},
            '1031': {codigo: '103-1', descricao: 'Órgão Público do Poder Executivo Municipal'},
            '1040': {codigo: '104-0', descricao: 'Órgão Público do Poder Legislativo Federal'},
            '1058': {codigo: '105-8', descricao: 'Órgão Público do Poder Legislativo Estadual ou do Distrito Federal'},
            '1066': {codigo: '106-6', descricao: 'Órgão Público do Poder Legislativo Municipal'},
            '1074': {codigo: '107-4', descricao: 'Órgão Público do Poder Judiciário Federal'},
            '1082': {codigo: '108-2', descricao: 'Órgão Público do Poder Judiciário Estadual'},
            '1104': {codigo: '110-4', descricao: 'Autarquia Federal'},
            '1112': {codigo: '111-2', descricao: 'Autarquia Estadual ou do Distrito Federal'},
            '1120': {codigo: '112-0', descricao: 'Autarquia Municipal'},
            '1139': {codigo: '113-9', descricao: 'Fundação Pública de Direito Público Federal'},
            '1147': {codigo: '114-7', descricao: 'Fundação Pública de Direito Público Estadual ou do Distrito Federal'},
            '1155': {codigo: '115-5', descricao: 'Fundação Pública de Direito Público Municipal'},
            '1163': {codigo: '116-3', descricao: 'Órgão Público Autônomo Federal'},
            '1171': {codigo: '117-1', descricao: 'Órgão Público Autônomo Estadual ou do Distrito Federal'},
            '1180': {codigo: '118-0', descricao: 'Órgão Público Autônomo Municipal'},
            '1198': {codigo: '119-8', descricao: 'Comissão Polinacional'},
            '1210': {codigo: '121-0', descricao: 'Consórcio Público de Direito Público (Associação Pública)'},
            '1228': {codigo: '122-8', descricao: 'Consórcio Público de Direito Privado'},
            '1236': {codigo: '123-6', descricao: 'Estado ou Distrito Federal'},
            '1244': {codigo: '124-4', descricao: 'Município'},
            '1252': {codigo: '125-2', descricao: 'Fundação Pública de Direito Privado Federal'},
            '1260': {codigo: '126-0', descricao: 'Fundação Pública de Direito Privado Estadual ou do Distrito Federal'},
            '1279': {codigo: '127-9', descricao: 'Fundação Pública de Direito Privado Municipal'},
            '1287': {codigo: '128-7', descricao: 'Fundo Público da Administração Indireta Federal'},
            '1295': {
                codigo: '129-5',
                descricao: 'Fundo Público da Administração Indireta Estadual ou do Distrito Federal'
            },
            '1309': {codigo: '130-9', descricao: 'Fundo Público da Administração Indireta Municipal'},
            '1317': {codigo: '131-7', descricao: 'Fundo Público da Administração Direta Federal'},
            '1325': {
                codigo: '132-5',
                descricao: 'Fundo Público da Administração Direta Estadual ou do Distrito Federal'
            },
            '1333': {codigo: '133-3', descricao: 'Fundo Público da Administração Direta Municipal'},
            '1341': {codigo: '134-1', descricao: 'União'},
            '2011': {codigo: '201-1', descricao: 'Empresa Pública'},
            '2038': {codigo: '203-8', descricao: 'Sociedade de Economia Mista'},
            '2046': {codigo: '204-6', descricao: 'Sociedade Anônima Aberta'},
            '2054': {codigo: '205-4', descricao: 'Sociedade Anônima Fechada'},
            '2062': {codigo: '206-2', descricao: 'Sociedade Empresária Limitada'},
            '2070': {codigo: '207-0', descricao: 'Sociedade Empresária em Nome Coletivo'},
            '2089': {codigo: '208-9', descricao: 'Sociedade Empresária em Comandita Simples'},
            '2097': {codigo: '209-7', descricao: 'Sociedade Empresária em Comandita por Ações'},
            '2127': {codigo: '212-7', descricao: 'Sociedade em Conta de Participação'},
            '2135': {codigo: '213-5', descricao: 'Empresário (Individual)'},
            '2143': {codigo: '214-3', descricao: 'Cooperativa'},
            '2151': {codigo: '215-1', descricao: 'Consórcio de Sociedades'},
            '2160': {codigo: '216-0', descricao: 'Grupo de Sociedades'},
            '2178': {codigo: '217-8', descricao: 'Estabelecimento, no Brasil, de Sociedade Estrangeira'},
            '2194': {
                codigo: '219-4',
                descricao: 'Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira'
            },
            '2216': {codigo: '221-6', descricao: 'Empresa Domiciliada no Exterior'},
            '2224': {codigo: '222-4', descricao: 'Clube/Fundo de Investimento'},
            '2232': {codigo: '223-2', descricao: 'Sociedade Simples Pura'},
            '2240': {codigo: '224-0', descricao: 'Sociedade Simples Limitada'},
            '2259': {codigo: '225-9', descricao: 'Sociedade Simples em Nome Coletivo'},
            '2267': {codigo: '226-7', descricao: 'Sociedade Simples em Comandita Simples'},
            '2275': {codigo: '227-5', descricao: 'Empresa Binacional'},
            '2283': {codigo: '228-3', descricao: 'Consórcio de Empregadores'},
            '2291': {codigo: '229-1', descricao: 'Consórcio Simples'},
            '2305': {
                codigo: '230-5',
                descricao: 'Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)'
            },
            '2313': {
                codigo: '231-3',
                descricao: 'Empresa Individual de Responsabilidade Limitada (de Natureza Simples)'
            },
            '2321': {codigo: '232-1', descricao: 'Sociedade Unipessoal de Advogados'},
            '2330': {codigo: '233-0', descricao: 'Cooperativas de Consumo'},
            '3034': {codigo: '303-4', descricao: 'Serviço Notarial e Registral (Cartório)'},
            '3069': {codigo: '306-9', descricao: 'Fundação Privada'},
            '3077': {codigo: '307-7', descricao: 'Serviço Social Autônomo'},
            '3085': {codigo: '308-5', descricao: 'Condomínio Edilício'},
            '3107': {codigo: '310-7', descricao: 'Comissão de Conciliação Prévia'},
            '3115': {codigo: '311-5', descricao: 'Entidade de Mediação e Arbitragem'},
            '3131': {codigo: '313-1', descricao: 'Entidade Sindical'},
            '3204': {codigo: '320-4', descricao: 'Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras'},
            '3212': {codigo: '321-2', descricao: 'Fundação ou Associação Domiciliada no Exterior'},
            '3220': {codigo: '322-0', descricao: 'Organização Religiosa '},
            '3239': {codigo: '323-9', descricao: 'Comunidade Indígena '},
            '3247': {codigo: '324-7', descricao: 'Fundo Privado '},
            '3255': {codigo: '325-5', descricao: 'Órgão de Direção Nacional de Partido Político'},
            '3263': {codigo: '326-3', descricao: 'Órgão de Direção Regional de Partido Político'},
            '3271': {codigo: '327-1', descricao: 'Órgão de Direção Local de Partido Político'},
            '3280': {codigo: '328-0', descricao: 'Comitê Financeiro de Partido Político'},
            '3298': {codigo: '329-8', descricao: 'Frente Plebiscitária ou Referendária'},
            '3301': {codigo: '330-1', descricao: 'Organização Social (OS)'},
            '3310': {codigo: '331-0', descricao: 'Demais Condomínios'},
            '3999': {codigo: '399-9', descricao: 'Associação Privada'},
            '4014': {codigo: '401-4', descricao: 'Empresa Individual Imobiliária'},
            '4022': {codigo: '402-2', descricao: 'Segurado Especial'},
            '4081': {codigo: '408-1', descricao: 'Contribuinte individual'},
            '4090': {codigo: '409-0', descricao: 'Candidato a Cargo Político Eletivo'},
            '4111': {codigo: '411-1', descricao: 'Leiloeiro '},
            '4120': {codigo: '412-0', descricao: 'Produtor Rural (Pessoa Física)'},
            '5010': {codigo: '501-0', descricao: 'Organização Internacional'},
            '5029': {codigo: '502-9', descricao: 'Representação Diplomática Estrangeira'},
            '5037': {codigo: '503-7', descricao: 'Outras Instituições Extraterritoriais'}
        };

        var qualificacaoSocio = {
            5: "Administrador",
            8: "Conselheiro de Administração",
            10: "Diretor",
            16: "Presidente",
            20: "Sociedade Consorciada",
            21: "Sociedade Filiada",
            22: "Sócio",
            24: "Sócio Comanditado",
            25: "Sócio Comanditário",
            29: "Sócio ou Acionista Incapaz ou Relativamente Incapaz (exceto menor)",
            30: "Sócio ou Acionista Menor (assistido/representado)",
            31: "Sócio Ostensivo",
            37: "Sócio Pessoa Jurídica Domiciliado no Exterior",
            38: "Sócio Pessoa Física Residente ou Domiciliado no Exterior",
            49: "Sócio-Administrador",
            52: "Sócio com Capital",
            53: "Sócio sem Capital",
            54: "Fundador",
            55: "Sócio Comanditado Residente no Exterior",
            56: "Sócio Comanditário Pessoa Física Residente no Exterior",
            57: "Sócio Comanditário Pessoa Jurídica Domiciliado no Exterior",
            58: "Sócio Comanditário Incapaz ou Cotas",
            59: "Produtor Rural",
            63: "Cotas em Tesouraria",
            65: "Titular Pessoa Física Residente ou Domiciliado no Brasil",
            66: "Titular Pessoa Física Residente ou Domiciliado no Exterior",
            67: "Titular Pessoa Física Incapaz ou Relativamente Incapaz (exceto menor)",
            68: "Titular Pessoa Física Menor (Assistido/Representado)"
        };

        var dadosFornecedor = {
            "cnpj": "00000006000169",
            "matrizFilial": "MATRIZ",
            "razaoSocial": "ACADEMIA DE BALLET PAULA FIRETTI LTDA",
            "nomeFantasia": "",
            "situacaoCadastral": "ATIVA",
            "dataSituacaoCadastral": "2005-11-03",
            "motivoSituacaoCadastral": 0,
            "cidadeExterior": "",
            "codigoPais": "",
            "nomePais": "",
            "codigoNaturezaJuridica": naturezaJuridica[2062].codigo + ' - ' + naturezaJuridica[2062].descricao ,
            "dataInicioAtividade": "1994-06-07",
            "cnaeFiscal": 9329801,
            "tipoLogradouro": "AVENIDA",
            "logradouro": "BENEDITO ANDRADE",
            "numero": "532",
            "complemento": "",
            "bairro": "VILA PEREIRA BARRETO",
            "cep": '29360000',
            "uf": "SP",
            "codigoMunicipio": 7107,
            "municipio": "SAO PAULO",
            "telefone1": "1139932725",
            "telefone2": "1196049895",
            "fax": "",
            "email": "PAULAFIRETTI@HOTMAIL.COM",
            "qualificacaoResponsavel": 49,
            "capitalSocial": 0,
            "porteEmpresa": "03",
            "opcaoSimples": "OPTANTE_PELO_SIMPLES",
            "dataOpcaoSimples": "2018-01-01",
            "dataExclusaoSimples": "0000-00-00",
            "opcaoMei": "NAO",
            "situacaoEspecial": "",
            "dataSituacaoEspecial": null,
            "socios": [
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "MARCIO HAMILTON FERREIRA",
                    "cnpjCpf": "000***923641**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2010-11-17"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "NILSON MARTINIANO MOREIRA",
                    "cnpjCpf": "000***491386**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2010-11-17"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "WALTER MALIENI JUNIOR",
                    "cnpjCpf": "000***718468**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2010-11-17"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "ADRIANO MEIRA RICCI",
                    "cnpjCpf": "000***550741**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2012-03-27"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "CARLOS ALBERTO ARAUJO NETTO",
                    "cnpjCpf": "000***415907**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2012-03-27"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "ANTONIO MAURICIO MAURANO",
                    "cnpjCpf": "000***022878**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2012-03-27"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "MARCELO AUGUSTO DUTRA LABUTO",
                    "cnpjCpf": "000***238081**",
                    "qualificacao": "Presidente",
                    "percentualCapital": 0,
                    "dataEntrada": "2012-03-27"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "LEONARDO SILVA DE LOYOLA REIS",
                    "cnpjCpf": "000***761707**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2015-05-28"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "ROGERIO MAGNO PANCA",
                    "cnpjCpf": "000***035618**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2015-05-28"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "SIMAO LUIZ KOVALSKI",
                    "cnpjCpf": "000***714970**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2015-05-28"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "TARCISIO HUBNER",
                    "cnpjCpf": "000***600309**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2015-05-28"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "EDUARDO CESAR PASA",
                    "cnpjCpf": "000***035920**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2015-06-30"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "MARCIO LUIZ MORAL",
                    "cnpjCpf": "000***859038**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2015-09-01"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "JOSE EDUARDO MOREIRA BERGO",
                    "cnpjCpf": "000***045721**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "ALEXANDRE ALVES DE SOUZA",
                    "cnpjCpf": "000***093057**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "CICERO PRZENDSIUK",
                    "cnpjCpf": "000***435159**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "EDSON ROGERIO DA COSTA",
                    "cnpjCpf": "000***309260**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "FABIANO MACANHAN FONTES",
                    "cnpjCpf": "000***816019**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "FERNANDO FLORENCIO CAMPOS",
                    "cnpjCpf": "000***755588**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "GUSTAVO DE SOUZA FOSSE",
                    "cnpjCpf": "000***160007**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "MARCO TULIO DE OLIVEIRA MENDONCA",
                    "cnpjCpf": "000***403336**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "MARVIO MELO FREITAS",
                    "cnpjCpf": "000***983941**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "REINALDO KAZUFUMI YOKOYAMA",
                    "cnpjCpf": "00***390059**0",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "JOSE CAETANO DE ANDRADE MINCHILLO",
                    "cnpjCpf": "000***907166**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-06-21"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "CARLOS RENATO BONETTI",
                    "cnpjCpf": "000***198248**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-04-29"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "MARCO ANTONIO ASCOLI MASTROENI",
                    "cnpjCpf": "000***198128**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-12-06"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "JOSE EDUARDO PEREIRA FILHO",
                    "cnpjCpf": "000***916073**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2017-02-17"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "MARCO TULIO MORAES DA COSTA",
                    "cnpjCpf": "000***677786**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2017-01-05"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "JOAO PINTO RABELO JUNIOR",
                    "cnpjCpf": "000***347521**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2016-01-19"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "CARLOS HAMILTON VASCONCELOS ARAUJO",
                    "cnpjCpf": "000***794793**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2017-01-05"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "LUCINEIA POSSAR",
                    "cnpjCpf": "000***309199**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2017-11-28"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "JOSE RICARDO FAGONDE FORNI",
                    "cnpjCpf": "000***261501**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2018-04-10"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "MARCOS RENATO COLTRI",
                    "cnpjCpf": "000***653918**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2018-04-10"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "CARLA NESI",
                    "cnpjCpf": "000***295868**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2018-04-12"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "BERNARDO DE AZEVEDO SILVA ROTHE",
                    "cnpjCpf": "000***890627**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2018-04-18"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "ANTONIO GUSTAVO MATOS DO VALE",
                    "cnpjCpf": "000***370266**",
                    "qualificacao": qualificacaoSocio[10],
                    "percentualCapital": 0,
                    "dataEntrada": "2017-06-14"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "ROSANGELA RESENDE ROQUE",
                    "cnpjCpf": "000***296048**",
                    "qualificacao": "Presidente",
                    "percentualCapital": 0,
                    "dataEntrada": "2005-09-12"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "CLEUSA ALVES DE SOUZA",
                    "cnpjCpf": "000***465858**",
                    "qualificacao": "Presidente",
                    "percentualCapital": 0,
                    "dataEntrada": "2005-09-12"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "SEBASTIAO EDSON DE ARAUJO",
                    "cnpjCpf": "000***410393**",
                    "qualificacao": "Presidente",
                    "percentualCapital": 0,
                    "dataEntrada": "2005-09-12"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "SIDNEI LUIZ FIRETTI",
                    "cnpjCpf": "000***893998**",
                    "qualificacao": "Sócio_Administrador",
                    "percentualCapital": 0,
                    "dataEntrada": "2000-09-28"
                },
                {
                    "identificador": "PESSOA_FISICA",
                    "nome": "PAULA CRISTINA FIRETTI",
                    "cnpjCpf": "000***104458**",
                    "qualificacao": "Sócio_Administrador",
                    "percentualCapital": 0,
                    "dataEntrada": "1994-06-07"
                }
            ]
        };

        return service;

        function getDadosReceita(cnpj) {
            return ConsultaReceitaFederalResources.one(uri.CONSULTA_CNPJ + cnpj).get();
        }

        function getDadosFake() {
            var deferred = $q.defer();
            deferred.resolve(dadosFornecedor);
            return deferred.promise;
        }
    }
})();
