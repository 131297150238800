(function() {
    'use strict';

    angular
            .module('core.configuracao-organograma')
            .filter('coreConfiguracaoOrganogramaFilter', ConfiguracaoOrganogramaFilter);

    function ConfiguracaoOrganogramaFilter() {
        return function(digitos) {
            var string = '';
            for (var i = 0; i < digitos; i++) {
                string += '0';
            }
            return string;
        };
    }
})();
