(function () {
	'use strict';

	/**
	 * @ngdoc service
	 * @name compras.processo-administrativo.service:ProcessoAdministrativoService
	 *
	 * @description
	 * Serviço de comunicação com o Back-end do Processo Administrativo
	 *
	 * @requires $q
	 * @requires Restangular
	 * @requires UtilsService
	 **/
	angular
		.module('core.commons')
		.factory('core.ProcessoAdministrativoUtilService', ProcessoAdministrativoUtilService);

	ProcessoAdministrativoUtilService.$inject = [
		'$q',
		'ui.components.EventEmitter'
	];

	function ProcessoAdministrativoUtilService($q,
											   EventEmitter) {

		var service = new EventEmitter();


		_.extend(service, ProcessoAdministrativoUtilService);

		service.formaEspecialPercentual = formaEspecialPercentual;
		service.formaEspecialValorUnitario = formaEspecialValorUnitario;
		service.formaEspecialCredenciamento = formaEspecialCredenciamento;
		service.getListFormaEspecial = getListFormaEspecial;
		service.formasEspeciaisDiminuemBaseCalculoSolicitacaoFornecimento = formasEspeciaisDiminuemBaseCalculoSolicitacaoFornecimento;
		service.formaEspecialMaiorLance = formaEspecialMaiorLance;
		service.formaEspecialLotesSemItens = formaEspecialLotesSemItens;
		service.rateioValoresLoteGlobal = rateioValoresLoteGlobal;

		return service;

		function getListFormaEspecial() {
			return [
				'NENHUMA',
				'MAIOR_DESCONTO_ITEM',
				'MAIOR_DESCONTO_TABELA',
				'MENOR_ADICIONAL_TABELA',
				'MENOR_TAXA_ADMINISTRATIVA',
				'MAIOR_LANCE_PREGAO',
				'LOTES_SEM_ITEM',
				'CRED_QTD_FIXA',
				'CRED_QTD_RATEADA',
				'CRED_QTD_LIVRE'
			];
		}

		function formaEspecialPercentual(dataFormaEspecial) {
			var habilita = [1, 2, 3, 4];
			var posicao = getListFormaEspecial().indexOf(dataFormaEspecial);
			return habilita.indexOf(posicao) >= 0;
		}

		function formaEspecialValorUnitario(dataFormaEspecial) {
			var habilita = [0, 5, 6, 7, 8, 9];
			var posicao = getListFormaEspecial().indexOf(dataFormaEspecial);
			return habilita.indexOf(posicao) >= 0;
		}

		function formaEspecialCredenciamento(dataFormaEspecial) {
			var habilita = [7, 8, 9];
			var posicao = getListFormaEspecial().indexOf(dataFormaEspecial);
			return habilita.indexOf(posicao) >= 0;
		}

		function formasEspeciaisDiminuemBaseCalculoSolicitacaoFornecimento(dataFormaEspecial) {
			var habilita = [1, 2];
			var posicao = getListFormaEspecial().indexOf(dataFormaEspecial);
			return habilita.indexOf(posicao) >= 0;
		}

		function formaEspecialMaiorLance(dataFormaEspecial) {
			var habilita = [5];
			var posicao = getListFormaEspecial().indexOf(dataFormaEspecial);
			return habilita.indexOf(posicao) >= 0;
		}

		function formaEspecialLotesSemItens(dataFormaEspecial) {
			var habilita = [6];
			var posicao = getListFormaEspecial().indexOf(dataFormaEspecial);
			return habilita.indexOf(posicao) >= 0;
		}

		function rateioValoresLoteGlobal(dataFormaEspecial){
			var habilita = [1, 2, 3, 4, 5, 6, 7, 8, 9];
			var posicao = getListFormaEspecial().indexOf(dataFormaEspecial);
			return habilita.indexOf( posicao) >= 0;
		}
	}
})();