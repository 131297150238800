(function () {
    'use strict';

    angular
        .module('core.configuracao-entidade')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var configurandoEntidade = {
            route: {
                url: '/configuracao-entidade',
                data: {
                    id: 'EntidadePageMapping',
                    mirrorOf: 'configuracao-entidade'
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-configuracao-entidade', configurandoEntidade.route);
    }
})();