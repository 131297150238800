(function () {
    'use strict';

    angular
        .module('core.unidade-medida')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var unidade = {
            route: {
                url: '/unidade-medida',
                data: {
                    id: 'UnidadeMedidaPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'unidade-medida.html',
                        controller: 'core.UnidadeMedidaController',
                        controllerAs: 'unidadeMedida'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-unidade-medida', unidade.route);
    }
})();