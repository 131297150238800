(function () {
    'use strict';

    angular
            .module('core.organograma')
            .directive('coreOrganogramaEstoqueSelect', organogramaEstoqueSelect);

    organogramaEstoqueSelect.$inject = ['CONFIG_SELECT2'];

    function organogramaEstoqueSelect(CONFIG_SELECT2) {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" ng-change="ngChange()" bf-required="bfRequired" ng-disabled="ngDisabled" search="vm.search"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                ngChange: '&?',
                bfRequired: '=?',
                ngDisabled: '=?',
                hiddenAddValue: '=?',
                criterionSearch: '=?',
                materialEspecificacao: '=?'
            },
            compile: function (element, attributes) {
                var addValue = attributes.hiddenAddValue === 'true';
                if (!addValue) {
                    element.children('ui-select').attr('add-value', 'vm.onAdd');
                }
                if (attributes.multiple === 'true') {
                    element.children('ui-select').attr('multiple', 'true');
                }
                element.children('ui-select').attr('format-result', 'vm.formatResult(data)');
                element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
                element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
                element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('ORGANOGRAMA'));
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'bfc.$$PermissionsService',
        'core.OrganogramaService',
        'core.ParametrosService',
        'core.OrganogramaCadModalService',
        'ParametrosEstoqueService',
        'bfcApplicationContext'
    ];

    function Controller($scope, PermissionsService, service, ParametrosService, modal, ParametrosEstoqueService, bfcApplicationContext) {
        var viewModel = this;
        var ano = bfcApplicationContext.get('exercicio');
        var paramsEstoque = {exercicio: ano};

        viewModel.search = search;
        viewModel.onAdd = !PermissionsService.isRevokedOperation('OrganogramaPageMapping', 'criar') && onAdd;
        viewModel.params = $scope.criterionSearch;
        viewModel.formatResult = formatResult;
        viewModel.materialEspecificacao = $scope.materialEspecificacao;

        ParametrosEstoqueService.getParametroEstoque(paramsEstoque).then(function (data) {
            viewModel.parametrosEstoque = data;
            viewModel.configuracaoOrganograma = data.parametroExercicio.configuracaoOrganograma;
        });

        function search(params) {
            var paramsDefault = '(numero like "' + params.term + '" or descricao like "' + params.term + '")';
            params.filter = $scope.criterionSearch ? viewModel.params({termo: params.term}) : paramsDefault;
            params.configuracaoOrganogramaId = viewModel.parametrosEstoque.parametroExercicio.configuracaoOrganograma.id;
            params.parametroEstoqueId = viewModel.parametrosEstoque.id;
            params.controleSaldo = true;
            params.materialEspecificacao = viewModel.materialEspecificacao;

            return service.getListSaldoEstoque(params).then(function (data) {
                return data;
            });
        }

        function onAdd(descricao) {
            var params = {
                configuracaoOrganogramaId: viewModel.configuracaoOrganograma.id,
                descricao: descricao
            };
            return modal.onAdd(params);
        }

        function formatResult(data) {
            return data.numeroFormatado ? data.numeroFormatado + ' - ' + data.descricao : data.descricao;
        }
    }
})();