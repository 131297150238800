(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.utils.Select2Factory
     * @description
     * Fábrica de objetos select2
     * @example
     * #JS
     <pre>
       angular
          .module('ctr.service')
          .service('ServiceSelect', ServiceSelect);

         ServiceSelect.$inject = [
            'bfc.Select2Config',
            'ServiceSelect'
         ];

        function ServiceSelect(
            Select2Config,
            ServiceSelect
        ) {

            this.select2Config = select2Config;

            function select2Config(options) {
                var methods = {
                    formatResult: formatResult,
                    formatSelection: formatSelection,
                    formatValue: formatValue,
                    initSelection: initSelection,
                    result: result
                };

            function formatResult(item) {
                return getDescription(item);
            }

            function formatSelection(item) {
                return getDescription(item);
            }

            function formatValue(item) {
                return item;
            }

            function initSelection(element, callback) {
                var id = $(element).val();

                if (id) {
                    ServiceSelect.get(id).then(callback);
                }
            }

            function result(params, callback) {
                var filter = 'descricao like "%' + params.term + '%"';

                var result = ServiceSelect.getList(filter);
                result.then(function(itens) {
                    callback({
                        results: itens,
                        more: false
                    });
                });

                return result;
            }

            function getDescription(item) {

                if (item.text) {
                    return item.text;
                }

                if (item) {
                    return item.id + ' - ' + item.descricao;
                }
            }

            return Select2Config.create(angular.extend(methods, options));
        }
    }
    </pre>

    <pre>
       function Controller(ServiceSelect, Select2Factory)  {
          var viewModel = this;
          viewModel.save = save;

          init();

          function init() {
              initSelect2();
          }

          function initSelect2() {
                viewModel.select2 = Select2Factory.init([{
                    element: 'service',
                    select2: ServiceSelect
                }]);
           }
        }
    </pre>
      #HTML
       <pre>
           <input type="hidden" data-ng-model='controller.select2.service' />
       </pre>
    **/
    angular
        .module('core.commons')
        .factory('core.Select2Factory', Select2Factory);

    function Select2Factory() {

        return {
            init: init
        };

        function init(array) {
            var object = {};

            for (var i = 0, length = array.length; i < length; i++) {
                var item = array[i];

                var defaults = {
                  options: {
                    dropdownAutoWidth: false, //para nao quebrar o campo input
                    allowClear: true
                  },
                  method: 'select2Config'
                };

                var element = item.element;
                var select2 = item.select2;
                var options = item.options ? item.options : defaults.options;
                var method = item.method ? item.method : defaults.method;

                object[element] = select2[method](options);
            }
            return object;
        }
    }
})();
