(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name compras.entidades.service:EntidadesService
     *
     * @description
     * Serviço de comunicação com o Back-end de entidades
     *
     * @requires $q
     * @requires Restangular
     **/
    angular
        .module('core.organograma')
        .factory('EntidadesService', entidadesService);

    entidadesService.$inject = ['$q', 'Restangular'];

    function entidadesService($q, Restangular) {
        var uri = ['entidades', 'entidadesgestora'];
        var meta;
        var service = {
            get: get,
            getList: getList,
            getNome: getNome,
            getTotalEntidade: getTotalEntidade,
            getEntidadeGestora: getEntidadeGestora
        };

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getList(filter) {
            return Restangular.one(uri[0]).get({ filter: filter });
        }

        function getEntidadeGestora(id) {
            return Restangular.one(uri[0]).one(uri[1], id).get();
        }

        function getNome(id) {
            var deferred = $q.defer();
            if (!meta) {
                get(id).then(function (data) {
                    meta = data.nome;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getTotalEntidade() {
            var deferred = $q.defer();
            getList().then(function (data) {
                deferred.resolve(data);
            }, function (data) {
                deferred.reject(data);
            });
            return deferred.promise;
        }
    }
})();