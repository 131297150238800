(function () {
	'use strict';

	/**
	 * @ngdoc controller
	 * @name contratos-core.distrito:DistritoController
	 * @description Controller principal da listagem de distritos
	 * @requires $scope
	 * @requires bfc.dialog.Dialog
	 **/

	angular
			.module('core.distrito')
			.controller('core.DistritoController', DistritoController);

	DistritoController.$inject = [
		'$rootScope',
		'$q',
		'$scope',
		'core.TrackerFactory',
		'core.DistritoService',
		'core.DistritoModalService'
	];

	function DistritoController(
			$rootScope,
			$q,
			$scope,
			TrackerFactory,
			DistritoService,
			DistritoModalService
      ) {

		var viewModel = this;
		viewModel.service = DistritoService;

		viewModel.search = search;
		viewModel.cadastrar = cadastrar;
		viewModel.editar = editar;
		viewModel.excluir = excluir;

		viewModel.showDescricao = showDescricao;
		viewModel.showMunicipio = showMunicipio;

		inicializa();

		function inicializa() {
			inicializaPropriedades();
			inicializaLoadings();
      escutaEventos();
		}

    function escutaEventos() {
      $scope.$on('distrito:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

		function inicializaPropriedades() {
			viewModel.distrito = [];
			viewModel.list = {};
			viewModel.filter = {};
			viewModel.columns = [
				{
					id: 'nome',
					model: 'nome',
					label: 'Descrição',
					filterable: true,
					alwaysVisible: false
				},
				{
					id: 'municipio',
					model: 'municipio',
					label: 'Município',
					filterable: true,
					alwaysVisible: false
				}
			];

			viewModel.listCustomBuilder = {
				onBeforeBuild: function () {
					return '';
				}
			};

			$scope.$on('filtroRegistroPadrao:refresh', function (eventos, params) {
				viewModel.listCustomBuilder.onBeforeBuild = function(){
					return params.criterio;
				};
			
				viewModel.listControls.search();
			});


			viewModel.listControls = {};

			viewModel.colDescricaoIsVisible = true;
			viewModel.colMunicipioIsVisible = true;
		}

		$scope.$watch(
				'distrito.colDescricaoIsVisible',
				function changeValueDesc(newValue, oldValue) {
					if (viewModel.listControls.setColumnVisible !== undefined) {
						viewModel.listControls.setColumnVisible('nome', newValue);
						viewModel.colDescricaoIsVisible = newValue;
					}

				}
		);
		$scope.$watch(
				'distrito.colMunicipioIsVisible',
				function changeValueDesc(newValue, oldValue) {
					if (viewModel.listControls.setColumnVisible !== undefined) {
						viewModel.listControls.setColumnVisible('municipio', newValue);
						viewModel.colMunicipioIsVisible = newValue;
					}
				}
		);

		function showDescricao() {
			return viewModel.colDescricaoIsVisible;
		}
		function showMunicipio() {
			return viewModel.colMunicipioIsVisible;
		}

		function inicializaLoadings() {
			viewModel.loadings = TrackerFactory.init(['distritos', 'getList', 'exclusao']);
		}

		function search() {
			viewModel.listControls.search();
		}

		function cadastrar() {
			DistritoModalService.cadastrar();
		}

		function editar(item) {
			DistritoModalService.editar(item);
		}

		function excluir(item) {
		   var promise = DistritoService.excluir(item.id);
		   return promise.then(function () {
         DistritoService.mensagemExclusao();
		   },function error(){
			  return promise;
		   });
		}

		$scope.setLineHeight = function (h) {
			$scope.lineHeight = h;
		};
	}
})();
