(function () {
	'use strict';
	/**
	 * @ngdoc overview
	 * @name contratos-core.estado
	 * @description
	 * # ccontratos-core.estado
	 * Módulo de Estados
	 */
	angular
			.module('core.estado')
			.factory('core.EstadoService', EstadoService);

	EstadoService.$inject = [
		'$q',
		'Restangular',
		'core.UtilsService',
		'$filter',
		'ui.components.EventEmitter'
	];

	function EstadoService(
			$q,
			Restangular,
			UtilsService,
			$filter,
			EventEmitter) {

		var meta;
		var uri = ['estados'];

		var orderBy = $filter('orderBy');

		var service = new EventEmitter();

		_.extend(service, EstadoService);

		service.get = get;
		service.getList = getList;
		service.getDefault = getDefault;
		service.getTypes = getTypes;
		service.salvar = salvar;
		service.excluir = excluir;
		service.getEnumSiglaEstado = getEnumSiglaEstado;
		service.getSugestoes = getSugestoes;

		return service;

		function get(id) {
			return Restangular.one(uri[0], id).get();
		}

		function getSugestoes(params) {
			params = params || {};

			return Restangular.one(uri[0]+'/sugestoes').get({
				filter: params.filter
			});
		}

		function getList(params) {
			params = params || {};

			params.offset = params.offset || 0;

			return Restangular.one(uri[0]).get({
				offset: params.offset,
				limit: params.limit,
				filter: params.filter,
				sort: params.sort
			}).then(function (data) {
				if (params.offset > 0 && data.total <= params.offset) {
					params.offset = 0;
					data = getList(params);
				}
				return data;
			});
		}

		function getDefault() {
			return getMetadata().then(function (data) {
				return angular.copy(data.representation.default);
			});
		}

		function getMetadata() {
			var deferred = $q.defer();
			if (!meta) {
				Restangular.all(uri[0]).options().then(function (data) {
					meta = data;
					deferred.resolve(meta);
				}, function (data) {
					deferred.reject(data);
				});
			} else {
				deferred.resolve(meta);
			}
			return deferred.promise;
		}

		function getTypes() {
			return getMetadata().then(function (data) {
				return angular.copy(data.types);
			});
		}

		function salvar(estado) {
			var rest = Restangular.all(uri[0]);
			if (estado.id) {
				return rest.customPUT(estado, estado.id).then(emitDirty);
			} else {
				return rest.post(estado).then(emitDirty);
			}
		}

		function excluir(id) {
			return Restangular.one(uri[0], id).remove().then(emitDirty);
		}

		function emitDirty(data) {
			service.emit('dirty');
			return data;
		}

		function getEnumSiglaEstado() {
			return getMetadata().then(function (data) {
				var novo = data.enums.UF;
				var siglaEstado = {};
				angular.forEach(novo.values, function (valor, index) {
					siglaEstado[valor.id] = valor;
				});
				siglaEstado.values = [
					siglaEstado.AC,
					siglaEstado.AL,
					siglaEstado.AP,
					siglaEstado.AM,
					siglaEstado.BA,
					siglaEstado.CE,
					siglaEstado.DF,
					siglaEstado.ES,
					siglaEstado.GO,
					siglaEstado.MA,
					siglaEstado.MT,
					siglaEstado.MS,
					siglaEstado.MG,
					siglaEstado.PA,
					siglaEstado.PB,
					siglaEstado.PR,
					siglaEstado.PE,
					siglaEstado.PI,
					siglaEstado.RJ,
					siglaEstado.RN,
					siglaEstado.RS,
					siglaEstado.RO,
					siglaEstado.RR,
					siglaEstado.SC,
					siglaEstado.SP,
					siglaEstado.SE,
					siglaEstado.TO
				];
				return siglaEstado;
			});
		}
	}
})();
