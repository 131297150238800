(function() {
	'use strict';
	/**
	 * @ngdoc overview
	 * @name contratacao.natureza-texto-juridico-ato
	 * @description
	 * #contratacao.natureza-texto-juridico-ato
	 * Módulo de natureza do texto jurídico dos atos
	 **/
	angular
	  .module('core.natureza-texto-juridico-ato', ['core', 'core.commons']);
})();
