(function () {
  'use strict';

  angular
    .module('core.integracao')
    .factory('core.ScriptIntegracaoResources', ScriptIntegracaoResources);

  ScriptIntegracaoResources.$inject = ['Restangular', 'URL_API'];

  function ScriptIntegracaoResources(Restangular, URL_API) {
    return Restangular.withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setBaseUrl(URL_API);
      RestangularConfigurer.setDefaultHeaders({'Content-Type': 'application/json'});
    });
  }
})();
