(function() {
    'use strict';

    /**
     * @ngdoc overview
     * @name contratos-core.organograma
     * @description
     * # contratos-core.organograma
     * Módulo do organograma
     * @requires ctr.configuracao-organograma
     **/
    angular
        .module('core.organograma', ['core', 'core.commons', 'core.parametros', 'core.configuracao-organograma']);
})();
