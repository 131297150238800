(function() {
    'use strict';

    angular
        .module('core.commons')
        .service('core.YesOrNoService', YesOrNoService);

    YesOrNoService.$inject = ['core.UtilsService'];

    function YesOrNoService(UtilsService) {

        angular.extend(this, {
            getYesNo: getYesNo,
            getYesOrNo: getYesOrNo,
            getYes: getYes,
            getNo: getNo
        });

        var enums = {
            'YesOrNo': {
                'values': [{
                    'key': 'SIM',
                    'value': 'true',
                    'description': 'Sim'
                }, {
                    'key': 'NAO',
                    'value': 'false',
                    'description': 'Não'
                }]
            }
        };

        function getYesNo(key) {
            return UtilsService.getValueFromKey(enums.YesOrNo.values, key);
        }

        function getYesOrNo(filter) {
            return UtilsService.getFilteredValues(enums.YesOrNo.values, filter);
        }

        function getYes() {
            return enums.YesOrNo.values[0];
        }

        function getNo() {
            return enums.YesOrNo.values[1];
        }
    }
})();
