(function () {
    'use strict';

    angular
        .module('core.veiculos-publicacao')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var veiculo = {
            route: {
                url: '/veiculos-publicacao',
                data: {
                    id: 'VeiculoPublicacaoPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'veiculos-publicacao.html',
                        controller: 'core.VeiculosPublicacaoController',
                        controllerAs: 'veiculosPublicacao'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-veiculos-publicacao', veiculo.route);
    }
})();