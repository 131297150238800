(function () {
  'use strict';

  angular
    .module('core.organograma')
    .directive('coreOrganogramaSelect', organogramaSelect);

  organogramaSelect.$inject = ['CONFIG_SELECT2'];

  function organogramaSelect(CONFIG_SELECT2) {
    return {
      restrict: 'E',
      template: '<ui-select ng-model="ngModel" ng-change="ngChange()" bf-required="bfRequired" ng-disabled="ngDisabled" search="vm.search"></ui-select>',
      scope: {
        ngModel: '=ngModel',
        bfRequired: '=?',
        ngDisabled: '=?',
        hiddenAddValue: '=?',
        criterionSearch: '=?',
        entidadeId: '=?',
        ultimoNivel: '=?',
        buscaPorAno: '=?',
        ordernarPorNivel: '=?',
        configuracoesOrganogramasId: '=?',
        ngChange: '&?',
        filtrarPermissoes: '=?',
        customSearch: '=?'
      },
      compile: function (element, attributes) {
        var addValue = attributes.hiddenAddValue === 'true';
        if (!addValue) {
          element.children('ui-select').attr('add-value', 'vm.onAdd');
        }
        if (attributes.multiple === 'true') {
          element.children('ui-select').attr('multiple', 'true');
        }
        if (attributes.ultimoNivel === 'true') {
          element.children('ui-select').attr('ultimo-nivel', 'true');
        }
        if (attributes.ordernarPorNivel === 'true') {
          element.children('ui-select').attr('ordernar-por-nivel', 'true');
        }
        if (attributes.filtrarPermissoes === 'true') {
          element.children('ui-select').attr('filtro-permissao-organ', 'true');
        }
        element.children('ui-select').attr('format-result', 'vm.formatResult(data)');
        element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
        element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
        element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('ORGANOGRAMA'));
      },
      controller: Controller,
      controllerAs: 'vm'
    };
  }

  Controller.$inject = [
    '$scope',
    'bfc.$$PermissionsService',
    'core.OrganogramaService',
    'core.ParametrosService',
    'core.OrganogramaCadModalService',
    '$rootScope'
  ];

  function Controller($scope, PermissionsService, service, ParametrosService, modal, $rootScope) {
    var viewModel = this;

    var UM = 1;

    viewModel.search = search;
    viewModel.onAdd = !PermissionsService.isRevokedOperation('OrganogramaPageMapping', 'criar') && onAdd;
    viewModel.params = $scope.criterionSearch;
    viewModel.ultimoNivel = $scope.ultimoNivel;
    viewModel.ordernarPorNivel = $scope.ordernarPorNivel;
    viewModel.formatResult = formatResult;
    viewModel.filtrarPermissoes = $scope.filtrarPermissoes;
    viewModel.entidadeId = $scope.entidadeId;
    viewModel.customSearch = $scope.customSearch;

    function search(params) {


      var configuracoesOrganogramasId = '';
      if(!_.isEmpty($scope.configuracoesOrganogramasId)){
          params.ignorarConfiguracaoAtual = true;
          configuracoesOrganogramasId = ' and configuracaoOrganogramaId in ('+$scope.configuracoesOrganogramasId.toString()+')'
      }

      var promise = $scope.buscaPorAno ? ParametrosService.getConfiguracaoOrganogramaPorAno($scope.buscaPorAno) : ParametrosService.getConfiguracaoOrganograma();
      return promise.then(function (data) {
        if (!viewModel.configuracaoOrganogramaProvenienteEvento) {
          viewModel.configuracaoOrganograma = data;
        } else {
          viewModel.configuracaoOrganograma = viewModel.configuracaoOrganogramaProvenienteEvento;
        }
        var paramsDefault = '';
        paramsDefault = '(descricao like "' + params.term + '")' + ' or (numero like "' + params.term.replace(/[^\w%]/g, '') + '")' + configuracoesOrganogramasId;

        params.filter = $scope.criterionSearch ? viewModel.params({termo: params.term}) + configuracoesOrganogramasId : paramsDefault;

        var promiseBuscarOrganogramas;

        if(viewModel.customSearch){

          if(viewModel.ultimoNivel){
            params.ultimoNivel = viewModel.ultimoNivel;
          }

          promiseBuscarOrganogramas = viewModel.customSearch(params);

        }else if(viewModel.filtrarPermissoes){

          if(viewModel.ultimoNivel){
            params.ultimoNivel = viewModel.ultimoNivel;
          }

          params.total = false;
          promiseBuscarOrganogramas = service.getOrganPorPermissao(params, viewModel.configuracaoOrganograma.id, $scope.entidadeId);

        }else if(!viewModel.ordernarPorNivel){

          promiseBuscarOrganogramas = service.getList(params, viewModel.configuracaoOrganograma.id, viewModel.ultimoNivel);

        }else{

          promiseBuscarOrganogramas = service.getListOrderByNivel(params, viewModel.configuracaoOrganograma.id, viewModel.ultimoNivel);
          
        }

        return promiseBuscarOrganogramas.then(function (data) {
          return data;
        });
          
      });
    }

    function onAdd(descricao) {
      var params = {
        configuracaoOrganogramaId: viewModel.configuracaoOrganograma.id,
        descricao: descricao
      };
      return modal.onAdd(params);
    }

    function formatResult(data) {
      if($scope.configuracoesOrganogramasId && $scope.configuracoesOrganogramasId.length > UM){ 
        return data.numeroFormatado ? 
               data.numeroFormatado + ' - ' 
                                    + data.descricao
                                    + ' (' + data.configuracao.descricao + ')': 
               data.descricao;
    }
      return data.numeroFormatado ? data.numeroFormatado + ' - ' + data.descricao : data.descricao;
    }

    $rootScope.$on('organograma:alteracaoConfiguracaoOrganograma', function (event, params) {
        viewModel.configuracaoOrganogramaProvenienteEvento = params.configuracaoOrganogramaSelecionada;
    });
  }
})();
