(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.fornecedor.controller:FornecedorCadController
     * @description Controller do cadastro de fornecedor
     **/
    angular
        .module('core.fornecedor')
        .controller('core.FornecedorCadController', FornecedorCadController);

    FornecedorCadController.$inject = [
        '$scope',
        '$rootScope',
        '$q',
        '$modalInstance',
        '$modalTracker',
        'bfc.FocusCtrl',
        'core.TrackerFactory',
        'core.Select2Factory',
        'core.FornecedorService',
        'core.FornecedorPessoaSelect',
        'core.UfSelect',
        'fornecedorId',
        'optionsCad',
        'fnCad',
        'bfc.Notification',
        'CamposAdicionaisService',
        'CPA_CONSTANTS',
        'core.ModalCadFactory',
        'CONTRATOS_CORE',
        'CONFIG_SELECT2',
        'core.NaturezaQualificacaoService',
        'core.ContaBancariaModalService',
        'core.ConsultaReceitaFederalModalService',
        'ContratosConfirmService',
        'core.ConsultaCnaeService',
        'core.ConsultaReceitaFederalService'
        
    ];

    function FornecedorCadController($scope,
                                     $rootScope,
                                     $q,
                                     $modalInstance,
                                     $modalTracker,
                                     Focus,
                                     TrackerFactory,
                                     Select2Factory,
                                     FornecedorService,
                                     FornecedorPessoaSelect,
                                     UfSelect,
                                     fornecedorId,
                                     optionsCad,
                                     fnCad,
                                     Notification,
                                     CamposAdicionaisService,
                                     CPA_CONSTANTS,
                                     ModalCadFactory,
                                     CONTRATOS_CORE,
                                     CONFIG_SELECT2,
                                     NaturezaQualificacaoService,
                                     ContaBancariaModalService,
                                     ConsultaReceitaFederalModalService,
                                     ConfirmService,
                                     ConsultaCnaeService,
                                     ConsultaReceitaFederalService) {

        var viewModel = this;
        var CAD_MODE = {
            NENHUM: {value: 0, text: "Cadastro de"},
            ADICIONANDO: {value: 1, text: "Adicionando"},
            EDITANDO: {value: 2, text: "Editando"},
            VISUALIZANDO: {value: 3, text: "Visualizado"}
        };

        inicializaLoadings();
        inicializa();

        function inicializa() {
            inicializaPropriedades();
            inicializaMetodos();
            inicializaSelect2();
            inicializaCadastro();
            inicializaCamposAdicionais();
            escutaEventos();
            viewModel.visivel = 1;
        }

        function escutaEventos() {
            $scope.$on('adicionaContaBancaria', function (eventos, params) {
                adicionaContaBancariaFornecedor(params.cadastro, params.index);
            });
        }

        function inicializaPropriedades() {
            viewModel.origemAberturaReceitaFederal = fornecedorId && fornecedorId.origemAberturaReceitaFederal ? true : false;
            fornecedorId = fornecedorId && fornecedorId.origemAberturaReceitaFederal ? fornecedorId.id : fornecedorId;
            viewModel.paramsNaturezaQualificacao = _.template('');
            viewModel.argumentos = FornecedorCadController.$inject;
            viewModel.types = {};
            viewModel.fornecedor = {};
            viewModel.fornecedor.pessoa = {};
            viewModel.rowSelected = [];
            viewModel.mostrarCnaeSelect = false;
            viewModel.cnaePrimario = undefined;
        }

        function inicializaMetodos() {
            viewModel.acaoCad = acaoCad;
            viewModel.adicionando = adicionando;
            viewModel.desabilitaSalvamento = desabilitaSalvamento;
            viewModel.editando = editando;
            viewModel.tipoPessoa = tipoPessoa;
            viewModel.buscaPessoa = buscaPessoa;
            viewModel.getMascaraDocumento = getMascaraDocumento;
            viewModel.emUso = emUso;
            viewModel.salvar = salvar;
            viewModel.salvarAdicionar = salvarAdicionar;
            viewModel.existeFornecedor = existeFornecedor;
            viewModel.excluir = excluir;
            viewModel.selecionaPessoa = selecionaPessoa;
            viewModel.showInscricaoEstadual = showInscricaoEstadual;
            viewModel.temSocios = temSocios;
            viewModel.removerSocio = removerSocio;
            viewModel.adicionarSocio = adicionarSocio;
            viewModel.calculaPercentual = calculaPercentual;
            viewModel.getExibicaoResponsavel = getExibicaoResponsavel;
            viewModel.desabilitaOptanteSimples = desabilitaOptanteSimples;
            viewModel.adicionarContaBancaria = adicionarContaBancaria;
            viewModel.removerContaBancaria = removerContaBancaria;
            viewModel.editarContaBancaria = editarContaBancaria;
            viewModel.tornarPrincipal = tornarPrincipal;
            viewModel.formataDadosBancarios = formataDadosBancarios;
            viewModel.consultaReceitaFederal = consultaReceitaFederal;
            viewModel.setFocus = setFocus;
            viewModel.tornarCnaePrimario = tornarCnaePrimario;
            viewModel.adicionarCnae = adicionarCnae;
            viewModel.exibeCnaeFormatado = exibeCnaeFormatado;
            viewModel.removerCnae = removerCnae;
            viewModel.importarCnaesReceitaFederal = importarCnaesReceitaFederal;
            viewModel.buscarDadosCnaes = buscarDadosCnaes;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init(['salvar', 'salvarAdicionar', 'excluir', 'camposAdicionais', 'carregaPessoa', 'loadingCnaes']);
        }

        function inicializaSelect2() {
            viewModel.select2 = Select2Factory.init([
                {
                    element: 'nome',
                    select2: FornecedorPessoaSelect
                },
                {
                    element: 'uf',
                    select2: UfSelect
                },
                {
                    element: 'socio',
                    select2: FornecedorPessoaSelect,
                    options: {
                        result: _.debounce(function result(params, callback) {
                            var filter = params.term;
                            var idPessoa;
                            if (viewModel.pessoaTemplate && viewModel.pessoaTemplate.pessoa) {
                                idPessoa = viewModel.pessoaTemplate.pessoa.id;
                            } else if (viewModel.fornecedor.id) {
                                idPessoa = viewModel.fornecedor.id;
                            }
                            var resultado = FornecedorService.getSocio(filter);
                            resultado.then(function (fornecedores) {
                                callback({
                                    results: _.map(fornecedores, 'pessoa'),
                                    more: fornecedores.hasNext
                                });
                            });
                            return resultado;
                        }, CONFIG_SELECT2.DELAY),
                        formatResult: function (fornecedor) {
                            return fornecedor.text ? fornecedor.text : fornecedor.nome;
                        },
                        formatValue: function (fornecedor) {
                            return fornecedor;
                        },
                        onAdd: function (val, target) {
                            ModalCadFactory.open({
                                templateUrl: 'fornecedor-cad/fornecedor-cad.html',
                                controller: 'core.FornecedorCadController as fornecedorCad',
                                resolve: {
                                    fornecedorId: null
                                },
                                optionsCad: {
                                    nome: val
                                },
                                selectElem: target
                            });
                            return false;
                        }
                    }
                }
            ]);
        }

        function selecionaPessoa() {
            var promise;
            if (viewModel.pessoaTemplate && viewModel.pessoaTemplate.pessoa) {
                if (viewModel.pessoaTemplate.pessoa.id) {
                    promise = FornecedorService.getPessoaCompleto(viewModel.pessoaTemplate.pessoa.id).then(function (data) {
                        viewModel.fornecedor = data;
                    });
                    viewModel.loadings.carregaPessoa.addPromise(promise);
                } else {
                    viewModel.fornecedor.pessoa = viewModel.pessoaTemplate.pessoa;
                }
            }
            return promise;
        }

        function getFornecedor() {
            return FornecedorService.get(fornecedorId);
        }

        function getFornecedorDefault() {
            var promise;
            if (viewModel.representation) {
                var deffered = $q.defer();
                deffered.resolve(viewModel.representation);
                promise = deffered.promise;
            } else {
                promise = FornecedorService.getDefault();
            }
            return promise;
        }

        function inicializaFornecedor() {
            var promise = fornecedorId ? getFornecedor() : getFornecedorDefault();
            promise.then(function (fornecedor) {
                viewModel.fornecedor = fornecedor;

                if (!fornecedorId) {
                    viewModel.representation = angular.copy(fornecedor);
                    viewModel.fornecedor.pessoa = {contasBancarias: []};
                    viewModel.dadosReceita = FornecedorService.getConsultaReceita();
                    carregaDadosSemFornecedor(viewModel.dadosReceita);
                } else {
                    viewModel.dadosReceita = FornecedorService.getConsultaReceita();
                    if (viewModel.dadosReceita) {
                        carregaDadosComFornecedor(viewModel.dadosReceita);
                    }
                }

                if (!viewModel.fornecedor.optanteSimples) {
                    viewModel.fornecedor.optanteSimples = undefined;
                }

                if (!fornecedorId && optionsCad && optionsCad.fornecedor) {
                    angular.extend(viewModel.fornecedor, optionsCad.fornecedor);
                    viewModel.form.$setDirty();
                }

                if (optionsCad && optionsCad.nome) {
                    if (viewModel.fornecedor) {
                        if (!viewModel.fornecedor.pessoa) {
                            viewModel.fornecedor.pessoa = {};
                        }
                        viewModel.fornecedor.pessoa.nome = optionsCad.nome;
                    }
                    viewModel.pessoaTemplate = {};
                    viewModel.pessoaTemplate.nome = viewModel.fornecedor.pessoa.nome;
                }
            });
            return promise;
        }

        function inicializaCamposAdicionais() {
            var id = (viewModel.fornecedor.id === undefined) ? fornecedorId : viewModel.fornecedor.id;
            var promise = FornecedorService.getCamposAdicionais(id)
                .then(function(data) {
                    viewModel.campoAdicional = data;
                });

            return promise;
        }

        function inicializaEnums() {
            viewModel.porteEmpresa = FornecedorService.getEnumPorteEmpresa();
            viewModel.orgaoRegistroEmpresa = FornecedorService.getEnumOrgaoRegistro();
            viewModel.tipoParticipacao = FornecedorService.getEnumTipoParticipacao();
        }

        function excluir() {
            $rootScope.$broadcast('fornecedor:actionRemove', {id: viewModel.fornecedor.id});
            fechar();
        }

        function inicializaTypes() {
            var promise = FornecedorService.getTypes();
            promise.then(function (types) {
                viewModel.types = types;
            });
            return promise;
        }

        function inicializaCadastro() {
            var promise = $q.all([
                inicializaEnums(),
                inicializaTypes(),
                inicializaFornecedor()
            ]);
            $modalTracker.addPromise(promise);
        }

        function adicionando() {
            return !editando();
        }

        function desabilitaSalvamento() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
        }

        function editando() {
            return (viewModel.fornecedor.id === undefined) ? fornecedorId : viewModel.fornecedor.id;
        }

        function acaoCad() {
            return (editando()) ? 'Editando' : 'Adicionando';
        }

        function existeFornecedor() {
            return Boolean(editando());
        }

        function tipoPessoa() {
            if (viewModel.fornecedor.pessoa && viewModel.fornecedor.pessoa.tipoPessoa) {
                return (viewModel.fornecedor.pessoa.tipoPessoa === 'FISICA') ? 'Pessoa física' :
                    (viewModel.fornecedor.pessoa.tipoPessoa === 'JURIDICA') ? 'Pessoa juridica' : '';
            }
            if (viewModel.fornecedor.pessoa && viewModel.fornecedor.pessoa.cpfCnpj) {
                return (viewModel.fornecedor.pessoa.cpfCnpj.length === 11) ? 'Pessoa física' :
                    (viewModel.fornecedor.pessoa.cpfCnpj.length === 14) ? 'Pessoa juridica' : '';
            }
        }

        function showInscricaoEstadual() {
            if (viewModel.fornecedor && viewModel.fornecedor.pessoa && viewModel.fornecedor.pessoa.tipoPessoa && viewModel.fornecedor.pessoa.tipoPessoa === 'FISICA') {
                return false;
            } else if (viewModel.fornecedor && viewModel.fornecedor.pessoa && viewModel.fornecedor.pessoa.cpfCnpj && viewModel.fornecedor.pessoa.cpfCnpj.length === 11) {
                return false;
            }
            return true;
        }

        function emUso() {
            return viewModel.fornecedor && viewModel.fornecedor.emUso;
        }

        function fechar() {
            $modalInstance.close();
        }

        function getMascaraDocumento() {
            if (viewModel.fornecedor.pessoa &&
                viewModel.fornecedor.pessoa.cpfCnpj &&
                viewModel.fornecedor.pessoa.cpfCnpj.length <= 11) {
                return '000.000.000-000';
            }
            return '00.000.000/0000-00';
        }

        function buscaPessoa() {
            if (viewModel.pessoaTemplate && viewModel.pessoaTemplate.pessoa && viewModel.pessoaTemplate.pessoa.id) {
                FornecedorService.getPessoa(viewModel.pessoaTemplate.pessoa.id).then(function (busca) {
                    if (busca.length !== 0) {
                        viewModel.fornecedor = busca.plain();
                        if (busca.id === undefined) {
                            viewModel.fornecedor = busca;
                            angular.extend(viewModel.fornecedor, viewModel.representation);
                        }
                    }
                });
            }
        }

        function novo() {
            fornecedorId = undefined;
            inicializaFornecedor();
            if (viewModel.form) {
                viewModel.form.$setPristine();
                viewModel.form.$setUntouched();
            }
            viewModel.pessoaTemplate.nome = '';
            if ($modalInstance && $modalInstance.params && $modalInstance.params.params) {
                if ($modalInstance.params.params.nome) {
                    $modalInstance.params.params.nome = undefined;
                }
            }
            inicializaCamposAdicionais();
            viewModel.visivel = 1;
            Focus.set('lblFornecedorCadControllerNome');
        }

        function limparCamposForm() {
            fornecedorId = undefined;
            viewModel.fornecedor = {};
            if (viewModel.form) {
                viewModel.form.$setPristine();
                viewModel.form.$setUntouched();
            }
            viewModel.pessoaTemplate.nome = '';
            if ($modalInstance && $modalInstance.params && $modalInstance.params.params) {
                if ($modalInstance.params.params.nome) {
                    $modalInstance.params.params.nome = undefined;
                }
            }
            inicializaCamposAdicionais();
            viewModel.visivel = 1;
            viewModel.pessoaTemplate = null;
            Focus.set('lblFornecedorCadControllerNome');
        }

        function salva() {
            viewModel.fornecedor.pessoa.telefone = removeCaracteresEspeciais(viewModel.fornecedor.pessoa.telefone);
            if (viewModel.pessoaTemplate && viewModel.pessoaTemplate.nome) {
                viewModel.fornecedor.pessoa.nome = viewModel.pessoaTemplate.nome;
            }
            if (viewModel.campoAdicional)
                viewModel.fornecedor.campoAdicional = viewModel.campoAdicional;

            if (viewModel.fornecedor.ativo) {
                viewModel.fornecedor.dtSituacao = undefined;
            }

            if (viewModel.cnaePrimario == undefined) {
                viewModel.fornecedor.cnaes = undefined;
            }

            var promise = FornecedorService.save(viewModel.fornecedor);
            promise.then(function (fornecedor) {

                if (angular.isFunction(fnCad)) {
                    fnCad(fornecedor);
                }

                showNotificationSave();
            });
            return promise;
        }

        function showNotificationSave() {
            if (fornecedorId) {
                Notification.publish('Fornecedor alterado com sucesso', 'success');
            } else {
                Notification.publish('Fornecedor adicionado com sucesso', 'success');
            }
        }

        function removeCaracteresEspeciais(string) {
            if (string) {
                string = string.replace(/[^\d]/g, '');
            }

            if (string === '') {
                string = undefined;
            }
            return string;
        }

        function salvar() {
            var promise = salva();
            promise.then(function () {
                fechar();
            });
            viewModel.loadings.salvar.addPromise(promise);
        }

        function salvarAdicionar() {
            var promise = salva();
            promise.then(function () {
                limparCamposForm();
            });
            viewModel.loadings.salvarAdicionar.addPromise(promise);
        }

        function temSocios() {
            return viewModel.fornecedor && viewModel.fornecedor.socios && viewModel.fornecedor.socios.length === 0;
        }

        function removerSocio(index) {
            viewModel.fornecedor.socios.splice(index, 1);
        }

        function adicionarSocio(data) {
            if (angular.isUndefined(data)) {
                data = {
                    pessoa: undefined,
                    percentual: undefined,
                    dtInclusao: undefined,
                    dtDesligamento: undefined
                };
            }
            viewModel.fornecedor.socios.push(data);
        }

        function calculaPercentual(item) {
            var calculo = _.map(viewModel.fornecedor.socios, 'percentual');
            var calculoTotal = new BigNumber(0);
            _.forEach(calculo, function (value) {
                calculoTotal = calculoTotal.plus(value);
            });
        }

        function getExibicaoResponsavel(responsavel) {
            if (responsavel.text) {
                return responsavel.text;
            }
            if (responsavel) {
                var situacao = responsavel.ativo === true ? 'Ativo' : 'Inativo';
                return responsavel.pessoa.nome +
                    ' (CPF: ' + $filter('bfMaskCpfCnpj')(responsavel.pessoa.cpfCnpj) +
                    ' - Situação: ' + situacao + ')';
            }
        }

        function desabilitaOptanteSimples() {
            if (viewModel.fornecedor.porteEmpresa === undefined || viewModel.fornecedor.porteEmpresa === 'EMPRESA_MEDIO_PORTE' || viewModel.fornecedor.porteEmpresa === 'EMPRESA_GRANDE_PORTE') {
                viewModel.fornecedor.optanteSimples = undefined;
                return true;
            }
        }

        $scope.$watch(
            'fornecedorCad.fornecedor.naturezaJuridica',
            function alteracao(newValue, oldValue) {
                if (oldValue) {
                    viewModel.fornecedor.naturezaJuridicaQualificacao = null;
                }
                if (newValue) {
                    viewModel.paramsNaturezaQualificacao = _.template('(idNaturezaJuridica=' + newValue.id + ')');
                } else {
                    viewModel.paramsNaturezaQualificacao = _.template('');
                }
                $rootScope.$broadcast('naturezaQualificacaoSelect:refresh');
            }
        );

        function adicionarContaBancaria() {
            ContaBancariaModalService.cadastrar();
        }

        function editarContaBancaria(data, index) {
            ContaBancariaModalService.editar(data, index);
        }

        function adicionaContaBancariaFornecedor(data, index) {
            if (!angular.isUndefined(data)) {
                if (viewModel.fornecedor.pessoa.contasBancarias[index]) {
                    viewModel.fornecedor.pessoa.contasBancarias[index] = data;
                } else {
                    data.padrao = viewModel.fornecedor.pessoa.contasBancarias.length === 0 ? true : false;
                    viewModel.fornecedor.pessoa.contasBancarias.push(data);
                }
            }
        }

        function removerContaBancaria(index) {
            viewModel.fornecedor.pessoa.contasBancarias.splice(index, 1);
        }

        function tornarPrincipal(index, conta) {

            if (conta.padrao === true) {
                conta.padrao = false;
            } else {
                conta.padrao = true;
            }
            alteraUltimaConta(conta);
            viewModel.form.$setDirty();
        }

        function alteraUltimaConta(conta) {
            angular.forEach(viewModel.fornecedor.pessoa.contasBancarias, function (data) {
                if (conta !== data && data.padrao === true) {
                    data.padrao = false;
                    return;
                }
            });
        }

        function formataDadosBancarios(data) {
            var numeroDigitoConta = getNumeroDigitoConta(data),
                numeroDigitoAgencia = getNumeroDigitoAgencia(data),
                tipoConta = getTipoConta(data);
            return data.banco.nome + ' ' + numeroDigitoAgencia + ' ' + numeroDigitoConta + tipoConta;
        }

        function getNumeroDigitoConta(data) {
            return data.digito ? data.numero + '-' + data.digito : data.numero;
        }

        function getNumeroDigitoAgencia(data) {
            if (!data.agenciaBancaria) return;
            return (data.agenciaBancaria && data.agenciaBancaria.digitoAgencia) ? data.agenciaBancaria.numeroAgencia + '-' + data.agenciaBancaria.digitoAgencia : data.agenciaBancaria.numeroAgencia;
        }

        function getTipoConta(data) {
            var tipoConta = {
                CORRENTE: {'descricao': 'Corrente'},
                POUPANCA: {'descricao': 'Poupança'},
                SALARIO: {'descricao': 'Salário'}
            };
            return data.tipoConta ? ' (' + tipoConta[data.tipoConta].descricao + ')' : '';
        }

        function consultaReceitaFederal() {
            fechar();
            var cnpjInformado = viewModel.fornecedor && 
                                viewModel.fornecedor.pessoa && 
                                viewModel.fornecedor.pessoa.cpfCnpj;
            if (cnpjInformado) {
                ConsultaReceitaFederalModalService.cadastrar(viewModel.fornecedor.pessoa.cpfCnpj, false);
            } else {
                ConsultaReceitaFederalModalService.cadastrar(null, false);
            }           
        }

        function carregaDadosComFornecedor(data) {
            if (viewModel.origemAberturaReceitaFederal) {
                viewModel.fornecedor = data.fornecedor;
                viewModel.pessoaTemplate = data.fornecedor;
                viewModel.pessoaTemplate = data.razaoSocial;
                viewModel.fornecedor.pessoa.cpfCnpj = data.cnpj;
                viewModel.fornecedor.pessoa.id = data.fornecedor.id;
                dePara(data, angular.copy(viewModel.fornecedor.pessoa.emails), angular.copy(viewModel.fornecedor.pessoa.telefones));
                inserirDadosReceitaViewModel(data);
            }
        }

        function carregaDadosSemFornecedor(data) {
            viewModel.pessoaTemplate = data.razaoSocial;
            viewModel.fornecedor.pessoa = {contasBancarias: []};
            viewModel.fornecedor.pessoa.nome = data.razaoSocial;
            viewModel.fornecedor.pessoa.cpfCnpj = data.cnpj;
            dePara(data, angular.copy(viewModel.fornecedor.pessoa.emails), angular.copy(viewModel.fornecedor.pessoa.telefones));
            if (viewModel.origemAberturaReceitaFederal) {
                inserirDadosReceitaViewModel(data);
            }
        }

        function dePara(data, dataEmails, dataTelefones) {
            viewModel.fornecedor.pessoa.emails = listaEmails(data, dataEmails);
            viewModel.fornecedor.pessoa.telefones = listaTelefones(data, dataTelefones);
            var porteEmpresaReceita = {
                '03': {
                    id: 'EMPRESA_PEQUENO_PORTE',
                    value: 'EMPRESA_PEQUENO_PORTE',
                    description: 'Empresa de Pequeno Porte'
                },
                '01': {id: 'MICROEMPRESA', value: 'MICROEMPRESA', description: 'Microempresa'}
            };
            if (porteEmpresaReceita[data.porte]) {
                viewModel.fornecedor.porteEmpresa = porteEmpresaReceita[data.porte].id;
            }
            viewModel.fornecedor.optanteSimples = (data.opcaoSimples === 'OPTANTE_PELO_SIMPLES') ? true : false;
        }

        function inserirDadosReceitaViewModel(data) {
            inserirNomeAtualizadoFornecedorReceitaViewModel(data);
            inserirDadosEnderecoReceitaViewModel(data);
            inserirDadosPorteEmpresaReceitaViewModel(data);
            inserirDadosNaturezaJuridicaReceitaViewModel(data);
            inserirDadosOptanteSimplesViewModel(data);
            inserirDadosCnaeViewModel(data);
        }

        function inserirNomeAtualizadoFornecedorReceitaViewModel(data) {
            viewModel.fornecedor.pessoa.nome = data.razaoSocial;
        }

        function inserirDadosEnderecoReceitaViewModel(data) {
            viewModel.fornecedor.pessoa.enderecos = [];
            var promiseMunicipio = FornecedorService.getMunicipioFornecedor(data.codigoMunicipio || data.municipio);
            viewModel.loadings.carregaPessoa.addPromise(promiseMunicipio);
            promiseMunicipio.then(function(municipio) {
                var promiseLogradouro = FornecedorService.getLogradouroFornecedor(data.logradouro, data.tipoLogradouro, data.cep, municipio);
                viewModel.loadings.carregaPessoa.addPromise(promiseLogradouro);
                promiseLogradouro.then(function(logradouro) {
                    var promiseBairro = FornecedorService.getBairroFornecedor(data.bairro, municipio);
                    viewModel.loadings.carregaPessoa.addPromise(promiseBairro);
                    promiseBairro.then(function(bairro) {
                        viewModel.fornecedor.pessoa.enderecos.push({
                            cep: data.cep ? data.cep.toString() : '',
                            logradouro: logradouro, 
                            nro: data.numero,
                            complemento: data.complemento,
                            municipio: municipio,
                            bairro: bairro
                        });
                        $rootScope.$broadcast('camposFornecedores:refresh');
                    });
                });
            });
        }

        function inserirDadosPorteEmpresaReceitaViewModel(data) {
            if (data.opcaoMei === 'SIM') {
                viewModel.fornecedor.porteEmpresa = 'MICROEMPREENDEDOR_INDIVIDUAL';
                return;
            }
            switch (data.porteEmpresa) {
                case 'NAO_INFORMADO':
                    viewModel.fornecedor.porteEmpresa = 'NAO_CLASSIFICADA';
                    break;
                case 'MICRO_EMPRESA':
                    viewModel.fornecedor.porteEmpresa = 'MICROEMPRESA';
                    break;
                case 'EMPRESA_DE_PEQUENO_PORTE':
                    viewModel.fornecedor.porteEmpresa = 'EMPRESA_PEQUENO_PORTE';
                    break;
                case 'DEMAIS':
                    viewModel.fornecedor.porteEmpresa = 'NAO_CLASSIFICADA';
                    break;
                default:
                    viewModel.fornecedor.porteEmpresa = 'NAO_CLASSIFICADA';
                    break;
            }
        }

        function inserirDadosNaturezaJuridicaReceitaViewModel(data) {
            var promise = FornecedorService.getNaturezaJuridicaFornecedor(data.codigoNaturezaJuridica);
            viewModel.loadings.carregaPessoa.addPromise(promise);
            promise.then(function (response) {
                viewModel.fornecedor.naturezaJuridica = response;
            });
        }

        function inserirDadosOptanteSimplesViewModel(data) {
            if (!data.opcaoSimples && data.dataOpcaoSimples) {
                viewModel.fornecedor.optanteSimples = true;
            }
            if (data.opcaoSimples === 'NAO_OPTANTE') {
                viewModel.fornecedor.optanteSimples = false;
            }
        }

        function inserirDadosCnaeViewModel(data) {
            var cnaes = [], filtro = [], params = {}, listaResultadoConsultaCnae = [];

            if(!viewModel.fornecedor.cnaes) {
                viewModel.fornecedor.cnaes = [];
            }

            data.cnaeSecundarias.push({cnae:data.cnaeFiscal, primario: true});

            _.forEach(data.cnaeSecundarias, function (value) {
                var cnaeEncontrado = viewModel.fornecedor.cnaes.filter(function(filtro){return filtro.cnae.id.toString() === value.cnae.toString()});
                if(cnaeEncontrado.length === 0){
                    filtro.push('"'+value.cnae+'"');
                } else {
                    cnaes.push(cnaeEncontrado[0]);
                }
            });

            if(filtro.length > 0) {
                var dadosCnae = consultaDadosCnae(filtro);
                viewModel.loadings.loadingCnaes.addPromise(dadosCnae);
                dadosCnae.then(function(result){
                    listaResultadoConsultaCnae = result;
                    _.forEach(listaResultadoConsultaCnae, function (secundarias) {
                        if(secundarias.id.toString() === data.cnaeFiscal.toString()){
                            cnaes.push({
                                cnae:{
                                    id: secundarias.id.toString(),
                                    descricao: secundarias.descricao
                                },
                                primario: true
                            });
                        } else {
                            cnaes.push({
                                cnae:{
                                    id: secundarias.id.toString(),
                                    descricao: secundarias.descricao
                                },
                                primario: false
                            });
                        }
                    });
                    viewModel.fornecedor.cnaes = cnaes;
                    angular.forEach(viewModel.fornecedor.cnaes, function (cnae) {
                        if(cnae.primario == true){
                            viewModel.cnaePrimario = cnae;
                        } else {
                            cnae.primario = false;
                        }
                    });
                    ordenaCnae();
                });
            } else {
                viewModel.fornecedor.cnaes = cnaes;
                angular.forEach(viewModel.fornecedor.cnaes, function (cnae) {
                    if(cnae.cnae.id.toString() === data.cnaeFiscal.toString()) {
                        cnae.primario = true;
                        viewModel.cnaePrimario = cnae;
                    } else {
                        cnae.primario = false;
                    }
                });
                ordenaCnae();
            }
            
        }

        function consultaDadosCnae(filtro) {
            var limit = 25;
            var resultado = [];
            var promises = []
            for(var i = 0; i < filtro.length; i+=limit){
                const blocoDeIds = filtro.slice(i, i + limit);
                var params = {term: blocoDeIds.join(",")};

                var promise = ConsultaCnaeService.getDadosCnaePorId(params).then(function(retorno) {
                    resultado = resultado.concat(retorno.content);
                });
        
                promises.push(promise);
            }
            return Promise.all(promises).then(function() {
                return resultado;
            });
        }
        
        function listaEmails(data, emailLista) {
            if (!data.email) {
                return;
            }
            var email = [{
                endereco: data.email,
                ordem: 1
            }];
            if (emailLista && emailLista.length > 0) {
                email[0].ordem += 1;
            } else {
                emailLista = [];
            }
            if (emailLista.length === 0) {
                emailLista = email;
            } else {
                emailLista.concat(email);
            }
            return emailLista;
        }

        function listaTelefones(data, telefoneLista) {
            var listaTelefonesComIdentificador = angular.copy(telefoneLista);
            var telefones = [
                {
                    descricao: null,
                    numero: data.telefone1,
                    ordem: 1,
                    tipo: 'FIXO'
                },
                {
                    descricao: null,
                    numero: data.telefone2,
                    ordem: 2,
                    tipo: 'FIXO'
                },
                {
                    descricao: null,
                    numero: data.fax,
                    ordem: 3,
                    tipo: 'FAX'
                }];
            telefones = limpaTelefonesSemNumero(telefones);
            if (telefoneLista && telefoneLista.length > 0) {
                var ordemMaxima = getOrdemMaxima();
                telefoneLista = reordenaTelfones(ordemMaxima.ordem, telefones);
            } else {
                telefoneLista = [];
            }
            if (telefoneLista.length === 0) {
                telefoneLista = telefones;
            } else {
                telefoneLista.concat(telefones);
            }
            telefoneLista = removerTelefonesDuplicados(telefoneLista); 
            telefoneLista = popularTelefonesRespectivosIdentificadores(telefoneLista, listaTelefonesComIdentificador);
            return telefoneLista;
        }

        function limpaTelefonesSemNumero(telefones) {
            var novaLista = [];
            _.forEach(telefones, function (value, key) {
                if (!_.isEmpty(value.numero) && !verificaExisteTelefone(value.numero)) {
                    value.numero = value.numero.replace(/\s/g,'');
                    novaLista.push(value);
                }
            });
            return novaLista;
        }

        function verificaExisteTelefone(telefone) {
            return _.find(viewModel.fornecedor.pessoa.telefones, function (data) {
                return data.numero === telefone;
            })
        }

        function popularTelefonesRespectivosIdentificadores(telefoneLista, listaTelefonesComIdentificador) {
            var notHasTelefone = !telefoneLista || telefoneLista.length < 1 || !listaTelefonesComIdentificador || listaTelefonesComIdentificador < 1;
            if (notHasTelefone) {
                return telefoneLista;
            }
            angular.forEach(telefoneLista, function(telefone, key) {
                var telefoneComIdentificadorEncontrado = listaTelefonesComIdentificador.find(function(x) {
                    return x.numero === telefone.numero;
                });
                if (telefoneComIdentificadorEncontrado) {
                    telefone.id = telefoneComIdentificadorEncontrado.id;
                }
            });
            return telefoneLista;
        }

        function removerTelefonesDuplicados(telefones) {
            var notHasTelefone = !telefones || telefones.length < 1;
            if (notHasTelefone) {
                return telefones;
            }
            var unicos = [];
            telefones.filter(function(telefone) {
                return unicos.find(function (x) { return x.numero === telefone.numero }) ? false : unicos.push(telefone);
            });
            return unicos;
        }

        function getOrdemMaxima() {
            return _.max(viewModel.fornecedor.pessoa.telefones, function (data) {
                return data.ordem;
            });
        }

        function reordenaTelfones(max, telefones) {
            _.forEach(telefones, function (value, key) {
                value.numero = value.numero.trim();
                max += 1;
                value.ordem = max;
            });
            return telefones
        }

        function setFocus(idDado) {
            viewModel.rowSelected = []
            viewModel.rowSelected[idDado] = true;
        }

        function adicionarCnae() {
            viewModel.mostrarCnaeSelect = true;
        }
 
        $scope.$watch(
            'fornecedorCad.cnaes',
            function alteracao(newValue, oldValue) {
                if (newValue === oldValue || !newValue) {
                    return;
                }
                adicionarCnaeSelecionado(newValue);
            }
        )
 
        function adicionarCnaeSelecionado(data) {
            if(!viewModel.fornecedor.cnaes) {
                viewModel.fornecedor.cnaes = [];
            };

            var adicionaCnae = true;
            _.forEach(viewModel.fornecedor.cnaes, function (cnaes, key) {
                if(data.id == cnaes.cnae.id){
                    adicionaCnae = false;
                    viewModel.cnaes = null;
                    return;
                };
            });
            if(adicionaCnae){
                var objFornecedorCnae = {
                    cnae:  {
                        id: data.id,
                        descricao: data.descricao
                    },
                    primario: viewModel.fornecedor.cnaes.length == 0,
                };
                viewModel.fornecedor.cnaes.push(objFornecedorCnae);
                viewModel.cnaes = null;

                if(viewModel.fornecedor.cnaes.length == 1){
                    viewModel.cnaePrimario = objFornecedorCnae;
                }
            }
        }

        function exibeCnaeFormatado(cnaeId) {
            cnaeId = cnaeId.toString();
            return cnaeId.substring(0, 2) + '.' + cnaeId.substring(2, 4) + '-' + cnaeId.substring(4, 5) + '/' + cnaeId.substring(5)
        }

        function removerCnae(cnae, index) {
            viewModel.fornecedor.cnaes.splice(index, 1);

            if (_.has(viewModel, 'fornecedor.cnaes[0]')) {
                tornarCnaePrincipal(0, _.get(viewModel, 'fornecedor.cnaes[0]'))
            }
        }

        function tornarCnaePrimario(index, cnae){
            tornarCnaePrincipal(index, cnae)
        }

        function tornarCnaePrincipal(index, cnae) {
            var cnae = viewModel.fornecedor.cnaes[index].cnae.id
            viewModel.fornecedor.cnaes[index].primario = true
            alteraUltimoCnae(cnae);
            ordenaCnae();
            viewModel.form.$setDirty();
        }

        function alteraUltimoCnae(cnae) {
            angular.forEach(viewModel.fornecedor.cnaes, function (data) {
                if (cnae == data.cnae.id) {
                    data.primario = true;
                    viewModel.cnaePrimario = data;
                } else {
                    data.primario = false;
                }
            });
        }
 
        function ordenaCnae(){
            var index = null;
            angular.forEach(viewModel.fornecedor.cnaes, function (data, key) {
                if(data.primario && data.cnae.id === viewModel.cnaePrimario.cnae.id){
                    index = key;
                } else {
                    data.primario = false;
                }
            });
            var item = viewModel.fornecedor.cnaes.splice(index, 1);
            viewModel.fornecedor.cnaes.sort(function(a, b) { return a.id - b.id});
            viewModel.fornecedor.cnaes.unshift(item[0]);
        }
 
        function importarCnaesReceitaFederal(){
            if(viewModel.fornecedor.cnaes && viewModel.fornecedor.cnaes.length >= 1) {
                return ConfirmService.confirm({
                    title: 'Confirmar dados da receita federal',
                    body: 'Esta ação irá atualizar os CNAEs do fornecedor. Você deseja continuar?'
                }, function () {
                    var loadReceita = ConsultaReceitaFederalService.getDadosReceita(viewModel.fornecedor.pessoa.cpfCnpj).then(function (fornecedores) {
                        inserirDadosCnaeViewModel(fornecedores);
                    });
                    viewModel.loadings.loadingCnaes.addPromise(loadReceita);
                }, function () {
                    return;
                });
            } else {
                var loadReceita = ConsultaReceitaFederalService.getDadosReceita(viewModel.fornecedor.pessoa.cpfCnpj).then(function (fornecedores) {
                    inserirDadosCnaeViewModel(fornecedores);
                });
                viewModel.loadings.loadingCnaes.addPromise(loadReceita);
            }
        }

        function buscarDadosCnaes() {
            if(!viewModel.fornecedor.cnaes || viewModel.fornecedor.cnaes.length === 0){
                var buscaCnaes = FornecedorService.getCnaesFornecedor(viewModel.fornecedor.id).then(function(content) {
                    if(content.length != 0) {
                        viewModel.fornecedor.cnaes = content;
                        angular.forEach(viewModel.fornecedor.cnaes, function (data) {
                            if(data.primario == true){
                                viewModel.cnaePrimario = data;
                            }
                        });
                        ordenaCnae();
                    }
                });   
                viewModel.loadings.loadingCnaes.addPromise(buscaCnaes);
            }
        }

    }
})();
