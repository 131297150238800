(function() {
    'use strict';

    angular.module('ui-components')
        .directive('uiNumberFormat', NumberDirective);

    NumberDirective.$inject = ['$injector'];

    function NumberDirective($injector) {
        var $parse = $injector.get('$parse');
        var uiNumberFormatServiceConfig = $injector.get('uiNumberFormatConfig');

        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: NumberDirectivePostLink
        };

        function NumberDirectivePostLink($scope, $element, $attrs, $ctrl) {
            var propNumber = {};

            _.merge(propNumber, uiNumberFormatServiceConfig);
            _.merge(propNumber, { precision: $parse($attrs.uiNumberFormat)($scope) });

            var uiNumberConfig = $scope.$eval($attrs.uiNumberConfig);
            _.merge(propNumber, uiNumberConfig);

            _.merge(propNumber, getPrecisao($scope, propNumber.precision));

            var fraction = propNumber.fraction;
            var integer = propNumber.integer;
            var maxlength = propNumber.maxlength;

            $element.on('keypress', function(e) {
                if (e.charCode && isLimiteAlcancado() && isNaoPossuiTextoSelecionado() && isMinusSignCharacter()) {
                    e.preventDefault();
                }

                function isNaoPossuiTextoSelecionado() {
                    return Boolean($element[0].selectionEnd - $element[0].selectionStart === 0);
                }

                function isLimiteAlcancado() {
                    return Boolean($element.val().replace(/\D/g, '').length >= maxlength);
                }

                function isMinusSignCharacter() {
                    return Boolean(String.fromCharCode(e.charCode) !== '-');
                }
            });

            $element.on('input', function() {
                if (!$element.val()) {
                    $ctrl.$setViewValue(null);
                    return;
                }

                formatValue({
                    value: $element.val(),
                    updateViewValue: true,
                    keepCursorPosition: true
                });
            });

            $element.on('blur', function() {

                $ctrl.$setTouched();

                if (!$element.val()) {
                    $ctrl.$setViewValue(null);
                    return;
                }

                formatValue({
                    value: $element.val(),
                    updateViewValue: true,
                    completeValue: true
                });
            });

            $ctrl.$render = function() {
                if (angular.isUndefined($ctrl.$viewValue) || $ctrl.$viewValue === null) {
                    $element.val('');
                    return;
                }

                var value = String($ctrl.$viewValue).replace('.', ',');

                formatValue({
                    value: value,
                    completeValue: true
                });
            };

            function formatValue(options) {
                options = options || {};
                var value = options.value || '';

                var separadorDecimalAdicionado = false;
                var negativo = false;

                var numerosInteiros = '';
                var numerosDecimais = '';

                var cursorOffset = 0;
                for (var posicaoString = 0; posicaoString < value.length; posicaoString++) {

                    if (numerosInteiros.length + numerosDecimais.length >= maxlength) {
                        break;
                    }

                    if (fraction && numerosDecimais.length >= fraction) {
                        break;
                    }

                    var char = value[posicaoString];

                    if (!separadorDecimalAdicionado && char === ',') {
                        separadorDecimalAdicionado = true;
                        cursorOffset++;
                    }

                    if (char === '-' && !numerosInteiros.length && !numerosDecimais.length) {
                        negativo = true;
                        cursorOffset++;
                    }

                    if (char.match(/\d/)) {
                        if (!separadorDecimalAdicionado && numerosInteiros.length === integer) {
                            separadorDecimalAdicionado = true;
                            cursorOffset++;
                        }

                        if (numerosInteiros.length === 1 && numerosInteiros[0] === '0' && !separadorDecimalAdicionado) {
                            numerosInteiros = '';
                        }

                        if (separadorDecimalAdicionado) {
                            numerosDecimais += char;
                        } else {
                            numerosInteiros += char;
                        }
                    } else {
                        cursorOffset--;
                    }
                }

                if (options.completeValue) {
                    if (numerosInteiros.length === 0 && integer !== 0) {
                        numerosInteiros = '0';
                    }

                    if (!separadorDecimalAdicionado && fraction) {
                        separadorDecimalAdicionado = true;
                    }

                    var qtdFaltante = fraction - numerosDecimais.length;
                    for (var i1 = 0; i1 < qtdFaltante; i1++) {
                        numerosDecimais += '0';
                    }
                }

                negativo = negativo && isAllowNegative();

                if (options.updateViewValue) {
                    $ctrl.$setViewValue(Number((negativo ? '-' : '') + numerosInteiros + '.' + numerosDecimais));
                }

                var temp = numerosInteiros;
                numerosInteiros = '';
                for (var i2 = temp.length - 1, j = 0; i2 >= 0; i2--, j++) {
                    if (j && j % 3 === 0) {
                        numerosInteiros = '.' + numerosInteiros;
                        cursorOffset++;
                    }

                    numerosInteiros = temp[i2] + numerosInteiros;
                }

                var cursorPosition = $element[0].selectionStart;
                $element.val((negativo ? '-' : '') + numerosInteiros + (separadorDecimalAdicionado ? ',' : '') + numerosDecimais);

                if (options.keepCursorPosition) {
                    $element[0].selectionStart = cursorPosition + cursorOffset;
                    $element[0].selectionEnd = cursorPosition + cursorOffset;
                }
            }

            function getPrecisao(scope, uiNumber) {
                var _uiNumber = uiNumber ? uiNumber.toString() : '2';

                if (_uiNumber.indexOf(',') === -1) {
                    return {
                        fraction: parseInt(_uiNumber),
                        maxlength: parseInt(_uiNumber)
                    };
                } else {
                    var split = uiNumber.split(','),
                        fraction = $parse(restrictNumber(split[1]))(scope),
                        integer = $parse(restrictNumber(split[0]))(scope);

                    return {
                        fraction: fraction,
                        integer: integer,
                        maxlength: (fraction + integer)
                    };
                }
            }

            function restrictNumber(value) {
                var transformedValue;

                if (value && typeof(value) !== 'string') {
                    value = value.toString();
                }

                transformedValue = value;
                if (value) {
                    transformedValue = transformedValue.replace(/(?!-)[^0-9.]/g, '');
                }

                return transformedValue;
            }

            function isAllowNegative() {
                return Boolean(propNumber && propNumber.allowNegative);
            }
        }

        return directive;
    }
})();