(function () {
  'use strict';
  angular
    .module('core.commons')
    .factory('core.TrackerFactory', TrackerFactory);

  TrackerFactory.$inject = ['$q', 'promiseTracker'];

  function TrackerFactory($q, promiseTracker) {

    return {
      init: init
    };

    function init(array) {
      var objectTracker = {};
      for (var i = 0; i < array.length; i++) {
        objectTracker[array[i]] = promiseTracker();
        objectTracker[array[i]].addPromises = function (array) {
          this.addPromise($q.all(array));
        };
      }
      objectTracker.active = function () {
        var expression = '';
        _.forEach(array, function (value, key) {
          var pipe = key < array.length - 1 ? ' || ' : '';
          expression = expression + 'objectTracker.' + value + '.active()' + pipe;
        });
        return eval(expression);
      };
      return objectTracker;
    }
  }
})();
