(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.fonte-divulgacao.FonteDivulgacaoService
     * @descripition
     * Serviço de fonte de divulgacao
     * @requires Restangular
     * @requires $q
     **/
    angular
            .module('core.fonte-divulgacao')
            .service('core.FonteDivulgacaoService', FonteDivulgacaoService);

    FonteDivulgacaoService.$inject = [
        '$q',
        'Restangular',
        'ui.components.EventEmitter'
    ];

    function FonteDivulgacaoService(
            $q,
            Restangular,
            EventEmitter) {

        var meta;
        var uri = ['fontesdivulgacao'];

        var service = new EventEmitter();

        _.extend(service, FonteDivulgacaoService);

        service.get = get;
        service.getDefault = getDefault;
        service.getList = getList;
        service.getTypes = getTypes;
        service.remove = remove;
        service.save = save;
        service.emitDirty = emitDirty;
        service.getEnumMeioComunicacao = getEnumMeioComunicacao;
        service.getSugestoes = getSugestoes;

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getSugestoes(params) {
            params = params || {};

            return Restangular.one(uri[0]+'/sugestoes').get({
                filter: params.filter
            });
        }

        function getList(params) {
            params = params || {};
            params.offset = params.offset || 0;

            return Restangular.one(uri[0]).get({
                offset: params.offset,
                limit: params.limit,
                filter: params.filter,
                sort: params.sort
            }).then(function (data) {
                if(params.offset > 0 && data.total <= params.offset) {
                    params.offset = 0;
                    data = getList(params);
                }
                return data;
            });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri[0]).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getTypes() {
            return getMetadata().then(function (data) {
                return angular.copy(data.types);
            });
        }

        function getEnumMeioComunicacao() {
            return getMetadata().then(function (data) {
                var novo = data.enums.MeioDivulgacaoEnum;
                var meioComunicacaoEnum = {};
                angular.forEach(novo.values, function (valor, index) {
                    meioComunicacaoEnum[valor.id] = valor;
                });
                meioComunicacaoEnum.values = [
                    meioComunicacaoEnum.JORNAL_DIVULGACAO_NACIONAL,
                    meioComunicacaoEnum.JORNAL_DIVULGACAO_ESTADUAL,
                    meioComunicacaoEnum.JORNAL_DIVULGACAO_REGIONAL,
                    meioComunicacaoEnum.JORNAL_DIVULGACAO_MUNICIPAL,
                    meioComunicacaoEnum.DIARIO_OFICIAL_UNIAO,
                    meioComunicacaoEnum.DIARIO_OFICIAL_ESTADO,
                    meioComunicacaoEnum.DIARIO_OFICIAL_MUNICIPIO,
                    meioComunicacaoEnum.DIARIO_JUSTICA,
                    meioComunicacaoEnum.DIARIO_ASSEMBLEIA,
                    meioComunicacaoEnum.MURAL_PUBLICO,
                    meioComunicacaoEnum.INTERNET,
                    meioComunicacaoEnum.EDITORA
                ];
                return meioComunicacaoEnum;
            });
        }

        function remove(id) {
            return Restangular.one(uri[0], id)
                    .remove()
                    .then(emitDirty);
        }

        function save(data) {
            var rest = Restangular.all(uri[0]);
            return !data.id ?
                    rest.post(data).then(emitDirty) :
                    rest.customPUT(data, data.id).then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }
    }
})();
