(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.configuracao-organograma.SeparadorSelect
   * @descripition
   * Serviço do select2 para tipo de entrega/execução do prazo de entrega
   * @requires bfc.Select2Config
   * @requires SeparadorSelect
   **/
  angular
    .module('core.configuracao-organograma')
    .service('core.SeparadorSelect', SeparadorSelect);

  SeparadorSelect.$inject = [
    '$q',
    'bfc.Select2',
    'core.ConfiguracaoOrganogramaService',
    'core.UtilsService',
    'CONFIG_SELECT2'
  ];

  function SeparadorSelect($q,
                           Select2,
                           ConfiguracaoOrganogramaService,
                           UtilsService,
                           CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var methods = {
        id: options.id ? options.id : id,
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT(),
        dropdownAutoWidth: false
      };

      function getSeparador(separador) {
        return separador && separador.description;
      }

      function id(separador) {
        return separador && separador.value;
      }

      function formatResult(separador) {
        return getSeparador(separador);
      }

      function formatSelection(separador) {
        return getSeparador(separador);
      }

      function formatValue(separador) {
        return separador.value;
      }

      function initSelection(element, callback) {
        var id = element.val();

        if (id) {
          ConfiguracaoOrganogramaService.getSeparador(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter = params && params.term;
        var result = ConfiguracaoOrganogramaService.getSeparadores(filter);
        result.then(function (separadores) {
          callback({
            results: separadores,
            more: separadores.hasNext
          });
        });
        return result;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
