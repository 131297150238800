(function () {
    'use strict';
    angular
        .module('core.marcadores')
        .service('core.MarcadoresService', marcadoresService);

    marcadoresService.$inject = [
        '$q',
        'Restangular',
        'ui.components.EventEmitter'
    ];

    function marcadoresService($q,
                         Restangular,
                         EventEmitter) {
        var uri = [
                'tags'
            ],
            service = new EventEmitter();

        _.extend(service, marcadoresService);

        service.get = get;
        service.getList = getList;

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getList(params) {
            params = params || {};
            params.offset = params.offset || 0;
            return Restangular.one(uri[0])
                .get(params);
        }
    }
})();