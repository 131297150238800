(function() {
	'use strict';
	/**
	 * @ngdoc overview
	 * @name contratos-core.fonte-divulgacao
	 * @description
	 * # contratos-core.fonte-divulgacao
	 * Módulo de fontes de divulgação
	 **/
	angular
	  .module('core.fonte-divulgacao', ['core', 'core.commons']);
})();
