(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratacao.tipo-aditivo.service:TipoAditivoModalService
   * @dcontratacaocription Serviço para abrir o cadastro de tipos de aditivos
   * @requircontratacao ModalCadFactory
   **/
  angular
    .module('core.relatorios')
    .service('core.EmitirRelatorioModalService', emitirRelatorioModalService);

  emitirRelatorioModalService.$inject = ['core.AcoesCadastrosModalService'];

  function emitirRelatorioModalService(AcoesCadastrosModalService) {

    var modal = {
      templateUrl: 'emitir-relatorio/emitir-relatorio.html',
      controller: 'core.EmitirRelatorioModalController',
      controllerAs: 'vm',
      params: {
        identificador_cadastro: null
      },
      size: 'sm'
    };

    var service = {
      abrir: abrir
    };

    angular.extend(this, service);

    function abrir(natureza, parametrosRelatorio, opcoesExecucao, isOcultarNotificacoes) {
      var copy = angular.copy(modal);
      copy.params = {
        identificador_cadastro: null,
        parametros: parametrosRelatorio,
        natureza: natureza,
        opcoesExecucao: opcoesExecucao,
        isOcultarNotificacoes: isOcultarNotificacoes
      }
      return AcoesCadastrosModalService.cadastrar(copy);
    }

  }
})();
