(function() {
	'use strict';
/**
	* @ngdoc service
	* @name contratos-core.utils.YesOrNoFilter
	* @description Filtro para imprimir Sim ou Não quando a alternativa for true ou false
	* @example
    * <pre>
    * 	<td>{{convidado.autoConvocacao | yesOrNoFilter }}</td>
    * </pre>

**/
	angular
	  .module('core.commons')
	  .filter('coreYesOrNoFilter', YesOrNoFilter);

	function YesOrNoFilter() {
		return function(b) {
			return b === true ? "Sim" : "Não";
		};
	}
})();
