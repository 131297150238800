(function() {
	'use strict';
	/**
	 * @ngdoc service 
	 * @name contratos-core.estado.service:EstadoModalService
	 * @description Serviço para abrir o cadastro de estados
	 * @requires ModalCadFactory
	 **/
	angular
			.module('core.estado')
			.service('core.EstadoModalService', EstadoModalService);

	EstadoModalService.$inject = ['ui.components.Modal'];

	function EstadoModalService(uiModal) {

		var modal = {
			templateUrl: 'estado-cad/estado-cad.html',
			controller: 'core.EstadoCadController',
			controllerAs: 'estadoCad',
			params: {
				estadoId: null
			}
		};

		var service = {
			cadastrar: cadastrar,
			editar: editar,
			onAdd: onAdd
		};

		angular.extend(this, service);

		function abrir(modal) {
			 return uiModal.open(modal).result;
		}

		function copia(modal) {
			return angular.copy(modal);
		}

        function cadastrar() {
            var m = copia(modal);
            abrir(m);
        }

		function editar(estado) {
			var m = copia(modal);
			m.params = {
				estadoId: estado.id
			};
			abrir(m);
		}

		function onAdd(params){
            var m = copia(modal);
            m.params = {
                estadoId: null,
                params: params
            };
            return uiModal.open(m).result;
        }
	}
})();