(function() {
	'use strict';

	angular
	  .module('core.integracao')
	  .service('core.NaturezaDespesaServicePlanejamento', NaturezaDespesaServicePlanejamento);

	NaturezaDespesaServicePlanejamento.$inject = ['core.PlanejamentoResources'];

	function NaturezaDespesaServicePlanejamento(PlanejamentoResources) {

		this.getList = getList;

		function getList() {
			return PlanejamentoResources.one('configuracoes-naturezas-despesa', 1).all('naturezas-despesa').getList();
		}


	}
})();
