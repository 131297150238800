(function() {
    'use strict';

    angular
            .module('core.configuracao-organograma')
            .service('core.ConfiguracaoOrganogramaCadService', ConfiguracaoOrganogramaCadService);

    ConfiguracaoOrganogramaCadService.$inject = [
        'core.ModalCadFactory'
    ];

    function ConfiguracaoOrganogramaCadService(
            ModalCadFactory
            ) {

        var $private = {};
        var $public = {};

        // Propridades privadas
        $private.modal = _modal;

        // Métodos privados
        $private.abrir = _abrir;
        $private.copiar = _copiar;

        // Métodos publicos
        $public.editar = editar;
        $public.nova = nova;

        _.assign(this, $public);

        var _modal = {
            templateUrl: 'configuracao-organograma-cad/configuracao-organograma-cad.html',
            controller: 'core.ConfiguracaoOrganogramaCadController as cfgOrgCad',
            resolve: {
                id: null
            }
        };

        function _abrir(modal) {
            ModalCadFactory.open(modal);
        }

        function _copiar(object) {
            return _.cloneDeep(object);
        }

        function editar(configuracaoOrganograma) {
            var m = _.assign(_copiar(_modal), {
                resolve: {
                    id: configuracaoOrganograma.id
                }
            });
            _abrir(m);
        }

        function nova() {
            _abrir(_modal);
        }
    }
})();
