(function () {
    'use strict';

    angular.module('core.configuracao-entidade')
            .directive('naturezaJuridicaSelect', naturezaJuridicaSelect);

    naturezaJuridicaSelect.$inject = ['CONFIG_SELECT2'];

    function naturezaJuridicaSelect(CONFIG_SELECT2) {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" bf-required="bfRequired" ng-disabled="ngDisabled" search="vm.search" format-result="vm.formatResult(data)" format-selection="vm.formatSelection(data)"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                bfRequired: '=?',
                ngDisabled: '=?',
                hiddenAddValue: '=?',
                criterionSearch: '=?'
            },
            compile: function (element, attributes) {
                var addValue = attributes.hiddenAddValue === 'true';
                if (!addValue) {
                    element.children('ui-select').attr('add-value', 'vm.onAdd');
                }
                element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
                element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
                element.children('ui-select').attr('maximum-input-length', 280);
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'bfc.$$PermissionsService',
        'core.ConfiguracaoEntidadeService'
    ];

    function Controller($scope, PermissionsService, service, modal) {
        var vm = this;
        vm.search = search;
        vm.formatResult = formatResult;
        vm.formatSelection = formatSelection;
        vm.params = $scope.criterionSearch;

        function search(params) {
            var paramsDefault = '(descricao like "' + params.term + '")';
            params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
            return service.getListNaturezaJuridica(params).then(function (data) {
                return data;
            });
        }

        function formatResult(value) {
            return value.numero + " - " + value.descricao;
        }

        function formatSelection(value) {
            return value.numero + " - " + value.descricao;
        }
    }
})();