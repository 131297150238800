(function() {
	'use strict';

	angular
	  .module('core.commons')
	  .factory('core.ModalCadServiceFactory', ModalCadServiceFactory);

	ModalCadServiceFactory.$inject = ['core.ModalCadFactory'];

	function ModalCadServiceFactory(ModalCadFactory) {

		return {
			create: create
		};

		function create(modalOptions) {
			var objectModal =  {
				_modalOptions: modalOptions,
				create: create,
				edit: edit,
				register: create
			};

			function open(optionsCad, fnCad) {
				var m = objectModal._modalOptions;
				m.resolve = _.extend(m.resolve, {optionsCad: optionsCad});
				m.resolve = _.extend(m.resolve, {fnCad: fnCad});
				ModalCadFactory.open(m);
			}

			function create(optionsCad, fnCad) {
				var resolve = objectModal._modalOptions.resolve;
				for(var propriedade in resolve) {
					resolve[propriedade] = null;
				}
				open(optionsCad, fnCad);
			}

			function edit(params, optionsCad, fnCad) {
				if(params.resolve) {
					objectModal._modalOptions.resolve = params.resolve;
				}
				open(optionsCad, fnCad);
			}

			return objectModal;

		}

	}
})();
