(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.estado:EstadoController
   * @description Controller principal da listagem de estados
   * @requires $scope
   * @requires bfc.dialog.Dialog
   **/

  angular
    .module('core.estado')
    .controller('core.EstadoController', EstadoController);

  EstadoController.$inject = [
    '$rootScope',
    '$q',
    '$scope',
    'core.TrackerFactory',
    'core.EstadoService',
    'core.EstadoModalService',
    'bfc.Notification'
  ];

  function EstadoController($rootScope,
                            $q,
                            $scope,
                            TrackerFactory,
                            EstadoService,
                            EstadoModalService,
                            Notification) {

    var viewModel = this;
    viewModel.service = EstadoService;

    viewModel.search = search;
    viewModel.cadastrar = cadastrar;
    viewModel.editar = editar;
    viewModel.excluir = excluir;
    viewModel.showDescricao = showDescricao;
    viewModel.showSiglaEstado = showSiglaEstado;
    viewModel.showCodigoIbge = showCodigoIbge;
    viewModel.showPais = showPais;

    inicializa();

    function inicializa() {
      inicializaPropriedades();
      inicializaLoadings();
      escutaEventos();
    }

    function inicializaPropriedades() {
      viewModel.estado = [];
      viewModel.list = {};
      viewModel.filter = {};
      viewModel.columns = [
        {
          id: 'nome',
          model: 'nome',
          label: 'Descrição',
          filterable: true,
          alwaysVisible: false
        },
        {
          id: 'unidadeFederativa',
          model: 'unidadeFederativa',
          label: 'Sigla',
          filterable: true,
          alwaysVisible: false
        },
        {
          id: 'codigoIbge',
          model: 'codigoIbge',
          label: 'Código do IBGE',
          filterable: true,
          alwaysVisible: false
        },
        {
          id: 'pais',
          model: 'pais',
          label: 'País',
          filterable: true,
          alwaysVisible: false
        }
      ];

      viewModel.listCustomBuilder = {
        onBeforeBuild: function () {
          return '';
        }
      };

      $scope.$on('filtroRegistroPadrao:refresh', function (eventos, params) {
        viewModel.listCustomBuilder.onBeforeBuild = function(){
            return params.criterio;
        };
    
        viewModel.listControls.search();
      });

      viewModel.listControls = {};

      viewModel.colDescricaoIsVisible = true;
      viewModel.colSiglaEstadoIsVisible = true;
      viewModel.colCodigoIbgeIsVisible = true;
      viewModel.colPaisIsVisible = true;
    }

    function escutaEventos() {
      $scope.$on('estado:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

    $scope.$watch(
      'estado.colDescricaoIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('nome', newValue);
          viewModel.colDescricaoIsVisible = newValue;
        }

      }
    );
    $scope.$watch(
      'estado.colSiglaEstadoIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('unidadeFederativa', newValue);
          viewModel.colSiglaEstadoIsVisible = newValue;
        }
      }
    );
    $scope.$watch(
      'estado.colCodigoIbgeIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('codigoIbge', newValue);
          viewModel.colCodigoIbgeIsVisible = newValue;
        }
      }
    );
    $scope.$watch(
      'estado.colPaisIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('pais', newValue);
          viewModel.colPaisIsVisible = newValue;
        }
      }
    );

    function showDescricao() {
      return viewModel.colDescricaoIsVisible;
    }

    function showSiglaEstado() {
      return viewModel.colSiglaEstadoIsVisible;
    }

    function showCodigoIbge() {
      return viewModel.colCodigoIbgeIsVisible;
    }

    function showPais() {
      return viewModel.colPaisIsVisible;
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init(['estados', 'getList', 'exclusao']);
    }

    function search() {
      viewModel.listControls.search();
    }

    function cadastrar() {
      EstadoModalService.cadastrar();
    }

    function editar(item) {
      EstadoModalService.editar(item);
    }

    function excluir(item) {
      var promise = EstadoService.excluir(item.id);
      return promise.then(function () {
        Notification.publish('Estado excluído com sucesso', 'success');
      }, function error() {
        return promise;
      });
    }

    $scope.setLineHeight = function (h) {
      $scope.lineHeight = h;
    };
  }
})();
