(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.fornecedor.FornecedorSelect
   * @description
   * Serviço para usar o select2 nos fornecedores
   * @requires bfc.Select2Config
   * @requires FornecedorService
   **/

  angular
    .module('core.fornecedor')
    .service('core.FornecedorPessoaSelect', FornecedorPessoaSelect);

  FornecedorPessoaSelect.$inject = [
    'bfc.Select2',
    'core.FornecedorService',
    'CONFIG_SELECT2'
  ];

  function FornecedorPessoaSelect(Select2,
                                  FornecedorService,
                                  CONFIG_SELECT2) {

    angular.extend(this, {
      select2Config: select2Config
    });

    function select2Config(options) {
      var methods = {
        onAdd: onAdd,
        id: options.id ? options.id : id,
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        minimumInputLength: CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('FORNECEDOR'),
        dropdownAutoWidth: false
      };

      function formatResult(fornecedor) {
        return fornecedor.text ? fornecedor.text : fornecedor.pessoa.nome;
      }

      function formatSelection(fornecedor) {
        if (fornecedor.pessoa && fornecedor.pessoa.nome) {
          return fornecedor.pessoa.nome;
        }
        return fornecedor.nome;
      }

      function formatValue(fornecedor) {
        if (fornecedor.nome) {
          fornecedor = {
            nome: fornecedor.nome
          };
          //return fornecedor.nome;
        }

        //return fornecedor.nome ? fornecedor.nome : fornecedor.pessoa;
        return fornecedor;
      }

      function id(fornecedor) {
        if (fornecedor.pessoa) {
          return fornecedor.pessoa.nome ? fornecedor.pessoa.nome : fornecedor.pessoa.id;
        }
        return fornecedor.nome ? fornecedor.nome : fornecedor.id;
      }

      function initSelection(element, callback) {
        var id = element.val();

        if (!isNaN(id)) {
          FornecedorService.getPessoa(id).then(callback);
        } else {
          var fornecedor = {
            nome: element.val()
          };
          callback(fornecedor);
        }
      }

      function result(params, callback) {
        var filter = encodeURI(params.term),
        //var filter = params.term.replace(/['"]+/g, '').replace('%', '').replace('%', ''),
          result = FornecedorService.getPessoasList(filter);

        result.then(function (fornecedores) {
          callback({
            results: fornecedores,
            more: fornecedores.hasNext
          });
        });
        return result;
      }

      function onAdd(value, target) {
        Select2.set(target, value);
        return false;
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
