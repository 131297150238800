(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.bairro
     * @description
     * # contratos-core.bairro
     * Módulo de endereços
     **/
    angular
            .module('core.bairro', ['core', 'core.commons', 'core.endereco', 'core.municipio']);
})();
