(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.distrito.controller:DistritoCadController
     * @description Controller do cadastro de distritos
     **/
    angular
        .module('core.distrito')
        .controller('core.DistritoCadController', DistritoCadController);

    DistritoCadController.$inject = [
        '$injector',
        '$q',
        '$rootScope',
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'core.TrackerFactory',
        'core.DistritoService',
        'core.MunicipioService'
    ];

    function DistritoCadController($injector,
                                   $q,
                                   $rootScope,
                                   $scope,
                                   $modalInstance,
                                   Notification,
                                   TrackerFactory,
                                   DistritoService,
                                   MunicipioService) {

        var viewModel = this;
        var Select2Factory = $injector.get('core.Select2Factory');
        var DistritoSugestoesSelect = $injector.get('core.DistritoSugestoesSelect');
        var MunicipioModalService = $injector.get('core.MunicipioModalService');
        var CONFIG_SELECT2 = $injector.get('CONFIG_SELECT2');

        viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;
        viewModel.argumentos = DistritoCadController.$inject;
        viewModel.salvar = salvar;
        viewModel.excluir = excluir;
        viewModel.desabilitaSalvar = desabilitaSalvar;
        viewModel.getAcao = getAcao;
        viewModel.temDistrito = temDistrito;

        viewModel.buscarMunicipios = buscarMunicipios;
        viewModel.formataResultado = formataResultado;
        viewModel.adicionarNovoMunicipio = adicionarNovoMunicipio;

        viewModel.adicionando = adicionando;
        viewModel.editando = editando;
        viewModel.buscaDistrito = buscaDistrito;

        inicializaLoadings();
        inicializar();

        function inicializar() {
            inicializarDistrito();
            initSelect2();
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'iniciaDistrito',
                'salvar',
                'salvarAdicionar',
                'excluir'
            ]);
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([{
                element: 'nome',
                select2: DistritoSugestoesSelect
            }]);
        }

        function buscaDistrito() {
            viewModel.distrito = _.merge(viewModel.distrito, viewModel.template);
        }

        function buscarMunicipios(params) {
            params.filter = '(nome like "' + params.term + '")';
            return MunicipioService.getList(params).then(function (data) {
                return data;
            });
        }

        function formataResultado(value) {
            return value.nome;
        }

        function adicionarNovoMunicipio(nomeMunicipio) {
            var param = {
                nome: nomeMunicipio
            };
            return MunicipioModalService.onAdd(param);
        }

        function inicializarDistrito() {
            var promise;
            if ($modalInstance.params.distritoId) {
                promise = DistritoService.get($modalInstance.params.distritoId);
                promise.then(function (distrito) {
                    viewModel.distrito = distrito;
                });
            } else {
                promise = DistritoService.getDefault();
                promise.then(function (distritoDefault) {
                    viewModel.distrito = distritoDefault;
                    viewModel.distrito.definidaUsuario = true;

                    if ($modalInstance.params.params) {
                        viewModel.desabilitaCampo = !!$modalInstance.params.params.params;
                        viewModel.form.$setDirty();
                        viewModel.distrito.nome = $modalInstance.params.params.nome;
                        if ($modalInstance.params.params.params) {
                            viewModel.distrito.municipio = $modalInstance.params.params.params.municipio;
                        }
                    }
                });
            }
            viewModel.loadings.iniciaDistrito.addPromise(promise);

        }

        function excluir() {
            $rootScope.$broadcast('distrito:actionRemove', {id: viewModel.distrito.id});
            fechar();
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;
            tracker.addPromise(DistritoService.salvar(viewModel.distrito).then(function (distrito) {
                if (continuar) {
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                        viewModel.form.$setUntouched();
                    }
                    $modalInstance.params.distritoId = undefined;
                    inicializar();
                } else {
                    fechar(distrito);
                }
                showNotificationSave();
            }));
        }

        function showNotificationSave() {
            if (temDistrito()) {
                Notification.publish('Distrito alterado com sucesso', 'success');
            } else {
                Notification.publish('Distrito adicionado com sucesso', 'success');
            }
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
        }

        function fechar(distrito) {
            $modalInstance.close(distrito);
        }

        function getAcao() {
            return $modalInstance.params.distritoId ? "Editando" : "Adicionando";
        }

        function temDistrito() {
            return $modalInstance.params.distritoId || (viewModel.distrito && viewModel.distrito.id);
        }

        function adicionando() {
            return !editando();
        }

        function editando() {
            return Boolean(viewModel.distrito && !viewModel.distrito.id) ? ($modalInstance.params.params || $modalInstance.params.distritoId) : viewModel.distrito && viewModel.distrito.id;
        }
    }
})();
