(function () {
	'use strict';
	/**
	 * @ngdoc overview
	 * @name contratos-core.bairro
	 * @description
	 * # ccontratos-core.bairro
	 * Módulo de bairros
	 */
	angular
			.module('core.bairro')
			.factory('core.BairroService', BairroService);

	BairroService.$inject = [
		'$q',
		'Restangular',
		'core.UtilsService',
		'$filter',
		'ui.components.EventEmitter',
		'bfc.Notification'
	];

	function BairroService(
			$q,
			Restangular,
			UtilsService,
			$filter,
			EventEmitter,
			Notification) {

		var meta;
		var uri = ['bairros','bairrosmunicipio'];

		var orderBy = $filter('orderBy');

		var service = new EventEmitter();

		_.extend(service, BairroService);

		service.get = get;
		service.getList = getList;
		service.getDefault = getDefault;
		service.getTypes = getTypes;
		service.salvar = salvar;
		service.excluir = excluir;
		service.getBairrosMunicipioList = getBairrosMunicipioList;
		service.mensagemExclusao = mensagemExclusao;
		service.getSugestoes = getSugestoes;

		return service;

		function get(id) {
			return Restangular.one(uri[0], id).get();
		}

		function getList(params) {
			params = params || {};

			params.offset = params.offset || 0;

			return Restangular.one(uri[0]).get({
				offset: params.offset,
				limit: params.limit,
				filter: params.filter,
				sort: params.sort
			}).then(function (data) {
				if (params.offset > 0 && data.total <= params.offset) {
					params.offset = 0;
					data = getList(params);
				}
				return data;
			});
		}

		function getSugestoes(params) {
			params = params || {};

			return Restangular.one(uri[0]+'/sugestoes').get({
				filter: params.filter
			});
		}

		function getBairrosMunicipioList(params) {
			return Restangular.one(uri[0],params.id).one(uri[1]).get({
				filter: params.filter
			}).then(function (data) {
				return data;
			});
		}


		function getDefault() {
			return getMetadata().then(function (data) {
				return angular.copy(data.representation.default);
			});
		}

		function getMetadata() {
			var deferred = $q.defer();
			if (!meta) {
				Restangular.all(uri[0]).options().then(function (data) {
					meta = data;
					deferred.resolve(meta);
				}, function (data) {
					deferred.reject(data);
				});
			} else {
				deferred.resolve(meta);
			}
			return deferred.promise;
		}

		function getTypes() {
			return getMetadata().then(function (data) {
				return angular.copy(data.types);
			});
		}

		function salvar(bairro) {
			var rest = Restangular.all(uri[0]);
			if (bairro.id) {
				return rest.customPUT(bairro, bairro.id).then(emitDirty);
			} else {
				return rest.post(bairro).then(emitDirty);
			}
		}

		function excluir(id) {
			return Restangular.one(uri[0], id).remove().then(emitDirty);
		}

		function emitDirty(data) {
			service.emit('dirty');
			return data;
		}

		function mensagemExclusao() {
			Notification.publish('Bairro excluído com sucesso', 'success');
		}
	}
})();
