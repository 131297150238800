(function () {
  'use strict';
  /**
   * @ngdoc controller
   * @name contratos-core.veiculos-publicacao.controller:VeiculosPublicacaoCadController
   * @description Controller responsável pelo cadastro de veiculos publicacao
   **/
  angular
    .module('core.veiculos-publicacao')
    .controller('core.VeiculosPublicacaoCadController', VeiculosPublicacaoCadController);

  VeiculosPublicacaoCadController.$inject = [
    '$rootScope',
    '$q',
    '$scope',
    'bfc.Focus',
    '$modalTracker',
    '$modalInstance',
    'core.TrackerFactory',
    'UserAccessContext',
    'VeiculosPublicacaoId',
    'core.VeiculosPublicacaoService',
    'core.Select2Factory',
    'core.FornecedorSelect',
    'core.FornecedorService',
    'core.ModalCadFactory',
    'EntidadesSelect',
    'bfc.Notification',
    'fnCad',
    'CONTRATOS_CORE',
    'core.ProcessoAdministrativoServiceCompras',
    'CONFIG_SELECT2',
    'CamposAdicionaisService',
    'CPA_CONSTANTS'
  ];

  function VeiculosPublicacaoCadController($rootScope,
                                           $q,
                                           $scope,
                                           Focus,
                                           $modalTracker,
                                           $modalInstance,
                                           TrackerFactory,
                                           UserAccessContext,
                                           VeiculosPublicacaoId,
                                           VeiculosPublicacaoService,
                                           Select2Factory,
                                           FornecedorSelect,
                                           FornecedorService,
                                           ModalCadFactory,
                                           EntidadesSelect,
                                           Notification,
                                           fnCad,
                                           CONTRATOS_CORE,
                                           ProcessoAdministrativoServiceCompras,
                                           CONFIG_SELECT2,
                                           CamposAdicionaisService,
                                           CPA_CONSTANTS) {
    var viewModel = this;

    viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

    var veiculoPublicacaoEnum = {
      EMPRESA_CONTRATADA: {
        "id": "EMPRESA_CONTRATADA",
        "key": "EMPRESA_CONTRATADA",
        "value": "EMPRESA_CONTRATADA",
        "description": "Empresa contratada (Órgão oficial)"
      },
      ORGAO_OFICIAL_PROPRIO: {
        "id": "ORGAO_OFICIAL_PROPRIO",
        "key": "ORGAO_OFICIAL_PROPRIO",
        "value": "ORGAO_OFICIAL_PROPRIO",
        "description": "Órgão oficial próprio"
      },
      ORGAO_OFICIAL_ELETRONICO: {
        "id": "ORGAO_OFICIAL_ELETRONICO",
        "key": "ORGAO_OFICIAL_ELETRONICO",
        "value": "ORGAO_OFICIAL_ELETRONICO",
        "description": "Órgão oficial eletrônico"
      },
      NAO_OFICIAL: {
        "id": "NAO_OFICIAL",
        "key": "NAO_OFICIAL",
        "value": "NAO_OFICIAL",
        "description": "Não oficial"
      }
    };
    veiculoPublicacaoEnum.values = [
      veiculoPublicacaoEnum.EMPRESA_CONTRATADA,
      veiculoPublicacaoEnum.ORGAO_OFICIAL_PROPRIO,
      veiculoPublicacaoEnum.ORGAO_OFICIAL_ELETRONICO,
      veiculoPublicacaoEnum.NAO_OFICIAL
    ];
    viewModel.enumOptions = $q.when(veiculoPublicacaoEnum);

    //Usado para teste Unitário
    viewModel.argumentos = VeiculosPublicacaoCadController.$inject;

    viewModel.situacaoCadastro = situacaoCadastro;
    viewModel.limpaCampos = limpaCampos;
    viewModel.salvar = salvar;
    viewModel.excluir = excluir;
    viewModel.formataResultadoProcesso = formataResultadoProcesso;
    viewModel.temVeiculoPublicacao = temVeiculoPublicacao;
    viewModel.desabilitaSalvar = desabilitaSalvar;
    viewModel.apagarProcesso = apagarProcesso;
    viewModel.buscarProcessoAdministrativo = buscarProcessoAdministrativo;

    inicializaLoadings();
    inicializa();

    function inicializa() {
      inicializaSelect2();
      inicializaModal();
      inicializaCamposAdicionais();
      viewModel.visivel = 1;
    }

    $scope.$watch(
      'veiculosPublicacaoCad.veiculoPublicacao.contratadoLicitacao',
      function handleFooChange(newValue, oldValue) {
        if (!newValue && viewModel.veiculoPublicacao) {
          viewModel.veiculoPublicacao.processoAdmBO = undefined;
          viewModel.veiculoPublicacao.entidadeGestora = undefined;
          apagarProcesso();
          viewModel.showEntidadeGestora = false;
        } else if (newValue && viewModel.veiculoPublicacao) {
          if (!viewModel.veiculoPublicacao.entidadeGestora) {
            viewModel.veiculoPublicacao.entidadeGestora = {
              id: UserAccessContext.getUserAccess().values.entity
            };
            viewModel.showEntidadeGestora = true;
          }

        }
      }
    );

    function buscarProcessoAdministrativo(params) {
      params.filter = '(objeto like "' + params.term + '")';
      if (!viewModel.veiculoPublicacao.entidadeGestora.id) {
        return $q.reject();
      }
      return ProcessoAdministrativoServiceCompras.getList(params).then(function (data) {

        var newData = {
          content: []
        };
        for (var i = 0; i < data.content.length; i++) {

          var pa = {
            id: '' + data.content[i].parametroExercicio.exercicio + data.content[i].numeroProcesso,
            ano: data.content[i].parametroExercicio.exercicio,
            numero: data.content[i].numeroProcesso
          };
          newData.content.push(pa);
        }
        return newData;
      });
    }


    function inicializaSelect2() {
      viewModel.select2 = Select2Factory.init([
        {
          element: 'fornecedor',
          select2: FornecedorSelect,
          options: {
            result: _.debounce(function result(params, callback) {
              var filter;
              filter = 'nome like "' + encodeURI(params.term) + '"';
              filter += ' and (situacao="A")';
              var result = FornecedorService.getFornecedoresJuridicosList(filter);
              result.then(function (fornecedor) {
                callback({
                  results: fornecedor,
                  more: fornecedor.hasNext
                });
              });
              return result;
            }, CONFIG_SELECT2.DELAY),
            onAdd: function (val, target) {
              ModalCadFactory.open({
                templateUrl: 'fornecedor-cad/fornecedor-cad.html',
                controller: 'core.FornecedorCadController as fornecedorCad',
                resolve: {
                  fornecedorId: null
                },
                optionsCad: {
                  nome: val
                },
                selectElem: target
              });
              return false;
            }
          }
        },
        {
          element: 'entidadeGestora',
          select2: EntidadesSelect,
          options: {
            maximumInputLength: 60
          }
        }
      ]);
    }

    function inicializaCamposAdicionais() {
      var promise = CamposAdicionaisService.get(CPA_CONSTANTS.Cadastros.VEICULO_PUBLICACAO, VeiculosPublicacaoId);
      promise.then(function(response) {
          viewModel.campoAdicional = response.data;
      });
      return promise;
    }   

    function formataResultadoProcesso(value) {
      return value.numero + '/' + value.ano;
    }


    function inicializaVeiculosPublicacao() {
      var promise;
      if (VeiculosPublicacaoId) {
        promise = VeiculosPublicacaoService.get(VeiculosPublicacaoId);
        promise.then(function (veiculoPublicacao) {
          viewModel.veiculoPublicacao = veiculoPublicacao;
          viewModel.desativarRegistro = (veiculoPublicacao.dataInativacao || veiculoPublicacao.motivoInativacao) ? true : false;
          viewModel.form.$setPristine();
        });
      } else {
        promise = VeiculosPublicacaoService.getDefault();
        promise.then(function (veiculoPublicacao) {
          viewModel.veiculoPublicacao = veiculoPublicacao;
        });
      }
      viewModel.loadings.veiculosPublicacao.addPromise(promise);
    }

    function inicializaModal() {
      var promise = $q.all([
        inicializaTypes(),
        inicializaVeiculosPublicacao()
      ]);
      $modalTracker.addPromise(promise);
    }

    function inicializaTypes() {
      viewModel.veiculoPublicacao = {};
      VeiculosPublicacaoService.getTypes().then(function (data) {
        viewModel.veiculosPublicacaoProperties = data.VeiculoPublicacaoBO;
      });
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init([
        'veiculosPublicacao',
        'salvar',
        'salvarAdicionar',
        'excluir'
      ]);
    }

    function situacaoCadastro() {
      return temVeiculoPublicacao() ? 'Editando' : 'Adicionando';
    }

    function temVeiculoPublicacao() {
      return viewModel.veiculoPublicacao && viewModel.veiculoPublicacao.id ? VeiculosPublicacaoId : viewModel.veiculoPublicacao.id;
    }

    function limpaCampos() {
      viewModel.veiculoPublicacao.dataInativacao = undefined;
      viewModel.veiculoPublicacao.motivoInativacao = undefined;
      viewModel.veiculoPublicacao.ativo = true;
    }

    function fechar() {
      $modalInstance.close();
    }

    function salvar(continuar) {
      var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;

      if (viewModel.campoAdicional)
        viewModel.veiculoPublicacao.campoAdicional = viewModel.campoAdicional;

      tracker.addPromise(
        VeiculosPublicacaoService.save(viewModel.veiculoPublicacao).then(function (veiculoPublicacao) {
          if (angular.isFunction(fnCad)) {
            fnCad(veiculoPublicacao);
          }
          if (continuar) {
            if (viewModel.form) {
              viewModel.form.$setPristine();
              viewModel.form.$setUntouched();
            }
            VeiculosPublicacaoId = undefined;
            inicializa();
            Focus('veiculosPublicacaoCadFornecedor');
          } else {
            fechar();
          }
          showNotificationSave();
        }));
    }

    function showNotificationSave() {
      if (temVeiculoPublicacao()) {
        Notification.publish('Veículo de publicação alterado com sucesso', 'success');
      } else {
        Notification.publish('Veículo de publicação adicionado com sucesso', 'success');
      }
    }

    function excluir() {
      $rootScope.$broadcast('veiculoPublicacao:actionRemove', { id: viewModel.veiculoPublicacao.id });
      fechar();
    }

    /*function desabilitaSalvar() {
      return (viewModel.form.$dirty && viewModel.form.$invalid)
              || viewModel.form.$pristine;
    }*/
    function desabilitaSalvar() {
      return (viewModel.form.$dirty && viewModel.form.$invalid) 
            || (viewModel.form.$pristine && !temVeiculoPublicacao())
            || !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
    }    

    function apagarProcesso() {
      viewModel.veiculoPublicacao.processoAdministrativo = null;
    }
  }
})();
