(function() {
	'use strict';
	/**
	 * @ngdoc overview
	 * @name contratos-core.pessoa
	 * @description
	 * #contratos-core.pessoa
	 * Módulo de pessoas
	 **/
	angular
	  .module('core.pessoa', ['core', 'core.commons']);
})();
