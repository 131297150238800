(function () {
    'use strict';

    angular
    .module('core.configuracao-organograma')
    .directive('coreConfiguracaoOrganogramaSelect', configuracaoOrganogramaSelect);

    configuracaoOrganogramaSelect.$inject = [
        'CONFIG_SELECT2'
    ];

    function configuracaoOrganogramaSelect(CONFIG_SELECT2) {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" bf-required="bfRequired" ng-disabled="ngDisabled" search="vm.search"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                bfRequired: '=?',
                ngDisabled: '=?',
                hiddenAddValue: '=?',
                criterionSearch: '=?',
                multiple: '=?',
                buscarPorExercicio: '=?'
            },
            compile: function (element, attributes) {
                if (attributes.multiple === 'true') {
                    element.children('ui-select').attr('multiple', 'true');
                }

                if (attributes.buscarPorExercicio === 'true') {
                    element.children('ui-select').attr('buscarPorExercicio', 'true');
                }

                element.children('ui-select')
                        .attr('format-result', 'vm.formatResult(data)');
                element.children('ui-select')
                        .attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
                element.children('ui-select')
                        .attr('debounce-delay', CONFIG_SELECT2.DELAY);
                element.children('ui-select')
                        .attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('CONFIGURACAO_ORGANOGRAMA'));
            },
            controller: Controller,
            controllerAs: 'vm'
        };
    }

    Controller.$inject = [
        '$scope',
        'core.ConfiguracaoOrganogramaService',
        'core.ParametrosService'
    ];

    function Controller($scope, service, ParametrosService) {
        var vm = this;
        vm.search = search;
        vm.params = $scope.criterionSearch;
        vm.formatResult = formatResult;

        vm.buscarPorExercicio = $scope.buscarPorExercicio;

        var promise;

        if(vm.buscarPorExercicio && !$scope.ngModel){
            promise = ParametrosService.getConfiguracaoOrganograma();
            promise.then(function (data) {
               var params = {};

                params.offset = 0;
                params.limit  = 20;
                params.filter = ' id in('+ data.id + ')';
                service.getListPage(params).then(function (data) {
                    $scope.ngModel = data.content;
                    return data;
                });
            });
        }

        function search(params) {

            var paramsDefault = '(descricao like "' + params.term + '")';
            params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;            
            return service.getListPage(params).then(function (data) {
                return data;
            });
        }

        function formatResult(data) {
            return data.descricao;
        }
    }
   
})();  