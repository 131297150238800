(function () {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.endereco
     * @description
     * # contratos-core.endereco
     * Módulo de endereços
     **/
    angular
        .module('core.endereco', [
            'core',
            'core.commons'
        ]);
})();
