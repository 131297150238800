(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name contratos.natureza-texto-juridico-ato.controller:NaturezaTextoJuridicoAtoCadController
     *
     * @description
     * Controller responsável pelo cadastro de naturezas do texto jurídico dos atos
     *
     * @requires $rootScope
     * @requires $q
     * @requires promiseTracker
     * @requires $modalInstance
     * @requires $modalTracker
     * @requires bfc.FocusCtrl
     **/
    angular
            .module('core.natureza-texto-juridico-ato')
            .controller('core.NaturezaTextoJuridicoAtoCadController', NaturezaTextoJuridicoAtoCadController);

    NaturezaTextoJuridicoAtoCadController.$inject = [
        '$rootScope',
        '$q',
        '$modalInstance',
        '$modalTracker',
        'core.TrackerFactory',
        'bfc.FocusCtrl',
        'bfc.Notification',
        'core.NaturezaTextoJuridicoAtoService',
        'core.Select2Factory',
        'core.NaturezaTextoJuridicoSugestoesSelect'
    ];

    function NaturezaTextoJuridicoAtoCadController(
            $rootScope,
            $q,
            $modalInstance,
            $modalTracker,
            TrackerFactory,
            Focus,
            Notification,
            NaturezaTextoJuridicoAtoService,
            Select2Factory,
            NaturezaTextoJuridicoSugestoesSelect) {
        var viewModel = this;

        viewModel.cancelar = cancelar;
        viewModel.estaAdicionando = estaAdicionando;
        viewModel.salvar = salvar;
        viewModel.existeNaturezaTextoJuridicoAto = existeNaturezaTextoJuridicoAto;
        viewModel.excluir = excluir;
        viewModel.formataResultado = formataResultado;

        viewModel.adicionando = adicionando;
        viewModel.editando = editando;
        viewModel.buscaNatureza = buscaNatureza;

      inicializaLoadings();
        inicializar();

        function inicializar() {
            viewModel.naturezaTextoJuridico = {};
            inicializaNaturezaTextoJuridicoAto();
            initSelect2();
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([{
                element: 'descricao',
                select2: NaturezaTextoJuridicoSugestoesSelect
            }]);
        }

        function inicializaNaturezaTextoJuridicoAto() {
            var promise;
            if ($modalInstance.params.identificador_cadastro) {
                promise = NaturezaTextoJuridicoAtoService.get($modalInstance.params.identificador_cadastro);
                promise.then(function (novaNaturezaTextoJuridicoAto) {
                    viewModel.naturezaTextoJuridico = novaNaturezaTextoJuridicoAto.plain();
                });
            } else {
                promise = NaturezaTextoJuridicoAtoService.getDefault();
                promise.then(function (novaNaturezaTextoJuridicoAto) {
                    viewModel.naturezaTextoJuridico = novaNaturezaTextoJuridicoAto;
                    if ($modalInstance.params.params) {
                        viewModel.naturezaTextoJuridico.descricao = $modalInstance.params.params.descricao;
                        viewModel.form.$setDirty();
                    }
                });
            }
            viewModel.loadings.naturezaTextoJuridico.addPromise(promise);
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'salvar',
                'salvarAdicionar',
                'excluir',
                'naturezaTextoJuridico'
            ]);
        }

        function buscaNatureza() {
            viewModel.naturezaTextoJuridico = viewModel.template;
        }

        function cancelar(naturezaTextoJuridico) {
            $modalInstance.close(naturezaTextoJuridico);
        }

        function estaAdicionando() {
            return temNaturezaTextoJuridicoAto() ? 'Editando' : 'Adicionando';
        }

        function temNaturezaTextoJuridicoAto() {
            return (viewModel.naturezaTextoJuridico.id === undefined) ? $modalInstance.params.identificador_cadastro : viewModel.naturezaTextoJuridico.id;
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;
            tracker.addPromise(
                    NaturezaTextoJuridicoAtoService.save(viewModel.naturezaTextoJuridico).then(function (naturezaTextoJuridico) {
                if (continuar) {
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                      viewModel.form.$setUntouched();
                    }
                    $modalInstance.params.identificador_cadastro = null;
                    inicializaNaturezaTextoJuridicoAto();
                    Focus.set('naturezaTextoJuridicoCadDescricao');
                } else {
                    cancelar(naturezaTextoJuridico);
                }
                var mensagem = $modalInstance.params.identificador_cadastro ? 'alterada' : 'adicionada';
                Notification.publish('Natureza do texto jurídico ' + mensagem + ' com sucesso', 'success');
            })
                    );
        }

        function excluir() {
          $rootScope.$broadcast('naturezaTexto:actionRemove', { id: viewModel.naturezaTextoJuridico.id });
          cancelar();
        }

        function existeNaturezaTextoJuridicoAto() {
            return Boolean(temNaturezaTextoJuridicoAto());
        }

        function formataResultado(value) {
            return value.descricao;
        }

        function adicionando() {
            return !editando();
        }

        function editando() {
            return (viewModel.naturezaTextoJuridico && viewModel.naturezaTextoJuridico.id === undefined) ? $modalInstance.params.identificador_cadastro : viewModel.naturezaTextoJuridico && viewModel.naturezaTextoJuridico.id;
        }
    }
})();
