(function () {
  'use strict';

  angular.module('core.relatorios')
    .directive('coreRelatoriosSelect', relatoriosSelectDirective);

  relatoriosSelectDirective.$inject = ['CONFIG_SELECT2'];

  function relatoriosSelectDirective(CONFIG_SELECT2) {
    return {
      restrict: 'E',
      template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search" format-result="vm.formatResult(data)"></ui-select>',
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=?',
        criterionSearch: '=?'
      },
      compile: function (element, attributes) {
        element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
        element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
        element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT());
      },
      controller: controller,
      controllerAs: 'vm'
    };
  }

  controller.$inject = [
    '$scope',
    'core.RelatoriosService'
  ];

  function controller($scope, service) {
    var vm = this;
    vm.search = search;
    vm.formatResult = formatResult;
    vm.params = $scope.criterionSearch;

    function search(params) {
      var paramsDefault = '(titulo like "' + params.term + '")';
      params.filter = $scope.criterionSearch ? vm.params({termo: decodeURI(params.term)}) : paramsDefault;
      return service.getList(params).then(function (data) {
        return data;
      });
    }

    function formatResult(value) {
      return value.titulo;
    }
  }
})();
