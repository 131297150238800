(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.organograma.OrganogramaSelect
   * @description
   * Serviço do select2 para o organograma
   * @requires bfc.Select2Config
   * @requires ParametrosService
   * @requires OrganogramaService
   **/

  angular
    .module('core.organograma')
    .service('core.OrganogramaSelect', OrganogramaSelect);

  OrganogramaSelect.$inject = [
    'bfc.Select2',
    'core.ParametrosService',
    'core.OrganogramaService',
    'CONFIG_SELECT2'
  ];

  function OrganogramaSelect(Select2,
                             ParametrosService,
                             OrganogramaService,
                             CONFIG_SELECT2) {
    this.select2Config = select2Config;

    function select2Config(options) {
      var metodos = {
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        minimumInputLength : CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT('ORGANOGRAMA'),
        dropdownAutoWidth: false,
        dropdownCssClass: 'select2-spreadsheet'
      };

      function formatResult(organograma) {
        return getDescricaoExibicao(organograma);
      }

      function formatSelection(organograma) {
        return getDescricaoExibicao(organograma);
      }

      function formatValue(organograma) {
        return organograma.id;
      }

      function initSelection(element, callback) {
        var id = $(element).val();

        if (id) {
          ParametrosService.getConfiguracaoOrganograma().then(function (configuracaoOrganograma) {
            OrganogramaService.get(configuracaoOrganograma.id, id).then(callback);
          });
        }
      }

      function result(params, callback) {
        var filter = 'descricao like "' + encodeURI(params.term) + '"';
        ParametrosService.getConfiguracaoOrganograma().then(function (configuracaoOrganograma) {
          var result = OrganogramaService.getList(configuracaoOrganograma.id, filter);
          result.then(function (organograma) {
            callback({
              results: organograma,
              more: organograma.hasNext
            });
          });
          return result;
        });
      }

      function getDescricaoExibicao(organograma) {
        if (organograma.text) {
          return organograma.text;
        }
        if (organograma) {
          return organograma.numeroFormatado + ' - ' + organograma.descricao;
        }
      }

      return Select2.create(angular.extend(metodos, options));
    }
  }
})();
