(function () {
	'use strict';
	/**
	 * @ngdoc controller
	 * @name contratos-core.municipio.controller:MunicipioCadController
	 * @description Controller do cadastro de municípios
	 **/
	angular
			.module('core.municipio')
			.controller('core.MunicipioCadController', MunicipioCadController);

	MunicipioCadController.$inject = [
		'$q',
		'$rootScope',
		'$scope',
		'$modalInstance',
		'bfc.Notification',
		'core.TrackerFactory',
		'core.MunicipioService',
		'core.EstadoService',
		'core.EstadoModalService',
		'CONFIG_SELECT2',
		'core.Select2Factory',
		'core.MunicipiosSugestoesSelect'
	];

	function MunicipioCadController($q,
			$rootScope,
			$scope,
			$modalInstance,
			Notification,
			TrackerFactory,
			MunicipioService,
			EstadoService,
			EstadoModalService,
			CONFIG_SELECT2,
			Select2Factory,
			MunicipiosSugestoesSelect) {

		var viewModel = this;

		viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

		viewModel.argumentos = MunicipioCadController.$inject;
		viewModel.salvar = salvar;
		viewModel.excluir = excluir;
		viewModel.desabilitaSalvar = desabilitaSalvar;
		viewModel.getAcao = getAcao;
		viewModel.temMunicipio = temMunicipio;

		viewModel.buscarEstados = buscarEstados;
		viewModel.formataResultado = formataResultado;
		viewModel.adicionarNovoEstado = adicionarNovoEstado;
		viewModel.buscarMunicipios = buscarMunicipios;
		viewModel.editando = editando;
		viewModel.adicionando = adicionando;

		inicializaLoadings();
		inicializar();

		function inicializar() {
			inicializarMunicipio();
			initSelect2();
		}

		function inicializaLoadings() {
			viewModel.loadings = TrackerFactory.init([
				'iniciaMunicipio',
				'salvar',
				'salvarAdicionar',
				'excluir'
			]);
		}

		function initSelect2() {
			viewModel.select2 = Select2Factory.init([{
				element: 'nome',
				select2: MunicipiosSugestoesSelect
			}]);
		}

		function buscarMunicipios() {
			viewModel.municipio = _.merge(viewModel.municipio, viewModel.template);
			viewModel.municipio.id = null;
		}

		function buscarEstados(params) {
			params.filter = '(nome like "' + params.term + '")';
			return EstadoService.getList(params).then(function (data) {
				return data;
			});
		}

		function formataResultado(value) {
			return value.nome;
		}

		function adicionarNovoEstado(nomeEstado) {
			var param = {
				nome: nomeEstado
			};
			return EstadoModalService.onAdd(param);
		}

		function inicializarMunicipio() {
			var promise;
			if ($modalInstance.params.municipioId) {
				promise = MunicipioService.get($modalInstance.params.municipioId);
				promise.then(function (municipio) {
					viewModel.municipio = municipio;
				});
			} else {
				promise = MunicipioService.getDefault();
				promise.then(function (municipioDefault) {
					viewModel.municipio = municipioDefault;
					viewModel.municipio.definidaUsuario = true;

					if ($modalInstance.params.params) {
						viewModel.municipio.nome = $modalInstance.params.params.nome;
					}
				});
			}
			viewModel.loadings.iniciaMunicipio.addPromise(promise);

		}

		function excluir() {
		  $rootScope.$broadcast('municipio:actionRemove', { id: viewModel.municipio.id });
		  fechar();
		}

		function salvar(continuar) {
			var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;
			tracker.addPromise(MunicipioService.salvar(viewModel.municipio).then(function (municipio) {
				if (continuar) {
					if (viewModel.form) {
						viewModel.form.$setPristine();
						viewModel.form.$setUntouched();
					}
					$modalInstance.params.municipioId = undefined;
					inicializar();
				} else {
					fechar(municipio);
				}
				showNotificationSave();
			}));
		}

		function showNotificationSave() {
			if (temMunicipio()) {
				Notification.publish('Município alterado com sucesso', 'success');
			} else {
				Notification.publish('Município adicionado com sucesso', 'success');
			}
		}

		function desabilitaSalvar() {
			//return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
			if (viewModel.form.$invalid) return true;
			if (!viewModel.municipio.nome || !viewModel.municipio.estado) return true;
			return false;
		}

		function fechar(municipio) {
			$modalInstance.close(municipio);
		}

		function getAcao() {
			return $modalInstance.params.municipioId ? "Editando" : "Adicionando";
		}

		function temMunicipio() {
			return $modalInstance.params.municipioId || (viewModel.municipio && viewModel.municipio.id);
		}

		function adicionando() {
            return !editando();
        }

        function editando() {
          return (viewModel.municipio && viewModel.municipio.id === undefined) ? ($modalInstance.params.params || $modalInstance.params.municipioId) : viewModel.municipio && viewModel.municipio.id;
        }
	}
})();
