(function () {
  'use strict';

  angular.module('core.natureza-texto-juridico-ato')
    .directive('coreNaturezaTextoJuridicoAtoSelect', naturezaTextoJuridicoAtoSelectDirective);

  naturezaTextoJuridicoAtoSelectDirective.$inject = ['CONFIG_SELECT2'];

  function naturezaTextoJuridicoAtoSelectDirective(CONFIG_SELECT2) {
    return {
      restrict: 'E',
      template: '<ui-select ng-model="ngModel" ng-required="ngRequired" search="vm.search"></ui-select>',
      scope: {
        ngModel: '=ngModel',
        ngRequired: '=?',
        hiddenAddValue: '=?',
        criterionSearch: '=?'
      },
      compile: function (element, attributes) {
        var addValue = attributes.hiddenAddValue === 'true';
        if (!addValue) {
          element.children('ui-select').attr('add-value', 'vm.onAdd');
        }
        element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
        element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
        element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('NATUREZA_TEXTO_JURIDICO_ATO'));
      },
      controller: Controller,
      controllerAs: 'vm'
    };
  }

  Controller.$inject = [
    '$scope',
    'bfc.$$PermissionsService',
    'core.NaturezaTextoJuridicoAtoService',
    'core.NaturezaTextoJuridicoAtoModalService'
  ];

  function Controller($scope, PermissionsService, service, modal) {
    var vm = this;
    vm.search = search;
    vm.onAdd = !PermissionsService.isRevokedOperation('NaturezaTextoJuridicoAtoPageMapping', 'criar') && onAdd;
    vm.params = $scope.criterionSearch;

    function search(params) {
      var paramsDefault = '(descricao like "' + params.term + '")';
      params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
      return service.getList(params).then(function (data) {
        return data;
      });
    }

    function onAdd(descricao) {
      var param = {
        descricao: descricao
      };
      return modal.onAdd(param);
    }
  }
})();
