(function () {
  'use strict';
  /**
   * @ngdoc service
   * @name contratos-core.fornecedor.UfSelect
   * @description
   * Serviço para usar o select2 nas ufs
   * @requires bfc.Select2Config
   * @requires UfService
   **/

  angular
    .module('core.fornecedor')
    .service('core.UfSelect', UfSelect);

  UfSelect.$inject = [
    'bfc.Select2',
    'core.UfService',
    'CONFIG_SELECT2'
  ];

  function UfSelect(Select2,
                    UfService,
                    CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var metodos = {
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        id: id,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT(),
        dropdownAutoWidth: false
      };

      function id(uf) {
        return uf.value;
      }

      function formatResult(uf) {
        return getDescricaoExibicao(uf);
      }

      function formatSelection(uf) {
        return getDescricaoExibicao(uf);
      }

      function formatValue(uf) {
        return uf.value;
      }

      function initSelection(element, callback) {
        var id = $(element).val();
        if (id) {
          UfService.getUf(id).then(callback);
        }
      }

      function result(params, callback) {
        var filter = params && params.term;

        var result = UfService.getUfs(filter);
        result.then(function (ufs) {
          callback({
            results: ufs,
            more: ufs.hasNext
          });
        });
        return result;
      }

      function getDescricaoExibicao(uf) {
        if (uf.text) {
          return uf.text;
        }
        if (uf) {
          return uf.value;
        }
      }

      return Select2.create(angular.extend(metodos, options));
    }
  }
})();
