(function () {
    'use strict';

    /**
     * @ngdoc controller
     * @name contratos-core.logradouro:LogradouroController
     * @description Controller principal da listagem de logradouros
     * @requires $scope
     * @requires bfc.dialog.Dialog
     **/

    angular
        .module('core.logradouro')
        .controller('core.LogradouroController', LogradouroController);

    LogradouroController.$inject = [
        '$rootScope',
        '$q',
        '$scope',
        'core.TrackerFactory',
        'core.LogradouroService',
        'core.LogradouroModalService',
        'bfc.Notification'
    ];

    function LogradouroController($rootScope,
                                  $q,
                                  $scope,
                                  TrackerFactory,
                                  LogradouroService,
                                  LogradouroModalService,
                                  Notification) {

        var viewModel = this;
        viewModel.service = LogradouroService;

        viewModel.search = search;
        viewModel.cadastrar = cadastrar;
        viewModel.editar = editar;
        viewModel.excluir = excluir;

        viewModel.showDescricao = showDescricao;
        viewModel.showMunicipio = showMunicipio;
        viewModel.showTipo = showTipo;
        viewModel.showCep = showCep;

        inicializa();

        function inicializa() {
            inicializaPropriedades();
            inicializaLoadings();
            escutaEventos();
        }

        function escutaEventos() {
            $scope.$on('logradouro:actionRemove', function (eventos, params) {
                angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
            });
        }

        function inicializaPropriedades() {
            viewModel.logradouro = [];
            viewModel.list = {};
            viewModel.filter = {};
            viewModel.columns = [
                {
                    id: 'nome',
                    model: 'nome',
                    label: 'Descrição',
                    filterable: true,
                    alwaysVisible: false
                },
                {
                    id: 'tipoLogradouro',
                    model: 'tipoLogradouro',
                    label: 'Tipo',
                    filterable: true,
                    alwaysVisible: false
                },
                {
                    id: 'municipio',
                    model: 'municipio',
                    label: 'Município',
                    filterable: true,
                    alwaysVisible: false
                },
                {
                    id: 'cep',
                    model: 'cep',
                    label: 'CEP',
                    filterable: true,
                    alwaysVisible: false
                }
            ];

            viewModel.listCustomBuilder = {
                onBeforeBuild: function () {
                    return '';
                },
                unidadeFederativa: function (filters) {
                    if (filters.unidadeFederativa && filters.unidadeFederativa.length > 0) {
                        return getCustomFilter(filters);
                    }
                }
            };

            $scope.$on('filtroRegistroPadrao:refresh', function (eventos, params) {
                viewModel.listCustomBuilder.onBeforeBuild = function(){
                    return params.criterio;
                };
            
                viewModel.listControls.search();
            });

            viewModel.listControls = {};

            viewModel.colDescricaoIsVisible = true;
            viewModel.colMunicipioIsVisible = true;
            viewModel.colTipoIsVisible = true;
            viewModel.colCepIsVisible = true;
        }

        $scope.$watch(
            'logradouro.colDescricaoIsVisible',
            function changeValueDesc(newValue, oldValue) {
                if (viewModel.listControls.setColumnVisible !== undefined) {
                    viewModel.listControls.setColumnVisible('nome', newValue);
                    viewModel.colDescricaoIsVisible = newValue;
                }

            }
        );
        $scope.$watch(
            'logradouro.colMunicipioIsVisible',
            function changeValueDesc(newValue, oldValue) {
                if (viewModel.listControls.setColumnVisible !== undefined) {
                    viewModel.listControls.setColumnVisible('municipio', newValue);
                    viewModel.colMunicipioIsVisible = newValue;
                }
            }
        );
        $scope.$watch(
            'logradouro.colTipoIsVisible',
            function changeValueDesc(newValue, oldValue) {
                if (viewModel.listControls.setColumnVisible !== undefined) {
                    viewModel.listControls.setColumnVisible('tipoLogradouro', newValue);
                    viewModel.colTipoIsVisible = newValue;
                }
            }
        );
        $scope.$watch(
            'logradouro.colCepIsVisible',
            function changeValueDesc(newValue, oldValue) {
                if (viewModel.listControls.setColumnVisible !== undefined) {
                    viewModel.listControls.setColumnVisible('cep', newValue);
                    viewModel.colCepIsVisible = newValue;
                }
            }
        );

        function showDescricao() {
            return viewModel.colDescricaoIsVisible;
        }

        function showTipo() {
            return viewModel.colTipoIsVisible;
        }

        function showCep() {
            return viewModel.colCepIsVisible;
        }

        function showMunicipio() {
            return viewModel.colMunicipioIsVisible;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init(['logradouros', 'getList', 'exclusao']);
        }

        function search() {
            viewModel.listControls.search();
        }

        function cadastrar() {
            LogradouroModalService.cadastrar();
        }

        function editar(item) {
            LogradouroModalService.editar(item);
        }

        function excluir(item) {
            var promise = LogradouroService.excluir(item.id);
            return promise.then(function () {
                Notification.publish('Logradouro excluído com sucesso', 'success');
            }, function error() {
                return promise;
            });
        }

        $scope.setLineHeight = function (h) {
            $scope.lineHeight = h;
        };
    }
})();
