(function() {
	'use strict';
	/**
	 * @ngdoc service 
	 * @name contratos-core.bairro.service:BairroModalService
	 * @description Serviço para abrir o cadastro de bairros
	 * @requires ModalCadFactory
	 **/
	angular
			.module('core.bairro')
			.service('core.BairroModalService', BairroModalService);

	BairroModalService.$inject = ['ui.components.Modal'];

	function BairroModalService(uiModal) {

		var modal = {
			templateUrl: 'bairro-cad/bairro-cad.html',
			controller: 'core.BairroCadController',
			controllerAs: 'bairroCad',
			params: {
				bairroId: null
			}
		};

		var service = {
			cadastrar: cadastrar,
			editar: editar,
			onAdd: onAdd
		};

		angular.extend(this, service);

		function abrir(modal) {
			 return uiModal.open(modal).result;
		}

		function copia(modal) {
			return angular.copy(modal);
		}

		function cadastrar() {
			var m = copia(modal);
			m.resolve = {
				bairroId: null
			};
			abrir(m);
		}

		function editar(bairro) {
			var m = copia(modal);
			m.params = {
				bairroId: bairro.id
			};
			abrir(m);
		}

	    function onAdd(params){
            var m = copia(modal);
            m.params = {
                bairroId: null,
                params: params
            };
            return uiModal.open(m).result;
        }
	}
})();