(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.fornecedor
     * @description
     * # contratos-core.fornecedor
     * Módulo de fornecedores
     **/
    angular
            .module('core.fornecedor', ['core', 'core.commons', 'core.natureza-qualificacao']);
})();
