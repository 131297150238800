(function() {
	'use strict';

	angular
	  .module('core.commons')
	  .factory('core.ServiceFactory', ServiceFactory);

	ServiceFactory.$inject = [
		'$q',
		'Restangular'
	];

	function ServiceFactory(
		$q,
		Restangular
	) {

		return {
			create: create
		};

		function create(uri, methods) {

			var serviceDefault = {
				get: get,
				getList: getList,
				getOptions: getOptions,
				getDefault: getDefault,
		        getTypes: getTypes,
        		getEnums: getEnums,
				remove: remove,
		        save: save
			};
			var service = {};

			function get(id) {
				if(_.isArray(id) && _.isArray(uri)) {
					var oRestangular = Restangular;
					_.forEach(id, function(identify, index) {
						oRestangular = oRestangular.one(uri[index], id[index]);
					});
					return oRestangular.get();
				}
				return Restangular.one(uri, id).get();
			}

			function getList(filter, id) {
				if(_.isArray(id) && _.isArray(uri)) {
					var oRestangular = Restangular;
					var i=0;
					for(var length=id.length; i < length; i++) {
						oRestangular = oRestangular.one(uri[i], id[i]);
					}
					return oRestangular.all(uri[i]).getList();
				}

				return Restangular.all(uri).getList({filter: filter});
			}

			function getOptions() {
				var deferred = $q.defer();
				var stringUri = _.isArray(uri) ? uri.join("/") : uri;
	            var promise = Restangular.all(stringUri).options();
				promise.then(function(options){
				    deferred.resolve(options);
				}, function(error) {
	                deferred.reject(error);
	            });
	            return deferred.promise;
			}

			function getDefault() {
	            return getOptions().then(function(data) {
	                return _.cloneDeep(data.representation.default);
	            });
	        }

	        function getTypes() {
	            return getOptions().then(function(data) {
	                return _.cloneDeep(data.types);
	            });
    		}

    		function getEnums() {
	            return getOptions().then(function(data) {
	                return _.cloneDeep(data.enums);
	            });
	        }

	        function save(data) {
	            var rest = Restangular.all(uri);
	            if(data.id) {
	            	return rest.customPUT(data, data.id);
	   			} else {
	                return rest.post(data);
			    }
			}

	        function remove(id) {
	            return Restangular.one(uri, id).remove();
	        }

			if(methods !== undefined) {
				if(methods.include !== undefined) {
					for(var i=0, l= methods.include.length; i < l; i++) {
						service[methods.include[i]] = serviceDefault[methods.include[i]];
					}
				} else if(methods.exclude !== undefined) {
					service = serviceDefault;
					for(var i=0, l = methods.exclude.length; i < l; i++) {
						delete service[methods.exclude[i]];
					}
				}
			} else {
				service = serviceDefault;
			}

			return _.cloneDeep(service);
		}
	}
})();
