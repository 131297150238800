(function() {
    'use strict';
/**
 * @ngdoc service
 * @name ctr.utils.service:YesOrNoSelect
 *
 * @descripition
 * Serviço do select2 para tipo de entrega/execução do prazo de entrega
 *
 * @requires bfc.Select2
 * @requires UtilsService
 **/

    angular
            .module('core.commons')
            .service('core.YesOrNoSelect', YesOrNoSelect);

    YesOrNoSelect.$inject = [
        'bfc.Select2',
        'core.YesOrNoService'
    ];

    function YesOrNoSelect(
        Select2,
        YesOrNoService
    ) {

        var service = {
            select2Config: select2Config,
            getSim: getSim,
            getNao: getNao
        };

        _.extend(this, service);

        function getSim() {
            return YesOrNoService.getSim();
        }

        function getNao() {
            return YesOrNoService.getNao();
        }

        function select2Config(options) {

            var methods = {
                id: options.id ? options.id : id,
                formatResult: options.formatResult ? options.formatResult : formatResult,
                formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
                formatValue: options.formatValue ? options.formatValue : formatValue,
                initSelection: options.initSelection ? options.initSelection : initSelection,
                result: options.result ? options.result : result,
                dropdownAutoWidth: false,
                allowClear: false,
                maximumInputLength: 3
            };


            function id(opcao) {
                return opcao.description ? opcao.description : opcao.key;
            }

            function formatResult(opcao) {
                return opcao.description;
            }

            function formatSelection(opcao) {
                return opcao && opcao.description;
            }

            function formatValue(opcao) {
                return opcao.key === "SIM" ? true : false;
            }

            function initSelection(element, callback) {
                var id = element.val() === '' ? 'NAO' : 'SIM';
                if (id) {
                    YesOrNoService.getYesNo(id).then(callback);
                }
            }

            function result(params, callback) {
                 var filter = params && params.term;
                var result = YesOrNoService.getYesOrNo(filter);
                result.then(function(opcao) {
                    callback({
                        results: opcao,
                        more: opcao.hasNext
                    });
                });
                return result;
            }

            return Select2.create(angular.extend(methods, options));
        }
    }
})();
