(function() {
    'use strict';
    /**
     * @ngdoc overview
     * @name contratos-core.veiculos-publicacao
     * @description
     * # contratos-core.veiculos-publicacao
     * Módulo de veiculos de publicação
     **/
    angular
        .module('core.veiculos-publicacao', ['core', 'core.commons', 'core.integracao', 'core.fornecedor']);
})();
