(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos.natureza-texto-juridico-ato.NaturezaTextoJuridicoAtoService
     * @descripition
     * Serviço de naturezas do texto jurídico dos atos
     * @requires Restangular
     * @requires $q
     **/
    angular
            .module('core.natureza-texto-juridico-ato')
            .service('core.NaturezaTextoJuridicoAtoService', NaturezaTextoJuridicoAtoService);

    NaturezaTextoJuridicoAtoService.$inject = [
        '$q',
        'Restangular',
        'ui.components.EventEmitter'
    ];

    function NaturezaTextoJuridicoAtoService(
            $q,
            Restangular,
            EventEmitter) {

        var meta;
        var uri = ['naturezastextojuridicoatos'];

        var service = new EventEmitter();

        _.extend(service, NaturezaTextoJuridicoAtoService);

        service.get = get;
        service.getDefault = getDefault;
        service.getList = getList;
        service.getTypes = getTypes;
        service.remove = remove;
        service.save = save;
        service.emitDirty = emitDirty;
        service.getSugestoes = getSugestoes;

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }

        function getList(params) {
            params = params || {};
            params.offset = params.offset || 0;

            return Restangular.one(uri[0]).get({
                offset: params.offset,
                limit: params.limit,
                filter: params.filter,
                sort: params.sort
            }).then(function (data) {
                        if(params.offset > 0 && data.total <= params.offset) {
                            params.offset = 0;
                            data = getList(params);
                        }
                        return data;
                    });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri[0]).options().then(function (data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function (data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getTypes() {
            return getMetadata().then(function (data) {
                return angular.copy(data.types);
            });
        }

        function getEnumClassificacaoAto() {
            return getMetadata().then(function (data) {
                var novo = data.enums.ClassificacaoAtoEnum;
                var classificacaoAtoEnum = {};
                angular.forEach(novo.values, function (valor, index) {
                    classificacaoAtoEnum[valor.id] = valor;
                });
                classificacaoAtoEnum.values = [
                    classificacaoAtoEnum.ATA,
                    classificacaoAtoEnum.ATA_POSSE,
                    classificacaoAtoEnum.ATO,
                    classificacaoAtoEnum.ATO_ADMINISTRATIVO,
                    classificacaoAtoEnum.ATO_COMISSAO_EXEC_LEGIS,
                    classificacaoAtoEnum.ATO_CONSORCIO,
                    classificacaoAtoEnum.ATO_CONSELHO_DELIBERATIVO,
                    classificacaoAtoEnum.ATO_CONSELHO_FISCAL,
                    classificacaoAtoEnum.COMUNICACAO_INTERNA,
                    classificacaoAtoEnum.CONCESSAO_AFAST_INTER_PARTICULAR,
                    classificacaoAtoEnum.CONSTITUICAO,
                    classificacaoAtoEnum.CONTRATO,
                    classificacaoAtoEnum.CONVENIO,
                    classificacaoAtoEnum.DECRETO,
                    classificacaoAtoEnum.DECRETO_LEGISLATIVO,
                    classificacaoAtoEnum.DECRETO_LEI,
                    classificacaoAtoEnum.DELIBERACAO,
                    classificacaoAtoEnum.DESPACHO,
                    classificacaoAtoEnum.EDITAL,
                    classificacaoAtoEnum.EMENDA,
                    classificacaoAtoEnum.EMENDA_CONSTITUCIONAL,
                    classificacaoAtoEnum.EMENDA_MODIFICATIVA,
                    classificacaoAtoEnum.ESTATUTO_SOCIAL,
                    classificacaoAtoEnum.IMAGEM_EXEMPLAR_PUBLICA_ORGAO_OFICIAL,
                    classificacaoAtoEnum.INSTRUCAO,
                    classificacaoAtoEnum.JUSTIFICATIVA_CANCELAMENTO_INTERVENCAO,
                    classificacaoAtoEnum.LEI,
                    classificacaoAtoEnum.LEI_AUTORIZATIVA,
                    classificacaoAtoEnum.LEI_COMPLEMENTAR,
                    classificacaoAtoEnum.LEI_CRIACAO,
                    classificacaoAtoEnum.LEI_MUNICIPAL,
                    classificacaoAtoEnum.LEI_ORDINARIA,
                    classificacaoAtoEnum.LEI_ORGANICA,
                    classificacaoAtoEnum.MEDICAO,
                    classificacaoAtoEnum.MEDIDA_PROVISORIA,
                    classificacaoAtoEnum.MEMORANDO,
                    classificacaoAtoEnum.MULTIMIDIA,
                    classificacaoAtoEnum.OFICIO,
                    classificacaoAtoEnum.ORCAMENTO,
                    classificacaoAtoEnum.ORCAMENTO_BASE,
                    classificacaoAtoEnum.PARECER,
                    classificacaoAtoEnum.PLANILHA_ORCAMENTARIA_ADITIVO,
                    classificacaoAtoEnum.PLANILHA_ORCAMENTARIA_CONTRATADA,
                    classificacaoAtoEnum.PORTARIA,
                    classificacaoAtoEnum.PROCESSO,
                    classificacaoAtoEnum.PROCESSO_JUDICIAL,
                    classificacaoAtoEnum.PROJETO,
                    classificacaoAtoEnum.REGIMENTO_INTERNO,
                    classificacaoAtoEnum.REGISTRO_IMOVEIS,
                    classificacaoAtoEnum.REQUERIMENTO,
                    classificacaoAtoEnum.RESOLUCAO,
                    classificacaoAtoEnum.TERMO_MEDICAO,
                    classificacaoAtoEnum.TERMO_PARALISACAO,
                    classificacaoAtoEnum.TERMO_RECEBIMENTO
                ];
                return classificacaoAtoEnum;
            });
        }

        function remove(id) {
            return Restangular.one(uri[0], id)
                    .remove()
                    .then(emitDirty);
        }

        function save(data) {
            var rest = Restangular.all(uri[0]);
            return !data.id ?
                    rest.post(data).then(emitDirty) :
                    rest.customPUT(data, data.id).then(emitDirty);
        }

        function emitDirty(data) {
            service.emit('dirty');
            return data;
        }

        function getSugestoes(params) {
            params = params || {};

            return Restangular.one(uri[0]+'/sugestoes').get({
                filter: params.filter
            });
        }
    }
})();
