(function() {
	'use strict';
	/**
	 * @ngdoc service
	 * @name contratos-core.utils.acoes-cadastros-modal.service:AcoesCadastrosModalService
	 * @description Serviço para gerenciar as ações dos cadastros básicos
	 * @requires ui.components.Modal
	 **/
	angular
			.module('core.commons')
			.service('core.AcoesCadastrosModalService', AcoesCadastrosModalService);

	AcoesCadastrosModalService.$inject = ['ui.components.Modal'];

	function AcoesCadastrosModalService(uiModal) {
		var service = {
			cadastrar: cadastrar,
			editar: editar,
			onAdd: onAdd
		};

		angular.extend(this, service);

		function abrir(modal) {
			 return uiModal.open(modal).result;
		}

		function copia(modal) {
			return angular.copy(modal);
		}

		function cadastrar(modal) {
			var m = copia(modal);
			m.resolve = {
				identificador_cadastro: null
			};
			return abrir(m);
		}

		function editar(modal,objeto_cadastro) {
			var m = copia(modal);
			m.params = {
				identificador_cadastro: objeto_cadastro.id,
        params: objeto_cadastro.params || null
			};
			return abrir(m);
		}

		function onAdd(modal, params){
			var m = copia(modal);
			m.params = {
				identificador_cadastro: null,
				params: params
			};
			return uiModal.open(m).result;
		}

	}
})();
