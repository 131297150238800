(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name contratos-core.utils.directive:aDisabled
     *
     * @restrict EA
     *
     * @description
     * Diretiva para disabilitar um href
     **/
    angular
        .module('core.commons')
        .directive('coreADisabled', aDisabled)
        .directive('aDisabled', aDisabled);


    function aDisabled() {
        return {
            compile: function (tElement, tAttrs, transclude) {
                //Disable ngClick
                tAttrs["ngClick"] = "!(" + tAttrs["aDisabled"] + ") && (" + tAttrs["ngClick"] + ")";

                //Toggle "disabled" to class when aDisabled becomes true
                return function (scope, iElement, iAttrs) {
                    scope.$watch(iAttrs["aDisabled"], function (newValue) {
                        if (newValue !== undefined) {
                            iElement.toggleClass("disabled", newValue);

                            if (newValue) {
                                iElement.addClass('tx__gray');
                            } else {
                                iElement.removeClass('tx__gray');
                            }
                        }
                    });

                    //Disable href on click
                    iElement.on("click", function (e) {
                        if (scope.$eval(iAttrs["aDisabled"])) {
                            e.preventDefault();
                        }
                    });
                };
            }
        };
    }
})();
