(function () {
  'use strict';

  /**
   * @ngdoc controller
   * @name contratos-core.bairro:BairroController
   * @description Controller principal da listagem de bairros
   * @requires $scope
   * @requires bfc.dialog.Dialog
   **/

  angular
    .module('core.bairro')
    .controller('core.BairroController', BairroController);

  BairroController.$inject = [
    '$rootScope',
    '$q',
    '$scope',
    'core.TrackerFactory',
    'core.BairroService',
    'core.BairroModalService'
  ];

  function BairroController($rootScope,
                            $q,
                            $scope,
                            TrackerFactory,
                            BairroService,
                            BairroModalService) {

    var viewModel = this;
    viewModel.service = BairroService;

    viewModel.search = search;
    viewModel.cadastrar = cadastrar;
    viewModel.editar = editar;
    viewModel.excluir = excluir;

    viewModel.showDescricao = showDescricao;
    viewModel.showMunicipio = showMunicipio;

    inicializa();

    function inicializa() {
      inicializaPropriedades();
      inicializaLoadings();
      escutaEventos();
    }

    function escutaEventos() {
      $scope.$on('bairro:actionRemove', function (eventos, params) {
        angular.element('#hrefActionExcluir-' + params.id).triggerHandler('click');
      });
    }

    function inicializaPropriedades() {
      viewModel.bairro = [];
      viewModel.list = {};
      viewModel.filter = {};
      viewModel.columns = [
        {
          id: 'nome',
          model: 'nome',
          label: 'Descrição',
          filterable: true,
          alwaysVisible: false
        },
        {
          id: 'municipio',
          model: 'municipio',
          label: 'Município',
          filterable: true,
          alwaysVisible: false
        }
      ];

      viewModel.listCustomBuilder = {
        onBeforeBuild: function () {
          return '';
        }
      };

      $scope.$on('filtroRegistroPadrao:refresh', function (eventos, params) {
        viewModel.listCustomBuilder.onBeforeBuild = function(){
            return params.criterio;
        };
    
        viewModel.listControls.search();
      });

      viewModel.listControls = {};

      viewModel.colDescricaoIsVisible = true;
      viewModel.colMunicipioIsVisible = true;
    }

    $scope.$watch(
      'bairro.colDescricaoIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('nome', newValue);
          viewModel.colDescricaoIsVisible = newValue;
        }

      }
    );
    $scope.$watch(
      'bairro.colMunicipioIsVisible',
      function changeValueDesc(newValue, oldValue) {
        if (viewModel.listControls.setColumnVisible !== undefined) {
          viewModel.listControls.setColumnVisible('municipio', newValue);
          viewModel.colMunicipioIsVisible = newValue;
        }
      }
    );

    function showDescricao() {
      return viewModel.colDescricaoIsVisible;
    }

    function showMunicipio() {
      return viewModel.colMunicipioIsVisible;
    }

    function inicializaLoadings() {
      viewModel.loadings = TrackerFactory.init(['bairros', 'getList', 'exclusao']);
    }

    function search() {
      viewModel.listControls.search();
    }

    function cadastrar() {
      BairroModalService.cadastrar();
    }

    function editar(item) {
      BairroModalService.editar(item);
    }

    function excluir(item) {
      var promise = BairroService.excluir(item.id);
      return promise.then(function () {
        BairroService.mensagemExclusao();
      }, function error() {
        return promise;
      });
    }

    $scope.setLineHeight = function (h) {
      $scope.lineHeight = h;
    };
  }
})();
