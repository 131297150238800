(function () {
    'use strict';

    angular.module('core.marcadores')
        .directive('coreMarcadoresSelect', marcadoresSelect);

    marcadoresSelect.$inject = ['CONFIG_SELECT2'];

    function marcadoresSelect(CONFIG_SELECT2) {
        return {
            restrict: 'E',
            templateUrl: 'marcadores-select.directive.html',
            scope: {
                ngModel: '=ngModel',
                bfRequired: '=?',
                ngDisabled: '=?',
                hiddenAddValue: '=?',
                criterionSearch: '=?',
                multiple: '=?',
                dataClassTag: '=?',
                recentFile: '='
            },
            compile: function (element, attributes) {
                var addValue = attributes.hiddenAddValue === 'true';
                if (!addValue) {
                    element.children('div').children('ui-select')
                        .attr('add-value', 'vm.onAdd');
                }
                if (attributes.multiple === 'true') {
                    element.children('div').children('ui-select').attr('multiple', 'true');
                }
                element.children('div').children('ui-select')
                    .attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
                element.children('div').children('ui-select')
                    .attr('debounce-delay', CONFIG_SELECT2.DELAY);
                element.children('div').children('ui-select')
                    .attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('MARCADORES'));
            },
            controller: controller,
            controllerAs: 'vm'
        };
    }

    controller.$inject = [
        '$scope',
        'core.MarcadoresService',
        '$q',
        '$rootScope'
    ];

    function controller($scope, service, $q, $rootScope) {
        var vm = this;
        var classDefault = {
            titleLabel: 'Marcadores',
            labelClass: '',
            divClass: 'col-md-12'
        };
        var changeMarcador = 0;
        var index = 0;
        vm.search = search;
        vm.onAdd = onAdd;
        vm.changeValue = changeValue;
        vm.params = $scope.criterionSearch;
        vm.paramsClass = $scope.dataClassTag ? $scope.dataClassTag : classDefault;

        function search(params) {
            var splitResult = params.term.split('%25');
            var paramsDefault = splitResult[1];
            params.descricao = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
            return service.getList(params).then(function (data) {
                return data;
            });
        }

        function changeValue(indexMarcador) {
            if (changeMarcador !== indexMarcador) {
                $rootScope.$emit('fileChangedTags', $scope.ngModel);
                ++changeMarcador;
            } else {
                changeMarcador = 0;
            }
        }

        function onAdd(descricao) {
            var deferred = $q.defer();
            index = ($scope.ngModel && $scope.ngModel.length) || index;
            var param = {
                id: ++index,
                descricao: descricao
            };
            deferred.resolve(param);
            $rootScope.$emit('fileChangedTags', $scope.ngModel);
            return deferred.promise;
        }
    }
})();
