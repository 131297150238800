(function() {
    'use strict';

    angular
            .module('core.commons')
            .factory('core.ModalCadFactory', ModalCadFactory);
    /**
     * Factory para ser utilizada na abertura dos cadastros.
     * Através do objeto de entrada (prop) a factory irá  criar um objeto de configuração,
     * dependendo do tipo da abertura do cadastro, se é editar, adicionar, visualizar
     * e copiar
     *
     * @return {object} 	Objeto com o metodo open que sera utilizado no utilizo da modal
     */

    ModalCadFactory.$inject = ['bfc.dialog.Dialog', 'bfc.Notification'];

    function ModalCadFactory(
            Dialog,
            Notification
                    ) {

        /**
         * Função para criar o objeto conf
         * @param  {object} prop Propriedades da abertura da modal para poder criar o objeto conf
         *                       ex. {
         *                       	templateUrl: '/myTemplate.html',
         *                       	controller: 'myController',
         *                       	id: <number>,
         *                       	copiar: <boolean>,
         *                       	filters: <object>,
         *                       	readOnly: <boolean>
         *                       	optionsCad: <object>
         *                       	fnCad: <function>
         *                       	configId: <number>			 *
         *                       }
         * @return {object}      Objeto conf conforme propriedades passadas por parametro
         */

        function criarConf(prop) {
            if (!prop) {
                Notification.publish('Ops! Ocorreu um erro ao abrir o cadastro. Por favor contatar a Betha Sistemas!');
                throw new Error('É obrigatório informar as propriedades');
            }
            if (!prop.templateUrl || !prop.controller) {
                Notification.publish('Ops! Ocorreu um erro ao abrir o cadastro. Por favor contatar a Betha Sistemas!');
                throw new Error('É obrigatório a informação do templateUrl e do controller');
            }

            // Criando objeto de configuração
            var conf = {
                templateUrl: prop.templateUrl,
                controller: prop.controller,
                styleClass: prop.styleClass,
                resolve: {
                    id: prop.id,
                    copiar: undefined,
                    filters: undefined,
                    readOnly: prop.readOnly,
                    optionsCad: undefined,
                    fnCad: undefined,
                    configId: undefined,
                    addGrupo: undefined
                }
            };

            if (prop.resolve) {
                _.merge(conf.resolve, prop.resolve);
            }

            if (prop.readOnly) {
                return conf;
            } else {
                // Novo cadastro
                if (!prop.id && !prop.objAlterado) {

                    //Adicionando com informações padrão de um grupo
                    if (prop.addGrupo) {
                        _.merge(conf.resolve, {
                            addGrupo: prop.addGrupo
                        });
                    }

                    //Condição do openCad
                    if (prop.optionsCad && prop.selectElem) {
                        conf.focusOnClose = false;
                        _.merge(conf.resolve, {
                            optionsCad: prop.optionsCad,
                            fnCad: prop.fnCad ? prop.fnCad : ['bfc.Select2',
                                function(Select2) {
                                    return function(data) {
                                        Select2.set(prop.selectElem, data.id);
                                    };
                                }
                            ]
                        });
                        return conf;

                    } else if (prop.fnCad) {
                        _.merge(conf.resolve, {
                            fnCad: function() {
                                return prop.fnCad;
                            }
                        });
                    }

                    //Caso utilizar filters
                    if (prop.filters) {
                        _.merge(conf.resolve, {
                            filters: prop.filters
                        });
                    }

                    //Caso for uma janela que possui um código de configuração
                    if (prop.configId) {
                        _.merge(conf.resolve, {
                            configId: prop.configId
                        });
                    }
                    return conf;
                }

                // copiar e editar
                if (prop.id) {
                    _.merge(conf.resolve, {
                        id: prop.id
                    });

                    // copiar
                    if (prop.copiar) {
                        conf.resolve.copiar = prop.copiar;
                    }

                    if (prop.fnCad) {
                        _.merge(conf.resolve, {
                            fnCad: function() {
                                return prop.fnCad;
                            }
                        });
                    }
                    return conf;
                }

                //Alteração do cadastro
                if (prop.objAlterado) {
                    _.merge(conf.resolve, {
                        objAlterado: prop.objAlterado
                    });
                    return conf;
                }
            }
        }

        /**
         * Função que irá abrir a modal, conforme o objeto conf que será criado
         * @param  {object} prop  	Propriedades da abertura da modal.
         * @return {void}      		Abertura da modal conforme propriedades
         */

        function _open(prop) {
            var _conf = criarConf(prop);
            Dialog.open(angular.copy(_conf));
        }


        return {
            open: _open
        };
    }
})();
