(function() {
	'use strict';
	/**
	 * @ngdoc service 
	 * @name contratos-core.distrito.service:DistritoModalService
	 * @description Serviço para abrir o cadastro de distrito
	 * @requires ModalCadFactory
	 **/
	angular
			.module('core.distrito')
			.service('core.DistritoModalService', DistritoModalService);

	DistritoModalService.$inject = ['ui.components.Modal'];

	function DistritoModalService(uiModal) {

		var modal = {
			templateUrl: 'distrito-cad/distrito-cad.html',
			controller: 'core.DistritoCadController',
			controllerAs: 'distritoCad',
			params: {
				distritoId: null
			}
		};

		var service = {
			cadastrar: cadastrar,
			editar: editar,
			onAdd: onAdd
		};

		angular.extend(this, service);

		function abrir(modal) {
			 return uiModal.open(modal).result;
		}

		function copia(modal) {
			return angular.copy(modal);
		}

		function cadastrar() {
			var m = copia(modal);
			m.resolve = {
				distritoId: null
			};
			abrir(m);
		}

		function editar(distrito) {
			var m = copia(modal);
			m.params = {
				distritoId: distrito.id
			};
			abrir(m);
		}

	    function onAdd(params){
            var m = copia(modal);
            m.params = {
                distritoId: null,
                params: params
            };
            return uiModal.open(m).result;
        }
	}
})();