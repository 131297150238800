(function () {
    'use strict';

    angular.module('core.banco')
        .directive('coreBancoSelect', bancoSelect);

    bancoSelect.$inject = ['CONFIG_SELECT2'];

    function bancoSelect(CONFIG_SELECT2) {
        return {
            restrict: 'E',
            template: '<ui-select ng-model="ngModel" bf-required="bfRequired" ng-disabled="ngDisabled" search="vm.search" format-result="vm.formatResult(data)" title="{{vm.titulo}}"></ui-select>',
            scope: {
                ngModel: '=ngModel',
                bfRequired: '=?',
                ngDisabled: '=?',
                hiddenAddValue: '=?',
                criterionSearch: '=?',
                multiple: '=?'
            },
            compile: function (element, attributes) {
                var addValue = attributes.hiddenAddValue === 'true';
                if (!addValue) {
                    element.children('ui-select')
                        .attr('add-value', 'vm.onAdd');
                }
                if (attributes.multiple === 'true') {
                    element.children('ui-select').attr('multiple', 'true');
                }
                element.children('ui-select')
                    .attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
                element.children('ui-select')
                    .attr('debounce-delay', CONFIG_SELECT2.DELAY);
                element.children('ui-select')
                    .attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('BANCO'));
            },
            controller: controller,
            controllerAs: 'vm'
        };
    }

    controller.$inject = [
        '$scope',
        'core.BancoService'
    ];

    function controller($scope, service) {
        var vm = this;
        vm.search = search;
        vm.params = $scope.criterionSearch;
        vm.formatResult = formatResult;

        function search(params) {
            var paramsDefault = '(nome like "' + params.term + '") or (numeroBanco like "' + params.term + '")';
            params.filter = $scope.criterionSearch ? vm.params({termo: params.term}) : paramsDefault;
            return service.getList(params).then(function (data) {
                return data;
            });
        }

        function formatResult(value) {
            var valorFormatado = value.nome + ' (' + value.numeroBanco + ')';
            vm.titulo = valorFormatado;
            return valorFormatado;
        }
    }
})();
