(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name estoque.encerramentos.controller:EncerramentosCadController
     *
     * @description
     * Controller cadastro de encerramentos do estoque
     *
     * @requires $scope
     **/
    angular
        .module('core.agencia-bancaria')
        .controller('core.AgenciaBancariaCadController', agenciaBancariaCadController);

    agenciaBancariaCadController.$inject = [
        '$rootScope',
        '$modalInstance',
        '$modalTracker',
        '$injector',
        '$q',
        '$scope'
    ];

    function agenciaBancariaCadController($rootScope,
                                          $modalInstance,
                                          $modalTracker,
                                          $injector,
                                          $q,
                                          $scope) {
        var viewModel = this;

        var TrackerFactory = $injector.get('core.TrackerFactory'),
            AgenciaBancariaService = $injector.get('core.AgenciaBancariaService'),
            CamposAdicionaisService = $injector.get('CamposAdicionaisService'),
            CPA_CONSTANTS = $injector.get('CPA_CONSTANTS'),
            CONFIG_SELECT2 = $injector.get('CONFIG_SELECT2'),
            MunicipioService = $injector.get('core.MunicipioService'),
            MunicipioModalService = $injector.get('core.MunicipioModalService'),
            LogradouroService = $injector.get('core.LogradouroService'),
            LogradouroModalService = $injector.get('core.LogradouroModalService'),
            BairroService = $injector.get('core.BairroService'),
            BairroModalService = $injector.get('core.BairroModalService'),
            Notification = $injector.get('bfc.Notification');

        viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

        inicializaLoadings();
        inicializar();

        function inicializar() {
            inicializaMetodos();
            inicializaPropriedades();
            inicializaPromises();
            inicializaCamposAdicionais();
        }

        function inicializaPromises() {
            var promise = $q.all([
                inicializaCadastro()
            ]);
            viewModel.loadings.inicializaCadastro.addPromise(promise);
            $modalTracker.addPromise(promise);
        }

        function inicializaPropriedades() {
            viewModel.argumentos = agenciaBancariaCadController.$inject;
            viewModel.cadastro = {};
            viewModel.visivel = 1;
        }

        function inicializaMetodos() {
            viewModel.situacaoCadastro = situacaoCadastro;
            viewModel.temItem = temItem;
            viewModel.salvar = salvar;
            viewModel.desabilitaSalvar = desabilitaSalvar;
            viewModel.excluir = excluir;
            viewModel.buscarMunicipios = buscarMunicipios;
            viewModel.formataMunicipio = formataMunicipio;
            viewModel.adicionarMunicipio = adicionarMunicipio;
            viewModel.hasMunicipio = hasMunicipio;
            viewModel.buscarLogradouros = buscarLogradouros;
            viewModel.adicionarLogradouro = adicionarLogradouro;
            viewModel.buscarBairros = buscarBairros;
            viewModel.adicionarBairro = adicionarBairro;
            viewModel.formata = formata;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'salvar',
                'salvarAdicionar',
                'excluir',
                'inicializaCadastro',
                'cadastro',
                'camposAdicionais'
            ]);
        }

        function inicializaCadastro() {
            var promise;
            if ($modalInstance.params.identificador_cadastro) {
                promise = AgenciaBancariaService.get($modalInstance.params.identificador_cadastro);
                promise.then(function (cadastro) {
                    viewModel.cadastro = cadastro;
                });
            } else {
                promise = AgenciaBancariaService.getDefault();
                promise.then(function (cadastro) {
                    viewModel.cadastro = cadastro;
                    if ($modalInstance.params.params) {
                        if ($modalInstance.params.params.banco) {
                            viewModel.cadastro.banco = $modalInstance.params.params.banco;
                        }
                        viewModel.cadastro.nome = $modalInstance.params.params.nome;
                        viewModel.form.$setDirty();
                    }
                });
            }
            viewModel.loadings.inicializaCadastro.addPromise(promise);
        }

        function inicializaCamposAdicionais() {
            if (!CPA_CONSTANTS.Cadastros.AGENCIA_BANCARIA) {
                viewModel.campoAdicional = undefined;
            } else {
                var promise = AgenciaBancariaService.getCamposAdicionais($modalInstance.params.identificador_cadastro)
                    .then(function (data) {
                        viewModel.campoAdicional = data;
                    });

                viewModel.loadings.camposAdicionais.addPromise(promise);

                return promise;
            }
        }

        function situacaoCadastro() {
            return temItem() ? 'Editando' : 'Adicionando';
        }

        function temItem() {
            return (viewModel.cadastro.id === undefined) ? $modalInstance.params.identificador_cadastro : viewModel.cadastro.id;
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;

            if (viewModel.campoAdicional) {
                viewModel.cadastro.campoAdicional = viewModel.campoAdicional;
            }
            tracker.addPromise(
                AgenciaBancariaService.save(viewModel.cadastro).then(function (retorno) {
                    if (continuar) {
                        if (viewModel.form) {
                            viewModel.form.$setPristine();
                            viewModel.form.$setUntouched();
                        }
                        $modalInstance.params.identificador_cadastro = null;
                        $modalInstance.params.params = null;
                        inicializaPropriedades();
                        inicializaCadastro();
                        inicializaCamposAdicionais();
                    } else {
                        cancelar(retorno);
                    }
                    var mensagem = $modalInstance.params.identificador_cadastro ? 'alterada' : 'salva';
                    Notification.publish('Agência bancária ' + mensagem + ' com sucesso', 'success');
                })
            );
        }

        function cancelar(item) {
            $modalInstance.close(item);
        }

        function verificaForm() {
            return (viewModel.form.$dirty && viewModel.form.$invalid);
        }

        function desabilitaSalvar() {
            var primeiraCondicao = viewModel.form.$pristine && !temItem();
            var segundaCondicao = !CamposAdicionaisService.isFormValid(viewModel.campoAdicional);
            return verificaForm() || primeiraCondicao || segundaCondicao;
        }

        function excluir() {
            $rootScope.$broadcast('agenciaBancaria:actionRemove', {id: viewModel.cadastro.id});
            cancelar();
        }

        function buscarMunicipios(params) {
            params.filter = '(nome like "' + params.term + '")';
            return MunicipioService.getList(params).then(function (data) {
                return data;
            });
        }

        function formataMunicipio(value) {
            if (!value.descricao) {
                return value.nome + (value.estado ? (' - ' + value.estado.unidadeFederativa) : '');
            }
        }

        function adicionarMunicipio(descricao) {
            var param = {
                nome: descricao
            };
            return MunicipioModalService.onAdd(param);
        }

        function hasMunicipio() {
            return Boolean(viewModel.cadastro && viewModel.cadastro.municipio && viewModel.cadastro.municipio.id);
        }

        function buscarLogradouros(params) {
            params.filter = '(nome like "' + params.term + '" ';
            if (hasMunicipio()) {
                params.filter = params.filter + 'and idMunicipio = ' + viewModel.cadastro.municipio.id;
            }
            params.filter = params.filter + ')';


            return LogradouroService.getList(params).then(function (data) {
                return data;
            });
        }

        function adicionarLogradouro(descricao) {
            var param = {
                nome: descricao,
                params: viewModel.cadastro
            };
            return LogradouroModalService.onAdd(param);
        }

        function buscarBairros(params) {
            params.filter = '(nome like "' + params.term + '" ';
            if (hasMunicipio()) {
                params.filter = params.filter + 'and idMunicipio = ' + viewModel.cadastro.municipio.id;
            }
            params.filter = params.filter + ')';

            return BairroService.getList(params).then(function (data) {
                return data;
            });
        }

        function adicionarBairro(descricao) {
            var param = {
                nome: descricao,
                params: viewModel.cadastro
            };
            return BairroModalService.onAdd(param);
        }

        function formata(value) {
            return value.nome;
        }

        $scope.$watch(
            'vm.cadastro.municipio',
            function (newValue, oldValue) {
                if (oldValue) {
                    viewModel.cadastro.logradouro = null;
                    viewModel.cadastro.bairro = null;
                }
            }
        );

        $scope.$watch(
            'vm.cadastro.logradouro',
            function (newValue, oldValue) {
                if (oldValue) {
                    viewModel.cadastro.bairro = null;
                }
            }
        );
    }
})();