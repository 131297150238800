(function () {
    'use strict';
    angular
        .module('core.configuracao-casa-decimal')
        .controller('core.ConfiguracaoCasaDecimalController', configuracaoCasaDecimalController);

    configuracaoCasaDecimalController.$inject = [
        '$rootScope',
        '$modalInstance',
        '$modalTracker',
        '$injector',
        '$q',
        '$scope'
    ];

    function configuracaoCasaDecimalController($rootScope,
                                            $modalInstance,
                                            $modalTracker,
                                            $injector,
                                            $q,
                                            $scope) {
        var viewModel = this;

        var TrackerFactory = $injector.get('core.TrackerFactory'),
            ConfiguracaoCasaDecimalService = $injector.get('core.ConfiguracaoCasaDecimalService'),
            Notification = $injector.get('bfc.Notification'),
            ConfirmService = $injector.get('ContratosConfirmService'),
            ConfiguracaoRegraGeralProvider = $injector.get('ConfiguracaoRegraGeralProvider');

        inicializaLoadings();
        inicializar();

        function inicializar() {
            inicializaMetodos();
            inicializaPropriedades();
            inicializaPromises();
        }


        function inicializaPromises() {
            var promise = $q.all([
                inicializaCadastro()
            ]);
            
            viewModel.loadings.inicializaCadastro.addPromise(promise);
            $modalTracker.addPromise(promise);
        }

        function inicializaPropriedades() {
            viewModel.argumentos = configuracaoCasaDecimalController.$inject;
            viewModel.cadastro = {};
            viewModel.cadastroSalvo = {};
        }

        function inicializaMetodos() {
            viewModel.salvar = salvar;
            viewModel.desabilitaSalvar = desabilitaSalvar;
            viewModel.formatDate = formatDate;
            viewModel.formatHour = formatHour;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'salvar',
                'inicializaCadastro'
            ]);
        }

        function inicializaCadastro() {
        
            var promise;
            promise = ConfiguracaoCasaDecimalService.get();
            promise.then(function (cadastro) {
                viewModel.cadastro = cadastro;
                viewModel.cadastroSalvo = cadastro.plain();
            });
            viewModel.loadings.inicializaCadastro.addPromise(promise);
        }

        function salvar() {
            if ((viewModel.cadastro.quantidade < viewModel.cadastroSalvo.quantidade) || (viewModel.cadastro.precoUnitario < viewModel.cadastroSalvo.precoUnitario)){
                ConfirmService.confirm({
                    title: 'Aviso',
                    body: 'Você está reduzindo o número de casas decimais!\n\n ' +
                          'O sistema não mostrará mais as casas decimais que foram suprimidas, mesmo que esteja maior que 0(zero).\n ' +
                          'Além disso poderá surgir diferenças de centavos em razão do arredondamento automático que será feito pelo sistema ao mostrar os valores na tela!\n ' +
                          'Você confirma a alteração?',
                    buttonYes: 'Sim',
                    buttonNo: 'Não',
                    buttonSecondDanger: false
                }, function () {
                    confirmadoSalvar();
                }, function () {
                });   
            }else{
                confirmadoSalvar();
            }                  
        }

        function confirmadoSalvar(){
            var tracker = viewModel.loadings.salvar;

            tracker.addPromise(
                ConfiguracaoCasaDecimalService.save(viewModel.cadastro).then(function () {
                    ConfiguracaoRegraGeralProvider.alteraConfiguracao(viewModel.cadastro.quantidade, viewModel.cadastro.precoUnitario);
                    cancelar();
                    Notification.publish('Configurações salvas com sucesso', 'success');
                })
            );
        }

        function cancelar(item) {
            $modalInstance.close(item);
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
        }

        $scope.$watch('vm.cadastro.precoUnitario', 
        function(newValue, oldValue){
            var valor = 0;
            viewModel.precoUnitarioFormato = valor.toFixed(newValue).replace('.', ',');
        });

        $scope.$watch('vm.cadastro.quantidade', 
        function(newValue, oldValue){
            var valor = 0;
            viewModel.quantidadeFormato = valor.toFixed(newValue).replace('.', ',');
        });

        function formatDate() {
            var newDate = viewModel.cadastro.dataHoraAlteracao || new Date();
            return moment(newDate).format('DD/MM/YYYY');
        }

        function formatHour(){
            var newDate = viewModel.cadastro.dataHoraAlteracao || new Date();
            return moment(newDate).format('HH:mm');
        }
    }
})();
