(function() {
    'use strict';

    /**
     * @ngdoc overview
     * @name ctr.configuracoes-gerais
     * @module ctr.configuracoes-gerais
     * @kind module
     *
     * @description
     * Módulo das Configurações Gerais da aplicação.
     *
     * @example
     * ```
     * angular
     *      .module('ctr.configuracoes-gerais', []);
     * ```
     */
    angular
            .module('core.configuracoes-gerais', ['core', 'core.commons', 'core.exercicio', 'core.parametros']);

})();
