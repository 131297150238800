(function () {
  'use strict';

  angular.module('core.fornecedor')
    .directive('cnaeSelect', cnaeSelect);

  cnaeSelect.$inject = ['CONFIG_SELECT2'];

  function cnaeSelect(CONFIG_SELECT2) {
    return {
      restrict: 'E',
      template: '<ui-select ng-model="ngModel" multiple="multiple" busca-local="buscaLocal" ng-change="ngChange" format-selection="vm.formatSelection(data)" format-result="vm.formatResult(data)" ng-disabled="ngDisabled" search="vm.search"></ui-select>',
      scope: {
          ngModel: '=ngModel',
          bfRequired: '=?',
          ngDisabled: '=?',
          hiddenAddValue: '=?',
          criterionSearch: '=?',
          multiple: '=?',
          buscaLocal: '=?'
      },
      compile: function (element, attributes) {
        var addValue = attributes.hiddenAddValue === 'true';
        if (!addValue) {
            element.children('ui-select').attr('add-value', 'vm.onAdd');
        }
        if (attributes.multiple != 'false') {
          element.children('ui-select').attr('multiple', true)
        } else {
          element.children('ui-select').attr('multiple', false)
        }
        element.children('ui-select').attr('minimum-input-length', CONFIG_SELECT2.MINIMUMINPUT);
        element.children('ui-select').attr('debounce-delay', CONFIG_SELECT2.DELAY);
        element.children('ui-select').attr('maximum-input-length', CONFIG_SELECT2.MAXIMUMINPUT('CNAE'));
      },
      controller: controller,
      controllerAs: 'vm'
    }
  }

  controller.$inject = [
    '$scope',
    'core.ConsultaCnaeService'
  ];

  function controller($scope, ConsultaCnaeService) {
    var vm = this;
    vm.search = search;
    vm.formatResult = formatResult;
    vm.formatSelection = formatSelection;
    vm.params = $scope.criterionSearch;
    vm.buscaLocal = $scope.buscaLocal;

    function search(params) {
      params.term = params.term = params.term.replaceAll(/[.,\/-]/g, '');
      
      if (vm.buscaLocal) {
        return ConsultaCnaeService.getDadosCnaeLocal(params).then(function (data) {
            return angular.copy(data);
        });
      } else {
        
        return ConsultaCnaeService.getDadosCnae(params).then(function (data) {
            return angular.copy(data);
        });
      }
    }

    function formatResult(cnae) {
      return exibeCnaeFormatado(cnae.id) + " - " + cnae.descricao;
    }

    function formatSelection(cnae) {
      return exibeCnaeFormatado(cnae.id) + " - " + cnae.descricao;
    }

    function exibeCnaeFormatado(cnaeId) {
      return cnaeId.substring(0, 2) + '.' + cnaeId.substring(2, 4) + '-' + cnaeId.substring(4, 5) + '/' + cnaeId.substring(5)
    }
  }
})();
