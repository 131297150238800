(function () {
    'use strict';

    angular
        .module('core.agencia-bancaria')
        .config(configuration);

    configuration.$inject = [
        'bfcStateProvider'
    ];

    function configuration(
        stateProvider) {

        var data = {
            route: {
                url: '/agencia-bancaria',
                data: {
                    id: 'AgenciaBancariaPageMapping'
                },
                views: {
                    '@main': {
                        templateUrl: 'agencia-bancaria.html',
                        controller: 'core.AgenciaBancariaController',
                        controllerAs: 'vm'
                    }
                }
            }
        };

        stateProvider
            .from('core')
            .create('core-agencia-bancaria', data.route);
    }
})();