(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.ConfiguracaoEntidade.controller:ConfiguracaoEntidadeController
     * @dcontratos-corecription Controller da página configurações de entidade
     **/

    angular
           .module('core.configuracao-entidade')
           .controller('core.ConfiguracaoEntidadeController', ConfiguracaoEntidadeController);

    ConfiguracaoEntidadeController.$inject = [
        '$rootScope',
        '$q',
        '$scope',
        'core.TrackerFactory',
        'core.ConfiguracaoEntidadeService',
        'core.ConfiguracaoEntidadeModalService',
        'bfc.Notification'
    ];

    function ConfiguracaoEntidadeController(
            $rootScope,
            $q,
            $scope,
            TrackerFactory,
            ConfiguracaoEntidadeService,
            ConfiguracaoEntidadeModalService,
            Notification) {

        var viewModel = this;
        viewModel.service = ConfiguracaoEntidadeService;

        //Usado para teste Unitário
        viewModel.argumentos = ConfiguracaoEntidadeController.$inject;

        viewModel.cadastrar = cadastrar;
        viewModel.editar = editar;
        //viewModel.excluir = excluir;

        inicializa();

        function inicializa() {
            inicializaPropriedades();
            inicializaLoadings();
        }

        function inicializaPropriedades() {
            viewModel.entidade = {};
            viewModel.list = {};
            viewModel.filter = {};
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init(['entidade', 'getList']);
        }

        function cadastrar() {
            ConfiguracaoEntidadeModalService.cadastrar();
        }

        function editar(item) {
            ConfiguracaoEntidadeModalService.editar(item);
        }


    }
})();    
