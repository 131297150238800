(function() {
	'use strict';
	/**
	 * @ngdoc service 
	 * @name contratos-core.configuracao-entidade.service:ConfiguracaoEntidadeModalService
	 * @dcontratos-corecription Serviço para abrir a configuração de entidade
	 * @requircontratos-core ModalCadFactory
	 **/
	angular
			.module('core.configuracao-entidade')
			.service('core.ConfiguracaoEntidadeModalService', ConfiguracaoEntidadeModalService);

	ConfiguracaoEntidadeModalService.$inject = ['ui.components.Modal','core.AcoesCadastrosModalService'];

	function ConfiguracaoEntidadeModalService(uiModal,AcoesCadastrosModalService) {

		var modal = {
			templateUrl: 'configuracao-entidade-cad/configuracao-entidade-cad.html',
			controller: 'core.ConfiguracaoEntidadeCadController',
			controllerAs: 'vm',
			params: {
				identificador_cadastro: null
			},
			size: 'xl'
		};

		var service = {
			cadastrar: cadastrar,
			editar: editar,
			onAdd: onAdd
		};

		angular.extend(this, service);

		function abrir(modal) {
			 return AcoesCadastrosModalService.abrir(modal);
		}

		function cadastrar() {
			AcoesCadastrosModalService.cadastrar(modal);
		}

		function editar(obj) {
			AcoesCadastrosModalService.editar(modal,obj);
		}

		function onAdd(params){
			return AcoesCadastrosModalService.onAdd(modal,params);
		}

	}
})();