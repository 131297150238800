(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ctr.prazo-entrega.NivelOrganogramaSelect
   * @descripition
   * Serviço do select2 para tipo de entrega/execução do prazo de entrega
   * @requires $q
   * @requires bfc.Select2Config
   * @requires ParametrosEstoqueService
   * @requires UtilsService
   **/
  angular
    .module('core.organograma')
    .service('core.NivelOrganogramaSelect', NivelOrganogramaSelect);

  NivelOrganogramaSelect.$inject = [
    '$q',
    'bfc.Select2',
    'core.ParametrosService',
    'ParametrosEstoqueService',
    'core.UtilsService',
    'CONFIG_SELECT2'
  ];

  function NivelOrganogramaSelect($q,
                                  Select2,
                                  ParametrosService,
                                  ParametrosEstoqueService,
                                  UtilsService,
                                  CONFIG_SELECT2) {

    var select2 = this;

    this.select2Config = select2Config;

    function select2Config(options) {
      var methods = {
        formatResult: options.formatResult ? options.formatResult : formatResult,
        formatSelection: options.formatSelection ? options.formatSelection : formatSelection,
        formatValue: options.formatValue ? options.formatValue : formatValue,
        initSelection: options.initSelection ? options.initSelection : initSelection,
        result: _.debounce(options.result ? options.result : result, CONFIG_SELECT2.DELAY),
        maximumInputLength: CONFIG_SELECT2.MAXIMUMINPUT(),
        dropdownAutoWidth: false
      };

      function formatResult(niveis) {
        return niveis.nivel;
      }

      function formatSelection(niveis) {
        return niveis.nivel;
      }

      function formatValue(niveis) {
        return niveis;
      }

      function initSelection(element, callback) {
        var nivelOrganograma = {
          nivel: element.val()
        };
        callback(nivelOrganograma.nivel);
      }

      function result(params, callback) {
        var filter = '(nivel like "' + params.term + '")';

        ParametrosService.getConfiguracaoOrganograma().then(function (configuracaoOrganograma) {
          var result = ParametrosEstoqueService.getNiveis(filter, configuracaoOrganograma.id);
          result.then(function (niveis) {
            callback({
              results: niveis,
              more: niveis.hasNext
            });
          });
          return result;
        });
      }

      return Select2.create(angular.extend(methods, options));
    }
  }
})();
