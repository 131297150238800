(function () {
    'use strict';

        /**
     * @ngdoc service
     * @name contratos-core.fornecedor.service:ConsultaCnaeService
     * @description Serviço que se comunica com o back-end para consultar uma lista de atividades (CNAE)
     * @requires $q
     * @requires Restangular
     **/

    angular
        .module('core.fornecedor')
        .service('core.ConsultaCnaeService', ConsultaCnaeService);
    
    ConsultaCnaeService.$inject = [
        '$q',
        'Restangular'
    ];

    function ConsultaCnaeService($q, Restangular) {

        var uri = {
            CONSULTA_CNAE: 'cnaes',
            CONSULTA_CNAE_LOCAL: 'local'
        };

        var service = {
            getDadosCnae: getDadosCnae,
            getDadosCnaeLocal: getDadosCnaeLocal,
            getDadosCnaePorId: getDadosCnaePorId
        };

        _.extend(service, ConsultaCnaeService);

        service.getDadosCnae = getDadosCnae;
        service.getDadosCnaePorId = getDadosCnaePorId;
        service.getDadosCnaeLocal = getDadosCnaeLocal;

        return service;

        function getDadosCnae(params) {
            if(params.term){
                params.term = decodeURIComponent(params.term).split("%").slice(1,-1).join(" ");
                params.filter = 'tipoClassificacao = 4 and (descricao elike "%'+params.term+'%" or (id elike "%'+params.term+'%"))';
            } else {
                params.filter = 'tipoClassificacao = 4';
            }
            return Restangular.all(uri.CONSULTA_CNAE).get('', params);
        }

        function getDadosCnaePorId(params) {
            params.filter = 'tipoClassificacao = 4 and id in ('+params.term+')';
            return Restangular.all(uri.CONSULTA_CNAE).get('', params);
        }

        function getDadosCnaeLocal(params) {
            if(params.term){
                params.term = decodeURIComponent(params.term).split("%").slice(1,-1).join(" ");
                params.filter = 'descricao elike "%'+params.term+'%" or (id elike "%'+params.term+'%")';
            }
            return Restangular.all(uri.CONSULTA_CNAE+"/"+uri.CONSULTA_CNAE_LOCAL).get('', params);
        }
    }
})();
