(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.estado.controller:EstadoCadController
     * @description Controller do cadastro de estados
     **/
    angular
            .module('core.estado')
            .controller('core.EstadoCadController', EstadoCadController);

    EstadoCadController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'core.TrackerFactory',
        'core.EstadoService',
        'core.PaisService',
        'CONFIG_SELECT2',
        'core.Select2Factory',
        'core.EstadosSugestoesSelect'
    ];

    function EstadoCadController(
            $q,
            $rootScope,
            $scope,
            $modalInstance,
            Notification,
            TrackerFactory,
            EstadoService,
            PaisService,
            CONFIG_SELECT2,
            Select2Factory,
            EstadosSugestoesSelect
            ) {

        var viewModel = this;

        viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

        viewModel.argumentos = EstadoCadController.$inject;
        viewModel.salvar = salvar;
        viewModel.excluir = excluir;
        viewModel.desabilitaSalvar = desabilitaSalvar;
        viewModel.getAcao = getAcao;
        viewModel.temEstado = temEstado;

        viewModel.buscarPaises = buscarPaises;
        viewModel.formataResultado = formataResultado;
        viewModel.buscarEstados = buscarEstados;
        viewModel.editando = editando;
        viewModel.adicionando = adicionando;

        inicializaLoadings();
        inicializar();

        function inicializar() {
            inicializarEstado();
            inicializaEnums();
            initSelect2();
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([{
                element: 'nome',
                select2: EstadosSugestoesSelect
            }]);
        }

        function buscarEstados() {
            viewModel.estado = _.merge(viewModel.estado, viewModel.template);
            viewModel.estado.id = null;
        }

        function adicionando() {
            return !editando();
        }

        function editando() {
          return Boolean(viewModel.estado && !viewModel.estado.id) ? ($modalInstance.params.params || $modalInstance.params.estadoId) : viewModel.estado && viewModel.estado.id;
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'iniciaEstado',
                'salvar',
                'salvarAdicionar',
                'excluir'
            ]);
        }

        function buscarPaises(params) {
            params.filter = '(nome like "' + params.term + '")';
            return PaisService.getList(params).then(function (data) {
                return data;
            });
        }

        function formataResultado(value) {
            return value.nome;
        }

        function inicializarEstado() {
            var promise;
            if ($modalInstance.params.estadoId) {
                promise = EstadoService.get($modalInstance.params.estadoId);
                promise.then(function (estado) {
                    viewModel.estado = estado;
                });
            } else {
                promise = EstadoService.getDefault();
                promise.then(function (estadoDefault) {
                    viewModel.estado = estadoDefault;
                    viewModel.estado.definidaUsuario = true;

                    if ($modalInstance.params.params) {
                        viewModel.estado.nome = $modalInstance.params.params.nome;
                    }
                });
            }
            viewModel.loadings.iniciaEstado.addPromise(promise);
        }

        function excluir() {
          $rootScope.$broadcast('estado:actionRemove', { id: viewModel.estado.id });
          fechar();
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;
            tracker.addPromise(EstadoService.salvar(viewModel.estado).then(function (estado) {
                if (continuar) {
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                        viewModel.form.$setUntouched();
                    }
                    $modalInstance.params.estadoId = undefined;
                    inicializar();
                } else {
                    fechar(estado);
                }
                showNotificationSave();
            }));
        }

        function showNotificationSave() {
            if (temEstado()) {
                Notification.publish('Estado alterado com sucesso', 'success');
            } else {
                Notification.publish('Estado adicionado com sucesso', 'success');
            }
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
        }

        function fechar(estado) {
            $modalInstance.close(estado);
        }

        function getAcao() {
            return $modalInstance.params.estadoId ? "Editando" : "Adicionando";
        }

        function temEstado() {
            return $modalInstance.params.estadoId || (viewModel.estado && viewModel.estado.id);
        }

        function inicializaEnums() {
            viewModel.siglaEstado = EstadoService.getEnumSiglaEstado();
        }
    }
})();
