(function () {
    'use strict';
    /**
     * @ngdoc controller
     * @name contratos-core.loteamento.controller:LoteamentoCadController
     * @description Controller do cadastro de loteamentos
     **/
    angular
        .module('core.loteamento')
        .controller('core.LoteamentoCadController', LoteamentoCadController);

    LoteamentoCadController.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$modalInstance',
        'bfc.Notification',
        'core.TrackerFactory',
        'core.LoteamentoService',
        'core.BairroService',
        'core.BairroModalService',
        'core.DistritoService',
        'core.DistritoModalService',
        'core.MunicipioService',
        'core.MunicipioModalService',
        'CONFIG_SELECT2',
        'core.Select2Factory',
        'core.LoteamentosSugestoesSelect'
    ];

    function LoteamentoCadController($q,
                                     $rootScope,
                                     $scope,
                                     $modalInstance,
                                     Notification,
                                     TrackerFactory,
                                     LoteamentoService,
                                     BairroService,
                                     BairroModalService,
                                     DistritoService,
                                     DistritoModalService,
                                     MunicipioService,
                                     MunicipioModalService,
                                     CONFIG_SELECT2,
                                     Select2Factory,
                                     LoteamentosSugestoesSelect) {

        var viewModel = this;
        var carregandoDados = false;

        viewModel.CONFIG_SELECT2 = CONFIG_SELECT2;

        viewModel.argumentos = LoteamentoCadController.$inject;
        viewModel.salvar = salvar;
        viewModel.excluir = excluir;
        viewModel.desabilitaSalvar = desabilitaSalvar;
        viewModel.getAcao = getAcao;
        viewModel.temLoteamento = temLoteamento;

        viewModel.buscaLoteamento = buscaLoteamento;
        viewModel.buscarMunicipio = buscarMunicipio;
        viewModel.adicionarNovoMunicipio = adicionarNovoMunicipio;
        viewModel.buscarBairros = buscarBairros;
        viewModel.adicionarNovoBairro = adicionarNovoBairro;
        viewModel.buscarDistritos = buscarDistritos;
        viewModel.adicionarNovoDistrito = adicionarNovoDistrito;
        viewModel.formataResultado = formataResultado;
        viewModel.changeBairro = changeBairro;
        viewModel.changeDistrito = changeDistrito;

        viewModel.adicionando = adicionando;
        viewModel.editando = editando;
        viewModel.selecionaTemplate = selecionaTemplate;

        inicializaLoadings();
        inicializar();

        function inicializar() {
            if ($modalInstance.params.loteamentoId) {
                carregandoDados = true;
            }

            inicializarLoteamento();
            initSelect2();
        }

        function initSelect2() {
            viewModel.select2 = Select2Factory.init([{
                element: 'nome',
                select2: LoteamentosSugestoesSelect
            }]);
        }

        function inicializaLoadings() {
            viewModel.loadings = TrackerFactory.init([
                'iniciaLoteamento',
                'salvar',
                'salvarAdicionar',
                'excluir'
            ]);
        }

        function buscaLoteamento() {
            viewModel.loteamento = _.merge(viewModel.loteamento, viewModel.template);
            viewModel.loteamento.id = null;
        }

        function selecionaTemplate() {
            if (viewModel.template) {
                viewModel.loteamento.nome = viewModel.template.nome;
            }
        }


        function buscarMunicipio(params) {
            params.filter = '(nome like "' + params.term + '")';
            return MunicipioService.getList(params).then(function (data) {
                return data;
            });
        }

        function adicionarNovoMunicipio(nomeMunicipio) {
            var param = {
                nome: nomeMunicipio
            };

            return MunicipioModalService.onAdd(param);
        }

        function buscarBairros(params) {
            params.filter = '(nome like "' + params.term + '")';
            if (viewModel.loteamento.municipio) {
                params.filter = params.filter + ' and idMunicipio = ' + viewModel.loteamento.municipio.id;
            }
            return BairroService.getList(params).then(function (data) {
                return data;
            });
        }

        function adicionarNovoBairro(nomeBairro) {
            var param = {
                nome: nomeBairro
            };
            return BairroModalService.onAdd(param);
        }

        function buscarDistritos(params) {
            params.filter = '(nome like "' + params.term + '")';
            if (viewModel.loteamento.municipio) {
                params.filter = params.filter + ' and idMunicipio = ' + viewModel.loteamento.municipio.id;
            }
            return DistritoService.getList(params).then(function (data) {
                return data;
            });
        }

        function adicionarNovoDistrito(nomeDistrito) {
            var param = {
                nome: nomeDistrito,
                params: viewModel.loteamento
            };
            return DistritoModalService.onAdd(param);
        }

        function formataResultado(value) {
            return value.nome;
        }

        function inicializarLoteamento() {
            var promise;
            if ($modalInstance.params.loteamentoId) {
                promise = LoteamentoService.get($modalInstance.params.loteamentoId);
                promise.then(function (loteamento) {
                    viewModel.loteamento = loteamento;
                });
            } else {
                promise = LoteamentoService.getDefault();
                promise.then(function (loteamentoDefault) {
                    viewModel.loteamento = loteamentoDefault;

                    if ($modalInstance.params.params) {
                        viewModel.desabilitaCampo = !!$modalInstance.params.params.params;
                        viewModel.form.$setDirty();
                        viewModel.loteamento.nome = $modalInstance.params.params.nome;
                        if ($modalInstance.params.params.params) {
                            viewModel.loteamento.municipio = $modalInstance.params.params.params.municipio;
                            viewModel.loteamento.bairro = $modalInstance.params.params.params.bairro;
                            viewModel.loteamento.distrito = $modalInstance.params.params.params.distrito;
                        }
                    }
                });
            }
            viewModel.loadings.iniciaLoteamento.addPromise(promise);

        }

        function excluir() {
            $rootScope.$broadcast('loteamento:actionRemove', {id: viewModel.loteamento.id});
            fechar();
        }

        function salvar(continuar) {
            var tracker = continuar ? viewModel.loadings.salvarAdicionar : viewModel.loadings.salvar;
            tracker.addPromise(LoteamentoService.salvar(viewModel.loteamento).then(function (loteamento) {
                if (continuar) {
                    if (viewModel.form) {
                        viewModel.form.$setPristine();
                        viewModel.form.$setUntouched();
                    }
                    $modalInstance.params.loteamentoId = undefined;
                    inicializar();
                } else {
                    fechar(loteamento);
                }
                showNotificationSave();
            }));
        }

        function showNotificationSave() {
            if (temLoteamento()) {
                Notification.publish('Loteamento alterado com sucesso', 'success');
            } else {
                Notification.publish('Loteamento adicionado com sucesso', 'success');
            }
        }

        function desabilitaSalvar() {
            return (viewModel.form.$dirty && viewModel.form.$invalid) || viewModel.form.$pristine;
        }

        function fechar(loteamento) {
            $modalInstance.close(loteamento);
        }

        function getAcao() {
            return $modalInstance.params.loteamentoId ? 'Editando' : 'Adicionando';
        }

        function temLoteamento() {
            return $modalInstance.params.loteamentoId || (viewModel.loteamento && viewModel.loteamento.id);
        }

        $scope.$watch(
            'loteamentoCad.loteamento.municipio',
            function (newValue, oldValue) {
                if (newValue !== oldValue && !viewModel.desabilitaCampo) {
                    if (!carregandoDados) {
                        viewModel.loteamento.distrito = null;
                        viewModel.loteamento.bairro = null;
                    }
                    carregandoDados = false;
                }
            }
        );

        function changeDistrito() {
            if (!viewModel.loteamento.municipio && viewModel.loteamento.distrito) {
                carregandoDados = true;
                viewModel.loteamento.municipio = viewModel.loteamento.distrito.municipio;
            }
        }

        function changeBairro() {
            if (!viewModel.loteamento.municipio && viewModel.loteamento.bairro) {
                carregandoDados = true;
                viewModel.loteamento.municipio = viewModel.loteamento.bairro.municipio;
            }
        }

        function adicionando() {
            return !editando();
        }

        function editando() {
            return Boolean(viewModel.loteamento && !viewModel.loteamento.id) ?
                ($modalInstance.params.params || $modalInstance.params.loteamentoId) : viewModel.loteamento && viewModel.loteamento.id;
        }

    }
})();
