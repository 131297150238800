(function() {
    'use strict';

    /**
     * @ngdoc service
     * @name contratos-core.organograma.OrganogramaService
     * @description
     * Serviço que se comunica com o back-end de organogramas
     * @requires $q
     * @requires Restangular
     **/
    angular
            .module('core.natureza-juridica')
            .factory('core.NaturezaJuridicaService', NaturezaJuridicaService);

  NaturezaJuridicaService.$inject = [
        '$q',
        'Restangular',
        'ui.components.EventEmitter'
    ];

    function NaturezaJuridicaService(
            $q,
            Restangular,
            EventEmitter
            ) {
        var meta;
        var uri = ['fornecedores/naturezasjuridicas'];

        var service = new EventEmitter();
        _.extend(service, NaturezaJuridicaService);

        service.get = get;
        service.getList = getList;
        service.getDefault = getDefault;

        return service;

        function get(id) {
            return Restangular.one(uri[0], id).get();
        }

        function getList(params) {
            params = params || {};
            params.offset = params.offset || 0;
            return Restangular.one(uri[0])
                .get(params)
                .then(function (data) {
                    return data;
            });
        }

        function getMetadata() {
            var deferred = $q.defer();
            if (!meta) {
                Restangular.all(uri).options().then(function(data) {
                    meta = data;
                    deferred.resolve(meta);
                }, function(data) {
                    deferred.reject(data);
                });
            } else {
                deferred.resolve(meta);
            }
            return deferred.promise;
        }

        function getDefault() {
            return getMetadata().then(function (data) {
                return angular.copy(data.representation.default);
            });
        }
    }
})();
