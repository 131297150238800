(function() {
  'use strict';

  /**
   * @ngdoc service
   * @name contratos-core.tipo-ato.TipoAtoSugestoesSelect
   * @description
   * Serviço do select2 para o template de condominio
   * @requires bfc.Select2Config
   * @requires TipoAtoService
   **/

  angular
    .module('core.tipo-ato')
    .service('core.TipoAtoSugestoesSelect', TipoAtoSugestoesSelect);

  TipoAtoSugestoesSelect.$inject = [
    '$filter',
    'bfc.Select2',
    'core.TipoAtoService',
    'CONFIG_SELECT2'
  ];

  function TipoAtoSugestoesSelect($filter,
    Select2,
    TipoAtoService,
    CONFIG_SELECT2) {

    this.select2Config = select2Config;

    function select2Config(options) {
      var metodos = {
        formatResult: formatResult,
        formatSelection: formatSelection,
        formatValue: formatValue,
        initSelection: initSelection,
        result: _.debounce(result, CONFIG_SELECT2.DELAY),
        minimumInputLength: CONFIG_SELECT2.MINIMUMINPUT,
        maximumInputLength: 40,
        dropdownAutoWidth: false,
        onAdd: onAdd
      };

      function formatResult(data) {
        return getDescricaoExibicao(data);
      }

      function formatSelection(data) {
        return getDescricaoExibicao(data);
      }

      function formatValue(data) {
        return data;
      }

      function initSelection(element, callback) {
        var id = $(element).val();

        if (Number(id)) {
          TipoAtoService.get(id).then(callback);
        } else {
          var tipoAto = {
            descricao: element.val()
          };
          callback(tipoAto);
        }
      }

      function result(params, callback) {
        var result = TipoAtoService.getSugestoes({ filter: params.term });
        result.then(function(data) {
          callback({
            results: data,
            more: data.hasNext
          });
        });
        return result;
      }

      function getDescricaoExibicao(data) {
        if (data.text) {
          return data.text;
        }

        if (data) {
          return data.descricao;
        }
      }

      function onAdd(value, target) {
        Select2.set(target, value);
        return false;
      }

      return Select2.create(angular.extend(metodos, options));
    }
  }
})();
